import MockAdapter from 'axios-mock-adapter'
import mockAuth from '../../app/modules/Auth/__mocks__/mockAuth'
import mockStatus from '../../app/modules/Engine/__mocks__/mockStatus'

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 })

  mockAuth(mock)
  mockStatus(mock)

  return mock
}
