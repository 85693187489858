import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormControl, TextField, Select, InputLabel, OutlinedInput, MenuItem, FormHelperText } from '@material-ui/core'
import { saveDocument } from '../../redux/resourceManagementCrud'
import { Button } from 'react-bootstrap'
import { ACCEPTED_FILE_TYPES } from '../../../../../../../utils/Constants'
import { validateFileSize } from '../../../../../../../utils/FileUploadUtils'
import { env } from '../../../../../../../env'

const maxFilesSize = env.REACT_APP_PIN_ORDER_MAX_FILES_SIZE_IN_MB
const maxFileNameLength = env.REACT_APP_PIN_ORDER_MAX_FILE_NAME_LENGTH

const FormCreateDocument = ({
  intl,
  theme,
  documentTypeList,
  contentTypeList,
  locationList,
  cx,
  handleCloseCreateModal,
  generateAlert,
  reloadData
}) => {
  const [typeInput, setTypeInput] = useState('HTML')
  const fileInput = useRef()
  const INIT_CREATE_DOCUMENT = {
    documentId: '',
    description: '',
    code: '',
    documentTypeCode: documentTypeList[1].code,
    contentType: contentTypeList[0],
    content: '',
    locationCode: locationList[1].url,
    active: ''
  }

  const [fileChoose, setFileChoose] = useState('')
  const [fileInputError, setFileInputError] = useState('')

  const createDocumentSchema = Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    documentTypeCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    contentType: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    content: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const createDocumentFormik = useFormik({
    initialValues: INIT_CREATE_DOCUMENT,
    validationSchema: createDocumentSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      saveDocument(values, fileChoose)
        .then(res => {
          const { resultCode, resultDescription } = res.data
          if (resultCode === 0) {
            generateAlert('success', 'Create Document', resultDescription)
            handleCloseCreateModal()
            reloadData()
            resetForm()
          } else {
            generateAlert('danger', 'Create Document', resultDescription)
          }
        })
        .catch(({ message }) => {
          generateAlert('danger', 'Create Document', message.toString())
        })
        .finally(data => {
          setSubmitting(false)
        })
    }
  })

  const getInputClasses = fieldname => {
    if (createDocumentFormik.touched[fieldname] && createDocumentFormik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (createDocumentFormik.touched[fieldname] && !createDocumentFormik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const handleChangeDocumentType = e => {
    let documentType = e.target.value
    createDocumentFormik.setFieldValue('documentTypeCode', documentType)
  }

  const handleChangeFile = e => {
    let contentType = e.target.value
    createDocumentFormik.setFieldValue('contentType', contentType)
    createDocumentFormik.setFieldValue('content', '')
    setTypeInput(contentType)
  }

  const handleChangeLocation = e => {
    let location = e.target.value
    createDocumentFormik.setFieldValue('locationCode', location)
  }

  const handleCancel = () => {
    createDocumentFormik.resetForm()
    handleCloseCreateModal()
  }

  useEffect(() => {
    if (fileChoose != '') {
      createDocumentFormik.setFieldValue('content', fileChoose?.name)
    }
  }, [fileChoose])

  const handleFileChange = event => {
    if (!event.target.files) {
      return
    }
    setFileInputError('')
    const file = event.target.files[0]
    if (!validateFileSize(file.size, maxFilesSize)) {
      fileInput.current.value = null
      setFileInputError(`Max file size: ${maxFilesSize}mb`)
      return
    }
    if (file.name?.length > maxFileNameLength) {
      fileInput.current.value = null
      setFileInputError(`Max filename length: ${maxFileNameLength}`)
      return
    }
    setFileChoose(file)
  }

  const handleTestHiddenFileInput = () => {
    window.document.getElementById('selectedFile').click()
  }

  return (
    <form
      onSubmit={createDocumentFormik.handleSubmit}
      className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
    >
      <ul className={cx('create-document-content')}>
        <li className={cx('create-document-item')}>
          <FormControl sx={{ m: 2 }} className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Description"
              type="text"
              name="description"
              onChange={createDocumentFormik.handleChange}
              value={createDocumentFormik.values.description}
              onBlur={createDocumentFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              error={Boolean(createDocumentFormik.touched.description && createDocumentFormik.errors.description)}
              helperText={(createDocumentFormik.touched.description && createDocumentFormik.errors.description) || ''}
            />
          </FormControl>
        </li>

        <li className={cx('create-document-item')}>
          <FormControl sx={{ m: 2 }} className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Code"
              type="text"
              name="code"
              onChange={createDocumentFormik.handleChange}
              value={createDocumentFormik.values.code}
              onBlur={createDocumentFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              error={Boolean(createDocumentFormik.touched.code && createDocumentFormik.errors.code)}
              helperText={(createDocumentFormik.touched.code && createDocumentFormik.errors.code) || ''}
            />
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              Merchant Portal Resource
            </InputLabel>
            <Select
              value={createDocumentFormik.values.documentTypeCode}
              onChange={handleChangeDocumentType}
              onBlur={createDocumentFormik.handleBlur}
              input={<OutlinedInput labelWidth={170} name="documentTypeCode" id="documentTypeCode" />}
              inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              error={Boolean(
                createDocumentFormik.touched.documentTypeCode && createDocumentFormik.errors.documentTypeCode
              )}
            >
              {documentTypeList &&
                documentTypeList.map(item => {
                  if (item.code !== 'UNSET') {
                    return (
                      <MenuItem key={item.id} value={item.code}>
                        {item.description}
                      </MenuItem>
                    )
                  }
                })}
            </Select>
            <FormHelperText error>
              {(createDocumentFormik.touched.documentTypeCode && createDocumentFormik.errors.documentTypeCode) || ''}
            </FormHelperText>
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              File
            </InputLabel>
            <Select
              value={createDocumentFormik.values.contentType}
              onChange={handleChangeFile}
              onBlur={createDocumentFormik.handleBlur}
              input={<OutlinedInput labelWidth={25} name="contentType" id="contentType" />}
              inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              error={Boolean(createDocumentFormik.touched.contentType && createDocumentFormik.errors.contentType)}
            >
              {contentTypeList &&
                contentTypeList.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText error>
              {(createDocumentFormik.touched.contentType && createDocumentFormik.errors.contentType) || ''}
            </FormHelperText>
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl className={cx('width-100-percent')} variant="filled">
            {typeInput === 'HTML' ? (
              <TextField
                id="outlined-multiline-static"
                label="Content"
                multiline
                rows="6"
                name="content"
                onChange={createDocumentFormik.handleChange}
                value={createDocumentFormik.values.content}
                onBlur={createDocumentFormik.handleBlur}
                margin="normal"
                variant="outlined"
                size="small"
                error={Boolean(createDocumentFormik.touched.content && createDocumentFormik.errors.content)}
                helperText={(createDocumentFormik.touched.content && createDocumentFormik.errors.content) || ''}
              />
            ) : (
              <>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    disabled={createDocumentFormik.isSubmitting}
                    type="file"
                    accept={ACCEPTED_FILE_TYPES}
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('content')}`}
                    name="content"
                    ref={fileInput}
                    onChange={handleFileChange}
                    id="selectedFile"
                    style={{ display: 'none' }}
                  />
                </div>
                <div className={cx('actions')}>
                  <Button
                    className={cx('upload-btn')}
                    disabled={createDocumentFormik.isSubmitting}
                    variant="outline-primary"
                    size="lg"
                    onClick={() => handleTestHiddenFileInput()}
                  >
                    Attach File
                  </Button>
                  {fileChoose == '' ? 'No File Chosen' : fileChoose.name}
                </div>
                {fileInputError && <div className={cx('error-message')}>{fileInputError}</div>}
              </>
            )}
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              Location
            </InputLabel>
            <Select
              value={createDocumentFormik.values.locationCode}
              onChange={handleChangeLocation}
              onBlur={createDocumentFormik.handleBlur}
              input={<OutlinedInput labelWidth={60} name="locationCode" id="locationCode" />}
              inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              error={Boolean(createDocumentFormik.touched.locationCode && createDocumentFormik.errors.locationCode)}
            >
              {locationList &&
                locationList.map(item => {
                  return (
                    <MenuItem key={item.url} value={item.url}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText error>
              {(createDocumentFormik.touched.contentType && createDocumentFormik.errors.contentType) || ''}
            </FormHelperText>
          </FormControl>
        </li>
      </ul>
      <div className={cx('action-button')}>
        <button
          disabled={createDocumentFormik.isSubmitting}
          type="submit"
          className={cx(
            'button',
            'button-block',
            'btn',
            'btn-primary',
            'btn-lg',
            'submit-button',
            'btn-primary-custom'
          )}
        >
          <span>Create</span>
          {createDocumentFormik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <button
          type="button"
          onClick={handleCancel}
          className={cx(
            'button',
            'button-block',
            'btn-secondary',
            'btn',
            `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`,
            'btn-lg'
          )}
        >
          <span>Cancel</span>
        </button>
      </div>
    </form>
  )
}

export default FormCreateDocument
