import { put, call, takeLatest } from 'redux-saga/effects'
import { getResourceList } from './resoucesCrud'

export const actionTypes = {
  resourcesLoading: '[Resources] Loading',
  resourcesReceived: '[Resources] Received',
  resourcesFailed: '[Resources] Failed'
}

const initialState = {
  resources: {
    totalRecord: 0,
    data: [],
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resourcesLoading: {
      return {
        ...state,
        resources: {
          ...state.resources,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.resourcesReceived: {
      const payload = action.payload
      return {
        ...state,
        resources: {
          totalRecord: payload.totalRecord,
          data: payload.data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.resourcesFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        resources: {
          ...state.resources,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  resourcesLoading: params => ({
    type: actionTypes.resourcesLoading,
    payload: null,
    params
  }),
  resourcesReceived: payload => ({
    type: actionTypes.resourcesReceived,
    payload
  }),
  resourcesFailed: payload => ({
    type: actionTypes.resourcesFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.resourcesLoading, function* resourcesLoadingSaga(params) {
    try {
      const response = yield call(getResourceList, params)
      const { resultCode, resultDescription, totalRecord, data } = response.data
      if (resultCode === 0)
        yield put(
          actions.resourcesReceived({
            resultCode,
            resultDescription,
            data,
            totalRecord
          })
        )
      else yield put(actions.resourcesFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.resourcesFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
