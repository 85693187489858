/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { downloadResource } from '../redux/resoucesCrud'
import { exportFileData, convertArrayBufferToJson } from '../../../../utils/ExporterUtils'
import Spinner from 'react-bootstrap/Spinner'
import style from '../resources.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { isDownLoading, setIsDownLoading, downLoadIndex, setRowIndex, generateAlert, theme }
) => {
  return (
    <>
      <a
        id={'download-report-' + rowIndex}
        className={cx('btn', 'btn-icon', `btn-${theme === 'Dark' ? 'dark' : 'light'}`, 'btn-hover-danger', 'btn-sm')}
        onClick={() => {
          setRowIndex(rowIndex)
          setIsDownLoading(true)
          downloadResource(row?.id)
            .then(response => {
              let responseData = convertArrayBufferToJson(response.data)
              if (responseData && responseData?.resultCode !== 0) {
                generateAlert(
                  'danger',
                  'Download Document File',
                  responseData?.resultDescription || responseData?.error
                )
                return
              }
              exportFileData(response)
            })
            .catch(() => {})
            .finally(() => {
              setIsDownLoading(false)
            })
        }}
      >
        {downLoadIndex === rowIndex && isDownLoading ? (
          <div>
            {' '}
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <span className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Download.svg')} />
          </span>
        )}
      </a>
    </>
  )
}

export default ActionsColumnFormatter
