import React from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'
const WeeklyTransactionChart = ({ date, transactionCount, theme }) => {
  let state = {
    options: {
      chart: {
        id: 'weekly-transaction',
        type: 'bar',

        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: [...date],
        labels: {
          style: {
            colors: theme === 'Dark' && ['#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2']
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val
        },
        offsetY: -20,
        style: {
          fontWeight: 'bold',
          colors: theme === 'Dark' ? ['#bebfc2'] : ['#304758']
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: theme === 'Dark' && ['#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2', '#bebfc2']
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
        theme: theme === 'Dark' ? 'dark' : 'light',
        x: {
          formatter: function(value, { dataPointIndex, w }) {
            let date = w.config.series[0].data[dataPointIndex].x
            let dateFormat = moment(date).format('DD/MM/yyyy')
            return dateFormat
          }
        }
      }
    },
    series: [
      {
        name: 'Total',
        data: [...transactionCount]
      }
    ]
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Chart
        options={state.options}
        series={state.series}
        type={state.options?.chart.type}
        width={'100%'}
        height={280}
      />
    </div>
  )
}

export default WeeklyTransactionChart
