/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
import { downloadReportFile } from '../redux/SettlementEarningsCrud'
import { exportFileData } from '../../../../utils/ExporterUtils'
import Spinner from 'react-bootstrap/Spinner'
import style from '../settlement-earnings.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { isDownLoading, setIsDownLoading, downLoadIndex, setRowIndex, generateAlert, theme }
) => {
  return (
    <>
      <a
        id={'download-report-' + rowIndex}
        className={cx('btn', 'btn-icon', `btn-${theme === 'Dark' ? 'dark' : 'light'}`, 'btn-hover-danger', 'btn-sm')}
        onClick={() => {
          setRowIndex(rowIndex)
          setIsDownLoading(true)
          downloadReportFile(row.fileName)
            .then(response => {
              if (response.data && response.data.resultCode) {
                if (response.data.resultCode !== 0) {
                  generateAlert('danger', 'Download Settlement Earnings Report File', response.data.resultDescription)
                }
              } else {
                exportFileData(response)
              }
            })
            .catch(() => {})
            .finally(() => {
              setIsDownLoading(false)
            })
        }}
      >
        {downLoadIndex === rowIndex && isDownLoading ? (
          <div>
            {' '}
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <span className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Download.svg')} />
          </span>
        )}
      </a>
    </>
  )
}

export default ActionsColumnFormatter
