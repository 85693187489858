import React from 'react'
import { useState, createContext, useEffect } from 'react'
import { getCustomTemplateDetail } from '../../../redux/appCrud'
const TemplateContext = createContext()
const SUCCESS_CODE = 0
const TemplateProvider = ({ children }) => {
  const [customTemplateValue, setCustomTemplateValue] = useState(null)
  useEffect(() => {
    let merchantPortalTemplateInfo = {
      portalName: '',
      brandLogoLight: '',
      brandLogoDark: '',
      faviconLogoLight: '',
      faviconLogoDark: '',
      primaryColor: '',
      secondaryColor: ''
    }
    getCustomTemplateDetail()
      .then(res => {
        const {
          resultCode,
          portalName,
          brandLogoLight,
          brandLogoDark,
          faviconLogoLight,
          faviconLogoDark,
          primaryColor,
          secondaryColor
        } = res.data
        if (resultCode === SUCCESS_CODE) {
          merchantPortalTemplateInfo = {
            portalName,
            brandLogoLight,
            brandLogoDark,
            faviconLogoLight,
            faviconLogoDark,
            primaryColor,
            secondaryColor
          }
          setCustomTemplateValue(merchantPortalTemplateInfo)
        } else {
          setCustomTemplateValue(merchantPortalTemplateInfo)
        }
      })
      .catch(error => {
        setCustomTemplateValue(merchantPortalTemplateInfo)
      })
  }, [])

  return <TemplateContext.Provider value={customTemplateValue}>{children}</TemplateContext.Provider>
}

export { TemplateContext, TemplateProvider }
