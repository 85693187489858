import { put, call, takeLatest } from 'redux-saga/effects'
import { getTransactionList, getDailyTransactionStats, getWeeklyTransactionStats } from './DashboardCrud'

export const actionTypes = {
  dailyTransactionListLoading: '[dailyTransactionList] Loading',
  dailyTransactionListReceived: '[dailyTransactionList] Received',
  dailyTransactionListFailed: '[dailyTransactionList] Failed',
  weeklyTransactionListLoading: '[weeklyTransactionList] Loading',
  weeklyTransactionListReceived: '[weeklyTransactionList] Received',
  weeklyTransactionListFailed: '[weeklyTransactionList] Failed',
  top10TransactionLoading: '[top10Transaction] Loading',
  top10TransactionReceived: '[top10Transaction] Received',
  top10TransactionFailed: '[top10Transaction] Failed'
}

const initialDashboardState = {
  dailyTransactions: {
    data: null,
    error: null,
    isLoading: false
  },
  weeklyTransactions: {
    data: null,
    error: null,
    isLoading: false
  },
  top10Transactions: {
    data: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialDashboardState, action) => {
  switch (action.type) {
    //Daily transactions
    case actionTypes.dailyTransactionListLoading: {
      return {
        ...state,
        dailyTransactions: {
          ...state.dailyTransactions,
          isLoading: true
        }
      }
    }

    case actionTypes.dailyTransactionListReceived: {
      const data = action.payload
      return {
        ...state,
        dailyTransactions: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }

    case actionTypes.dailyTransactionListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        dailyTransactions: {
          ...state.dailyTransactions,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    //Weekly transactions
    case actionTypes.weeklyTransactionListLoading: {
      return {
        ...state,
        weeklyTransactions: {
          ...state.weeklyTransactions,
          isLoading: true
        }
      }
    }

    case actionTypes.weeklyTransactionListReceived: {
      const data = action.payload
      return {
        ...state,
        weeklyTransactions: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }

    case actionTypes.weeklyTransactionListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        weeklyTransactions: {
          ...state.weeklyTransactions,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    //Top 10 transactions
    case actionTypes.top10TransactionLoading: {
      return {
        ...state,
        top10Transactions: {
          ...state.top10Transactions,
          isLoading: true
        }
      }
    }

    case actionTypes.top10TransactionReceived: {
      const { data } = action.payload
      return {
        ...state,
        top10Transactions: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }

    case actionTypes.top10TransactionFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        top10Transactions: {
          ...state.top10Transactions,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    default:
      return state
  }
}

export const actions = {
  dailyTransactionListLoading: () => ({
    type: actionTypes.dailyTransactionListLoading,
    payload: null
  }),
  dailyTransactionListReceived: payload => ({
    type: actionTypes.dailyTransactionListReceived,
    payload
  }),
  dailyTransactionListFailed: payload => ({
    type: actionTypes.dailyTransactionListFailed,
    payload
  }),
  weeklyTransactionListLoading: () => ({
    type: actionTypes.weeklyTransactionListLoading,
    payload: null
  }),
  weeklyTransactionListReceived: payload => ({
    type: actionTypes.weeklyTransactionListReceived,
    payload
  }),
  weeklyTransactionListFailed: payload => ({
    type: actionTypes.weeklyTransactionListFailed,
    payload
  }),
  top10TransactionLoading: () => ({
    type: actionTypes.top10TransactionLoading,
    payload: null
  }),
  top10TransactionReceived: payload => ({
    type: actionTypes.top10TransactionReceived,
    payload
  }),
  top10TransactionFailed: payload => ({
    type: actionTypes.top10TransactionFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.dailyTransactionListLoading, function* dailyTransactionListSaga() {
    try {
      const response = yield call(getDailyTransactionStats)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.dailyTransactionListReceived(data))
      else yield put(actions.dailyTransactionListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.dailyTransactionListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
  yield takeLatest(actionTypes.weeklyTransactionListLoading, function* weeklyTransactionListSaga() {
    try {
      const response = yield call(getWeeklyTransactionStats)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.weeklyTransactionListReceived(data))
      else yield put(actions.weeklyTransactionListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.weeklyTransactionListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
