import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, call } from 'redux-saga/effects'
import { getUserByToken, logout, sendOtpToEmail, sendOtpToEmailForUnLockUser } from './authCrud'

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  UserLoadedFailed: '[Load User Failed] Auth API',
  ExpireLogout: '[Expire Logout] Action',
  ExpireLogoutRecieved: '[Expire Logout] Recieved',
  SendOtpToEmailLoading: '[Email Address Required] Loading',
  SendOtpToEmailReceived: '[Email Address Required] Received',
  SendOtpToEmailFailed: '[Email Address Required] Failed',
  SendUnlockCodeLoading: '[Send Unlock Code] Loading',
  SendUnlockCodeReceived: '[Send Unlock Code] Received',
  SendUnlockCodeFailed: '[Send Unlock Code] Failed'
}

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  message: null,
  error: '',
  isLoading: false,
  isUserLoading: false,
  emailConfirmation: {
    isConfirmEmailLoading: false,
    isSendConfirmEmailSuccess: false,
    email: null,
    error: '',
    otpExpiredTime: ''
  },
  verifyUnlock: {
    isVerifyUnlockLoading: false,
    isSendVerifyUnlockSuccess: false,
    email: null,
    error: '',
    otpExpiredTime: ''
  }
}

export const reducer = persistReducer(
  { storage, key: 'v706-demo1-auth', whitelist: ['authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload

        return { authToken, user: undefined }
      }

      case actionTypes.Register: {
        const { authToken } = action.payload

        return { authToken, user: undefined }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return { ...state, isUserLoading: true }
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user, isUserLoading: false }
      }

      case actionTypes.UserLoadedFailed: {
        return { ...state, isUserLoading: false }
      }

      case actionTypes.ExpireLogout: {
        return { ...state }
      }

      case actionTypes.ExpireLogoutRecieved: {
        const { message } = action.payload
        return { user: undefined, authToken: undefined, message: message, error: false }
      }
      case actionTypes.SendOtpToEmailLoading: {
        return {
          ...state,
          emailConfirmation: {
            isConfirmEmailLoading: true,
            isSendConfirmEmailSuccess: false,
            email: null,
            otpExpiredTime: '',
            error: ''
          }
        }
      }
      case actionTypes.SendOtpToEmailReceived: {
        const { email, otpExpiredTime } = action.payload
        return {
          ...state,
          emailConfirmation: {
            isConfirmEmailLoading: false,
            isSendConfirmEmailSuccess: true,
            email: email,
            otpExpiredTime: otpExpiredTime,
            error: ''
          }
        }
      }
      case actionTypes.SendOtpToEmailFailed: {
        const { resultDescription } = action.payload
        return {
          ...state,
          emailConfirmation: {
            isConfirmEmailLoading: false,
            isSendConfirmEmailSuccess: false,
            email: null,
            otpExpiredTime: '',
            error: resultDescription
          }
        }
      }
      case actionTypes.SendUnlockCodeLoading: {
        return {
          ...state,
          verifyUnlock: {
            isVerifyUnlockLoading: true,
            isSendVerifyUnlockSuccess: false,
            email: null,
            otpExpiredTime: '',
            error: ''
          }
        }
      }
      case actionTypes.SendUnlockCodeReceived: {
        const { email, otpExpiredTime } = action.payload
        return {
          ...state,
          verifyUnlock: {
            isVerifyUnlockLoading: false,
            isSendVerifyUnlockSuccess: true,
            email: email,
            otpExpiredTime: otpExpiredTime,
            error: ''
          }
        }
      }
      case actionTypes.SendUnlockCodeFailed: {
        const { resultDescription } = action.payload
        return {
          ...state,
          verifyUnlock: {
            isVerifyUnlockLoading: false,
            isSendVerifyUnlockSuccess: false,
            email: null,
            otpExpiredTime: '',
            error: resultDescription
          }
        }
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  requestUserFailed: () => ({ type: actionTypes.UserLoadedFailed }),
  expireLogout: () => ({ type: actionTypes.ExpireLogout }),
  expireLogoutRecieved: message => ({ type: actionTypes.ExpireLogoutRecieved, payload: message }),
  sendOtpToEmailLoading: params => ({
    type: actionTypes.SendOtpToEmailLoading,
    payload: null,
    params: params
  }),
  sendOtpToEmailReceived: payload => ({
    type: actionTypes.SendOtpToEmailReceived,
    payload
  }),
  sendOtpToEmailFailed: payload => ({
    type: actionTypes.SendOtpToEmailFailed,
    payload
  }),
  sendUnlockCodeLoading: params => ({
    type: actionTypes.SendUnlockCodeLoading,
    payload: null,
    params: params
  }),
  sendUnlockCodeReceived: payload => ({
    type: actionTypes.SendUnlockCodeReceived,
    payload
  }),
  sendUnlockCodeFailed: payload => ({
    type: actionTypes.SendUnlockCodeFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const { data: user } = yield call(getUserByToken)
      yield put(actions.fulfillUser(user))
    } catch (error) {
      yield put(actions.requestUserFailed())
    }
  })

  yield takeLatest(actionTypes.ExpireLogout, function* expireLogoutSaga() {
    try {
      yield call(logout)
    } catch (error) {
    } finally {
      yield put(actions.expireLogoutRecieved({ message: 'Your session has expired. Please login again' }))
    }
  })

  yield takeLatest(actionTypes.SendOtpToEmailLoading, function* sendOtpToEmailLoadingSaga({ params }) {
    try {
      const response = yield call(sendOtpToEmail, params)
      const { resultCode, resultDescription, otpExpiredTime } = response.data
      if (resultCode === 0) {
        const { email } = params
        localStorage.setItem('emailToConfirm', email)
        localStorage.setItem('otpExpiredTime', otpExpiredTime)
        yield put(actions.sendOtpToEmailReceived({ email, otpExpiredTime }))
      } else yield put(actions.sendOtpToEmailFailed({ resultDescription }))
    } catch (error) {
      yield put(
        actions.sendOtpToEmailFailed({
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.SendUnlockCodeLoading, function* sendUnlockCodeLoadingSaga({ params }) {
    try {
      const response = yield call(sendOtpToEmailForUnLockUser, params)
      const { resultCode, resultDescription, otpExpiredTime, email } = response.data
      if (resultCode === 0) {
        localStorage.setItem('emailToConfirm', email)
        localStorage.setItem('otpExpiredTime', otpExpiredTime)
        yield put(actions.sendUnlockCodeReceived({ email, otpExpiredTime }))
      } else yield put(actions.sendUnlockCodeFailed({ resultDescription }))
    } catch (error) {
      yield put(
        actions.sendUnlockCodeFailed({
          resultDescription: error.toString()
        })
      )
    }
  })
}
