import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { actions as actionAuth } from '../../modules/Auth/_redux/authRedux'
import { actions as actionsCompanyDetails } from '../companyDetails/redux/companyDetailsRedux'
import { actions as actionsTwoFactorAuth } from '../userProfile/redux/userProfileRedux'
import ProfileDetails from './components/ProfileDetails'
import ChangePassword from './components/ChangePassword'
import GoogleAuthenticator from './components/GoogleAuthenticator'
import Spinner from 'react-bootstrap/Spinner'
import {
  updateUserProfile,
  changeUserPassword,
  turnOnTwoFactorAuth,
  turnOffTwoFactorAuth
} from './redux/userProfileCrud'
import style from './userProfile.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import { AlertList } from 'react-bs-notifier'
import { CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE } from '../../../utils/Constants'

const UserProfile = ({
  logout,
  profile,
  requestUser,
  fulfillUser,
  countryList,
  countryListLoading,
  stateList,
  stateListLoading,
  twoFactorAuth,
  twoFactorAuthLoading,
  intl
}) => {
  const [profileData, setProfileData] = useState(profile)
  const [countryListData, setCountryListData] = useState()
  const [stateListData, setStateListData] = useState()
  const [alerts, setAlerts] = useState([])
  const [twoFactorAuthData, setTwoFactorAuthData] = useState()
  const [isSaving, setIsSaving] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const ALERT_TIMEOUT = 15000

  useEffect(() => {
    requestUser()
    return () => formikProfileDetails.setValues(initialValueProfileDetails)
  }, [])

  useEffect(() => {
    setProfileData(profile)
  }, [profile?.user])

  useEffect(() => {
    twoFactorAuthLoading()
  }, [])

  useEffect(() => {
    setTwoFactorAuthData(twoFactorAuth)
  }, [twoFactorAuth])

  const initialValueProfileDetails = {
    firstName: profileData?.user?.firstName ?? '',
    surname: profileData?.user?.surname ?? '',
    emailAddress: profileData?.user?.emailAddress ?? '',
    logo: profileData?.user?.logo ?? '',
    phoneNumber: profileData?.user?.phoneNumber ?? '',
    addressLine1: profileData?.user?.addressLine1 ?? '',
    addressLine2: profileData?.user?.addressLine2 ?? '',
    position: profileData?.user?.position ?? '',
    zipCode: profileData?.user?.zipCode ?? '',
    countryCode: profileData?.user?.countryCode ?? '',
    stateCode: profileData?.user?.stateCode ?? ''
  }

  const profileDetailsSchema = Yup.object().shape({
    emailAddress: Yup.string().email('Wrong email format'),
    firstName: Yup.string()
      .max(
        64,
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD'
          },
          { length: '64 characters' }
        )
      )
      .trim()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    surname: Yup.string()
      .max(
        64,
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD'
          },
          { length: '64 characters' }
        )
      )
      .trim()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^(\+|\d)[0-9]{3,20}$/, intl.formatMessage({ id: 'INVALID.PHONE.NUMBER' }))
  })

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const formikProfileDetails = useFormik({
    initialValues: initialValueProfileDetails,
    enableReinitialize: true,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, { setSubmitting, setValues }) => {
      updateUserProfile(values)
        .then(response => {
          setSubmitting(false)
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Update User Profile', resultDescription)
            fulfillUser({ ...profile?.user, ...values })
            setValues(formikProfileDetails.initialValues)
            setIsEdit(false)
          } else {
            generateAlert('danger', 'Update User Profile', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', 'Update User Profile', error.error || error.toString())
          setSubmitting(false)
        })
    }
  })

  const handleClickEdit = () => {
    setIsEdit(true)
  }

  const handleClickCancel = () => {
    setIsEdit(false)
    formikProfileDetails.setValues(initialValueProfileDetails)
  }

  const initialValueChangePassword = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    newPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*^\S*$)(?=.*[!@#&()?[\{\}\]\:\;\'\,\?\/\*\~$^\+\=\<\>]).{8,50}$/,
        'Password must be 8-50 characters long and contain at least one upper case letter, one lower case letter, one numeric character, and one special character with no spaces.'
      ),
    confirmNewPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .oneOf([Yup.ref('newPassword'), null], "Confirm new password doesn't match")
  })

  const formikChangePassword = useFormik({
    initialValues: initialValueChangePassword,
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      changeUserPassword(values)
        .then(response => {
          setSubmitting(false)
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Change Password', resultDescription)
            resetForm()
            window.localStorage.setItem(
              CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE,
              intl.formatMessage({
                id: 'CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE'
              })
            )
            logout()
          } else {
            generateAlert('danger', 'Change Password', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', 'Change Password', error.error || error.toString())
          setSubmitting(false)
        })
    }
  })

  const handleClickChangeStatus = password => {
    setIsSaving(true)
    if (twoFactorAuthData?.data?.status) {
      turnOffTwoFactorAuth({ password })
        .then(response => {
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Disable Two-factor Authenticator', resultDescription)
          } else {
            generateAlert('danger', 'Enable Two-factor Authenticator', resultDescription)
          }
          twoFactorAuthLoading()
          setIsSaving(false)
        })
        .catch(error => {
          generateAlert('danger', 'Disable Two-factor Authenticator', error.error || error.toString())
          setIsSaving(false)
        })
    } else {
      turnOnTwoFactorAuth()
        .then(response => {
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Enable Two-factor Authenticator', resultDescription)
          } else {
            generateAlert('danger', 'Enable Two-factor Authenticator', resultDescription)
          }
          twoFactorAuthLoading()
          setIsSaving(false)
        })
        .catch(error => {
          generateAlert('danger', 'Enable Two-factor Authenticator', error.error || error.toString())
          setIsSaving(false)
        })
    }
  }

  useEffect(() => {
    countryListLoading()
  }, [])

  useEffect(() => {
    setCountryListData(countryList)
  }, [countryList])

  useEffect(() => {
    setStateListData(stateList)
  }, [stateList])

  useEffect(() => {
    stateListLoading(formikProfileDetails.values.countryCode)
  }, [formikProfileDetails.values.countryCode])

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  return (
    <div className={cx('user-profile-content')}>
      <AlertList
        position={'top-right'}
        alerts={alerts}
        timeout={ALERT_TIMEOUT}
        dismissTitle="Begone!"
        onDismiss={onDismissed}
      />
      <div className={cx('child-content', 'wrapper-element')}>
        <div className={cx('header-child-content', theme === 'Light' ? 'header-child-content-light' : '')}>
          <p>Profile Details</p>
        </div>

        <ProfileDetails
          data={profileData?.user}
          countryList={countryListData}
          stateList={stateListData}
          stateListLoading={stateList.isLoading}
          formik={formikProfileDetails}
          initialValueProfileDetails={initialValueProfileDetails}
          isEdit={isEdit}
          handleClickEdit={handleClickEdit}
          handleClickCancel={handleClickCancel}
          theme={theme}
        />
      </div>
      <div className={cx('child-content', 'display-flex', 'flex-direction-column')}>
        <div
          className="wrapper-element"
          style={{
            marginBottom: '20px',
            padding: '20px',
            paddingLeft: '10%',
            paddingRight: '10%'
          }}
        >
          <div className={cx('header-child-content', theme === 'Light' ? 'header-child-content-light' : '')}>
            <p>Change Password</p>
          </div>
          <ChangePassword formik={formikChangePassword} theme={theme} />
        </div>
        <div
          className="wrapper-element"
          style={{
            padding: '20px',
            paddingLeft: '10%',
            paddingRight: '10%',
            height: '100%'
          }}
        >
          <div className={cx('header-child-content', theme === 'Light' ? 'header-child-content-light' : '')}>
            <p>Google Authenticator</p>
          </div>
          {twoFactorAuth.isLoading ? (
            <div className={cx('display-flex', 'justify-content-center', 'align-item-baseline')}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <GoogleAuthenticator
              data={twoFactorAuthData}
              handleClickChangeStatus={handleClickChangeStatus}
              isSaving={isSaving}
              theme={theme}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  profile: state.auth,
  countryList: state.companyDetails.countryList,
  stateList: state.companyDetails.stateList,
  twoFactorAuth: state.userProfile.twoFactorAuth
})

export default injectIntl(
  connect(mapStateToProps, { ...actionsCompanyDetails, ...actionsTwoFactorAuth, ...actionAuth })(UserProfile)
)
