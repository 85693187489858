import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormControl, InputAdornment, TextField } from '@material-ui/core'
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers'
import SVG from 'react-inlinesvg'
import style from '../../pinOrder.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import { makeStyles } from '@material-ui/core/styles'
import getSymbolFromCurrency from 'currency-symbol-map'
import { downloadPinBatchOrderDocument } from '../../redux/pinOrderCrud'
import Spinner from 'react-bootstrap/Spinner'
import { exportFileData, convertArrayBufferToJson } from '../../../../../utils/ExporterUtils'

const useStyles = makeStyles({
  input: {
    margin: '5px'
  }
})
const PinOrderDetailsDialog = ({ isOpenDetailsModal, setIsOpenDetailsModal, data, generateAlert, theme }) => {
  const [detailsData, setDetailsData] = useState({})
  const [totalValue, setTotalValue] = useState('0')
  const [isDownLoading, setIsDownLoading] = useState(false)
  useEffect(() => {
    setDetailsData(data?.data?.data)
    const value = data?.data?.data?.value
    const quantity = data?.data?.data?.qty
    setTotalValue(getSymbolFromCurrency(data?.data?.data?.currency) + ' ' + value * quantity)
  }, [data])
  const classes = useStyles()

  const handleDownloadOrderDocument = () => {
    setIsDownLoading(true)
    downloadPinBatchOrderDocument({ orderRef: detailsData?.orderRef })
      .then(response => {
        let responseData = convertArrayBufferToJson(response.data)
        if (responseData && responseData?.resultCode !== 0) {
          generateAlert(
            'danger',
            'Download Pin Batch Order Document',
            responseData?.resultDescription || responseData?.error
          )
          return
        }
        exportFileData(response)
      })
      .catch(error => generateAlert('danger', 'Download Pin Batch Order Document', error.message))
      .finally(() => {
        setIsDownLoading(false)
      })
  }
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpenDetailsModal}
        onHide={() => setIsOpenDetailsModal(false)}
      >
        <Modal.Header className="modal-header-custom">
          <Modal.Title id="example-modal-sizes-title-lg">Pin Batch Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx('modal-body', 'modal-body-custom')}>
          <div className={cx('detail-order-container')}>
            <ul className={cx('detail-order-item')}>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Order Ref"
                    type="text"
                    inputProps={{ className: classes.input }}
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.orderRef}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="File name"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="fileName"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.fileName}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Date"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="date"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.date}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Product"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="product"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.product}`}
                    size="medium"
                  />
                </FormControl>
              </li>

              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Currency"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="currency"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.currency}`}
                    size="medium"
                  />
                </FormControl>
              </li>
            </ul>
            <ul className={cx('detail-order-item')}>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Quantity Requested"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="quantity"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.qty}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Total Value"
                    type="text"
                    inputProps={{
                      className: classes.input,
                      style: { color: 'green', fontWeight: 'bold' }
                    }}
                    name="value"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${totalValue}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Status"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="status"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.status}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              <li>
                <FormControl variant="filled" className={cx('width-100-percent')}>
                  <TextField
                    label="Comment"
                    type="text"
                    inputProps={{ className: classes.input }}
                    name="comment"
                    margin="normal"
                    variant="outlined"
                    disabled
                    value={`${detailsData?.comment === null ? '' : detailsData?.comment}`}
                    size="medium"
                  />
                </FormControl>
              </li>
              {detailsData?.documents && detailsData?.documents.length > 0 && (
                <li>
                  <FormControl variant="filled" className={cx('width-100-percent')}>
                    <TextField
                      label="Document"
                      type="text"
                      inputProps={{
                        className: classes.input
                      }}
                      name="document"
                      margin="normal"
                      variant="outlined"
                      disabled
                      value={`${detailsData?.documents === null ? '' : detailsData?.documents}`}
                      size="medium"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {isDownLoading ? (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <a
                                className={cx(
                                  'btn',
                                  'btn-icon',
                                  `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
                                  'btn-hover-danger',
                                  'btn-sm'
                                )}
                                onClick={handleDownloadOrderDocument}
                              >
                                <span
                                  className={cx(
                                    'svg-icon svg-icon-md',
                                    theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white'
                                  )}
                                >
                                  <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Download.svg')} />
                                </span>
                              </a>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </li>
              )}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={cx('btn', 'btn-lg', 'btn-primary', 'btn-primary-custom')}
            onClick={() => setIsOpenDetailsModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PinOrderDetailsDialog
