import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { actions } from './redux/resourcesRedux'
import { AlertList } from 'react-bs-notifier'
import { CardBody } from '../../../_metronic/_partials/controls'
import ResourcesTable from './components/ResourcesTable'
import style from './resources.module.css'
import classNames from 'classnames/bind'
import { useSelector } from 'react-redux'
const cx = classNames.bind(style)

const ALERT_TIMEOUT = 15000
const Resources = ({ resourceList, resourcesLoading, resourcesReceived }) => {
  const [searchFilter, setSearchFilter] = useState({ page: 'resources', pageIndex: 1, pageSize: 10 })
  useEffect(() => {
    resourcesLoading(searchFilter)
    return () => {
      resourcesReceived({ data: [] })
    }
  }, [searchFilter])

  const { theme } = useSelector(state => ({
    theme: state.themeMode.themeMode.theme
  }))

  const [alerts, setAlerts] = useState([])
  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  return (
    <>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>
      <CardBody className={cx('wrapper', theme === 'Dark' && 'wrapper-dark')}>
        <ResourcesTable
          resourceList={resourceList}
          theme={theme}
          generateAlert={generateAlert}
          setSearchFilter={setSearchFilter}
          filter={searchFilter}
        />
      </CardBody>
    </>
  )
}

const mapStateToProps = state => ({
  resourceList: state.resources.resources
})

export default connect(mapStateToProps, actions)(Resources)
