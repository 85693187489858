import React from 'react'

function PermissionDeniedMessage({ roleDescription, intl }) {
  return (
    <div className={`card card-custom gutter-b text-white bg-danger card-user-status-shadow`}>
      <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
        <div className="mr-2">
          <i className={`fas fa-user-lock text-white icon-2x`}></i>
          <h3 className="font-weight-bolder mt-3">{intl.formatMessage({ id: 'MESSAGE.ERROR.PERMISSION_DENIED' })}</h3>
          <div className="font-size-lg mt-2">
            {intl.formatMessage({ id: 'MESSAGE.ERROR.DETAIL.PERMISSION_DENIED' }, { roleDescription })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PermissionDeniedMessage
