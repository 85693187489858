export function exportFileData(response) {
  const customType = response.headers['custom-content-type']
  let type = response.headers['content-type']
  if (customType) {
    type = customType
  }

  const url = window.URL.createObjectURL(new Blob([response.data], { type: type }))
  const link = document.createElement('a')

  link.href = url
  let fileName = ''
  if (response?.fileName) {
    fileName = response?.fileName
  } else if (response.headers['content-disposition']) {
    fileName = response.headers['content-disposition'].split('fileName=')[1]
  } else if (response.headers['Content-Disposition']) {
    fileName = response.headers['Content-Disposition'].split('fileName=')[1]
  }

  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    document.body.removeChild(link)
  }, 0)
}

export function convertArrayBufferToJson(data) {
  try {
    let textDecoder = new TextDecoder('utf-8')
    let uint8Array = new Uint8Array(data)
    return JSON.parse(textDecoder.decode(uint8Array))
  } catch (ex) {
    return null
  }
}
