import React from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import classNames from 'classnames/bind'
import style from '../../../../reports.module.css'
import { refundTransaction } from '../../../../redux/reportsCrud'
const cx = classNames.bind(style)

export default function TransactionRefundDialog({
  trans,
  show,
  onHide,
  generateAlert,
  setIsModalOpen,
  transactionSearchLoading,
  searchFilters
}) {
  const initialTransValue = {
    ...trans,
    comments: ''
  }
  const formik = useFormik({
    initialValues: initialTransValue,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      refundTransaction(values.refId, values.voucherSerial, values.comments)
        .then(response => {
          setSubmitting(false)
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Refund Transaction', resultDescription)
            setIsModalOpen(false)
            transactionSearchLoading(searchFilters)
          } else {
            generateAlert('danger', 'Refund Transaction', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', 'Refund Transaction', error.error)
          setSubmitting(false)
        })
    }
  })

  return (
    <Modal size="lg" show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Refund Transaction Reference: {trans.refId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          <ul className={cx('wrapper')}>
            <li>
              <label>Date</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="date"
                  {...formik.getFieldProps('date')}
                />
              </div>
            </li>
            <li>
              <label>Merchant</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="agentDescription"
                  maxLength="8"
                  {...formik.getFieldProps('agentDescription')}
                />
              </div>
            </li>
            <li>
              <label>Terminal</label>
              <div className={cx('input-group')}>
                <input
                  placeholder="Terminal"
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="terminalCode"
                  {...formik.getFieldProps('terminalCode')}
                />
              </div>
            </li>
            <li>
              <label>Result Description</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="resultDesc"
                  {...formik.getFieldProps('resultDesc')}
                />
              </div>
            </li>
            <li>
              <label>Status</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="status"
                  {...formik.getFieldProps('status')}
                />
              </div>
            </li>
            <li>
              <label>Product</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="product"
                  {...formik.getFieldProps('product')}
                />
              </div>
            </li>
            <li>
              <label>Serial Number</label>
              <div className={cx('input-group')}>
                <input
                  placeholder="Serial Number"
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="voucherSerial"
                  {...formik.getFieldProps('voucherSerial')}
                />
              </div>
            </li>
            <li>
              <label>Gross Value</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="value"
                  {...formik.getFieldProps('value')}
                />
              </div>
            </li>
            <li>
              <label>Net Value</label>
              <div className={cx('input-group')}>
                <input
                  disabled={true}
                  type="text"
                  className={`form-control py-2 px-1 `}
                  name="buyValue"
                  {...formik.getFieldProps('buyValue')}
                />
              </div>
            </li>
            <li>
              <label>Comment</label>
              <div className={cx('input-group')}>
                <textarea className={`form-control py-2 px-1 `} name="comments" {...formik.getFieldProps('comments')} />
              </div>
            </li>
          </ul>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className={cx('modal-footer-actions')}>
          <button onClick={onHide} style={{ marginRight: '5px' }} className={cx('button', 'btn', 'btn-primary')}>
            CANCEL
          </button>
          <button
            type="submit"
            onClick={formik.handleSubmit}
            style={{ marginRight: '5px' }}
            className={cx('button', 'btn', 'btn-primary')}
          >
            REFUND
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
