import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import { Pagination } from '../../../../../../_metronic/_partials/controls'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import style from '../../../reports.module.css'
import classNames from 'classnames/bind'
import { injectIntl, useIntl } from 'react-intl'
const cx = classNames.bind(style)
import Spinner from 'react-bootstrap/Spinner'
import { ActionList } from './action-list/ActionList'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../../../../../_metronic/_helpers'
import * as columnFormatters from './column-formatters'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { currencyFormat } from '../../../../../../utils/DataFormatUtils'
import getSymbolFromCurrency from 'currency-symbol-map'
const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
]
const defaultSorted = [{ dataField: 'refId', order: 'asc' }]

const ReportTable = ({
  openRefundModal,
  rightToRefund,
  clearTransactionReport,
  transactions,
  isLoading,
  filters,
  updateFilters,
  generateAlert,
  setIsPaging,
  theme,
  callBackExporting,
  forceLogout
}) => {
  const intl = useIntl()
  const INIT_DATA = { totalCount: 0, count: 0, entities: [], listLoading: false }
  const columnName = {
    agentDescription: intl.formatMessage({ id: 'TRANSACTION.LABEL.MERCHANT' }),
    refId: intl.formatMessage({ id: 'TRANSACTION.LABEL.REF_ID' }),
    txnId: intl.formatMessage({ id: 'TRANSACTION.LABEL.EXTERNAL_REF_ID' }),
    txType: intl.formatMessage({ id: 'TRANSACTION.LABEL.TYPE' }),
    date: intl.formatMessage({ id: 'TRANSACTION.LABEL.DATE' }),
    status: intl.formatMessage({ id: 'TRANSACTION.LABEL.STATUS' }),
    resultDesc: intl.formatMessage({ id: 'TRANSACTION.LABEL.RESULT_DESCRIPTION' }),
    terminalCode: intl.formatMessage({ id: 'TRANSACTION.LABEL.TERMINAL' }),
    product: intl.formatMessage({ id: 'TRANSACTION.LABEL.PRODUCT' }),
    voucherSerial: intl.formatMessage({ id: 'TRANSACTION.LABEL.VOUCHER_SERIAL' }),
    value: intl.formatMessage({ id: 'TRANSACTION.LABEL.GROSS_VALUE' }),
    buyValue: intl.formatMessage({ id: 'TRANSACTION.LABEL.NET_VALUE' }),
    returnDate: intl.formatMessage({ id: 'TRANSACTION.LABEL.REFUND_DATE' }),
    agentCode: intl.formatMessage({ id: 'TRANSACTION.LABEL.AGENT_CODE' }),
    reason: intl.formatMessage({ id: 'TRANSACTION.LABEL.REASON' }),
    redeemedDate: intl.formatMessage({ id: 'TRANSACTION.LABEL.REDEEMED_DATE' })
  }
  const INIT_HIDDEN_COLUMNS_TRANSACTION = {
    agentDescription: true,
    refId: true,
    txnId: true,
    txType: true,
    date: true,
    status: true,
    terminalCode: true,
    resultDesc: true,
    product: true,
    voucherSerial: true,
    value: true,
    buyValue: true,
    reason: true,
    returnDate: true,
    agentCode: true,
    redeemedDate: true,
    action: true
  }

  const INIT_COLUMNS_TRANSACTION = [
    {
      dataField: 'agentDescription',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.MERCHANT' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'refId',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.REF_ID' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'txnId',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.EXTERNAL_REF_ID' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'txType',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.TYPE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'date',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.DATE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'status',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.STATUS' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      },
      formatter: columnFormatters.ColorColumnFormatter
    },
    {
      dataField: 'terminalCode',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.TERMINAL' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'resultDesc',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.RESULT_DESCRIPTION' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'product',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.PRODUCT' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'voucherSerial',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.VOUCHER_SERIAL' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'value',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.GROSS_VALUE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      },
      formatter: columnFormatters.PriceColumnFormatter
    },
    {
      dataField: 'buyValue',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.NET_VALUE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      },
      formatter: columnFormatters.PriceColumnFormatter
    },
    {
      dataField: 'redeemedDate',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.REDEEMED_DATE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'agentCode',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.AGENT_CODE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'reason',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.REASON' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'returnDate',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.REFUND_DATE' }),
      sort: false,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'action',
      text: intl.formatMessage({ id: 'TRANSACTION.LABEL.ACTIONS' }),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openRefundTransactionDialog: openRefundModal,
        theme: theme
      },
      align: 'center',
      hidden: false,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    }
  ]

  const [data, setData] = useState(INIT_DATA)
  const [isExporting, setIsExporting] = useState(false)
  const [toogle, setToogle] = useState(INIT_HIDDEN_COLUMNS_TRANSACTION)
  const [isShowExpandColumn, setIsShowExpandColumn] = useState(false)
  const [tableColumns, setTableColumns] = useState(INIT_COLUMNS_TRANSACTION)
  const { totalCount, count, entities, listLoading, maxTransactionCount, exportSize } = data
  let arrayContent = []

  useEffect(() => {
    if (transactions?.error) {
      generateAlert('danger', intl.formatMessage({ id: 'TRANSACTION.INVALID.TRANSACTIONS_REPORT' }), transactions.error)
      clearTransactionReport()
    }
  }, [transactions?.error])

  const customTotal = (from, to, size) => {
    let infoCallBack = 'Showing ' + from + ' to ' + to
    let exportShowing = null
    if (exportSize) {
      exportShowing = 'Export available up to ' + exportSize + ' records'
    }
    if (maxTransactionCount && size > 0) {
      if (totalCount <= maxTransactionCount) {
        infoCallBack += ' of ' + totalCount + ' entries'
      } else {
        infoCallBack += ', limit up to ' + maxTransactionCount + ' of ' + totalCount + ' records'
      }
    } else {
      infoCallBack += ' of ' + size + ' entries'
    }
    return (
      <span className="react-bootstrap-table-pagination-total">
        {infoCallBack} <br />
        {exportShowing ? exportShowing : ''}
      </span>
    )
  }

  useEffect(() => {
    if (tableColumns) {
      const updatedColumns = tableColumns
      updatedColumns.map(column => {
        if (column.dataField === 'action') {
          column.formatExtraData = {
            ...column.formatExtraData,
            allowRefund: rightToRefund
          }
        }
      })
      setTableColumns(updatedColumns)
    }
  }, [rightToRefund])

  useEffect(() => {
    let isExpand = false
    const columns = tableColumns
    for (const item in toogle) {
      columns.forEach(column => {
        if (column.dataField === item) {
          column.hidden = !toogle[item]
        }
      })
      if (toogle[item] === false) {
        isExpand = true
      }
    }
    const newState = Object.assign([], columns)
    setIsShowExpandColumn(isExpand)
    setTableColumns(newState)
  }, [toogle])

  const expandRow = {
    expanded: [],
    renderer: row => {
      for (const item in toogle) {
        if (toogle[item] === false) {
          const checkContain = arrayContent.find(({ key }) => {
            if (key === item) {
              return true
            } else {
              return false
            }
          })
          if (!checkContain) {
            let column = columnName[item]
            arrayContent.push({ column: column, key: item, columnKey: item })
          }
        } else {
          const checkContain = arrayContent.filter(({ key }) => {
            return key !== item
          })
          arrayContent = checkContain
        }
      }
      if (arrayContent.length > 0) {
        return (
          <ul style={{ listStyle: 'none', marginLeft: '25px' }}>
            {arrayContent.map((item, index) => {
              return (
                <li style={{ padding: '15px 5px' }} key={index}>
                  <span>
                    <strong>{item.column}</strong>
                  </span>
                  :{' '}
                  <span>
                    {item.columnKey === 'value' || item.columnKey === 'buyValue' ? (
                      <>
                        {getSymbolFromCurrency(row.currencyCode)}
                        {currencyFormat(row[item.key])}
                      </>
                    ) : (
                      <>{row[item.key]}</>
                    )}
                  </span>
                </li>
              )
            })}
          </ul>
        )
      }
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    showExpandColumn: isShowExpandColumn,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return ''
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <button className={cx('not-expand')} type="button">
            <span>-</span>
          </button>
        )
      }
      return (
        <button className={cx('expand')} type="button">
          <span>+</span>
        </button>
      )
    }
  }

  const paginationOptions = {
    custom: true,
    totalSize: count,
    sizePerPageList: sizePerPageList,
    sizePerPage: filters.pageSize,
    page: filters.pageIndex,
    paginationTotalRenderer: customTotal
  }

  useEffect(() => {
    let data = transactions.data
    let count = 0
    let transList = []

    if (data?.data && data?.data.length) {
      transList = data.data
      count = data?.maxTransactionCount ? data.maxTransactionCount : data.totalRecord
      for (let i = 0; i < transList.length; i++) {
        transList[i].id = i + 1
      }
    }

    setData({
      count: count,
      totalCount: data?.totalRecord,
      entities: transList,
      listLoading: isLoading,
      maxTransactionCount: data?.maxTransactionCount,
      exportSize: data?.exportSize
    })
  }, [transactions.isLoading, transactions?.data])

  const ToggleList = ({ columns, onColumnToggle, toggles }) => {
    return (
      <ul className={cx('list-button-content')}>
        {columns
          .map(column => ({
            ...column,
            toggle: toggles[column.dataField]
          }))
          .map((column, index) => {
            return (
              <li className="list-button-item" key={index}>
                <button
                  type="button"
                  key={column.dataField}
                  className={cx('btn', 'btn-block', 'btn-item', !column.toggle ? 'toggle-active' : '')}
                  data-toggle="button"
                  onClick={() => {
                    let toggleObj = { ...toogle }
                    toggleObj[column.dataField] = !column.toggle
                    setToogle(toggleObj)
                    onColumnToggle(column.dataField)
                  }}
                >
                  {column.text}
                </button>
              </li>
            )
          })}
      </ul>
    )
  }

  const getHandlerTableChange = () => {
    return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
      const pageNumber = page || 1
      let updatedFilters =
        type === 'sort'
          ? { ...filters, orderBy: sortField, orderType: sortOrder }
          : type === 'pagination'
          ? { ...filters, pageIndex: pageNumber, pageSize: sizePerPage }
          : filters
      setIsPaging(true)
      updateFilters(updatedFilters)
    }
  }

  const submitExport = isExporting => {
    setIsExporting(isExporting)
    callBackExporting(isExporting)
  }
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <ToolkitProvider keyField="id" data={entities} columns={tableColumns} columnToggle>
                {props => {
                  return (
                    <div>
                      <div className={cx('function-button-content')}>
                        <div className={cx('report-table-title')}>
                          <p>{intl.formatMessage({ id: 'TRANSACTION.LABEL.REPORTS' })}</p>
                        </div>
                        <div className={cx('report-table-button')}>
                          <div className={cx('function-button-item')}>
                            <button
                              type="button"
                              className={cx('column-visibility-button', 'btn', 'btn-primary', 'btn-primary-custom')}
                            >
                              <span className={cx('column-visibility-button-title')}>
                                {intl.formatMessage({ id: 'TRANSACTION.BUTTON.COLUMN_VISIBILITY' })}
                              </span>
                              <span className={cx('caret')}></span>
                            </button>
                            <div className={cx('wrap')}>
                              <div className={cx('toggle-list')}>
                                <ToggleList {...props.columnToggleProps} toggles={toogle} />
                              </div>
                            </div>
                          </div>
                          <div className={cx('function-button-item')}>
                            <button
                              type="button"
                              className={cx('operations-button', 'btn', 'btn-primary', 'btn-primary-custom')}
                            >
                              <span className={cx('action-button-title')}>
                                {intl.formatMessage({ id: 'TRANSACTION.BUTTON.OPERATIONS' })}
                              </span>
                              {isExporting ? (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
                                </Spinner>
                              ) : (
                                <span className={cx('caret')}></span>
                              )}
                            </button>
                            {!isExporting && (
                              <div className={cx('action-wrap')}>
                                <div className={cx('action-list')}>
                                  {isLoading == false && (
                                    <ActionList
                                      filters={filters}
                                      submitExport={submitExport}
                                      generateAlert={generateAlert}
                                      forceLogout={forceLogout}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses={cx('table-responsive')}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        remote
                        keyField="id"
                        data={entities === null ? [] : entities}
                        columns={tableColumns}
                        defaultSorted={defaultSorted}
                        onTableChange={getHandlerTableChange()}
                        expandRow={expandRow}
                        rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                      {!listLoading && (
                        <>
                          <PleaseWaitMessage entities={entities} />
                          <NoRecordsFoundMessage entities={entities} />
                        </>
                      )}
                    </div>
                  )
                }}
              </ToolkitProvider>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </>
  )
}

export default injectIntl(ReportTable)
