import React, { memo, useLayoutEffect, useRef } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret } from '../../../../../_metronic/_helpers'
import classNames from 'classnames/bind'
import style from '../../credit-limits.module.css'
const cx = classNames.bind(style)
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { Spinner } from 'react-bootstrap'
import ActionsColumnFormatter from './column-formatters/ActionsColumnFormatter'
import { ColorStatusColumnFormatter } from './column-formatters/ColorStatusColumnFormatter'
import { AssignedAgentColumnFormatter } from './column-formatters/AssignedAgentColumnFormatter'
import { headerSortingClasses } from '../../../../../_metronic/_helpers'
import { Icon } from '@material-ui/core'
const defaultSorted = [{ dataField: 'agentLimits.agentCode', order: 'desc' }]

const CreditLimitsTable = ({
  isLoading,
  intl,
  theme,
  creditLimitTemplatesData,
  hasPermissionToDelete,
  hasPermissionToCreate,
  onSetAgentLimitData,
  onSetTemplateName,
  onOpenModal,
  onOpenDeleteModal,
  openCreditLimitDetailsData
}) => {
  const { SearchBar } = Search
  let paginationPropsData = {}

  const CREDIT_LIMIT_COLUMNS = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
      formatter: (cell, row, rowIndex) => {
        let rowNumber = (paginationPropsData?.page - 1) * paginationPropsData?.sizePerPage + (rowIndex + 1)
        return rowNumber
      }
    },
    {
      dataField: 'limitInfo.templateCode',
      text: intl.formatMessage({ id: 'CREDIT.LIMIT.TABLE.COLUMN.TITLE.TEMPLATE_CODE' }),
      sort: true,
      headerSortingClasses,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'limitInfo.templateName',
      text: intl.formatMessage({ id: 'CREDIT.LIMIT.TABLE.COLUMN.TITLE.TEMPLATE_NAME' }),
      sort: true,
      headerSortingClasses,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'limitInfo.status',
      text: intl.formatMessage({ id: 'CREDIT.LIMIT.TABLE.COLUMN.TITLE.STATUS' }),
      sort: true,
      headerSortingClasses,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      formatter: ColorStatusColumnFormatter,
      style: {
        whiteSpace: 'nowrap',
        width: '150px'
      }
    },
    {
      dataField: 'agentLimits.agentCode',
      text: intl.formatMessage({ id: 'CREDIT.LIMIT.TABLE.COLUMN.TITLE.ASSIGNED_AGENTS' }),
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      sort: true,
      headerSortingClasses,
      sortCaret: sortCaret,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const maxPercentRowA = getMaxPercentInRow(rowA)

        const maxPercentRowB = getMaxPercentInRow(rowB)

        if (order === 'asc') {
          return maxPercentRowA - maxPercentRowB
        }

        return maxPercentRowB - maxPercentRowA
      },
      formatter: AssignedAgentColumnFormatter,
      formatExtraData: { intl, onSetAgentLimitData, onSetTemplateName, theme },
      style: {
        whiteSpace: 'nowrap',
        width: '150px'
      }
    },
    {
      dataField: 'action',
      text: intl.formatMessage({ id: 'CREDIT.LIMIT.TABLE.COLUMN.TITLE.ACTION' }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        theme,
        hasPermissionToDelete,
        onOpenModal,
        onOpenDeleteModal,
        openCreditLimitDetailsData,
        intl
      },
      headerStyle: () => {
        return { minWidth: '200px', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'end' }
      },
      style: {
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'end'
      }
    }
  ]

  const getMaxPercentInRow = row => {
    const listPercentInRow = []
    if (row.agentLimits) {
      row.agentLimits.forEach(agentLimit => {
        if (agentLimit.limitItems && agentLimit.limitItems.length > 0) {
          agentLimit.limitItems.forEach(limitItem => {
            const percent = isFinite(+limitItem.currentValue / +limitItem.limitValue)
              ? (+limitItem.currentValue / +limitItem.limitValue) * 100
              : 0
            listPercentInRow.push(percent)
          })
        }
      })
    }

    return Math.max(...listPercentInRow)
  }
  const options = {
    custom: true,
    totalSize: creditLimitTemplatesData?.length
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={creditLimitTemplatesData ? creditLimitTemplatesData : []}
            columns={CREDIT_LIMIT_COLUMNS}
            search
          >
            {props => {
              paginationPropsData = paginationProps
              return (
                <div>
                  <p className={cx('page-title')}>{intl.formatMessage({ id: 'CREDIT.LIMIT.PAGE.TITLE' })}</p>
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
                      </Spinner>
                    </div>
                  ) : (
                    <div>
                      <div className={cx('d-flex justify-content-between')}>
                        <SearchBar
                          {...props.searchProps}
                          className={cx(theme === 'Dark' ? 'search-bar-input-dark' : undefined)}
                        />
                        {hasPermissionToCreate && (
                          <div>
                            <button
                              type="button"
                              style={{ width: '150px' }}
                              onClick={() => onOpenModal({ modal: 'CREATE_MODAL', isOpen: true })}
                              className="btn btn-primary btn-primary-custom d-flex justify-content-between"
                            >
                              {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.OPEN.BUTTON.TITLE' })}
                              <Icon
                                className={cx('add-new-icon', theme === 'Dark' && cx('add-new-icon-dark'))}
                                color="action"
                              >
                                add_circle
                              </Icon>
                            </button>
                          </div>
                        )}
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses={cx('table-responsive')}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        keyField="id"
                        data={creditLimitTemplatesData === null ? [] : creditLimitTemplatesData}
                        columns={CREDIT_LIMIT_COLUMNS}
                        defaultSorted={defaultSorted}
                        pagination={paginationFactory(options)}
                        rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                      <PleaseWaitMessage entities={creditLimitTemplatesData} />
                      <NoRecordsFoundMessage entities={creditLimitTemplatesData} />

                      <div className={cx('d-flex justify-content-between align-items-baseline')}>
                        <div className={theme === 'Dark' ? 'pagination-dark' : undefined}>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div>
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            }}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </>
  )
}

export default memo(CreditLimitsTable)
