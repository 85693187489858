import { merchantApiClient } from '../../../../redux'

export const GET_TRANSACTION_LIST = `/api/v1/transactions`
export const PAYOUT_TRANSACTION_WEEKLY_STATS_URL = '/api/v1/payout-transaction-weekly-stats'

export const payoutTransactionWeeklyStats = () => {
  return merchantApiClient.get(PAYOUT_TRANSACTION_WEEKLY_STATS_URL)
}

export function getPayoutTransactionList(
  transType,
  status,
  start,
  end,
  pageSize,
  pageIndex,
  orderType,
  ignoreTotalSize,
  includeSubAgent,
  ignoreReturnDate,
  isGetPayout
) {
  return merchantApiClient.post(GET_TRANSACTION_LIST, {
    transType,
    status,
    start,
    end,
    pageSize,
    pageIndex,
    orderType,
    ignoreTotalSize,
    includeSubAgent,
    ignoreReturnDate,
    isGetPayout
  })
}
