import React, { useMemo, useRef, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { useIntl } from 'react-intl'
import { version } from '../../../../../package.json'
export function Footer() {
  const today = new Date().getFullYear()
  const uiService = useHtmlClassService()
  const intl = useIntl()
  const appVersion = useSelector(state => state.appVersion)

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true)
    }
  }, [uiService])

  const ref = useRef(null)
  const refContainer = useRef(null)

  useLayoutEffect(() => {
    ref.current.style.setProperty('justify-content', 'flex-end', 'important')
  }, [])
  useLayoutEffect(() => {
    if (theme === 'Dark') {
      refContainer.current.style.setProperty('color', 'black', 'important')
      refContainer.current.style.setProperty('background-color', '#1e1e2c', 'important')
    } else {
      refContainer.current.style.setProperty('color', 'white', 'important')
      refContainer.current.style.setProperty('background-color', '#ffffff', 'important')
    }
  }, [theme])

  return (
    <div
      ref={refContainer}
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        ref={ref}
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row  justify-content-between h-auto flex-column align-items-center`}
      >
        <div className="col-lg-6 col-md-6 text-dark d-flex justify-content-lg-start justify-content-md-start justify-content-center w-100">
          <span className="text-muted font-weight-bold mr-2">
            Copyright &copy; {today.toString()} Novatti Group Ltd (ASX:NOV). All rights reserved.
          </span>
        </div>
        <div className="col-lg-6 col-md-6 d-flex flex-column align-items-lg-end align-items-md-end align-items-center">
          <span className="text-muted text-nowrap font-weight-bold mr-2">
            {intl.formatMessage({ id: 'APP.VERSION.LABEL' })}
            {version}
          </span>
          {appVersion.versionData.version == null ? (
            <span className="text-muted text-nowrap font-weight-bold mr-2">
              {intl.formatMessage({ id: 'API.VERSION.LABEL' })}
              <span className="text-nowrap font-weight-bold mr-2 text-danger">
                {intl.formatMessage({ id: 'API.VERSION.VALUE.ERROR' })}
              </span>
            </span>
          ) : (
            <span className="text-muted text-nowrap font-weight-bold mr-2">
              {intl.formatMessage({ id: 'API.VERSION.LABEL' })}
              {appVersion.versionData.version}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
