import React from 'react'
import style from './loadingPage.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

function LoadingPage() {
  return (
    <div className={cx('loader-container')}>
      <div className={cx('spinner')}></div>
    </div>
  )
}

export default LoadingPage
