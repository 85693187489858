import React from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'
import style from './sessionTimeoutDialog.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
const SessionTimeoutDialog = ({ isOpenModal, seconds, onContinue, onLogout, sessionCountDownTimeExprires }) => {
  return (
    <Modal centered show={isOpenModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Session Timeout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your session is about to expire. Redirecting in {seconds} seconds.
        <ProgressBar className={cx('progress-bar-custom')} now={seconds} animated max={sessionCountDownTimeExprires} />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onLogout} className={cx('button', 'btn', 'btn-secondary', 'btn-lg')}>
          <span>Logout</span>
        </button>
        <button className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')} onClick={onContinue}>
          <span>Continue</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}
export default SessionTimeoutDialog
