import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const CustomCheckbox = withStyles({
  root: {
    color: 'var(--primary-color)',
    '&$checked': {
      color: 'var(--primary-color)'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />)

export default CustomCheckbox
