import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import PermissionDeniedMessage from '../../../../app/components/PermissionDeniedMessage'
import { checkHasPermission, checkExtensionRole } from '../../../../app/pages/_helper/MenuHelper'
import { Content } from './Content'
import { useIntl } from 'react-intl'

export function ContentRoute({ children, component, render, role = '', roleDescription = '', ...props }) {
  const { user } = useSelector(state => state.auth)
  const { agentUser } = useSelector(state => state.agentUser)
  const intl = useIntl()
  return (
    <Route {...props}>
      {routeProps => {
        if (role === '' || checkHasPermission(user.acls, role) || checkExtensionRole(agentUser, role, false)) {
          if (typeof children === 'function') {
            return <Content>{children(routeProps)}</Content>
          }

          if (!routeProps.match) {
            return null
          }

          if (children) {
            return <Content>{children}</Content>
          }

          if (component) {
            return <Content>{React.createElement(component, routeProps)}</Content>
          }

          if (render) {
            return <Content>{render(routeProps)}</Content>
          }

          return null
        } else {
          return <PermissionDeniedMessage roleDescription={roleDescription} intl={intl} />
        }
      }}
    </Route>
  )
}
