import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { SUCCESS_RESULT_CODE } from '../../../../../utils/Constants'

const ProgressBar = ({ currentValue, limitValue, cx }) => {
  const getPercentValueAndColor = (currentValue, limitValue) => {
    let percentAndColor = {}
    const percent = (+currentValue / +limitValue) * 100
    if (percent > 80) {
      percentAndColor = { color: 'bg-danger', value: percent }
    } else if (percent > 50) {
      percentAndColor = { color: 'bg-warning', value: percent }
    } else {
      percentAndColor = { color: 'bg-success', value: percent }
    }
    return percentAndColor
  }
  const percentAndColor = getPercentValueAndColor(currentValue, limitValue)

  return (
    <>
      <div
        className={cx('progress-bar', 'progress-bar-striped', percentAndColor.color)}
        role="progressbar"
        style={{ width: `${percentAndColor.value}%` }}
        aria-valuenow={percentAndColor.value}
        aria-valuemin="0"
        aria-valuemax={limitValue}
      ></div>
    </>
  )
}

const limitType = ['VALUE', 'COUNT']

const AgentLimitDetailModal = ({
  intl,
  agentLimitData,
  cx,
  isOpen,
  onClose,
  onEnableEdit,
  isEdit,
  templateName,
  hasPermissionToUpdate,
  onEditAgentLimitItem,
  editAgentLimitItemData,
  generateAlert,
  creditLimitTemplatesLoading,
  onReloadAgentUserInfo,
  onResetAgentLimitItemState
}) => {
  const [agentLimitUpdateRequest, setAgentLimitUpdateRequest] = useState()
  const [limitValue, setLimitValue] = useState()
  const [currentValue, setCurrentValue] = useState()
  const [limitValueError, setLimitValueError] = useState()
  const [currentValueError, setCurrentValueError] = useState()
  const [isGreaterThanData, setIsGreaterThanData] = useState()
  const [isValidToSave, setIsValidToSave] = useState(true)

  useEffect(() => {
    if (agentLimitData) {
      convertDateToRequest()
    }
  }, [agentLimitData])

  useEffect(() => {
    if (agentLimitData) {
      if (agentLimitData.limitItems && agentLimitData.limitItems.length > 0) {
        const limitValueErrors = []
        const currentValueErrors = []
        const isGreaterThanData = []
        agentLimitData.limitItems.forEach((item, index) => {
          if (+item.currentValue > +item.limitValue) {
            isGreaterThanData.push({ limitItemId: item.limitItemId, isGreaterThan: true })
          } else {
            isGreaterThanData.push({ limitItemId: item.limitItemId, isGreaterThan: false })
          }
          if (item.limitValue === '0' || item.limitValue === '0.0' || item.limitValue < 0 || item.limitValue === null) {
            limitValueErrors.push({ limitItemId: item.limitItemId, touch: true })
          } else {
            limitValueErrors.push({ limitItemId: item.limitItemId, touch: false })
          }
          if (item.currentValue < 0 || item.currentValue === null) {
            currentValueErrors.push({ limitItemId: item.limitItemId, touch: true })
          } else {
            currentValueErrors.push({ limitItemId: item.limitItemId, touch: false })
          }
        })
        setLimitValueError(limitValueErrors)
        setCurrentValueError(currentValueErrors)
      }
    }
  }, [agentLimitData])

  const handleChangeRequest = (limitItemId, key, value) => {
    agentLimitUpdateRequest.limitItems.forEach((element, index) => {
      if (element.limitItemId === limitItemId) {
        element[key] = value
        if (key === 'limitValue') {
          handleSetErrors(
            key,
            element,
            limitValueError,
            index,
            limitItemId,
            setLimitValueError,
            intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.LIMIT.VALUE_ERROR_MESSAGE' }),
            intl.formatMessage(
              { id: 'GREATER_OR_EQUAL_THAN' },
              { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.LIMIT_VALUE' }), value: 1 }
            )
          )

          if (+element['currentValue'] > +element['limitValue']) {
            if (isGreaterThanData) {
              const currentData = [...isGreaterThanData]
              if (isGreaterThanData[index]) {
                currentData[index] = { ...currentData[index], isGreaterThan: true }
              } else {
                currentData.push({ limitItemId, isGreaterThan: true })
              }
              setIsGreaterThanData(currentData)
            } else {
              setIsGreaterThanData([{ limitItemId, isGreaterThanData: true }])
            }
          } else {
            if (isGreaterThanData) {
              const currentData = [...isGreaterThanData]

              if (isGreaterThanData[index]) {
                currentData[index] = { ...currentData[index], isGreaterThan: false }
              } else {
                currentData.push({ limitItemId, isGreaterThan: false })
              }
              setIsGreaterThanData(currentData)
            } else {
              setIsGreaterThanData([{ limitItemId, isGreaterThan: false }])
            }
          }
        } else {
          handleSetErrors(
            key,
            element,
            currentValueError,
            index,
            limitItemId,
            setCurrentValueError,
            intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CURRENT.VALUE_ERROR_MESSAGE' }),
            intl.formatMessage(
              { id: 'GREATER_OR_EQUAL_THAN' },
              { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.INPUT.LABEL_CURRENT_VALUE' }), value: 0 }
            )
          )
          if (+element['currentValue'] > +element['limitValue']) {
            if (isGreaterThanData) {
              const currentData = [...isGreaterThanData]
              if (currentData[index]) {
                currentData[index] = { ...currentData[index], isGreaterThan: true }
              } else {
                currentData.push({ limitItemId, isGreaterThan: true })
              }
              setIsGreaterThanData(currentData)
            } else {
              setIsGreaterThanData([{ limitItemId, isGreaterThan: true }])
            }
          } else {
            if (isGreaterThanData) {
              const currentData = [...isGreaterThanData]

              if (isGreaterThanData[index]) {
                currentData[index] = { ...currentData[index], isGreaterThan: false }
              } else {
                currentData.push({ limitItemId, isGreaterThan: false })
              }
              setIsGreaterThanData(currentData)
            } else {
              setIsGreaterThanData([{ limitItemId, isGreaterThan: false }])
            }
          }
        }
      }
    })
  }

  const handleSetError = (data, index, limitItemId, message, touch, setFunc) => {
    if (data) {
      const errorData = [...data]
      if (errorData[index]) {
        errorData[index] = {
          ...errorData[index],
          limitItemId,
          touch,
          message
        }
      } else {
        errorData.push({
          limitItemId,
          touch,
          message
        })
      }
      setFunc(errorData)
    } else {
      setFunc([
        {
          limitItemId,
          touch,
          message
        }
      ])
    }
  }

  const handleSetErrors = (key, element, data, index, limitItemId, setFunc, message1, message2) => {
    if (element[key] === '') {
      handleSetError(data, index, limitItemId, message1, true, setFunc)
    } else if (key === 'limitValue' && element[key] <= 0) {
      handleSetError(data, index, limitItemId, message2, true, setLimitValueError)
    } else if (key === 'currentValue' && element[key] < 0) {
      handleSetError(data, index, limitItemId, message2, true, setCurrentValueError)
    } else if (element[key] !== '') {
      handleSetError(data, index, limitItemId, null, false, setFunc)
    }
  }

  const convertDateToRequest = () => {
    const request = { agentCode: agentLimitData.agentCode, limitItems: [] }
    if (agentLimitData && agentLimitData.limitItems && agentLimitData.limitItems.length > 0) {
      agentLimitData.limitItems.forEach(item => {
        let limitItem = {}
        limitItem.targetCode = item.targetCode
        limitItem.limitItemId = item.limitItemId
        limitItem.limitValue = item.limitValue
        limitItem.currentValue = item.currentValue
        limitItem.limitType = item.limitType
        request.limitItems.push(limitItem)
      })
    }
    setAgentLimitUpdateRequest(request)
  }

  const handleCancel = () => {
    if (limitValue) {
      if (agentLimitData && agentLimitData.limitItems && agentLimitData.limitItems.length > 0) {
        agentLimitData.limitItems.forEach(element => {
          if (element.limitItemId === limitValue.limitItemId) {
            setLimitValue({ limitItemId: element.limitItemId, value: element.limitValue })
          }
        })
      }
    }

    if (currentValue) {
      if (agentLimitData && agentLimitData.limitItems && agentLimitData.limitItems.length > 0) {
        agentLimitData.limitItems.forEach(element => {
          if (element.limitItemId === currentValue.limitItemId) {
            setCurrentValue({ limitItemId: element.limitItemId, value: element.currentValue })
          }
        })
      }
    }
    setLimitValueError(undefined)
    setCurrentValueError(undefined)
    setIsGreaterThanData(undefined)
  }

  const handleSave = async () => {
    if (agentLimitUpdateRequest) {
      const payload = { agentCode: agentLimitUpdateRequest.agentCode, limitItems: [] }
      await agentLimitUpdateRequest.limitItems.forEach(element => {
        const limitItem = { ...element }
        delete limitItem.targetCode
        delete limitItem.limitType
        payload.limitItems.push(limitItem)
      })
      onEditAgentLimitItem(payload)
    }
  }

  useEffect(() => {
    if (
      editAgentLimitItemData &&
      editAgentLimitItemData.data &&
      editAgentLimitItemData.data.resultCode === SUCCESS_RESULT_CODE
    ) {
      generateAlert(
        'success',
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.TITLE_EDIT_AGENT_LIMIT' }),
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.TITLE_EDIT_AGENT_LIMIT_MESSAGE_SUCCESS' })
      )
      creditLimitTemplatesLoading()
      onReloadAgentUserInfo()
      onClose(true)
      onEnableEdit(false)
      onResetAgentLimitItemState()
      setLimitValue(undefined)
      setCurrentValue(undefined)
    } else if (
      editAgentLimitItemData &&
      editAgentLimitItemData.data &&
      editAgentLimitItemData.data.resultCode !== SUCCESS_RESULT_CODE &&
      !editAgentLimitItemData.isLoanding
    ) {
      generateAlert(
        'danger',
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.TITLE_EDIT_AGENT_LIMIT' }),
        editAgentLimitItemData.data.resultDescription
      )
      onResetAgentLimitItemState()
    }
  }, [editAgentLimitItemData])

  const checkIsValidToSave = () => {
    let isGreaterThanItem = undefined
    let currentValueErrorItem = undefined
    let limitValueErrorItem = undefined
    if (limitValueError && limitValueError.length > 0) {
      limitValueErrorItem = isGreaterThanData.find((item, index) => {
        return item.touch
      })
    }

    if (currentValueError && currentValueError.length > 0) {
      currentValueErrorItem = currentValueError.find((item, index) => {
        return item.touch
      })
    }

    if (isGreaterThanData && isGreaterThanData.length > 0) {
      isGreaterThanItem = isGreaterThanData.find((item, index) => {
        return item.isGreaterThan
      })
    }

    if (limitValueErrorItem || currentValueErrorItem || isGreaterThanItem) {
      return false
    }

    return true
  }

  useEffect(() => {
    if (limitValueError && currentValueError && isGreaterThanData) {
      setIsValidToSave(checkIsValidToSave())
    }
  }, [limitValueError, currentValueError, isGreaterThanData])

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={isOpen}
        onHide={onClose}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.TITLE_AGENT_LIMIT' })}
          </Modal.Title>
          <Button
            onClick={() => {
              onClose()
              convertDateToRequest()
              handleCancel()
              onEnableEdit(false)
            }}
            variant="secondary"
          >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-start flex-column">
            <div className="my-1">
              <span className="h5" style={{ fontWeight: '400' }}>
                {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.LABEL_TEMPLATE_NAME' })}:
              </span>
              <span className="h5"> {templateName}</span>
            </div>
            <div className="my-1">
              <span className="h5" style={{ fontWeight: '400' }}>
                {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.LABEL_AGENT_CODE' })}:
              </span>
              <span className="h5"> {agentLimitData?.agentCode}</span>
            </div>
          </div>
          <form className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}>
            <div style={{ marginTop: '30px' }} className="d-flex flex-column justify-content-start ">
              {agentLimitUpdateRequest &&
              agentLimitUpdateRequest.limitItems &&
              agentLimitUpdateRequest.limitItems.length > 0 ? (
                agentLimitUpdateRequest?.limitItems.map((item, index) => {
                  return (
                    <div key={index} className="border-bottom my-5">
                      <div className="row align-items-center flex-nowrap ">
                        <span className="col-3 font-weight-bold">{item.targetCode}</span>
                        <div className="col">
                          <div className="progress justify-content-start">
                            <ProgressBar currentValue={item.currentValue} limitValue={item.limitValue} cx={cx} />
                          </div>
                        </div>
                        <span className="col-2 text-right font-weight-bold">
                          {isFinite(+item.currentValue / +item.limitValue)
                            ? ((+item.currentValue / +item.limitValue) * 100).toFixed(2)
                            : 0}
                          %
                        </span>
                      </div>
                      <div className="row align-items-center flex-nowrap">
                        <div className="col"></div>
                        <div className="col-10">
                          <div className="row align-items-center">
                            <div className="col font-weight-bold ">
                              <FormControl size="small" variant="outlined" margin="normal">
                                <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
                                  {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.INPUT.LABEL_LIMIT_TYPE' })}
                                </InputLabel>
                                <Select
                                  disabled
                                  value={item.limitType}
                                  input={<OutlinedInput labelWidth={70} name="limitType" id="limitType" />}
                                >
                                  {limitType.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col">
                              <FormControl sx={{ m: 2 }} className={cx('w-100')} variant="outlined">
                                <TextField
                                  label={intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.INPUT.LABEL_LIMIT_VALUE' })}
                                  type="number"
                                  disabled={!isEdit}
                                  value={item.limitValue}
                                  onChange={e => {
                                    const value = e.target.value
                                    handleChangeRequest(item.limitItemId, 'limitValue', value)
                                  }}
                                  name="limitValue"
                                  margin="normal"
                                  variant="outlined"
                                  size="small"
                                  error={Boolean(limitValueError?.touch)}
                                />
                              </FormControl>
                            </div>
                            <div className="col">
                              <FormControl sx={{ m: 2 }} className={cx('w-100')} variant="outlined">
                                <TextField
                                  label={intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.INPUT.LABEL_CURRENT_VALUE' })}
                                  type="number"
                                  disabled={!isEdit}
                                  value={item.currentValue}
                                  onChange={e => {
                                    const value = e.target.value
                                    handleChangeRequest(item.limitItemId, 'currentValue', value)
                                  }}
                                  name="username"
                                  margin="normal"
                                  variant="outlined"
                                  size="small"
                                  error={Boolean(currentValueError?.touch)}
                                />
                              </FormControl>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <></>
                            </div>
                            <div className="col">
                              {limitValueError &&
                                limitValueError[index] &&
                                limitValueError[index].limitItemId === item.limitItemId && (
                                  <div style={{ color: '#f018a6', fontSize: '10px' }}>
                                    {(limitValueError[index]?.touch && limitValueError[index].message) || ''}
                                  </div>
                                )}
                            </div>
                            <div className="col">
                              {isGreaterThanData &&
                              isGreaterThanData[index] &&
                              isGreaterThanData[index].limitItemId === item.limitItemId &&
                              isGreaterThanData[index].isGreaterThan ? (
                                <div style={{ color: '#f018a6', fontSize: '10px' }}>
                                  {intl.formatMessage({
                                    id: 'CREDIT.LIMIT.MODAL.CURRENT_VALUE_MUST_BE_LESS_THAN_LIMIT_VALUE_ERROR_MESSAGE'
                                  })}
                                </div>
                              ) : (
                                <>
                                  {currentValueError &&
                                  currentValueError[index] &&
                                  currentValueError[index].limitItemId === item.limitItemId ? (
                                    <div style={{ color: '#f018a6', fontSize: '10px' }}>
                                      {(currentValueError[index]?.touch && currentValueError[index].message) || ''}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <>
                  <span style={{ color: '#f018a6' }} className="text-center h6">
                    {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.MESSAGE_NOTIFY' })}
                  </span>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {!isEdit && hasPermissionToUpdate && (
            <Button
              disabled={
                !Boolean(
                  agentLimitUpdateRequest &&
                    agentLimitUpdateRequest.limitItems &&
                    agentLimitUpdateRequest.limitItems.length > 0
                )
              }
              variant="primary"
              onClick={() => onEnableEdit(true)}
            >
              {intl.formatMessage({ id: 'LABEL.EDIT' })}
            </Button>
          )}
          {isEdit && (
            <>
              <Button
                disabled={!isValidToSave || editAgentLimitItemData.isLoading === true}
                variant="primary"
                onClick={() => handleSave()}
              >
                {editAgentLimitItemData.isLoading ? (
                  <>
                    <Spinner size="sm" animation="border" role="status"></Spinner>{' '}
                    <span>{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
                  </>
                ) : (
                  <>{intl.formatMessage({ id: 'LABEL.SAVE' })}</>
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  onClose()
                  onEnableEdit(false)
                  convertDateToRequest()
                  handleCancel()
                }}
              >
                {intl.formatMessage({ id: 'LABEL.CANCEL' })}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default memo(AgentLimitDetailModal)
