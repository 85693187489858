import { merchantApiClient } from './index'

export const GET_CUSTOM_TEMPLATE_DETAIL_URL = '/api/custom-template-details'
export const GET_API_VERSION = '/api/version'
export const TRANSACTION_EXPORT_LIMIT = `/api/v1/transaction-export-limit`

export const getCustomTemplateDetail = () => {
  return merchantApiClient.get(GET_CUSTOM_TEMPLATE_DETAIL_URL)
}

export const getAPIVersion = () => {
  return merchantApiClient.get(GET_API_VERSION)
}

export function getTransactionExportLimit() {
  return merchantApiClient.get(TRANSACTION_EXPORT_LIMIT)
}
