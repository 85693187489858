import React, { useEffect, useState } from 'react'
import { currencyFormat } from '../../../../utils/DataFormatUtils'
import getSymbolFromCurrency from 'currency-symbol-map'
import style from '../dashboard.module.css'
import classNames from 'classnames/bind'
import Spinner from '../../../../_metronic/_partials/spinner'
import { useIntl } from 'react-intl'
import { getTransactionExportLimit } from '../../../../redux/appCrud'
import { SUCCESS_RESULT_CODE } from '../../../../utils/Constants'
const cx = classNames.bind(style)
const TransactionTable = ({ isLoading, data, error, theme }) => {
  const [limitRangeInMonth, setLimitRangeInMonth] = useState(null)

  useEffect(() => {
    getTransactionExportLimit()
      .then(resp => {
        const { resultCode, transactionSearchRangeInMonths } = resp.data
        if (resultCode === SUCCESS_RESULT_CODE) {
          setLimitRangeInMonth(transactionSearchRangeInMonths)
        }
      })
      .catch(error => {})
  }, [])

  const intl = useIntl()
  return (
    <div className="table-responsive">
      <h2 className={cx(theme === 'Dark' ? 'table-title-dark' : 'table-title-light')}>
        {intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.LAST_TEN_TRANSACTIONS' })}
      </h2>
      <table className={cx('table', theme === 'Dark' ? 'table-dark' : '')}>
        <thead className={cx(theme === 'Dark' ? 'thead-dark' : 'thead-light')}>
          <tr>
            <th scope="col">{intl.formatMessage({ id: 'TRANSACTION.LABEL.MERCHANT' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.REF' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.EXTERNAL_REF' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'TRANSACTION.LABEL.TYPE' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'TRANSACTION.LABEL.DATE' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'TRANSACTION.LABEL.STATUS' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'TRANSACTION.LABEL.TERMINAL' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.RESULT' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'TRANSACTION.LABEL.PRODUCT' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.SERIAL_NO' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.GROSS_VAL' })}</th>
            <th scope="col">{intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.NET_VAL' })}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr key="no-data">
              <td colSpan={12}>
                <Spinner wrapperClassName={cx('text-align-center')} size={25} />
              </td>
            </tr>
          ) : error ? (
            <tr key="no-data">
              <td colSpan={12}>
                <div className={cx('text-align-center', 'text-danger')}>{error}</div>
              </td>
            </tr>
          ) : data?.length > 0 ? (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.agentDescription}</td>
                  <td>{item.refId}</td>
                  <td>{item.txnId}</td>
                  <td>{item.txType}</td>
                  <td>{item.date}</td>
                  <td>
                    {item.status &&
                      (() => {
                        const status = item.status.toUpperCase()
                        switch (status) {
                          case 'SUCCESS':
                            return (
                              <span
                                className={cx(
                                  'label',
                                  'label-inline',
                                  `label-${theme === 'Dark' ? 'dark' : 'light'}-success`,
                                  'font-weight-bold'
                                )}
                              >
                                {item.status.toUpperCase()}
                              </span>
                            )
                          case 'FAILED':
                            return (
                              <span className="label label-inline label-light-danger  font-weight-bold">
                                {item.status.toUpperCase()}
                              </span>
                            )
                          case 'PENDING':
                            return (
                              <span className="label label-inline label-warning font-weight-bold">
                                {item.status.toUpperCase()}
                              </span>
                            )
                          case 'REVERSED':
                            return (
                              <span className="label label-light-primary label-inline font-weight-bold">
                                {item.status.toUpperCase()}
                              </span>
                            )
                          case 'REFUNDED':
                            return (
                              <span className="label label-info label-inline font-weight-bold">
                                {item.status.toUpperCase()}
                              </span>
                            )
                          default:
                            return false
                        }
                      })()}
                  </td>
                  <td>{item.terminalCode}</td>
                  <td>{item.resultDesc}</td>
                  <td>{item.product}</td>
                  <td>{item.voucherSerial}</td>
                  <td style={{ overflow: 'hidden' }}>
                    {getSymbolFromCurrency(item.currencyCode)}
                    {currencyFormat(item.value)}
                  </td>
                  <td style={{ overflow: 'hidden' }}>
                    {getSymbolFromCurrency(item.currencyCode)}
                    {currencyFormat(item.buyValue)}
                  </td>
                </tr>
              )
            })
          ) : (
            <tr key="no-data">
              <td>
                {intl.formatMessage(
                  {
                    id: 'DASHBOARD.TRANSACTION.TABLE.LABEL.NO_TRANSACTION'
                  },
                  {
                    transactionSearchRangeInMonths: (
                      <React.Fragment key="transactionSearchRangeInMonths">
                        <b>{limitRangeInMonth}</b>
                      </React.Fragment>
                    )
                  }
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TransactionTable
