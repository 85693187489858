/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'
const READY_FOR_DOWNLOAD = 'Ready for Download'
const WAITING_FOR_APPROVAL = 'Waiting for Approval'
import { Dropdown, ButtonGroup } from 'react-bootstrap'
import { downloadPinBatchOrderFile } from '../../../redux/pinOrderCrud'
import { exportFileData } from '../../../../../../utils/ExporterUtils'
import Spinner from 'react-bootstrap/Spinner'
import style from '../../../pinOrder.module.css'
import classNames from 'classnames/bind'
import { checkHasPermission } from '../../../../_helper/MenuHelper'
import Icon from '@material-ui/core/Icon'

const cx = classNames.bind(style)

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    isDownLoading,
    setIsDownLoading,
    downLoadIndex,
    setRowIndex,
    generateAlert,
    openPinOrderDetailsDialog,
    theme,
    user,
    onOpenDeleteModal
  }
) => {
  const downloadFile = e => {
    const type = e
    setRowIndex(rowIndex)
    setIsDownLoading(true)
    downloadPinBatchOrderFile({ type, orderRef: row.orderRef })
      .then(response => {
        if (response.data && response.data.resultCode) {
          if (response.data.resultCode !== 0) {
            generateAlert('danger', 'Download Pin Batch Order File', response.data.resultDescription)
          }
        } else {
          exportFileData(response)
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsDownLoading(false)
      })
  }
  return (
    <>
      <a
        onClick={() => openPinOrderDetailsDialog(row.orderRef)}
        className={cx(
          'btn',
          'btn-icon',
          `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
          'btn-hover-danger',
          'btn-sm',
          'view-btn'
        )}
      >
        <span
          style={{ height: '2.5rem', display: 'flex', alignItems: 'center' }}
          className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}
        >
          <SVG src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')} />
        </span>
      </a>
      {row.status === READY_FOR_DOWNLOAD && (
        <Dropdown style={{ marginRight: '5px', marginLeft: '5px' }} onSelect={e => downloadFile(e)} as={ButtonGroup}>
          <Dropdown.Toggle className={'btn-primary-custom'} size="sm">
            Download
          </Dropdown.Toggle>
          <Dropdown.Menu className={theme === 'Light' ? 'super-colors' : 'dropdown-dark'}>
            <Dropdown.Item
              className={theme === 'Light' ? 'super-colors' : 'dropdown-item-dark'}
              style={{ justifyContent: 'center' }}
              eventKey="txt"
            >
              {downLoadIndex === rowIndex && isDownLoading ? (
                <div>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                'txt'
              )}
            </Dropdown.Item>
            <Dropdown.Item
              className={theme === 'Light' ? 'super-colors' : 'dropdown-item-dark'}
              style={{ justifyContent: 'center' }}
              eventKey="pgp"
            >
              {downLoadIndex === rowIndex && isDownLoading ? (
                <div>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                'pgp'
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {checkHasPermission(user?.acls, ['SCP.EVDS.PIN.BATCH.FILE.REQUEST_C']) &&
        checkHasPermission(user?.acls, ['SCP.EVDS.PIN.BATCH.FILE.REQUEST_D']) &&
        row.status === WAITING_FOR_APPROVAL && (
          <a
            onClick={() => onOpenDeleteModal(row.orderRef)}
            className={cx(
              'btn',
              'btn-icon',
              `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
              'btn-hover-danger',
              'btn-sm',
              'view-btn',
              'delete-button'
            )}
          >
            <Icon className={cx('delete-icon', theme === 'Dark' && cx('delete-icon-dark'))}>delete</Icon>
          </a>
        )}
    </>
  )
}
