import { SatelliteSharp } from '@material-ui/icons'
import { put, call, takeLatest } from 'redux-saga/effects'
import { getPinBatchOrders, getPinBatchOrderDetails, getProductOrderList } from './pinOrderCrud'

export const actionTypes = {
  pinBatchOrderListLoading: '[Pin Batch Order List] Loading',
  pinBatchOrderListReceived: '[Pin Batch Order List] Received',
  pinBatchOrderListFailed: '[Pin Batch Order List] Failed',
  pinBatchOrderDetailsLoading: '[Pin Batch Order Details] Loading',
  pinBatchOrderDetailsReceived: '[Pin Batch Order Details] Received',
  pinBatchOrderDetailsFailed: '[Pin Batch Order Details] Failed',
  productOrderListLoading: '[Product Order List] Loading',
  productOrderListReceived: '[Product Order List] Received',
  productOrderListFailed: '[Product Order List] Failed',
  addPinBatchToCreateOrderList: '[Create Order List] Add',
  removePinBatchToCreateOrderList: '[Create Order List] Remove',
  clearCreateOrderList: '[Create Order List] Clear'
}

const initialPinBatchOrdersState = {
  pinBatchOrders: {
    data: [],
    totalRecord: 0,
    error: null,
    isLoading: false
  },
  pinBatchOrderDetails: {
    data: {},
    error: null,
    isLoading: false
  },
  productOrderList: {
    data: [],
    error: null,
    isLoading: false
  },
  createOrderList: []
}

export const reducer = (state = initialPinBatchOrdersState, action) => {
  switch (action.type) {
    case actionTypes.pinBatchOrderListLoading: {
      return {
        ...state,
        pinBatchOrders: {
          ...state.pinBatchOrders,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.pinBatchOrderListReceived: {
      const data = action.payload
      return {
        ...state,
        pinBatchOrders: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.pinBatchOrderListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        pinBatchOrders: {
          ...state.pinBatchOrders,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.pinBatchOrderDetailsLoading: {
      return {
        ...state,
        pinBatchOrderDetails: {
          ...state.pinBatchOrderDetails,
          data: {},
          isLoading: true
        }
      }
    }
    case actionTypes.pinBatchOrderDetailsReceived: {
      const data = action.payload
      return {
        ...state,
        pinBatchOrderDetails: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.pinBatchOrderDetailsFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        productOrderList: {
          ...state.productOrderListproductOrderList,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    case actionTypes.productOrderListLoading: {
      return {
        ...state,
        productOrderList: {
          ...state.productOrderList,
          data: {},
          isLoading: true
        }
      }
    }
    case actionTypes.productOrderListReceived: {
      const data = action.payload
      return {
        ...state,
        productOrderList: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.productOrderListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        productOrderList: {
          ...state.productOrderList,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.removePinBatchToCreateOrderList: {
      const productCode = action.payload
      let newList = state.createOrderList
      newList = newList.filter(item => !(item.productCode === productCode))
      return {
        ...state,
        createOrderList: newList
      }
    }
    case actionTypes.addPinBatchToCreateOrderList: {
      let pinBatchOrder = action.payload
      const orderList = state.createOrderList
      let isExisted = false
      const updatedOrderList = orderList.map(item => {
        if (item.productCode === pinBatchOrder.productCode) {
          isExisted = true
          return pinBatchOrder
        }
        return item
      })
      if (!isExisted) {
        updatedOrderList.push(pinBatchOrder)
      }
      return {
        ...state,
        createOrderList: updatedOrderList
      }
    }
    case actionTypes.clearCreateOrderList: {
      return {
        ...state,
        createOrderList: []
      }
    }
    default:
      return state
  }
}

export const actions = {
  pinBatchOrderListLoading: params => ({
    type: actionTypes.pinBatchOrderListLoading,
    payload: null,
    params: params
  }),
  pinBatchOrderListReceived: payload => ({
    type: actionTypes.pinBatchOrderListReceived,
    payload
  }),
  pinBatchOrderListFailed: payload => ({
    type: actionTypes.pinBatchOrderListFailed,
    payload
  }),
  pinBatchOrderDetailsLoading: params => ({
    type: actionTypes.pinBatchOrderDetailsLoading,
    payload: null,
    params: params
  }),
  pinBatchOrderDetailsReceived: payload => ({
    type: actionTypes.pinBatchOrderDetailsReceived,
    payload
  }),
  pinBatchOrderDetailsFailed: payload => ({
    type: actionTypes.pinBatchOrderDetailsFailed,
    payload
  }),
  productOrderListLoading: params => ({
    type: actionTypes.productOrderListLoading,
    payload: null,
    params: params
  }),
  productOrderListReceived: payload => ({
    type: actionTypes.productOrderListReceived,
    payload
  }),
  productOrderListFailed: payload => ({
    type: actionTypes.productOrderListFailed,
    payload
  }),
  addPinBatchToCreateOrderList: payload => ({
    type: actionTypes.addPinBatchToCreateOrderList,
    payload
  }),
  removePinBatchToCreateOrderList: payload => ({
    type: actionTypes.removePinBatchToCreateOrderList,
    payload
  }),
  clearCreateOrderList: payload => ({
    type: actionTypes.clearCreateOrderList,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.pinBatchOrderListLoading, function* pinBatchOrderListSaga({ params }) {
    try {
      const response = yield call(getPinBatchOrders, params)
      const { resultCode, resultDescription, data, totalRecord, totalPage } = response.data
      if (resultCode === 0)
        yield put(
          actions.pinBatchOrderListReceived({
            resultCode,
            resultDescription,
            data,
            totalRecord,
            totalPage
          })
        )
      else yield put(actions.pinBatchOrderListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.pinBatchOrderListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.pinBatchOrderDetailsLoading, function* pinBatchOrderDetailsSaga({ params }) {
    try {
      const response = yield call(getPinBatchOrderDetails, params)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0)
        yield put(
          actions.pinBatchOrderDetailsReceived({
            resultCode,
            resultDescription,
            data
          })
        )
      else yield put(actions.pinBatchOrderDetailsFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.pinBatchOrderDetailsFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.productOrderListLoading, function* productOrderListSaga() {
    try {
      const response = yield call(getProductOrderList)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.productOrderListReceived(data))
      else yield put(actions.productOrderListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.productOrderListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
