import React, { useEffect, useState } from 'react'
import style from './notice.module.css'
import classNames from 'classnames/bind'
import { downloadDocument } from './_redux/NoticeCrud'
import { exportFileData, convertArrayBufferToJson } from '../../../utils/ExporterUtils'
import { AlertList } from 'react-bs-notifier'
import { Spinner } from 'react-bootstrap'
import * as DOMPurify from 'dompurify'

const cx = classNames.bind(style)
const ALERT_TIMEOUT = 15000

const Notice = ({ messageProp, contentTypeProp, documentId }) => {
  const [message, setMessage] = useState(null)
  const [isContentTypeFile, setIsContentTypeFile] = useState(false)
  const [alerts, setAlerts] = useState([])
  const [isDownLoading, setIsDownLoading] = useState(false)

  useEffect(() => {
    if (contentTypeProp == 'FILE') {
      setIsContentTypeFile(true)
    }
    setMessage(DOMPurify.sanitize(messageProp))
  }, [])

  const handleDownloadDocument = () => {
    setIsDownLoading(true)
    downloadDocument(documentId)
      .then(response => {
        let responseData = convertArrayBufferToJson(response.data)
        if (responseData && responseData?.resultCode !== 0) {
          generateAlert('danger', 'Download Document File', responseData?.resultDescription || responseData?.error)
          return
        }
        exportFileData(response)
      })
      .catch(error => {
        generateAlert('danger', 'Download Document File', error.message)
      })
      .finally(() => {
        setIsDownLoading(false)
      })
  }

  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  return (
    <>
      <div className={cx('notice')}>
        <div>
          <AlertList
            position={'top-right'}
            alerts={alerts}
            timeout={ALERT_TIMEOUT}
            dismissTitle="Begone!"
            onDismiss={onDismissed}
          />
        </div>

        <div dangerouslySetInnerHTML={{ __html: message }}></div>

        {isContentTypeFile && (
          <>
            <button
              onClick={handleDownloadDocument}
              style={{ display: isDownLoading ? 'none' : 'block' }}
              className={cx('btn-download')}
            >
              Download
            </button>

            <div style={{ display: isDownLoading ? 'block' : 'none' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Notice
