import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/_helpers'
import Spinner from 'react-bootstrap/Spinner'
import style from '../../../resourceManagement.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const ActionsColumnFormatter = (cellContent, row, rowIndex, { theme, openDocumentDetailsDialog }) => {
  return (
    <>
      <a
        id={'download-report-' + rowIndex}
        onClick={() => openDocumentDetailsDialog(row)}
        className={cx('btn', 'btn-icon', `btn-${theme === 'Dark' ? 'dark' : 'light'}`, 'btn-hover-danger', 'btn-sm')}
      >
        <span className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}>
          <SVG src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')} />
        </span>
      </a>
    </>
  )
}

export default ActionsColumnFormatter
