import React, { useMemo, useState, useLayoutEffect, useContext } from 'react'
import { TemplateContext } from '../../_core/TemplateProvider'
import { Link } from 'react-router-dom'
import objectPath from 'object-path'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_helpers'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { useSelector } from 'react-redux'
import { BASE64_IMAGE_PREFIX } from '../../../../utils/Constants'

export function HeaderMobile() {
  const uiService = useHtmlClassService()
  const [logo, setLogo] = useState(toAbsoluteUrl('/media/logos/logo-light-mode.png'))
  const templateContext = useContext(TemplateContext)

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay: objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile')
    }
  }, [uiService])

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  useLayoutEffect(() => {
    if (templateContext) {
      let base64Logo
      if (theme == 'Dark') {
        base64Logo = templateContext.brandLogoDark
          ? BASE64_IMAGE_PREFIX + templateContext.brandLogoDark
          : toAbsoluteUrl('/media/logos/logo-dark-mode.png')
      } else {
        base64Logo = templateContext.brandLogoLight
          ? BASE64_IMAGE_PREFIX + templateContext.brandLogoLight
          : toAbsoluteUrl('/media/logos/logo-light-mode.png')
      }
      setLogo(base64Logo)
    }
  }, [theme])

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        <Link to="" className="brand-logo">
          <img alt="logo" src={logo} />
        </Link>
        {/*end::Logo*/}

        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                <span />
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          {layoutProps.headerMenuSelfDisplay && (
            <>
              {/*begin::Header Menu Mobile Toggle*/}
              <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                <span />
              </button>
              {/*end::Header Menu Mobile Toggle*/}
            </>
          )}

          {/*begin::Topbar Mobile Toggle*/}
          <button className="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  )
}
