import React, { useEffect, useRef, useState, useContext } from 'react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Alert } from 'react-bootstrap'
import style from '../auth.module.css'
import classNames from 'classnames/bind'
import { verifyOtpForUnlockUser } from '../_redux/authCrud'
import { connect } from 'react-redux'
import { actions } from '../_redux/authRedux'
import { AlertList } from 'react-bs-notifier'
import { LOGIN_TOKEN_KEY } from '../../../../utils/Constants'
import { TemplateContext } from '../../../../_metronic/layout/_core/TemplateProvider'
const ALERT_TIMEOUT = 15000
const cx = classNames.bind(style)
const initialValues = {
  otp: '',
  email: ''
}

let countDown = null
const VerifyOTP = ({ intl, verifyUnlock, sendUnlockCodeLoading, setIsLockedUser, handleLogin, formikLogin }) => {
  const [otp, setOtp] = useState(new Array(4).fill(''))
  const [activeOtpIndex, setActiveOtpIndex] = useState(0)
  const [remainingOnSeconds, setRemainingOnSeconds] = useState(-1)
  const [isResend, setIsResend] = useState(false)
  const [isVerifySuccess, setIsVerifySuccess] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const inputRef = useRef()
  const templateContext = useContext(TemplateContext)

  useEffect(() => {
    let isCleanUp = true
    if (isCleanUp) {
      if (remainingOnSeconds === 0) {
        formik.setStatus('Your session has expired.')
        setIsResend(true)
        clearInterval(countDown)
      }
    }

    return () => {
      isCleanUp = false
    }
  }, [remainingOnSeconds])

  useEffect(() => {
    let isFocus = true
    if (isFocus) inputRef.current?.focus()

    return () => {
      isFocus = false
    }
  }, [activeOtpIndex])

  useEffect(() => {
    let isCleanUp = false
    if (isVerifySuccess) {
      if (!isCleanUp) {
        if (localStorage.getItem('emailToConfirm')) {
          localStorage.removeItem('emailToConfirm')
        }
        if (localStorage.getItem('otpExpiredTime')) {
          localStorage.removeItem('otpExpiredTime')
        }

        const urlPage = window.location.search
        const params = new URLSearchParams(urlPage)
        const loginTokenRedirect = params.get(LOGIN_TOKEN_KEY)
        handleLogin(formikLogin, loginTokenRedirect)
      }
    }
    return () => {
      isCleanUp = true
    }
  }, [isVerifySuccess])

  useEffect(() => {
    initData()
  }, [])

  const handleSubmit = () => {
    formik.submitForm()
  }

  useEffect(() => {
    let isJoin = true
    if (isJoin) formik.setFieldValue('otp', otp.join(''))
    return () => {
      isJoin = false
    }
  }, [otp])

  useEffect(() => {
    if (isResend && verifyUnlock?.isSendVerifyUnlockSuccess) {
      setOtp(new Array(4).fill(''))
      initData()
      setTimeout(() => {
        generateAlert('success', 'Resend Email', 'Success')
      }, 1)
    }
  }, [verifyUnlock])

  const initData = () => {
    if (countDown) {
      clearInterval(countDown)
    }
    let email = localStorage.getItem('emailToConfirm')
    let otpExpiredTime = localStorage.getItem('otpExpiredTime')
    let otpExpiredDate = new Date(otpExpiredTime)
    let currentDate = new Date()
    let diff = (otpExpiredDate - currentDate) / 1000
    if (verifyUnlock.email != null && verifyUnlock.otpExpiredTime != '' && diff > 0) {
      email = verifyUnlock.email
      otpExpiredTime = verifyUnlock.otpExpiredTime
    }
    if (((verifyUnlock?.email == '' && !email) || !otpExpiredTime || diff < 0) && !isResend) {
      if (localStorage.getItem('emailToConfirm')) {
        localStorage.removeItem('emailToConfirm')
      }
      if (localStorage.getItem('otpExpiredTime')) {
        localStorage.removeItem('otpExpiredTime')
      }
      setIsLockedUser(false)
    }
    formik.values.email = email
    setRemainingOnSeconds(Math.round(diff))
    doCountDownTime()
    setIsResend(false)
  }
  const handleChange = (element, index) => {
    const value = element.value
    const newOtp = [...otp]
    newOtp[index] = value.substring(value.length - 1)
    setOtp(newOtp)
    if (!value) setActiveOtpIndex(index - 1)
    else setActiveOtpIndex(index + 1)
  }

  const handleOnKeyDown = ({ key }, index) => {
    if (key === 'Backspace') setActiveOtpIndex(index - 1)
  }

  const handleOnEnter = ({ key }) => {
    if (key == 'Enter') formik.submitForm()
  }

  const [resultCode, setResultCode] = useState(0)
  const ConfirmEmailSchema = Yup.object().shape({
    otp: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .min(4, 'Invalid OTP!')
  })

  const doCountDownTime = () => {
    countDown = setInterval(() => {
      setRemainingOnSeconds(preSecond => {
        return preSecond - 1
      })
    }, 1000)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ConfirmEmailSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      verifyOtpForUnlockUser(values)
        .then(res => {
          const { resultCode, resultDescription } = res.data
          if (resultCode === 0) {
            setStatus(null)
            resetForm()
            setIsVerifySuccess(true)
            setIsLogin(true)
          } else {
            setStatus(resultDescription)
            setIsResend(true)
          }
          setResultCode(resultCode)
        })
        .catch(error => {
          setResultCode(resultCode)
          setStatus(error.message)
          setIsResend(true)
        })
        .finally(data => {
          setSubmitting(false)
        })
    }
  })

  const handleDismiss = () => {
    formik.setStatus(null)
  }

  const handleResend = () => {
    if (localStorage.getItem('emailToConfirm')) {
      localStorage.removeItem('emailToConfirm')
    }
    if (localStorage.getItem('otpExpiredTime')) {
      localStorage.removeItem('otpExpiredTime')
    }

    clearInterval(countDown)
    const payload = { userName: formikLogin.values.username }
    sendUnlockCodeLoading(payload)
    formik.resetForm()
    handleDismiss()
  }
  const handleCancel = () => {
    if (localStorage.getItem('emailToConfirm')) {
      localStorage.removeItem('emailToConfirm')
    }
    if (localStorage.getItem('otpExpiredTime')) {
      localStorage.removeItem('otpExpiredTime')
    }
    setIsLockedUser(false)
  }
  const [alerts, setAlerts] = useState([])
  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  return (
    <>
      <AlertList
        position={'top-right'}
        alerts={alerts}
        timeout={ALERT_TIMEOUT}
        dismissTitle="Begone!"
        onDismiss={onDismissed}
      />

      <div onKeyDown={e => handleOnEnter(e)}>
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-5">
            <Link to="/" className="flex-column-auto mt-5">
              <div className="max-h-100px logo"></div>
            </Link>
            {templateContext?.portalName ? (
              <h3 className="font-size-h1">
                <FormattedMessage id="PORTAL.NAME" values={{ name: templateContext.portalName }} />
              </h3>
            ) : (
              <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.LOGIN.TITLE" />
              </h3>
            )}
            <h3 className="font-size-h3">
              <FormattedMessage id="AUTH.EMAIL.CONFIRMATION" />
            </h3>
          </div>
          {remainingOnSeconds > 0 && (
            <div className={cx('text-explain')} style={{ textAlign: 'center', marginBottom: '20px' }}>
              OTP code has been sent to <b>{formik.values.email}</b>. Your session will be expired in&nbsp;
              <b style={{ display: 'inline-block', minWidth: '20px' }}> {remainingOnSeconds} </b> seconds...
            </div>
          )}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <Alert variant="danger" onClose={handleDismiss} dismissible>
                <div style={{ textAlign: 'center' }}>{formik.status}</div>
              </Alert>
            )}
            <div className="form-group fv-plugins-icon-container">
              <div className="form-group fv-plugins-icon-container">
                <div className="row">
                  <div className="col text-center">
                    {otp.map((data, index) => {
                      return (
                        <input
                          disabled={formik.isSubmitting || isLogin}
                          ref={index === activeOtpIndex ? inputRef : null}
                          className={cx('otp-field')}
                          type="number"
                          name="otp"
                          key={index}
                          value={data}
                          onKeyDown={e => handleOnKeyDown(e, index)}
                          onChange={e => handleChange(e.target, index)}
                        />
                      )
                    })}
                    {formik.touched.otp && formik.errors.otp ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.otp}</div>
                      </div>
                    ) : null}

                    <div style={{ marginTop: '30px' }}>
                      <button
                        type="button"
                        disabled={formik.isSubmitting || isLogin}
                        className="btn btn-secondary mr-2 btn-secondary-custom"
                        onClick={e => setOtp([...otp.map(v => '')])}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSubmit()}
                        disabled={formik.isSubmitting || isLogin}
                        className="btn btn-primary btn-primary-custom"
                        style={{ width: '150px' }}
                      >
                        Verify OTP
                        {(formik.isSubmitting || isLogin) && <span className="ml-3 spinner spinner-white"></span>}
                      </button>
                    </div>
                    {verifyUnlock.email == '' || isResend ? (
                      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        You did not receive the OTP code? Do you want to{' '}
                        <a href="#" onClick={handleResend}>
                          <b>resend?</b>
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <a
                        href="#"
                        onClick={handleCancel}
                        className="text-dark text-hover-primary-custom mb-1 font-size-lg text-hover-primary-custom"
                      >
                        <FormattedMessage id={'AUTH.GENERAL.CANCEL_BUTTON'} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  verifyUnlock: state.auth.verifyUnlock
})
export default injectIntl(connect(mapStateToProps, actions)(VerifyOTP))
