import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { checkIsActive } from '../../../../_helpers'
import { useLocation } from 'react-router'

export function AsideMenuItem(props) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : ''
  }

  return (
    <>
      <li className={`menu-item ${getMenuItemActive(props.to)}`} aria-haspopup="true">
        <NavLink className="menu-link" to={props.to}>
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">{props.title}</span>
        </NavLink>
      </li>
    </>
  )
}
