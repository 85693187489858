/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Clock from 'react-live-clock'
import { connect } from 'react-redux'
import { actions as actionsTheme } from '../../../../../redux/basePageRedux'
const HeaderMenu = ({ layoutProps, themeMode }) => {
  const [theme, setTheme] = useState('Light')
  useEffect(() => {
    setTheme(themeMode?.themeMode?.theme)
  }, [themeMode?.themeMode?.theme])
  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item menu-item-rel`}>
          <div className="topbar-item" style={{ color: theme == 'Light' ? 'black' : 'white' }}>
            UTC&nbsp;
            <Clock format={'HH:mm:ss'} ticking={true} timezone={'UTC'} />
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  )
}

const mapStateToProps = state => ({
  themeMode: state.themeMode
})

export default connect(mapStateToProps, actionsTheme)(HeaderMenu)
