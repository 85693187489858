export const INVALID_SESSION_TOKEN_RESULT_CODE = 4003
export const EXPIRED_SESSION_TOKEN_RESULT_CODE = 4004
export const EXPIRED_JWT_TOKEN_RESULT_CODE = 9999
export const FORCE_LOG_OUT_RESULT_CODE = 10224
export const ACCOUNT_LOCKED = 10003
export const INTERNAL_SERVER_ERROR_RESULT_CODE = 999
export const EXPIRED_SESSION_LOGIN_MESSAGE = 'expiredLoginSessionMessage'
export const FORCE_LOG_OUT_MESSAGE = 'forceLogOutMessage'
export const CHANGE_PASSWORD_FORCE_LOG_OUT_MESSAGE = 'changePasswordForceLogOut'
export const FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY = 'duplicateLoginForceLogout'
export const REDIRECT_DEFAULT_COUNTDOWN_TIME_IN_SECONDS = 30
export const BASE64_IMAGE_PREFIX = 'data:image/gif;base64,'
export const PRODUCT_REDEEM_STEP_VALIDATE = 1
export const PRODUCT_REDEEM_STEP_REDEEM = 2
export const PRODUCT_REDEEM_STEP_RECEIPT = 3
export const AGENT_TYPE_DISTRIBUTOR = 'DISTRIBUTOR'
export const LOGIN_TOKEN_KEY = 'loginToken'
export const IS_ENABLE_PAYOUT_KEY = 'isEnablePayout'
export const SUCCESS_RESULT_CODE = 0
export const RETRY_CONNECT_SOCKET_LIMIT = 10
export const SOCKET_NORMAL_CLOSURE_CODE = 1000

// See https://support.google.com/mail/answer/6590?visit_id=637873439314585425-3017274466&p=BlockedMessage&rd=1#zippy=%2Cmessages-that-have-attachments
export const BLOCKED_FILE_TYPES = [
  'ade',
  'adp',
  'apk',
  'appx',
  'appxbundle',
  'bat',
  'cab',
  'chm',
  'cmd',
  'com',
  'cpl',
  'dll',
  'dmg',
  'ex',
  'ex_',
  'exe',
  'hta',
  'ins',
  'isp',
  'iso',
  'jar',
  'js',
  'jse',
  'lib',
  'lnk',
  'mde',
  'msc',
  'msi',
  'msix',
  'msixbundle',
  'msp',
  'mst',
  'nsh',
  'pif',
  'ps1',
  'scr',
  'sct',
  'shb',
  'sys',
  'vb',
  'vbe',
  'vbs',
  'vxd',
  'wsc',
  'wsf',
  'wsh'
]

export const ACCEPTED_FILE_TYPES = `.docx, .xls, .xlsx, .ppt, .pptx, .xps, .pdf, .dxf, .psd, .eps, .ps, .svg, .ttf,
.zip, .rar, .tar, .gzip, .mp3, .mpeg, .wav, .ogg, .jpeg, .jpg, .png, .gif, .bmp, .tif, .webm,
 .mpeg4, .3gpp, .mov, .avi, .mpegps, .wmv, .flv, .txt, .odt`
