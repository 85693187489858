import { put, call, takeLatest } from 'redux-saga/effects'
import { getListResourceManagement } from './resourceManagementCrud'

export const actionTypes = {
  resourceManagementLoading: '[resourceManagement] Loading',
  resourceManagementReceived: '[resourceManagement] Received',
  resourceManagementFailed: '[resourceManagement] Failed'
}

const initialResourceManagementState = {
  resources: {
    data: [],
    totalRecord: 0,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialResourceManagementState, action) => {
  switch (action.type) {
    case actionTypes.resourceManagementLoading: {
      return {
        ...state,
        resources: {
          ...state.resources,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.resourceManagementReceived: {
      const data = action.payload
      return {
        ...state,
        resources: {
          data: data,
          totalRecord: data.totalRecord,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.resourceManagementFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        resources: {
          ...state.resources,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  resourceManagementLoading: params => ({
    type: actionTypes.resourceManagementLoading,
    payload: null,
    params
  }),
  resourceManagementReceived: payload => ({
    type: actionTypes.resourceManagementReceived,
    payload
  }),
  resourceManagementFailed: payload => ({
    type: actionTypes.resourceManagementFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.resourceManagementLoading, function* resourceManagementLoadingSaga({ params }) {
    try {
      const response = yield call(getListResourceManagement, params)
      const { resultCode, resultDescription, totalRecord, data } = response.data
      if (resultCode === 0)
        yield put(actions.resourceManagementReceived({ resultCode, resultDescription, data, totalRecord }))
      else yield put(actions.resourceManagementFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.resourceManagementFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
