import React from 'react'

export const ColorStatusColumnFormatter = (cellContent, row) => {
  if (row.limitInfo.status === 'active') {
    return (
      <span className="label label-inline label-light-success font-weight-bold">
        {row.limitInfo.status.toUpperCase()}
      </span>
    )
  }

  if (row.limitInfo.status === 'inactive') {
    return (
      <span className="label label-inline label-light-info font-weight-bold">{row.limitInfo.status.toUpperCase()}</span>
    )
  }
}
