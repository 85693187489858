/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ContentRoute } from '../../../../_metronic/layout'
import Login from './Login'
import Registration from './Registration'
import ForgotPassword from './ForgotPassword'
import ContactSupport from './ContactSupport'
import ResetPassword from './ResetPassword'
import ImpersonateLogin from './ImpersonateLogin'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import { version } from '../../../../../package.json'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

export function AuthPage() {
  const appVersion = useSelector(state => state.appVersion)
  const intl = useIntl()
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/impersonate-login" component={ImpersonateLogin} />
                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                <ContentRoute path="/auth/contact-support" component={ContactSupport} />
                <ContentRoute path="/auth/user-reset-password" component={ResetPassword} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 d-flex justify-content-lg-end justify-content-sm-center justify-content-md-center">
                <div className="col-sm-12 col-md-8 col-lg-6 d-flex flex-lg-row flex-md-row flex-column justify-content-lg-end justify-content-md-end align-items-center">
                  <span>
                    {intl.formatMessage({ id: 'APP.VERSION.LABEL' })}
                    {version} &nbsp;&nbsp;
                  </span>
                  {appVersion.versionData.version == null ? (
                    <span className="text-nowrap">
                      {intl.formatMessage({ id: 'API.VERSION.LABEL' })}
                      <span className="text-nowrap text-danger">
                        {intl.formatMessage({ id: 'API.VERSION.VALUE.ERROR' })}
                      </span>
                    </span>
                  ) : (
                    <span className="text-nowrap">
                      {intl.formatMessage({ id: 'API.VERSION.LABEL' })}
                      {appVersion.versionData.version}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
