import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import style from '../resources.module.css'
import classNames from 'classnames/bind'
import { injectIntl } from 'react-intl'
const cx = classNames.bind(style)
import { NoRecordsFoundMessage, PleaseWaitMessage } from '../../../../_metronic/_helpers'
import ActionsColumnFormatter from './ActionsColumnFormatter'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { Pagination } from '../../../../_metronic/_partials/controls'
const defaultSorted = [{ dataField: 'date', order: 'asc' }]
const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
]
const ResourcesTable = ({ resourceList, generateAlert, theme, setSearchFilter, filter }) => {
  const [isDownLoading, setIsDownLoading] = useState(false)
  const [rowIndex, setRowIndex] = useState(null)

  const { data, totalRecord, isLoading } = resourceList

  const RESOURCES_COLUMN = [
    {
      dataField: 'id',
      text: 'No.',
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      },
      formatter: (cell, row, rowIndex) => {
        let rowNumber = (filter.pageIndex - 1) * filter.pageSize + (rowIndex + 1)
        return rowNumber
      }
    },
    {
      dataField: 'content',
      text: 'File Name',
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },

    {
      dataField: 'action',
      text: 'Action',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        isDownLoading: isDownLoading,
        setIsDownLoading: setIsDownLoading,
        downLoadIndex: rowIndex,
        setRowIndex: setRowIndex,
        generateAlert: generateAlert,
        theme: theme
      },
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'right' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'right'
      }
    }
  ]

  const getHandlerTableChange = () => {
    return (type, { page, sizePerPage, data }) => {
      const pageNumber = page || 1
      let updatedFilters = type === 'pagination' ? { ...filter, pageIndex: pageNumber, pageSize: sizePerPage } : filter
      setSearchFilter(updatedFilters)
    }
  }

  const paginationTotalRenderer = (from, to, size) => {
    let infoCallBack = 'Showing ' + from + ' to ' + to
    let exportShowing = null
    infoCallBack += ' of ' + size + ' entries'
    return (
      <span className="react-bootstrap-table-pagination-total">
        {infoCallBack} <br />
        {exportShowing ? exportShowing : ''}
      </span>
    )
  }

  const options = {
    custom: true,
    totalSize: totalRecord,
    sizePerPageList: sizePerPageList,
    sizePerPage: filter.pageSize,
    page: filter.pageIndex,
    paginationTotalRenderer
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <ToolkitProvider keyField="id" data={data || []} columns={RESOURCES_COLUMN} columnToggle>
                {props => {
                  return (
                    <div style={{ width: '100%' }}>
                      <p className={cx('page-title')}>Resources</p>
                      <div>
                        <BootstrapTable
                          wrapperClasses={cx('table-responsive')}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          bordered={false}
                          keyField="id"
                          remote
                          data={data === null ? [] : data}
                          columns={RESOURCES_COLUMN}
                          defaultSorted={defaultSorted}
                          pagination={paginationFactory()}
                          onTableChange={getHandlerTableChange()}
                          rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                          {...props.baseProps}
                          {...paginationTableProps}
                        />
                        {!isLoading && (
                          <>
                            <PleaseWaitMessage entities={data} />
                            <NoRecordsFoundMessage entities={data} />
                          </>
                        )}
                      </div>
                    </div>
                  )
                }}
              </ToolkitProvider>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </>
  )
}

export default injectIntl(ResourcesTable)
