import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { editUser } from '../../redux/userManagementCrud'
import {
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  FormHelperText
} from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { checkHasPermission } from '../../../_helper/MenuHelper'
import { useSelector } from 'react-redux'
const listStatus = [
  {
    label: 'Active',
    value: '1'
  },
  {
    label: 'Inactive',
    value: '4'
  }
]
const UserDetailModal = ({
  generateAlert,
  getUserManagementLoading,
  searchFilters,
  userDetailsData,
  handleCloseModal,
  isOpenModal,
  cx,
  theme,
  intl
}) => {
  const { user } = useSelector(state => state.auth)
  const [isEdit, setIsEdit] = useState(false)
  const INIT_VALUE = {
    id: userDetailsData.data?.id ? userDetailsData.data.id : '',
    statusId: userDetailsData.data?.statusId ? userDetailsData.data?.statusId : '',
    description: userDetailsData.data?.description ? userDetailsData.data?.description : '',
    emailAddress: userDetailsData.data?.emailAddress ? userDetailsData.data?.emailAddress : '',
    phoneNumber: userDetailsData.data?.phoneNumber ? userDetailsData.data?.phoneNumber : '',
    firstName: userDetailsData.data?.firstName ? userDetailsData.data?.firstName : '',
    surname: userDetailsData.data?.surname ? userDetailsData.data?.surname : '',
    position: userDetailsData.data?.position ? userDetailsData.data?.position : '',
    refundVoucherPermission: userDetailsData.data?.refundVoucherPermission === 'T' ? true : false,
    resetPassword: userDetailsData.data?.resetPassword ? userDetailsData.data?.resetPassword : false,
    validatePinPermission: userDetailsData.data?.validatePinPermission
      ? userDetailsData.data?.validatePinPermission
      : false,
    redeemVoucherPermission: userDetailsData.data?.redeemVoucherPermission
      ? userDetailsData.data?.redeemVoucherPermission
      : false,
    acls: userDetailsData.data?.acls ? userDetailsData.data?.acls : []
  }

  const editUserSchema = Yup.object().shape({
    description: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    emailAddress: Yup.string().email('Wrong email format'),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^(\+|\d)[0-9]{3,20}$/, intl.formatMessage({ id: 'INVALID.PHONE.NUMBER' })),
    firstName: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    surname: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const editUserFormik = useFormik({
    initialValues: INIT_VALUE,
    validationSchema: editUserSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      editUser(values)
        .then(res => {
          const { resultCode, resultDescription } = res.data
          if (resultCode === 0) {
            generateAlert('success', 'Update User', resultDescription)
            getUserManagementLoading(searchFilters)
            handleCloseEditModal()
            setIsEdit(false)
          } else {
            generateAlert('danger', 'Update User', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', 'Update User', error.message)
        })
        .finally(data => {
          setSubmitting(false)
        })
    }
  })

  const handleStatusChange = e => {
    const status = e.target.value
    editUserFormik.setFieldValue('statusId', status)
  }

  const handleCloseEditModal = () => {
    if (editUserFormik.isSubmitting) return
    setIsEdit(false)
    handleCloseModal()
  }

  const handleCancelEditModal = () => {
    if (editUserFormik.isSubmitting) return
    editUserFormik.setValues(INIT_VALUE)
    setIsEdit(false)
  }

  return (
    <>
      <Modal
        show={isOpenModal}
        onHide={handleCloseEditModal}
        centered
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">User Detail</Modal.Title>
          <Button onClick={handleCloseEditModal} variant="secondary">
            x
          </Button>
        </Modal.Header>
        <Modal.Body className={cx('modal-body')}>
          <form
            onSubmit={editUserFormik.handleSubmit}
            className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
          >
            <ul className={cx('user-create-container')}>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Login"
                    type="text"
                    name="username"
                    disabled
                    value={`${userDetailsData.data?.username}`}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black', backgroundColor: '#edeef2' }
                    }}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')} style={{ paddingTop: '10px' }}>
                <FormControl
                  size="small"
                  className={cx('width-90-percent')}
                  style={{ paddingTop: '12px' }}
                  variant="filled"
                >
                  <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
                    Select Status
                  </InputLabel>
                  <Select
                    value={editUserFormik.values.statusId}
                    onChange={handleStatusChange}
                    onBlur={editUserFormik.handleBlur}
                    disabled={!isEdit}
                    input={<OutlinedInput labelWidth={100} name="statusId" id="statusId" />}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    error={Boolean(editUserFormik.touched.statusId && editUserFormik.errors.statusId)}
                  >
                    {listStatus &&
                      listStatus.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        )
                      })}
                  </Select>
                  <FormHelperText error>
                    {(editUserFormik.touched.statusId && editUserFormik.errors.statusId) || ''}
                  </FormHelperText>
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>

              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Description"
                    type="text"
                    disabled={!isEdit}
                    name="description"
                    onChange={editUserFormik.handleChange}
                    value={editUserFormik.values.description}
                    onBlur={editUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(editUserFormik.touched.description && editUserFormik.errors.description)}
                    helperText={(editUserFormik.touched.description && editUserFormik.errors.description) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Email Address"
                    type="text"
                    disabled={!isEdit}
                    name="emailAddress"
                    onChange={editUserFormik.handleChange}
                    value={editUserFormik.values.emailAddress}
                    onBlur={editUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(editUserFormik.touched.emailAddress && editUserFormik.errors.emailAddress)}
                    helperText={(editUserFormik.touched.emailAddress && editUserFormik.errors.emailAddress) || ''}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Phone Number"
                    type="text"
                    disabled={!isEdit}
                    name="phoneNumber"
                    onChange={editUserFormik.handleChange}
                    value={editUserFormik.values.phoneNumber}
                    onBlur={editUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 25
                    }}
                    error={Boolean(editUserFormik.touched.phoneNumber && editUserFormik.errors.phoneNumber)}
                    helperText={(editUserFormik.touched.phoneNumber && editUserFormik.errors.phoneNumber) || ''}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="First name"
                    type="text"
                    disabled={!isEdit}
                    name="firstName"
                    onChange={editUserFormik.handleChange}
                    value={editUserFormik.values.firstName}
                    onBlur={editUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 64
                    }}
                    error={Boolean(editUserFormik.touched.firstName && editUserFormik.errors.firstName)}
                    helperText={(editUserFormik.touched.firstName && editUserFormik.errors.firstName) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Surname"
                    type="text"
                    disabled={!isEdit}
                    name="surname"
                    onChange={editUserFormik.handleChange}
                    value={editUserFormik.values.surname}
                    onBlur={editUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 64
                    }}
                    error={Boolean(editUserFormik.touched.surname && editUserFormik.errors.surname)}
                    helperText={(editUserFormik.touched.surname && editUserFormik.errors.surname) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Position"
                    type="text"
                    disabled={!isEdit}
                    name="position"
                    onChange={editUserFormik.handleChange}
                    value={editUserFormik.values.position}
                    onBlur={editUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(editUserFormik.touched.position && editUserFormik.errors.position)}
                    helperText={(editUserFormik.touched.position && editUserFormik.errors.position) || ''}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item', 'checkbox-custom')}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isEdit}
                      checked={editUserFormik.values.refundVoucherPermission}
                      onChange={editUserFormik.handleChange}
                      name="refundVoucherPermission"
                      color="primary"
                    />
                  }
                  label="Refund Voucher Permission"
                  labelPlacement="end"
                />
              </li>
              {checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_R']) && (
                <li className={cx('user-create-item', 'checkbox-custom')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={editUserFormik.values.validatePinPermission}
                        onChange={editUserFormik.handleChange}
                        name="validatePinPermission"
                        color="primary"
                      />
                    }
                    label="Validate Pin Permission"
                    labelPlacement="end"
                  />
                </li>
              )}

              {checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_W']) && (
                <li className={cx('user-create-item', 'checkbox-custom')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        checked={editUserFormik.values.redeemVoucherPermission}
                        onChange={editUserFormik.handleChange}
                        name="redeemVoucherPermission"
                        color="primary"
                      />
                    }
                    label="Redeem Voucher Permission"
                    labelPlacement="end"
                  />
                </li>
              )}
            </ul>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              {!isEdit ? (
                <button
                  onClick={() => setIsEdit(true)}
                  type="button"
                  className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
                >
                  <span>Edit</span>
                </button>
              ) : (
                <>
                  <button
                    onClick={handleCancelEditModal}
                    type="button"
                    className={cx(
                      'button',
                      'button-block',
                      'btn',
                      `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`,
                      'btn-lg'
                    )}
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="submit"
                    className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
                  >
                    <span>Update</span>
                    {editUserFormik.isSubmitting && (
                      <span style={{ marginRight: '10px' }} className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default injectIntl(UserDetailModal)
