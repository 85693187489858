import React, { useEffect, useState } from 'react'
import { AlertList } from 'react-bs-notifier'
import { Card, CardBody } from '../../../_metronic/_partials/controls'
import SettlementEarningsTable from './components/SettlementEarningsTable'
import { connect, useSelector } from 'react-redux'
import { actions } from './redux/SettlementEarningsRedux'
import style from './settlement-earnings.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const ALERT_TIMEOUT = 15000

const SettlementEarnings = ({
  settlementEarnings,
  settlementEarningsReportLoading,
  settlementEarningsReportReceived
}) => {
  const [reports, setReports] = useState([])
  useEffect(() => {
    settlementEarningsReportLoading()

    return () => settlementEarningsReportReceived({ data: [] })
  }, [])

  useEffect(() => {
    if (settlementEarnings.data) {
      const listData = settlementEarnings.data
      listData.map((item, index) => {
        item.id = index + 1
      })
      setReports(listData)
    }
  }, [settlementEarnings.data])

  const [alerts, setAlerts] = useState([])
  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  return (
    <>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>
      <CardBody className={cx('wrapper', theme === 'Dark' && 'wrapper-dark')}>
        <SettlementEarningsTable
          isLoading={settlementEarnings.isLoading}
          reportList={reports}
          generateAlert={generateAlert}
          theme={theme}
        />
      </CardBody>
    </>
  )
}

const mapStateToProps = state => ({
  settlementEarnings: state.settlementEarnings.settlementEarningsReport
})

export default connect(mapStateToProps, actions)(SettlementEarnings)
