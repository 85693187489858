import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import style from './payoutTransaction.module.scss'
import { actions } from './redux/payoutTransactionRedux'
import classNames from 'classnames/bind'
import { connect, useDispatch } from 'react-redux'
import CustomSpinner from '../../../_metronic/_partials/spinner'
import PayoutTransactionReport from './components/PayoutTransactionReport'
import PayoutTransactionTable from './components/PayoutTransactionTable'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { Client } from '@stomp/stompjs'
import { env } from '../../../env'
import { SUCCESS_RESULT_CODE } from '../../../utils/Constants'
const cx = classNames.bind(style)
const NUMBER_OF_DAYS_OF_THE_WEEK = 6

const PayoutTransaction = ({
  top10PayoutTransactions,
  weeklyTransactions,
  weeklyTransactionLoading,
  agentUser,
  owedBalances
}) => {
  const [payoutTransactionData, setPayoutTransactionData] = useState()
  const [availablePayoutBalanceData, setAvailablePayoutBalanceData] = useState([])
  const [maxBalance, setMaxBalance] = useState(null)
  const [otherBalances, setOtherBalances] = useState([])
  const [maxValueSuccessfulPayout, setMaxValueSuccessfulPayout] = useState(null)
  const [otherValueSuccessfulPayout, setOtherValueSuccessfulPayout] = useState([])
  const [transactionQuantity, setTransactionQuantity] = useState(0)
  const [successfulPayoutData, setSuccessfulPayoutData] = useState([])
  const [weeklyTransactionDay, setWeeklyTransactionDay] = useState(['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'])
  const [weeklyTransactionCounts, setWeeklyTransactionCounts] = useState([0, 0, 0, 0, 0, 0, 0])
  const [payoutVoucherCreditLimit, setPayoutVoucherCreditLimit] = useState(0)
  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))
  const dispatch = useDispatch()
  useEffect(() => {
    weeklyTransactionLoading()
    loadTop10PayoutTransactions({
      transType: 'all',
      status: 'all',
      start: null,
      end: null,
      pageSize: 10,
      pageIndex: 1,
      orderType: 'DESC',
      ignoreTotalSize: true,
      includeSubAgent: true,
      ignoreReturnDate: true,
      isGetPayout: true
    })
  }, [])

  useEffect(() => {
    if (weeklyTransactions.transactionStats) {
      const data = weeklyTransactions.transactionStats
      const days = setDaysArray(data)
      setWeeklyTransactionDay(days)

      let quantity = 0
      data.forEach(item => {
        quantity += item.total
      })
      setTransactionQuantity(quantity)

      const count = setCountArray(days, data)
      setWeeklyTransactionCounts(count)
    }

    if (weeklyTransactions.totalSuccessValues) {
      const data = weeklyTransactions.totalSuccessValues

      setSuccessfulPayoutData(data)
    }
  }, [weeklyTransactions])

  useEffect(() => {
    const payoutVoucherCreditLimit = +agentUser?.data?.agentDetail?.payoutVoucherCreditLimit
    if (owedBalances.data.length > 0 && agentUser.data) {
      let availablePayoutBalance = []

      owedBalances.data.map(item => {
        availablePayoutBalance = [
          ...availablePayoutBalance,
          { ...item, balance: item.balance + payoutVoucherCreditLimit }
        ]
      })

      setAvailablePayoutBalanceData(availablePayoutBalance)
    }
    setPayoutVoucherCreditLimit(payoutVoucherCreditLimit)
  }, [owedBalances, agentUser])

  useEffect(() => {
    setMaxValue(successfulPayoutData, setMaxValueSuccessfulPayout)
  }, [successfulPayoutData])

  useEffect(() => {
    setMaxValue(availablePayoutBalanceData, setMaxBalance)
  }, [availablePayoutBalanceData])

  const setMaxValue = (data, setValueFunction) => {
    if (data.length > 0) {
      if (data.length === 1) {
        setValueFunction(data[0])
      }

      if (data && data.length > 1) {
        setValueFunction(
          data.filter(
            balance => balance.balance === Math.max(...data.map(accumulatedBalance => accumulatedBalance.balance))
          )[0]
        )
      }
    }
  }

  useEffect(() => {
    if (maxBalance) {
      const currencyCodeOfMax = maxBalance.currencyCode
      setOtherBalances(availablePayoutBalanceData.filter(item => item.currencyCode != currencyCodeOfMax))
    }
  }, [maxBalance])

  useEffect(() => {
    if (maxValueSuccessfulPayout) {
      const currencyCodeOfMax = maxValueSuccessfulPayout.currencyCode
      setOtherValueSuccessfulPayout(
        successfulPayoutData.filter(item => item.currencyCode != currencyCodeOfMax && item.balance > 0)
      )
    }
  }, [maxValueSuccessfulPayout])

  useEffect(() => {
    if (top10PayoutTransactions.data) {
      setPayoutTransactionData(top10PayoutTransactions.data)
    }
  }, [top10PayoutTransactions.data])

  const setCountArray = (days, data) => {
    let count = []
    days.forEach(item => {
      let obj = data.find(o => {
        if (item === o.dayOfWeek) return o
      })
      if (obj) {
        const object = {
          x: obj.day,
          y: obj.total
        }
        count.push(object)
      } else {
        count.push({ x: 'NaN', y: 0 })
      }
    })
    return count
  }

  const setDaysArray = data => {
    let days = []
    let curr = new Date()
    let startDay = moment(curr.setDate(curr.getDate() - 6))
    for (let i = 0; i <= NUMBER_OF_DAYS_OF_THE_WEEK; i++) {
      let day = moment(startDay).format('ddd')
      days.push(day.toUpperCase())
      let nextDay = startDay.clone().add(1, 'days')
      startDay = nextDay
    }
    return days
  }
  const { authToken, user } = useSelector(state => state.auth)

  const loadTop10PayoutTransactions = searchFilters => {
    const headers = {
      Username: user.login,
      Authorization: authToken
    }
    dispatch(actions.top10PayoutTransactionLoading())
    const onConnected = () => {
      client.subscribe(
        `/transactions/${user.login}/dashboard`,
        message => {
          if (message.body) {
            const { data, resultCode, resultDescription } = JSON.parse(message.body).body
            if (resultCode === SUCCESS_RESULT_CODE) {
              dispatch(
                actions.top10PayoutTransactionReceived({
                  resultCode,
                  resultDescription,
                  data
                })
              )
            } else {
              dispatch(
                actions.top10PayoutTransactionFailed({
                  error: resultDescription
                })
              )
            }
          }
        },
        headers
      )

      client.publish({
        destination: `/app/transaction/${user.login}/dashboard`,
        body: JSON.stringify(searchFilters),
        headers: headers
      })
    }
    const client = new Client({
      brokerURL: env.REACT_APP_WS_URL,
      connectHeaders: headers,
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onConnect: onConnected
    })

    client.activate()

    return () => {
      client.deactivate()
    }
  }

  return (
    <>
      {agentUser.isLoading ? (
        <div className={cx('backdrop')}>
          <CustomSpinner style={{ color: theme === 'Dark' && 'white' }} />
        </div>
      ) : (
        <div>
          <div className={cx('wrapper-element', 'payout-transaction-wrapper')}>
            <PayoutTransactionReport
              theme={theme}
              maxBalance={maxBalance}
              otherBalances={otherBalances}
              payoutVoucherCreditLimit={payoutVoucherCreditLimit}
              maxValueSuccessfulPayout={maxValueSuccessfulPayout}
              otherValueSuccessfulPayout={otherValueSuccessfulPayout}
              transactionQuantity={transactionQuantity}
              weeklyTransactionDay={weeklyTransactionDay}
              weeklyTransactionCounts={weeklyTransactionCounts}
            />
          </div>
          <div className={cx('wrapper-element', 'payout-transaction-wrapper', 'payout-transaction-table')}>
            <PayoutTransactionTable
              isLoading={top10PayoutTransactions.isLoading}
              data={payoutTransactionData}
              error={top10PayoutTransactions.error}
              theme={theme}
            />
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  top10PayoutTransactions: state.payoutTransaction.top10PayoutTransactions,
  weeklyTransactions: state.payoutTransaction.weeklyTransactions,
  agentUser: state.agentUser.agentUser,
  owedBalances: state.agentUser.owedBalances
})

export default injectIntl(connect(mapStateToProps, actions)(PayoutTransaction))
