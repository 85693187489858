import { merchantApiClient } from '../../../../redux'
export const GET_USERS_URL = '/api/v1/user/list'
export const GET_USER_DETAILS_URL = '/api/v1/user/details?id='
export const EDIT_USER_URL = '/api/v1/user/edit'
export const ADD_NEW_USER_URL = '/api/v1/user/new'
export const DELETE_USER_URL = '/api/v1/user/delete'

export const getUsers = ({ orderBy, orderType, pageSize, pageIndex }) => {
  return merchantApiClient.post(GET_USERS_URL, { orderBy, orderType, pageSize, pageIndex })
}

export const getUserDetail = userId => {
  return merchantApiClient.get(GET_USER_DETAILS_URL + userId)
}

export const editUser = payload => {
  return merchantApiClient.put(EDIT_USER_URL, payload)
}

export const addUser = payload => {
  return merchantApiClient.post(ADD_NEW_USER_URL, payload)
}

export const deleteUser = payload => {
  return merchantApiClient.put(DELETE_USER_URL, payload)
}
