import { put, call, takeLatest } from 'redux-saga/effects'
import { getSubDivisionAndAgent, getNotice, searchTransactions, getTerminalId } from './reportsCrud'

export const actionTypes = {
  transactionReportLoading: '[transactionReport] Loading',
  transactionReportReceived: '[transactionReport] Received',
  transactionReportFailed: '[transactionReport] Failed',
  noticeReportLoading: '[noticeReport] Loading',
  noticeReportReceived: '[noticeReport] Received',
  noticeReportFailed: '[noticeReport] Failed',
  subDivisionAndAgentLoading: '[subDivisionAndAgent] Loading',
  subDivisionAndAgentReceived: '[subDivisionAndAgent] Received',
  subDivisionAndAgentFailed: '[subDivisionAndAgent] Failed',
  clearTransactionReport: '[clearTransactionReport] Clear',
  terminalIdReportLoading: '[terminalId] Loading',
  terminalIdReportReceived: '[terminalId] Received',
  terminalIdReportFailed: '[terminalId] Failed'
}

const initialReportsState = {
  transactions: {
    data: [],
    totalRecord: 0,
    error: null,
    isLoading: false
  },
  notice: {
    content: null,
    isLoading: false,
    error: null
  },
  subDivisionAndAgent: {
    data: [],
    isLoading: false,
    error: null
  },
  terminalId: {
    data: [],
    isLoading: false,
    error: null
  }
}

export const reducer = (state = initialReportsState, action) => {
  switch (action.type) {
    case actionTypes.clearTransactionReport: {
      return {
        ...state,
        transactions: {
          data: [],
          error: null,
          isLoading: false
        }
      }
    }
    case actionTypes.transactionReportLoading: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.transactionReportReceived: {
      const data = action.payload
      return {
        ...state,
        transactions: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.transactionReportFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.subDivisionAndAgentLoading: {
      return {
        ...state,
        subDivisionAndAgent: {
          ...state.subDivisionAndAgent,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.subDivisionAndAgentReceived: {
      const data = action.payload
      return {
        ...state,
        subDivisionAndAgent: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.subDivisionAndAgentFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        subDivisionAndAgent: {
          ...state.subDivisionAndAgent,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.noticeReportLoading: {
      return {
        ...state,
        notice: {
          ...state.notice,
          content: null,
          isLoading: true
        }
      }
    }
    case actionTypes.noticeReportReceived: {
      const { data } = action.payload
      return {
        ...state,
        notice: {
          content: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.noticeReportFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        notice: {
          ...state.notice,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.terminalIdReportLoading: {
      return {
        ...state,
        terminalId: {
          ...state.terminalId,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.terminalIdReportReceived: {
      const data = action.payload
      return {
        ...state,
        terminalId: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.terminalIdReportFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        terminalId: {
          ...state.terminalId,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  clearTransactionReport: () => ({
    type: actionTypes.clearTransactionReport,
    payload: null
  }),
  transactionReportLoading: params => ({
    type: actionTypes.transactionReportLoading,
    payload: null,
    params: params
  }),
  transactionReportReceived: payload => ({
    type: actionTypes.transactionReportReceived,
    payload
  }),
  transactionReportFailed: payload => ({
    type: actionTypes.transactionReportFailed,
    payload
  }),
  subDivisionAndAgentLoading: () => ({
    type: actionTypes.subDivisionAndAgentLoading,
    payload: null
  }),
  subDivisionAndAgentReceived: payload => ({
    type: actionTypes.subDivisionAndAgentReceived,
    payload
  }),
  subDivisionAndAgentFailed: payload => ({
    type: actionTypes.subDivisionAndAgentFailed,
    payload
  }),
  noticeReportLoading: () => ({
    type: actionTypes.noticeReportLoading,
    payload: null
  }),
  noticeReportReceived: payload => ({
    type: actionTypes.noticeReportReceived,
    payload
  }),
  noticeReportFailed: payload => ({
    type: actionTypes.noticeReportFailed,
    payload
  }),
  terminalIdReportLoading: () => ({
    type: actionTypes.terminalIdReportLoading,
    payload: null
  }),
  terminalIdReportReceived: payload => ({
    type: actionTypes.terminalIdReportReceived,
    payload
  }),
  terminalIdReportFailed: payload => ({
    type: actionTypes.terminalIdReportFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.subDivisionAndAgentLoading, function* subDivisionAndAgentLoadingSaga() {
    try {
      const response = yield call(getSubDivisionAndAgent)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.subDivisionAndAgentReceived({ resultCode, resultDescription, data }))
      else yield put(actions.subDivisionAndAgentFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.subDivisionAndAgentFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.noticeReportLoading, function* noticeReportLoadingSaga() {
    try {
      const response = yield call(getNotice)
      const { resultCode, resultDescription, data } = response.data

      if (resultCode === 0) yield put(actions.noticeReportReceived({ resultCode, resultDescription, data }))
      else yield put(actions.noticeReportFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.noticeReportFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.terminalIdReportLoading, function* terminalIdLoadingSaga() {
    try {
      const responseData = yield call(getTerminalId)
      const { resultCode, resultDescription, response } = responseData.data
      if (resultCode === 0) yield put(actions.terminalIdReportReceived({ resultCode, resultDescription, response }))
      else yield put(actions.terminalIdReportFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.terminalIdReportFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
