import { put, call, takeLatest } from 'redux-saga/effects'

export const actionTypes = {
  themeModeLoading: '[Theme Mode] Loading',
  themeModelReceived: '[Theme Mode] Received',
  resetStore: '[Reset Store] Reset Store'
}

const initialThemeModeState = {
  themeMode: {
    theme: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialThemeModeState, action) => {
  switch (action.type) {
    case actionTypes.themeModeLoading: {
      return {
        ...state,
        themeMode: {
          ...state.themeMode,
          isLoading: true
        }
      }
    }
    case actionTypes.themeModelReceived: {
      const theme = action.payload
      return {
        ...state,
        themeMode: {
          theme: theme,
          isLoading: false,
          error: null
        }
      }
    }

    default:
      return state
  }
}

export const actions = {
  themeModeLoading: params => ({
    type: actionTypes.themeModeLoading,
    payload: null,
    params: params
  }),
  themeModelReceived: payload => ({
    type: actionTypes.themeModelReceived,
    payload
  }),
  resetStore: () => ({
    type: actionTypes.resetStore
  })
}

export function* saga() {
  yield takeLatest(actionTypes.themeModeLoading, function* themeModeLoadingSaga({ params }) {
    try {
    } catch (error) {
    } finally {
      yield put(actions.themeModelReceived(params))
    }
  })
}
