import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../userManagement.module.css'
import classNames from 'classnames/bind'
import TextField from '@material-ui/core/TextField'
import { deleteUser } from '../../redux/userManagementCrud'

const cx = classNames.bind(style)

const DeleteUserModal = ({ requestDeleteUser, onCloseModal, generateAlert, reloadData, theme }) => {
  const [deleting, setDeleting] = useState(false)
  const handleDeleteUser = () => {
    setDeleting(true)
    deleteUser(requestDeleteUser)
      .then(res => {
        const { resultCode, resultDescription } = res.data
        if (resultCode === 0) {
          generateAlert('success', 'Delete User', resultDescription)
          reloadData()
          handleCloseModal()
        } else {
          generateAlert('danger', 'Delete User', resultDescription)
        }
      })
      .catch(({ message }) => {
        generateAlert('danger', 'Delete User', message)
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  const handleCloseModal = () => {
    if (!deleting) {
      onCloseModal()
    }
  }

  return (
    <Modal
      show={Boolean(requestDeleteUser)}
      onHide={handleCloseModal}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={deleting ? 'static' : true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete User</Modal.Title>
        <Button onClick={handleCloseModal} variant="secondary">
          x
        </Button>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <h6 className={cx('question')}>Are you sure you want to delete user with {requestDeleteUser.username}?</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={deleting}
          className={cx(theme === 'Dark' ? 'btn-dark' : 'btn-secondary-custom')}
          variant="secondary"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          disabled={deleting}
          style={{
            backgroundColor: 'var(--primary-color)',
            borderColor: 'var(--primary-color)'
          }}
          variant="primary"
          onClick={handleDeleteUser}
        >
          OK
          {deleting && (
            <span
              style={{ marginRight: '20px' }}
              className={cx('ml-3', 'spinner', 'spinner-white', 'submit-btn-spinner')}
            ></span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteUserModal
