import React, { useEffect } from 'react'
import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import style from '../../pinOrder.module.css'
import classNames from 'classnames/bind'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import getSymbolFromCurrency from 'currency-symbol-map'
const cx = classNames.bind(style)
function OrderDetails({ intl, productList, agentName, addPinBatchToCreateOrderList, theme }) {
  const INIT_ORDER_DETAILS = {
    agent: agentName,
    productCode: '',
    value: 0,
    qty: 0,
    currency: '',
    totalValue: '0',
    comment: ''
  }
  useEffect(() => {
    pinOrderDetailsFormik.setFieldValue('agent', agentName)
  }, [agentName])

  const pinOrderDetailsSchema = Yup.object().shape({
    agent: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    productCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    qty: Yup.number()
      .integer(
        intl.formatMessage(
          {
            id: 'MUST_BE_AN_INTEGER'
          },
          { name: 'Quantity' }
        )
      )
      .min(
        1,
        intl.formatMessage(
          {
            id: 'GREATER_OR_EQUAL_THAN'
          },
          { name: 'Quantity', value: 1 }
        )
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  })

  const pinOrderDetailsFormik = useFormik({
    initialValues: INIT_ORDER_DETAILS,
    validationSchema: pinOrderDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      const { productCode, qty, comment, currency, value } = values
      let order = { productCode, qty, comment, currency, value }
      addPinBatchToCreateOrderList(order)
      resetForm()
    }
  })

  const handleProductChange = e => {
    let productCode = e.target.value

    let product = productList.filter(product => {
      return product.productCode === productCode
    })
    let value = product[0].value
    let currency = product[0].currency
    pinOrderDetailsFormik.setFieldValue('value', value)
    pinOrderDetailsFormik.setFieldValue('currency', currency)
    pinOrderDetailsFormik.setFieldValue('productCode', productCode)
    pinOrderDetailsFormik.setFieldValue('qty', 0)
    pinOrderDetailsFormik.setFieldValue('totalValue', getSymbolFromCurrency(currency) + ' 0')
    pinOrderDetailsFormik.setErrors({})
    pinOrderDetailsFormik.setTouched({}, false)
  }

  const hanldeQtyChange = e => {
    let quantity = e.target.value
    pinOrderDetailsFormik.setFieldValue('qty', quantity)
    let total = pinOrderDetailsFormik.values.value * quantity
    pinOrderDetailsFormik.setFieldValue(
      'totalValue',
      getSymbolFromCurrency(pinOrderDetailsFormik.values?.currency) + ' ' + total
    )
  }

  return (
    <form
      onSubmit={pinOrderDetailsFormik.handleSubmit}
      className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
    >
      <ul className={cx('order-details-content')}>
        <li className={cx('order-details-item')}>
          <FormControl sx={{ m: 2 }} className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Distribution Entity"
              type="text"
              name="agent"
              onChange={pinOrderDetailsFormik.handleChange}
              value={pinOrderDetailsFormik.values.agent}
              onBlur={pinOrderDetailsFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              disabled
              InputLabelProps={{
                style: theme === 'Dark' ? undefined : { color: 'black' }
              }}
              inputProps={{
                style: theme === 'Dark' ? undefined : { color: 'black', backgroundColor: '#edeef2' }
              }}
              error={Boolean(pinOrderDetailsFormik.touched.agent && pinOrderDetailsFormik.errors.agent)}
              helperText={(pinOrderDetailsFormik.touched.agent && pinOrderDetailsFormik.errors.agent) || ''}
            />
          </FormControl>
        </li>
        <li className={cx('order-details-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              Select Product
            </InputLabel>
            <Select
              value={pinOrderDetailsFormik.values.productCode}
              onChange={handleProductChange}
              onBlur={pinOrderDetailsFormik.handleBlur}
              input={<OutlinedInput labelWidth={100} name="productCode" id="productCode" />}
              inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              error={Boolean(pinOrderDetailsFormik.touched.productCode && pinOrderDetailsFormik.errors.productCode)}
            >
              {productList?.length &&
                productList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.productCode}>
                      {item.productDescription}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText error>
              {(pinOrderDetailsFormik.touched.productCode && pinOrderDetailsFormik.errors.productCode) || ''}
            </FormHelperText>
          </FormControl>
        </li>
        <li className={cx('order-details-item')}>
          <FormControl className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Value"
              type="number"
              name="value"
              onChange={pinOrderDetailsFormik.handleChange}
              value={pinOrderDetailsFormik.values.value}
              onBlur={pinOrderDetailsFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              disabled
              InputLabelProps={{
                style: theme === 'Dark' ? undefined : { color: 'black' }
              }}
              inputProps={{
                style: theme === 'Dark' ? undefined : { color: 'black', backgroundColor: '#edeef2' }
              }}
              error={Boolean(pinOrderDetailsFormik.touched.value && pinOrderDetailsFormik.errors.value)}
              helperText={(pinOrderDetailsFormik.touched.value && pinOrderDetailsFormik.errors.value) || ''}
            />
          </FormControl>
        </li>
        <li className={cx('order-details-item')}>
          <FormControl className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Currency"
              type="text"
              name="currency"
              onChange={pinOrderDetailsFormik.handleChange}
              value={pinOrderDetailsFormik.values.currency}
              onBlur={pinOrderDetailsFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: theme === 'Dark' ? undefined : { color: 'black' }
              }}
              inputProps={{
                style: theme === 'Dark' ? undefined : { color: 'black', backgroundColor: '#edeef2' }
              }}
              error={Boolean(pinOrderDetailsFormik.touched.currency && pinOrderDetailsFormik.errors.currency)}
              helperText={(pinOrderDetailsFormik.touched.currency && pinOrderDetailsFormik.errors.currency) || ''}
              disabled
            />
          </FormControl>
        </li>
        <li className={cx('order-details-item')}>
          <FormControl className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Quantity"
              type="number"
              name="qty"
              onChange={hanldeQtyChange}
              disabled={pinOrderDetailsFormik.values.productCode === '' ? true : false}
              value={pinOrderDetailsFormik.values.qty}
              onBlur={pinOrderDetailsFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: theme === 'Dark' ? undefined : { color: 'black' }
              }}
              inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              error={Boolean(pinOrderDetailsFormik.touched.qty && pinOrderDetailsFormik.errors.qty)}
              helperText={(pinOrderDetailsFormik.touched.qty && pinOrderDetailsFormik.errors.qty) || ''}
            />
          </FormControl>
        </li>
        <li className={cx('order-details-item')}>
          <FormControl className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Total Purchase Value"
              name="totalValue"
              onChange={pinOrderDetailsFormik.handleChange}
              value={pinOrderDetailsFormik.values.totalValue}
              onBlur={pinOrderDetailsFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: theme === 'Dark' ? undefined : { color: 'black' }
              }}
              error={Boolean(pinOrderDetailsFormik.touched.totalValue && pinOrderDetailsFormik.errors.totalValue)}
              helperText={(pinOrderDetailsFormik.touched.totalValue && pinOrderDetailsFormik.errors.totalValue) || ''}
              disabled
              inputProps={{
                style: { color: 'green', backgroundColor: theme === 'Dark' ? undefined : '#edeef2', fontWeight: 'bold' }
              }}
            />
          </FormControl>
        </li>
        <li className={cx('order-details-item')}>
          <FormControl className={cx('width-100-percent')} margin="normal" variant="filled">
            <TextareaAutosize
              aria-label="empty textarea"
              style={{ minHeight: 100, padding: 10, borderRadius: 4, borderColor: 'rgb(237, 238, 242)' }}
              placeholder="Comment"
              margin="normal"
              name="comment"
              onChange={pinOrderDetailsFormik.handleChange}
              value={pinOrderDetailsFormik.values.comment}
              onBlur={pinOrderDetailsFormik.handleBlur}
            />
          </FormControl>
        </li>
      </ul>
      <div className={cx('filter-button')}>
        <button
          type="submit"
          className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
        >
          <span>Add to Order</span>
        </button>
      </div>
    </form>
  )
}

export default OrderDetails
