import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import classNames from 'classnames/bind'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import style from './product-redeem.module.scss'
import { injectIntl } from 'react-intl'
import VoucherValidateForm from './components/VoucherValidateForm'
import VoucherRedeemForm from './components/VoucherRedeemForm'
import VoucherReceiptForm from './components/VoucherReceiptForm'
import { actions } from './redux/VoucherProductRedux'
import {
  PRODUCT_REDEEM_STEP_VALIDATE,
  PRODUCT_REDEEM_STEP_REDEEM,
  PRODUCT_REDEEM_STEP_RECEIPT,
  AGENT_TYPE_DISTRIBUTOR
} from '../../../utils/Constants'
import { checkHasPermission } from '../_helper/MenuHelper'
const cx = classNames.bind(style)

function ProductRedeem(props) {
  const {
    intl,
    voucherValidateLoading,
    voucherStep,
    voucherStepSetting,
    voucherValidated,
    voucherRedeemLoading,
    voucherRedeemed
  } = props
  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const { user } = useSelector(state => state.auth)
  const { agentUser } = useSelector(state => state.agentUser)

  const isAllowRedeem =
    checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_W']) &&
    agentUser?.data?.agentType !== AGENT_TYPE_DISTRIBUTOR

  const [alert, setAlert] = useState('')
  const [resultCode, setResultCode] = useState(0)

  const handleDismiss = () => {
    handleShowAlert('', 0)
  }

  const handleShowAlert = (message, resultCode) => {
    setAlert(message)
    setResultCode(resultCode)
  }

  useEffect(() => {
    if (!voucherValidated || !voucherRedeemed) {
      voucherStepSetting({
        currentStep: PRODUCT_REDEEM_STEP_VALIDATE
      })
    } else if (voucherValidated?.data?.resultCode === 0 && voucherRedeemed?.data?.resultCode !== 0) {
      voucherStepSetting({
        currentStep: PRODUCT_REDEEM_STEP_REDEEM
      })
    } else if (voucherRedeemed?.data?.resultCode === 0) {
      voucherStepSetting({
        currentStep: PRODUCT_REDEEM_STEP_RECEIPT
      })
    }
  }, [voucherValidated, voucherRedeemed])

  return (
    <div className={`${cx('wrapper-element')} ${cx('product-redeem-wrapper')}`}>
      <div className={cx('title', 'page-title')}>
        {voucherStep.data.currentStep === PRODUCT_REDEEM_STEP_VALIDATE || !isAllowRedeem
          ? 'Product Validate'
          : 'Product Redeem'}{' '}
      </div>
      <div className={`row ${cx('content')}`}>
        {alert && (
          <div className={`col-6 ${cx('alert')}`}>
            <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
              <div style={{ textAlign: 'center' }}>{alert}</div>
            </Alert>
          </div>
        )}
        <div className={`col-12 col-md-7 ${cx('form-wrapper')}`}>
          {voucherStep.data.currentStep === PRODUCT_REDEEM_STEP_VALIDATE && (
            <VoucherValidateForm
              cx={cx}
              theme={theme}
              intl={intl}
              onShowAlert={handleShowAlert}
              voucherValidateLoading={voucherValidateLoading}
              voucherValidated={voucherValidated}
            />
          )}

          {voucherStep.data.currentStep === PRODUCT_REDEEM_STEP_REDEEM && (
            <VoucherRedeemForm
              cx={cx}
              theme={theme}
              intl={intl}
              onShowAlert={handleShowAlert}
              voucherRedeemLoading={voucherRedeemLoading}
              voucherValidated={voucherValidated}
              voucherRedeemed={voucherRedeemed}
              voucherStepSetting={voucherStepSetting}
            />
          )}
          {voucherStep.data.currentStep === PRODUCT_REDEEM_STEP_RECEIPT && (
            <VoucherReceiptForm
              cx={cx}
              theme={theme}
              voucherRedeemed={voucherRedeemed}
              voucherStepSetting={voucherStepSetting}
              onShowAlert={handleShowAlert}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  voucherValidated: state.productRedeem.voucherValidated,
  voucherRedeemed: state.productRedeem.voucherRedeemed,
  voucherStep: state.productRedeem.voucherStep
})

export default injectIntl(connect(mapStateToProps, actions)(ProductRedeem))
