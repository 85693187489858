import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../resourceManagement.module.css'
import classNames from 'classnames/bind'
import FormCreateDocument from './FormCreateDocument'
const cx = classNames.bind(style)
import { FormattedMessage, injectIntl } from 'react-intl'
const CreateDocumentModal = ({
  isOpenCreateModal,
  handleCloseCreateModal,
  theme,
  documentTypeList,
  contentTypeList,
  locationList,
  intl,
  reloadData,
  generateAlert
}) => {
  return (
    <>
      <Modal
        show={isOpenCreateModal}
        onHide={handleCloseCreateModal}
        centered
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">New Document</Modal.Title>
          <Button onClick={handleCloseCreateModal} variant="secondary">
            x
          </Button>
        </Modal.Header>
        <Modal.Body className={cx('modal-body')}>
          <FormCreateDocument
            intl={intl}
            theme={theme}
            documentTypeList={documentTypeList}
            contentTypeList={contentTypeList}
            locationList={locationList}
            cx={cx}
            handleCloseCreateModal={handleCloseCreateModal}
            reloadData={reloadData}
            generateAlert={generateAlert}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default injectIntl(CreateDocumentModal)
