import React, { forwardRef } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import { currencyFormat } from '../../../../../../../utils/DataFormatUtils'
import { useIntl } from 'react-intl'

export const TransactionPrint = forwardRef((props, ref) => {
  const intl = useIntl()

  return (
    <div ref={ref}>
      <div style={{ display: 'flex', justifyContent: 'space-between', justifyItems: 'baseline' }}>
        <div style={{ padding: 10, fontWeight: 'bold' }}>
          <span>{props.data.exportTime}</span>
        </div>
      </div>
      {props.data.transactions && (
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.MERCHANT' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.REF_ID' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.EXTERNAL_REF_ID' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.TYPE' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.DATE' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.STATUS' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.TERMINAL' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.RESULT_DESCRIPTION' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.PRODUCT' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.VOUCHER_SERIAL' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.REDEEMED_DATE' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.GROSS_VALUE' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.NET_VALUE' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.AGENT_CODE' })} </th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.REASON' })}</th>
              <th style={{ minWidth: '75px' }}>{intl.formatMessage({ id: 'TRANSACTION.LABEL.REFUND_DATE' })} </th>
            </tr>
          </thead>
          <tbody>
            {props.data.transactions &&
              props.data.transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.agentDescription}</td>
                  <td>{transaction.refId}</td>
                  <td>{transaction.txnId}</td>
                  <td>{transaction.txType}</td>
                  <td>{transaction.date}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.terminalCode}</td>
                  <td>{transaction.resultDesc}</td>
                  <td>{transaction.product}</td>
                  <td>{transaction.voucherSerial}</td>
                  <td>{transaction.redeemedDate}</td>
                  <td>
                    {getSymbolFromCurrency(transaction.currencyCode)}
                    {currencyFormat(transaction.value)}
                  </td>
                  <td>
                    {getSymbolFromCurrency(transaction.currencyCode)}
                    {currencyFormat(transaction.buyValue)}
                  </td>
                  <td>{transaction.agentCode}</td>
                  <td>{transaction.reason}</td>
                  <td>{transaction.returnDate}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  )
})
