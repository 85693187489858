import { merchantApiClient } from '../../../../redux'

export const GET_AGENT_USER_URL = `/api/v1/agent-user-info`

export const GET_OWED_BALANCES_URL = `/api/v1/owed-balances`

export function getAgentUser() {
  return merchantApiClient.get(GET_AGENT_USER_URL)
}

export function getOwedBalances() {
  return merchantApiClient.get(GET_OWED_BALANCES_URL)
}
