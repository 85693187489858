import React, { useEffect, useState } from 'react'
import style from '../resourceManagement.module.css'
import classNames from 'classnames/bind'
import { FormControl, TextField, OutlinedInput, MenuItem, Select, InputLabel } from '@material-ui/core'
const cx = classNames.bind(style)
import { useFormik } from 'formik'

const ResourceManagementFilter = ({
  theme,
  documentTypeList,
  locationList,
  statusList,
  initFilter,
  currentFilter,
  setSearchFilter,
  resourceManagementLoading,
  isLoading
}) => {
  const initialValues = {
    searchText: '',
    documentTypeCode: documentTypeList[0].code,
    location: locationList[0].url,
    status: statusList[0].code
  }
  const searchDocumentFormik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      resourceManagementLoading({ ...initFilter, ...values })
      setSearchFilter({ ...currentFilter, ...searchDocumentFormik.values })
    }
  })

  const handleChangeValue = (e, key) => {
    let value = e.target.value
    searchDocumentFormik.setFieldValue(key, value)
  }

  const handleCancel = () => {
    searchDocumentFormik.resetForm()
  }

  return (
    <>
      <form
        onSubmit={searchDocumentFormik.handleSubmit}
        className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
      >
        <ul className={cx('filter-container')}>
          <li className={cx('filter-item')}>
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label="Search description or code"
                type="text"
                name="searchText"
                onChange={searchDocumentFormik.handleChange}
                value={searchDocumentFormik.values.searchText}
                onBlur={searchDocumentFormik.handleBlur}
                margin="normal"
                variant="outlined"
                size="small"
              />
            </FormControl>
          </li>
          <li className={cx('filter-item')}>
            <FormControl size="small" variant="outlined" margin="normal" className={cx('width-100-percent')}>
              <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
                Document Type
              </InputLabel>
              <Select
                value={searchDocumentFormik.values.documentTypeCode}
                onChange={e => handleChangeValue(e, 'documentTypeCode')}
                onBlur={searchDocumentFormik.handleBlur}
                input={<OutlinedInput labelWidth={110} name="documentTypeCode" id="documentTypeCode" />}
                inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              >
                {documentTypeList &&
                  documentTypeList.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.code}>
                        {item.description}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </li>
          <li className={cx('filter-item')}>
            <FormControl size="small" variant="outlined" margin="normal" className={cx('width-100-percent')}>
              <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
                Location
              </InputLabel>
              <Select
                value={searchDocumentFormik.values.location}
                onChange={e => handleChangeValue(e, 'location')}
                onBlur={searchDocumentFormik.handleBlur}
                input={<OutlinedInput labelWidth={60} name="location" id="location" />}
                inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              >
                {locationList &&
                  locationList.map(item => {
                    return (
                      <MenuItem key={item.url} value={item.url}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </li>
          <li className={cx('filter-item')}>
            <FormControl size="small" variant="outlined" margin="normal" className={cx('width-100-percent')}>
              <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
                Status
              </InputLabel>
              <Select
                value={searchDocumentFormik.values.status}
                onChange={e => handleChangeValue(e, 'status')}
                onBlur={searchDocumentFormik.handleBlur}
                input={<OutlinedInput labelWidth={60} name="status" id="status" />}
                inputProps={{ style: theme === 'Dark' ? undefined : { color: 'black' } }}
              >
                {statusList &&
                  statusList.map(item => {
                    return (
                      <MenuItem key={item.code} value={item.code}>
                        {item.description}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </li>
        </ul>
        <div className={cx('action-button-search')}>
          <button
            disabled={isLoading}
            type="submit"
            className={cx(
              'button',
              'button-block',
              'btn',
              'btn-primary',
              'btn-lg',
              'search-button',
              'btn-primary-custom'
            )}
          >
            <span>Search</span>
            {isLoading && <span style={{ marginRight: '20px' }} className="ml-3 spinner spinner-white"></span>}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className={cx(
              'button',
              'button-block',
              'btn',
              'btn-secondary',
              `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`,
              'btn-lg'
            )}
          >
            <span>Cancel</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default ResourceManagementFilter
