import { merchantApiClient } from '../../../../redux'

export const GET_RESOURCE_LIST = '/api/v1/get-documents'
export const DOWNLOAD_RESOURCE = '/api/v1/download-document?documentId='

export function getResourceList(payload) {
  const { page, pageIndex, pageSize } = payload.params
  return merchantApiClient.post(GET_RESOURCE_LIST, { page, pageIndex, pageSize })
}

export function downloadResource(documentId) {
  return merchantApiClient.get(DOWNLOAD_RESOURCE + documentId, {
    responseType: 'arraybuffer'
  })
}
