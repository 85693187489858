import React from 'react'
import { login } from '../_redux/authCrud'
import { useFormik } from 'formik'
import { Alert } from 'react-bootstrap'
import style from '../auth.module.css'
import classNames from 'classnames/bind'
import * as Yup from 'yup'

const cx = classNames.bind(style)
const GoogleAuthenticator = ({ data, formik, checked, props, setIsGoogleAuthRequired, setCaptcha, resultCode }) => {
  const { intl } = props
  const IS_FIRST_LOGIN_RESULT_CODE = 998
  const IS_NOT_FIRST_LOGIN_RESULT_CODE = 997

  const urlPage = window.location.search
  const params = new URLSearchParams(urlPage)
  const loginTokenRedirect = params.get('loginToken')

  const authenticationCodeSchema = Yup.object().shape({
    pinCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })
  const formikAuth = useFormik({
    initialValues: {
      merchantId: formik.values.merchantId,
      username: formik.values.username,
      password: formik.values.password,
      pinCode: '',
      loginToken: loginTokenRedirect ? loginTokenRedirect : ''
    },
    validationSchema: authenticationCodeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const getMessageCode = resultCode => {
        switch (resultCode) {
          case 10003:
            return 'AUTH.VALIDATION.ACCOUNT_LOCKED'
          case 4099:
            return 'GOOGLE.AUTH.VALIDATION.CODE.FAILD'
          case 999:
            return 'GOOGLE.AUTH.VALIDATION.CODE.FAILD'
          default:
            return 'AUTH.VALIDATION.INVALID_LOGIN'
        }
      }
      setTimeout(() => {
        login(values.merchantId, values.username, values.password, values.pinCode, values.loginToken)
          .then(response => {
            const payload = {
              merchantId: values.merchantId,
              username: values.username
            }
            if (checked) {
              localStorage.setItem('MerchantPortalRememberMeInfo', JSON.stringify(payload))
            }
            let accessToken = response.headers['authorization'].replace('Bearer ', '')
            props.login(accessToken)
            setSubmitting(false)
          })
          .catch(({ response, message }) => {
            const resultCode = response?.data?.resultCode
            const countFailed = response?.data?.countFailed
            const resultDescription = response?.data?.resultDescription
            if (countFailed >= 2) {
              setIsGoogleAuthRequired(false)
              setCaptcha({ isShow: true, value: null })
            }

            if (resultDescription) {
              setStatus(resultDescription)
            } else {
              setStatus(
                intl.formatMessage({
                  id: getMessageCode(resultCode)
                })
              )
            }

            setSubmitting(false)
          })
      }, 1000)
    }
  })
  const handleDismiss = () => {
    formikAuth.setStatus(null)
  }
  return (
    <>
      <div className={cx('content-body')}>
        {data?.resultCode === IS_FIRST_LOGIN_RESULT_CODE && (
          <div className={cx('qr-content')}>
            <p className={cx('title')}>
              Use google authenticator application scan this QR Code to get Verification code or enter account and key
              below:
            </p>
            <p className={cx('title')}>
              Account: <span>{data?.account}</span>
            </p>
            <p className={cx('title')}>
              Secret key: <span>{data?.secretKey}</span>
            </p>
            <img className={cx('qr-code', 'align-self-center')} src={`data:image/jpeg;base64,${data?.qrCode}`} />
          </div>
        )}
        <div
          className={cx('verify-content', data?.resultCode === IS_NOT_FIRST_LOGIN_RESULT_CODE && 'width-100-percent')}
        >
          <form onSubmit={formikAuth.handleSubmit}>
            {formikAuth.status && (
              <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
                {formikAuth.status}
              </Alert>
            )}
            <input
              className={cx('verify-input')}
              name="pinCode"
              type="number"
              autoFocus
              disabled={formikAuth.isSubmitting}
              onChange={formikAuth.handleChange}
              placeholder="Verification code"
              {...formikAuth.getFieldProps('pinCode')}
            />
            {formikAuth.touched.pinCode && formikAuth.errors.pinCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formikAuth.errors.pinCode}</div>
              </div>
            ) : null}
            <button
              disabled={formikAuth.isSubmitting}
              type="submit"
              className={cx('btn', 'btn-lg', 'btn-primary', 'btn-block', 'verify-button', 'btn-primary-custom')}
            >
              Verify
              {formikAuth.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default GoogleAuthenticator
