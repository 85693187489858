import { put, call, takeLatest } from 'redux-saga/effects'
import { getPayoutTransactionList, payoutTransactionWeeklyStats } from './payoutTransactionCrud'
import { SUCCESS_RESULT_CODE } from '../../../../utils/Constants'
export const actionTypes = {
  top10PayoutTransactionLoading: '[top10PayoutTransaction] Loading',
  top10PayoutTransactionReceived: '[top10PayoutTransaction] Received',
  top10PayoutTransactionFailed: '[top10PayoutTransaction] Failed',
  weeklyTransactionLoading: '[weeklyTransaction] Loading',
  weeklyTransactionReceived: '[weeklyTransaction] Received',
  weeklyTransactionFailed: '[weeklyTransaction] Failed'
}

const initialPayoutTransactionState = {
  top10PayoutTransactions: {
    data: null,
    error: null,
    isLoading: false
  },
  weeklyTransactions: {
    transactionStats: [],
    totalSuccessValues: [],
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialPayoutTransactionState, action) => {
  switch (action.type) {
    case actionTypes.top10PayoutTransactionLoading: {
      return {
        ...state,
        top10PayoutTransactions: {
          ...state.top10PayoutTransactions,
          isLoading: true
        }
      }
    }

    case actionTypes.top10PayoutTransactionReceived: {
      const { data } = action.payload
      return {
        ...state,
        top10PayoutTransactions: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }

    case actionTypes.top10PayoutTransactionFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        top10PayoutTransactions: {
          ...state.top10PayoutTransactions,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    case actionTypes.weeklyTransactionLoading: {
      return {
        ...state,
        weeklyTransactions: {
          ...state.weeklyTransactions,
          isLoading: true
        }
      }
    }

    case actionTypes.weeklyTransactionReceived: {
      const { totalSuccessValues, transactionStats } = action.payload
      return {
        ...state,
        weeklyTransactions: {
          transactionStats: transactionStats,
          totalSuccessValues: totalSuccessValues,
          isLoading: false,
          error: null
        }
      }
    }

    case actionTypes.weeklyTransactionFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        weeklyTransactions: {
          ...state.weeklyTransactions,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    default:
      return state
  }
}

export const actions = {
  top10PayoutTransactionLoading: () => ({
    type: actionTypes.top10PayoutTransactionLoading,
    payload: null
  }),

  top10PayoutTransactionReceived: payload => ({
    type: actionTypes.top10PayoutTransactionReceived,
    payload
  }),

  top10PayoutTransactionFailed: payload => ({
    type: actionTypes.top10PayoutTransactionFailed,
    payload
  }),
  weeklyTransactionLoading: () => ({
    type: actionTypes.weeklyTransactionLoading,
    payload: null
  }),

  weeklyTransactionReceived: payload => ({
    type: actionTypes.weeklyTransactionReceived,
    payload
  }),

  weeklyTransactionFailed: payload => ({
    type: actionTypes.weeklyTransactionFailed,
    payload
  })
}

function* weeklyTransactionsSaga() {
  try {
    const response = yield call(payoutTransactionWeeklyStats)
    const { resultCode, resultDescription, totalSuccessValues, transactionStats } = response.data
    if (resultCode === SUCCESS_RESULT_CODE) {
      yield put(
        actions.weeklyTransactionReceived({ resultCode, resultDescription, totalSuccessValues, transactionStats })
      )
    } else {
      yield put(actions.weeklyTransactionFailed({ resultCode, resultDescription }))
    }
  } catch (error) {
    yield put(
      actions.weeklyTransactionFailed({
        resultCode: '',
        resultDescription: error.toString()
      })
    )
  }
}

export function* saga() {
  yield takeLatest(actionTypes.weeklyTransactionLoading, weeklyTransactionsSaga)
}
