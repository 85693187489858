/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { AsideMenuItem } from './AsideMenuItem'
import { useSelector } from 'react-redux'
import { checkExtensionRole, checkHasPermission } from '../../../../../app/pages/_helper/MenuHelper'
import { AGENT_TYPE_DISTRIBUTOR, IS_ENABLE_PAYOUT_KEY } from '../../../../../utils/Constants'
import { useIntl } from 'react-intl'

export function AsideMenuList({ layoutProps }) {
  const { user } = useSelector(state => state.auth)
  const { agentUser } = useSelector(state => state.agentUser)
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : ''
  }
  const intl = useIntl()

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <i className="flaticon2-cardiogram svg-icon menu-icon icon-xl"></i>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.DASHBOARD' })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/company-details', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/company-details">
            <i className="fas fa-sitemap svg-icon menu-icon icon-xl"></i>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.COMPANY_DETAILS' })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/weekly-transaction-summary', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/weekly-transaction-summary">
            <i className="fas fa-coins svg-icon menu-icon icon-xl"></i>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.WEEKLY_SUMMARY' })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {checkExtensionRole(agentUser, IS_ENABLE_PAYOUT_KEY, true) && (
          <li className={`menu-item ${getMenuItemActive('/payout-transaction')}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/payout-transaction">
              <i className="flaticon2-pie-chart svg-icon menu-icon icon-xl"></i>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PAYOUT_TRANSACTION' })}</span>
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/reports', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/reports">
            <i className="flaticon2-line-chart svg-icon menu-icon icon-xl"></i>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.REPORTS' })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {checkHasPermission(user.acls, ['SCP.EVDS.PIN.BATCH.FILE.LIST_R']) && (
          <li className={`menu-item ${getMenuItemActive('/pin-order', false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/pin-order">
              <i className="fas fa-shopping-cart svg-icon menu-icon icon-xl"></i>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PIN_ORDER' })}</span>
            </NavLink>
          </li>
        )}

        {checkHasPermission(user.acls, ['SCP.TTI.USER.LIMIT.TEMPLATE.ADMIN_R']) && (
          <li className={`menu-item ${getMenuItemActive('/credit-limits', false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/credit-limits">
              <i className="fas fa-credit-card svg-icon menu-icon icon-xl"></i>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.CREDIT_LIMIT' })}</span>
            </NavLink>
          </li>
        )}

        {checkHasPermission(user.acls, ['SCP.TTI.ADMIN.USER_R']) && (
          <li className={`menu-item ${getMenuItemActive('/user-management', false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/user-management">
              <i className="flaticon2-avatar svg-icon menu-icon icon-xl"></i>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.USER_MANAGEMENT' })}</span>
            </NavLink>
          </li>
        )}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/resources', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/resources">
            <i className="flaticon2-layers svg-icon menu-icon icon-xl"></i>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.RESOURCES' })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {(checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_R']) ||
          checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_W'])) && (
          <li className={`menu-item ${getMenuItemActive('/product-redeem', false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/product-redeem">
              <i className="flaticon2-shopping-cart svg-icon menu-icon icon-xl"></i>
              <span className="menu-text">
                {checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_W']) &&
                agentUser?.data?.agentType !== AGENT_TYPE_DISTRIBUTOR
                  ? intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PRODUCT_REDEEM' })
                  : intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PRODUCT_VALIDATE' })}{' '}
              </span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/contact-us', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/contact-us">
            <i className="flaticon2-send svg-icon menu-icon icon-xl"></i>
            <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.CONTACT_US' })}</span>
          </NavLink>
        </li>
        {checkHasPermission(user.acls, ['SCP.FLEX.ACCOUNT.MANAGER_R']) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive('/portal-management', true)}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/portal-management">
              <i className="flaticon-menu-1 svg-icon menu-icon icon-xl"></i>
              <span className="menu-text">{intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PORTAL_MANAGEMENT' })}</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <AsideMenuItem
                    title={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.PERFORMANCE_REPORT' })}
                    to="/portal-management/performance-report"
                  />
                  <AsideMenuItem
                    title={intl.formatMessage({ id: 'SIDE.BAR.MENU.TITLE.RESOURCE_REPORT' })}
                    to="/portal-management/resource-management"
                  />
                </ul>
              </ul>
            </div>
          </li>
        )}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
