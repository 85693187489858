import React from 'react'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import Spinner from 'react-bootstrap/Spinner'
import {
  sortCaret,
  headerSortingClasses,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from '../../../../../../../_metronic/_helpers'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import style from '../../resourceManagement.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import { injectIntl } from 'react-intl'
import ActionsColumnFormatter from './column-formatters/ActionsColumnFormatter'
import Icon from '@material-ui/core/Icon'
import { Pagination } from '../../../../../../../_metronic/_partials/controls'
const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
]
const ResourceManagementTable = ({
  resourceManagementList,
  theme,
  setIsOpenCreateModal,
  openDocumentDetailsDialog,
  isLoading,
  totalRecord,
  setSearchFilter,
  filter,
  sortData,
  setSortData,
  setIsPaging
}) => {
  const RESOURCE_MANAGEMENT_COLUMNS = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'code',
      text: 'Code',
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'documentType',
      text: 'Document Type',
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'location.name',
      text: 'Location',
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'active',
      text: 'Status',
      sort: true,
      sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: ActionsColumnFormatter,
      formatExtraData: { theme: theme, openDocumentDetailsDialog: openDocumentDetailsDialog },
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    }
  ]

  const getHandlerTableChange = () => {
    return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
      const pageNumber = page || 1

      let updatedFilters =
        type === 'sort'
          ? { ...filter, orderBy: sortField, orderType: sortOrder }
          : type === 'pagination'
          ? { ...filter, pageIndex: pageNumber, pageSize: sizePerPage }
          : filter
      setSortData({ orderBy: sortField, orderType: sortOrder })
      setIsPaging(true)
      setSearchFilter(updatedFilters)
    }
  }

  const paginationTotalRenderer = (from, to, size) => {
    let infoCallBack = 'Showing ' + from + ' to ' + to
    let exportShowing = null
    infoCallBack += ' of ' + size + ' entries'
    return (
      <span className="react-bootstrap-table-pagination-total">
        {infoCallBack} <br />
        {exportShowing ? exportShowing : ''}
      </span>
    )
  }

  const options = {
    custom: true,
    totalSize: totalRecord,
    sizePerPageList: sizePerPageList,
    sizePerPage: filter.pageSize,
    page: filter.pageIndex,
    paginationTotalRenderer
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <Pagination isLoading={isLoading} paginationProps={paginationProps}>
            <ToolkitProvider
              keyField="id"
              data={resourceManagementList || []}
              columns={RESOURCE_MANAGEMENT_COLUMNS}
              search
            >
              {props => {
                return (
                  <div>
                    <div>
                      <div className={cx('container-table-header')}>
                        <button
                          type="button"
                          className={cx('add-new-button', 'btn', 'btn-primary', 'btn-primary-custom')}
                          onClick={() => setIsOpenCreateModal(true)}
                        >
                          New Document
                          <Icon
                            className={cx('add-new-icon', theme === 'Dark' && cx('add-new-icon-dark'))}
                            color="action"
                          >
                            add_circle
                          </Icon>
                        </button>
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses={cx('table-responsive')}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        keyField="id"
                        remote
                        data={resourceManagementList === null ? [] : resourceManagementList}
                        columns={RESOURCE_MANAGEMENT_COLUMNS}
                        sort={sortData}
                        onTableChange={getHandlerTableChange()}
                        rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                      <PleaseWaitMessage entities={resourceManagementList} />
                      <NoRecordsFoundMessage entities={resourceManagementList} />
                    </div>
                  </div>
                )
              }}
            </ToolkitProvider>
          </Pagination>
        )}
      </PaginationProvider>
    </>
  )
}

export default injectIntl(ResourceManagementTable)
