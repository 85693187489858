import { put, call, takeLatest } from 'redux-saga/effects'
import { getAgentUser, getOwedBalances } from './AgentUserCrud'
export const actionTypes = {
  agentUserLoading: '[AgentUser] Loading',
  agentUserReceived: '[AgentUser] Received',
  agentUserFailed: '[AgentUser] Failed',
  owedBalancesLoading: '[OwedBalances] Loading',
  owedBalancesReceived: '[OwedBalances] Received',
  owedBalancesFailed: '[OwedBalances] Failed'
}

const initialAgentUserState = {
  agentUser: {
    data: null,
    error: null,
    isLoading: false
  },
  owedBalances: {
    data: [],
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialAgentUserState, action) => {
  switch (action.type) {
    case actionTypes.agentUserLoading: {
      return {
        ...state,
        agentUser: {
          ...state.agentUser,
          isLoading: true
        }
      }
    }
    case actionTypes.agentUserReceived: {
      const { data } = action.payload
      return {
        ...state,
        agentUser: {
          data: data.data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.agentUserFailed: {
      const { error } = action.payload
      return {
        ...state,
        agentUser: {
          ...state.agentUser,
          isLoading: false,
          error: error
        }
      }
    }
    case actionTypes.owedBalancesLoading: {
      return {
        ...state,
        owedBalances: {
          ...state.owedBalances,
          isLoading: true
        }
      }
    }
    case actionTypes.owedBalancesReceived: {
      const { data } = action.payload
      return {
        ...state,
        owedBalances: {
          data: data.data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.owedBalancesFailed: {
      const { error } = action.payload
      return {
        ...state,
        owedBalances: {
          ...state.owedBalances,
          isLoading: false,
          error: error
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  agentUserLoading: () => ({
    type: actionTypes.agentUserLoading
  }),
  agentUserReceived: payload => ({
    type: actionTypes.agentUserReceived,
    payload
  }),
  agentUserFailed: payload => ({
    type: actionTypes.agentUserFailed,
    payload
  }),
  owedBalancesLoading: () => ({
    type: actionTypes.owedBalancesLoading
  }),
  owedBalancesReceived: payload => ({
    type: actionTypes.owedBalancesReceived,
    payload
  }),
  owedBalancesFailed: payload => ({
    type: actionTypes.owedBalancesFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.agentUserLoading, function* agentUserSaga({ payload }) {
    try {
      const response = yield call(getAgentUser, payload)
      if (response != null) yield put(actions.agentUserReceived(response))
      else yield put(actions.agentUserFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.agentUserFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.owedBalancesLoading, function* owedBalancesSaga({ payload }) {
    try {
      const response = yield call(getOwedBalances, payload)
      if (response != null) yield put(actions.owedBalancesReceived(response))
      else yield put(actions.owedBalancesFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.owedBalancesFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
