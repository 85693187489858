import React, { useState } from 'react'
import style from '../companyDetail.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const CompanyStructure = ({ onClick, data, level }) => {
  const handleOnClick = (e, type, level) => {
    onClick(e, type, level)
  }
  const nextLevel = ++level
  return (
    <ol className={cx(`level-${level}-wrapper`)}>
      {data?.map(item => {
        return (
          <li key={item.code}>
            <div
              className={cx(`level-${level}`, item?.children?.length > 0 ? `level-${level}-has-children` : '', [
                'rectangle'
              ])}
            >
              <h3
                id={item.code}
                onClick={e => {
                  handleOnClick(e, item.type, level)
                }}
              >
                {item.name}
                <p id={item.code}>{item.status}</p>
              </h3>
            </div>
            {item?.children?.length > 0 && (
              <CompanyStructure onClick={onClick} data={item.children} level={nextLevel} />
            )}
          </li>
        )
      })}
    </ol>
  )
}

export default CompanyStructure
