import React, { useState, useEffect } from 'react'
import ReportsFilter from './components/ReportsFilter'
import Notice from '../../modules/notice/Notice'
import { connect, useDispatch, useSelector } from 'react-redux'
import { actions as actionReport, actionTypes } from './redux/reportsRedux'
import moment from 'moment'
import style from './reports.module.css'
import classNames from 'classnames/bind'
import ReportsTable from './components/reports/reports-table/ReportsTable'
import { CardBody } from '../../../_metronic/_partials/controls'
import { AlertList } from 'react-bs-notifier'
import { useIntl } from 'react-intl'
import TransactionsRefundDialog from './components/reports/reports-table/transaction-refund-dialog/TransactionRefundDialog'
import { Client } from '@stomp/stompjs'
import { useHistory } from 'react-router-dom'
import { env } from '../../../env'
import {
  SUCCESS_RESULT_CODE,
  RETRY_CONNECT_SOCKET_LIMIT,
  SOCKET_NORMAL_CLOSURE_CODE,
  INVALID_SESSION_TOKEN_RESULT_CODE,
  EXPIRED_JWT_TOKEN_RESULT_CODE,
  EXPIRED_SESSION_TOKEN_RESULT_CODE,
  FORCE_LOG_OUT_RESULT_CODE,
  EXPIRED_SESSION_LOGIN_MESSAGE
} from '../../../utils/Constants'
import { actions as actionsTheme } from '../../../redux/basePageRedux'
const cx = classNames.bind(style)

const ALERT_TIMEOUT = 15000
export const INIT_FILTER_REPORTS = {
  refId: '',
  exRefId: '',
  voucherSerial: '',
  phoneNo: '',
  agentCode: '',
  orderType: '',
  orderBy: '',
  transType: '',
  start: moment().format('yyyy-MM-DD 00:00:00'),
  end: moment().format('yyyy-MM-DD 23:59:59'),
  pageSize: 10,
  pageIndex: 1,
  period: 'today',
  includeSubAgent: true,
  isRefunded: false,
  isReversed: false
}
const PERMISSION_TO_REFUND = 'SCP.TTI.ADMIN.USER.REFUND_W'
const Reports = ({
  userAcls,
  subDivisionAndAgentData,
  noticeData,
  subDivisionAndAgentLoading,
  noticeReportLoading,
  clearTransactionReport,
  transactions,
  terminalIdData,
  terminalIdReportLoading,
  themeMode
}) => {
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [theme, setTheme] = useState('Light')
  const [isSubmited, setIsSubmited] = useState(false)
  const [isPaging, setIsPaging] = useState(false)
  const [alerts, setAlerts] = useState([])
  const [searchFilters, setSearchFilters] = useState(INIT_FILTER_REPORTS)
  const [agents, setAgents] = useState([])
  const [docActiveData, setDocActiveData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [refundTrans, setRefundTrans] = useState(null)
  const [rightToRefund, setRightToRefund] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [isRefunded, setIsRefunded] = useState(false)
  const [isReversed, setIsReversed] = useState(false)
  const { authToken, user } = useSelector(state => state.auth)
  const agentType = useSelector(state => state.agentUser?.agentUser?.data?.agentType)

  useEffect(() => {
    setTheme(themeMode?.themeMode?.theme)
  }, [themeMode?.themeMode?.theme])

  useEffect(() => {
    const refund = checkPermissionToRefund()
    setRightToRefund(refund)
  }, [userAcls])

  useEffect(() => {
    noticeReportLoading()
    terminalIdReportLoading()
  }, [])

  useEffect(() => {
    if (noticeData.content !== null) {
      const data = noticeData.content
      const docActiveDataTemp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].active === 'T') {
          docActiveDataTemp.push(data[i])
          break
        }
      }
      setDocActiveData(docActiveDataTemp)
    }
  }, [noticeData?.content])

  useEffect(() => {
    if (agentType) {
      subDivisionAndAgentLoading()
      clearTransactionReport()
    }
  }, [agentType])

  useEffect(() => {
    let data = subDivisionAndAgentData.data?.data
    if (data?.agents?.length) {
      setAgents(data?.agents)
    }
  }, [subDivisionAndAgentData.data])

  useEffect(() => {
    dispatch(
      actionReport.transactionReportReceived({
        data: [],
        totalRecord: 0,
        maxTransactionCount: 0,
        exportSize: 0
      })
    )
  }, [])

  useEffect(() => {
    const covertedFilter = convertFilters({ ...searchFilters })
    if (isSubmited) {
      handleSubmit({ ...covertedFilter, pageIndex: 1, pageSize: 10 })
      setSearchFilters({ ...searchFilters, pageIndex: 1, pageSize: 10 })
    }
    if (isPaging) {
      handleSubmit({ ...covertedFilter })
      setSearchFilters({ ...searchFilters })
    }
  }, [isSubmited, isPaging])

  useEffect(() => {
    if (!transactions?.isLoading) {
      setIsSubmited(false)
      setIsPaging(false)
    }
  }, [transactions?.isLoading])

  const checkPermissionToRefund = () => {
    if (userAcls) {
      const aclToRefund = userAcls?.some(item => {
        return item === PERMISSION_TO_REFUND
      })

      if (aclToRefund) {
        return true
      }
    }
    return false
  }

  const handleSubmit = searchFilters => {
    const headers = {
      Username: user.login,
      Authorization: authToken
    }
    dispatch(actionReport.transactionReportLoading(searchFilters))
    let retry = true
    let failedConnectionTotal = 0
    const onConnected = () => {
      client.subscribe(
        `/transactions/${user.login}/report`,
        message => {
          if (message.body) {
            const { data, resultCode, resultDescription, totalRecord, maxTransactionCount, exportSize } = JSON.parse(
              message.body
            ).body
            if (resultCode === SUCCESS_RESULT_CODE) {
              dispatch(
                actionReport.transactionReportReceived({
                  resultCode,
                  resultDescription,
                  data,
                  totalRecord,
                  maxTransactionCount,
                  exportSize
                })
              )
            } else {
              if (
                [
                  INVALID_SESSION_TOKEN_RESULT_CODE,
                  EXPIRED_SESSION_TOKEN_RESULT_CODE,
                  EXPIRED_JWT_TOKEN_RESULT_CODE,
                  FORCE_LOG_OUT_RESULT_CODE
                ].includes(resultCode)
              ) {
                forceLogout()
              } else {
                generateAlert(
                  'danger',
                  intl.formatMessage({ id: 'TRANSACTION.INVALID.TRANSACTIONS_REPORT' }),
                  resultDescription
                )
                dispatch(
                  actionReport.transactionReportFailed({
                    error: resultDescription
                  })
                )
              }
            }
          }
          retry = false
          client.deactivate()
        },
        headers
      )

      client.publish({
        destination: `/app/transaction/${user.login}/report`,
        body: JSON.stringify(searchFilters),
        headers: headers
      })
    }
    const client = new Client({
      brokerURL: env.REACT_APP_WS_URL,
      connectHeaders: headers,
      reconnectDelay: 1000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onConnect: onConnected,
      onWebSocketError: () => {
        failedConnectionTotal++
        if (failedConnectionTotal >= RETRY_CONNECT_SOCKET_LIMIT) {
          generateAlert(
            'danger',
            intl.formatMessage({ id: 'TRANSACTION.INVALID.TRANSACTIONS_REPORT' }),
            intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_MESSAGE' })
          )
          dispatch(
            actionReport.transactionReportFailed({
              error: intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_MESSAGE' })
            })
          )
          retry = false
          client.deactivate()
        } else {
          retry = true
        }
      },
      onWebSocketClose: closeEvent => {
        if (retry && closeEvent.code !== SOCKET_NORMAL_CLOSURE_CODE) {
          client.activate()
        }
      }
    })

    client.activate()

    return () => {
      client.deactivate()
    }
  }

  const handleFiltersChange = filters => {
    setSearchFilters({ ...filters })
  }

  const convertFilters = filters => {
    if (filters.subDivision && filters.agentCode === '') {
      filters.agentCode = filters.subDivision
    }
    filters.ignoreReturnDate = false
    delete filters.subDivision
    return filters
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const openRefundModal = trans => {
    setRefundTrans(trans)
    setIsModalOpen(true)
  }

  const callBackExporting = value => {
    setIsExporting(value)
  }

  const forceLogout = () => {
    if (!window.localStorage.getItem(EXPIRED_SESSION_LOGIN_MESSAGE)) {
      window.localStorage.setItem(
        EXPIRED_SESSION_LOGIN_MESSAGE,
        intl.formatMessage({ id: 'EXPIRED_SESSION_LOGIN_MESSAGE' })
      )
    }
    history.push('/logout')
  }

  return (
    <>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
        <div className={cx('top-content')}>
          <div className={cx('left-content', 'wrapper-element')}>
            <p className="page-title">{intl.formatMessage({ id: 'TRANSACTION.LABEL.REPORTS' })}</p>
            <ReportsFilter
              searchFilters={searchFilters}
              agents={agents}
              setSearchFilters={setSearchFilters}
              handleSubmit={handleFiltersChange}
              setIsSubmited={setIsSubmited}
              isLoading={transactions.isLoading}
              clearTransactionReport={clearTransactionReport}
              theme={theme}
              terminalIdData={terminalIdData}
              isExporting={isExporting}
              generateAlert={generateAlert}
              isRefunded={isRefunded}
              isReversed={isReversed}
              setIsRefunded={setIsRefunded}
              setIsReversed={setIsReversed}
            />
          </div>
          {docActiveData.length > 0 && (
            <div className={cx('right-content', 'wrapper-element')}>
              <Notice
                messageProp={docActiveData[0].content}
                contentTypeProp={docActiveData[0].contentType}
                documentId={docActiveData[0].id}
              />
            </div>
          )}
        </div>
        <div className="wrapper-element">
          <CardBody>
            <ReportsTable
              clearTransactionReport={clearTransactionReport}
              rightToRefund={rightToRefund}
              openRefundModal={openRefundModal}
              transactions={transactions}
              isLoading={transactions.isLoading}
              filters={searchFilters}
              updateFilters={handleFiltersChange}
              generateAlert={generateAlert}
              setIsPaging={setIsPaging}
              theme={theme}
              callBackExporting={callBackExporting}
              forceLogout={forceLogout}
            />
          </CardBody>
        </div>
        {isModalOpen && (
          <TransactionsRefundDialog
            show={isModalOpen}
            onHide={() => {
              setIsModalOpen(false)
            }}
            trans={refundTrans}
            generateAlert={generateAlert}
            setIsModalOpen={setIsModalOpen}
            transactionSearchLoading={handleSubmit}
            searchFilters={searchFilters}
            intl={intl}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  subDivisionAndAgentData: state.reports.subDivisionAndAgent,
  noticeData: state.reports.notice,
  transactions: state.reports.transactions,
  terminalIdData: state.reports.terminalId,
  userAcls: state.auth?.user?.acls,
  themeMode: state.themeMode
})

export default connect(mapStateToProps, { ...actionReport, ...actionsTheme })(Reports)
