import React, { useEffect, useRef, useState } from 'react'
import style from '../pinOrder.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import { FormControl, TextField } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useFormik } from 'formik'
import moment from 'moment'
import { enGB } from 'react-date-range/src/locale'
import staticRangesGenerator from './StaticRangesGenerator'

const INIT_FILTER = {
  searchText: '',
  start: '',
  end: '',
  pageSize: 10,
  pageIndex: 1,
  orderBy: 'orderRef',
  orderType: 'desc'
}
const PinOrderFilter = ({
  handleSubmit,
  searchFilters,
  setSearchFilters,
  onChange,
  open,
  setOpen,
  pinBatchOrderListLoading,
  isLoading,
  setIsClear,
  resetSortData,
  theme
}) => {
  const INIT_RANGE = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }
  const [range, setRange] = useState([INIT_RANGE])
  const onChangeCalendar = ranges => {
    const { selection } = ranges
    onChange(selection)
    setRange([selection])
  }
  const [isSearching, setIsSeaching] = useState(false)

  const staticRanges = staticRangesGenerator(enGB)
  const [isMount, setIsMount] = useState(true)

  useEffect(() => {
    if (!isLoading) {
      setIsSeaching(false)
    }
  }, [isLoading])
  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true)
    document.addEventListener('click', hideOnClickOutSide, true)
  }, [])

  const refDate = useRef(null)

  const hideOnEscape = e => {
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  const hideOnClickOutSide = e => {
    if (refDate.current && !refDate.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const formik = useFormik({
    initialValues: searchFilters,
    onSubmit: values => {
      setIsSeaching(true)
      const payload = {
        ...searchFilters,
        searchText: values.searchText,
        start: values.start,
        end: values.end
      }
      pinBatchOrderListLoading({ ...payload, pageIndex: searchFilters.pageIndex, pageSize: searchFilters.pageSize })
      handleSubmit(payload)
    }
  })

  useEffect(() => {
    if (isMount) {
      formik.setFieldValue('start', '')
      formik.setFieldValue('end', '')
      setIsMount(false)
      return
    }
    if (
      moment(range[0].startDate).format('yyyy-MM-DD 00:00:00') != 'Invalid date' &&
      moment(range[0].endDate).format('yyyy-MM-DD 23:59:59') != 'Invalid date'
    ) {
      formik.setFieldValue('start', moment(range[0].startDate).format('yyyy-MM-DD 00:00:00'))
      formik.setFieldValue('end', moment(range[0].endDate).format('yyyy-MM-DD 23:59:59'))
    } else {
      formik.setFieldValue('start', '')
      formik.setFieldValue('end', '')
    }
  }, [range])

  const handleClear = () => {
    //clear pin order
    formik.resetForm()
    formik.setFieldValue('searchText', '')
    formik.setFieldValue('start', '')
    formik.setFieldValue('end', '')
    setSearchFilters(INIT_FILTER)
    setIsClear(true)
    setIsMount(true)
    setRange([INIT_RANGE])
    resetSortData()
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
      >
        <ul className={cx('filter-content')}>
          <li className={cx('filter-item')}>
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label="Search"
                type="text"
                name="searchText"
                onChange={formik.handleChange}
                value={formik.values.searchText}
                onBlur={formik.handleBlur}
                margin="normal"
                variant="outlined"
                size="small"
              />
            </FormControl>
          </li>
          <li className={cx('filter-item')}>
            <FormControl className={cx('width-100-percent')} variant="filled">
              <TextField
                label="Requested Date Range"
                aria-readonly
                inputProps={{ style: { textAlign: 'center' } }}
                type="text"
                name="dateRange"
                value={`${
                  moment(formik.values.start).format('DD/MM/yyyy') == 'Invalid date'
                    ? ''
                    : moment(formik.values.start).format('DD/MM/yyyy')
                } - ${
                  moment(formik.values.end).format('DD/MM/yyyy') == 'Invalid date'
                    ? ''
                    : moment(formik.values.end).format('DD/MM/yyyy')
                } `}
                onClick={() => setOpen(!open)}
                margin="normal"
                variant="outlined"
                size="small"
              />
            </FormControl>
            <div ref={refDate} style={{ width: '100%' }}>
              {open && (
                <DateRangePicker
                  locale={enGB}
                  staticRanges={staticRanges}
                  onChange={onChangeCalendar}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={1}
                  direction="vertical"
                  className={cx('rdrDateRangePickerWrapper', 'calendarElement')}
                  inputRanges={[]}
                />
              )}
            </div>
          </li>
        </ul>
        <div className={cx('filter-button')}>
          <button type="submit" className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}>
            Search
            {isSearching && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <button
            type="button"
            onClick={handleClear}
            className={cx(
              'btn',
              'button',
              'btn-secondary',
              `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`,
              'btn-lg'
            )}
          >
            Clear
          </button>
        </div>
      </form>
    </>
  )
}

export default PinOrderFilter
