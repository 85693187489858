import React from 'react'
import style from '../userProfile.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
const ChangePassword = ({ formik, theme }) => {
  const handleCancel = () => {
    formik.resetForm()
  }
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className={cx(
          'form fv-plugins-bootstrap fv-plugins-framework',
          'display-flex',
          'flex-direction-column',
          'space-evelyn'
        )}
      >
        <div className={cx('form-group', 'display-flex', 'align-item-center', 'space-evelyn')}>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              placeholder="Old Password"
              type="password"
              name="oldPassword"
              className={cx(
                'form-control',
                theme === 'Dark' && 'form-control-dark',
                formik.touched.oldPassword && formik.errors.oldPassword ? 'error' : ''
              )}
              {...formik.getFieldProps('oldPassword')}
            />
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.oldPassword}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-center')}>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              placeholder="New password"
              disabled={formik.isSubmitting}
              type="password"
              name="newPassword"
              className={cx(
                'form-control',
                theme === 'Dark' && 'form-control-dark',
                formik.touched.newPassword && formik.errors.newPassword ? 'error' : ''
              )}
              {...formik.getFieldProps('newPassword')}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.newPassword}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-center')}>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              placeholder="Confirm new password"
              disabled={formik.isSubmitting}
              type="password"
              name="confirmNewPassword"
              className={cx(
                'form-control',
                theme === 'Dark' && 'form-control-dark',
                formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? 'error' : ''
              )}
              {...formik.getFieldProps('confirmNewPassword')}
            />
            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.confirmNewPassword}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={cx('form-group', 'display-flex', 'space-evelyn', 'form-button')}>
          <button
            disabled={formik.isSubmitting}
            type="submit"
            style={{ marginRight: '5px' }}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
          >
            Confirm
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            style={{ marginRight: '5px' }}
            className={cx(
              'button',
              'btn',
              theme === 'Dark' ? 'btn-dark' : 'btn-secondary btn-secondary-custom',
              'btn-lg'
            )}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default ChangePassword
