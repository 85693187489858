import { put, call, takeLatest } from 'redux-saga/effects'

import { getDocumentByAgentType } from './ContactUsCrud'

export const actionTypes = {
  resourceLoading: '[resource] Loading',
  resourceReceived: '[resource] Received',
  resourceFailed: '[resource] Failed'
}

const initialContactUsState = {
  resource: {
    data: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialContactUsState, action) => {
  switch (action.type) {
    case actionTypes.resourceLoading: {
      return {
        ...state,
        resource: {
          ...state.resource,
          isLoading: true
        }
      }
    }
    case actionTypes.resourceReceived: {
      const data = action.payload
      return {
        ...state,
        resource: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.resourceFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        resource: {
          ...state.resource,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  resourceLoading: () => ({
    type: actionTypes.resourceLoading,
    payload: null
  }),
  resourceReceived: payload => ({
    type: actionTypes.resourceReceived,
    payload
  }),
  resourceFailed: payload => ({
    type: actionTypes.resourceFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.resourceLoading, function* resourceSaga() {
    try {
      const response = yield call(getDocumentByAgentType)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.resourceReceived(data))
      else yield put(actions.resourceFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.resourceFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
