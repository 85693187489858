import React, { useEffect, useLayoutEffect, useState, useContext } from 'react'
import { TemplateContext } from '../../../../_metronic/layout/_core/TemplateProvider'
import { useFormik } from 'formik'
import { Link, Redirect, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Alert } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { actions } from '../_redux/authRedux'
import Spinner from 'react-bootstrap/Spinner'
import style from '../auth.module.css'
import classNames from 'classnames/bind'
import { AlertList } from 'react-bs-notifier'
const ALERT_TIMEOUT = 15000
const cx = classNames.bind(style)
const initialValues = {
  email: ''
}

const EmailAddressForm = ({
  intl,
  emailConfirmation,
  user,
  requestUser,
  isUserLoading,
  logout,
  sendOtpToEmailLoading
}) => {
  const history = useHistory()
  const templateContext = useContext(TemplateContext)

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  })

  const getInputClasses = fieldName => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return 'is-valid'
    }

    return ''
  }

  useEffect(() => {
    let isCleanUp = false
    if (user?.resultCode == 0) {
      if (!isCleanUp) history.push('/dashboard')
    }

    return () => {
      isCleanUp = true
    }
  }, [user])

  useEffect(() => {
    let isCleanUp = false
    if (user === undefined) {
      if (!isCleanUp) {
        requestUser()
      }
    }
    return () => {
      isCleanUp = true
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { resetForm }) => {
      sendOtpToEmailLoading(values)
    }
  })

  useEffect(() => {
    if (!emailConfirmation?.isConfirmEmailLoading) {
      if (
        emailConfirmation?.email &&
        emailConfirmation?.isSendConfirmEmailSuccess &&
        emailConfirmation?.otpExpiredTime &&
        localStorage.getItem('emailToConfirm') &&
        localStorage.getItem('otpExpiredTime')
      ) {
        history.push('/email-confirmation')
      }
      if (emailConfirmation?.error) {
        generateAlert('danger', 'Email Confirmation', emailConfirmation?.error)
      }
    }
  }, [emailConfirmation])

  const [alerts, setAlerts] = useState([])
  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const handleLogout = () => {
    logout()
  }
  return (
    <>
      <AlertList
        position={'top-right'}
        alerts={alerts}
        timeout={ALERT_TIMEOUT}
        dismissTitle="Begone!"
        onDismiss={onDismissed}
      />
      {isUserLoading ? (
        <div style={{ display: 'flex', margin: 'auto' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
          }}
        >
          <div>
            <div className="login-form login-forgot" style={{ display: 'block' }}>
              <div className="text-center mb-10 mb-lg-5">
                <Link to="/" className="flex-column-auto mt-5">
                  <div className="max-h-100px logo"></div>
                </Link>
                {templateContext?.portalName ? (
                  <h3 className="font-size-h1">
                    <FormattedMessage id="PORTAL.NAME" values={{ name: templateContext.portalName }} />
                  </h3>
                ) : (
                  <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                  </h3>
                )}
                <h3 className="font-size-h3">
                  <FormattedMessage id="AUTH.EMAIL.ADDRESS.REQUIRED" />
                </h3>
              </div>

              <div className={cx('text-explain')} style={{ maxWidth: '500px' }}>
                For security reasons we require an email address to be associated with your account. Please enter your
                email address below to continue.
              </div>

              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                <div className="form-group fv-plugins-icon-container">
                  <div
                    className="form-group fv-plugins-icon-container"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <input
                      style={{ maxWidth: '300px' }}
                      type="text"
                      placeholder="Email"
                      disabled={emailConfirmation?.isConfirmEmailLoading}
                      className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
                      name="email"
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.email}</div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className="form-group fv-plugin
                    s-icon-container"
                  >
                    <button
                      onClick={() => handleLogout()}
                      type="button"
                      className="btn btn-secondary mr-2 btn-secondary-custom"
                    >
                      Logout
                    </button>
                    <button
                      type="submit"
                      disabled={emailConfirmation?.isConfirmEmailLoading}
                      className="btn btn-primary btn-primary-custom"
                      style={{ width: '150px' }}
                    >
                      Submit
                      {emailConfirmation?.isConfirmEmailLoading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  emailConfirmation: state.auth.emailConfirmation,
  user: state.auth.user
})
export default injectIntl(connect(mapStateToProps, actions)(EmailAddressForm))
