import React, { useMemo } from 'react'
import objectPath from 'object-path'
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout'
// Import Layout components
import { Header } from './header/Header'
import { HeaderMobile } from './header-mobile/HeaderMobile'
import { Aside } from './aside/Aside'
import { Footer } from './footer/Footer'
import { LayoutInit } from './LayoutInit'
import { SubHeader } from './subheader/SubHeader'
import { QuickPanel } from './extras/offcanvas/QuickPanel'
import { QuickUser } from './extras/offcanvas/QuickUser'
import { ScrollTop } from './extras/ScrollTop'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
export function Layout({ children }) {
  const uiService = useHtmlClassService()
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended')
    }
  }, [uiService])

  const intl = useIntl()
  const history = useHistory()

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const { user } = useSelector(state => ({
    user: state.auth.user
  }))

  const handleCloseImpersonate = () => {
    history.push('/logout')
    localStorage.clear()
    window.close()
  }

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            {/*begin::Content*/}
            {localStorage.getItem('isImpersonate') && localStorage.getItem('isImpersonate') == 'true' && (
              <div
                className="p-3 bg-success text-white mb-5 impersonate-fixed"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'fixed',
                  zIndex: 96,
                  right: 0
                }}
              >
                <div style={{ flex: 1, textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'IMPERSONATE.MESSAGE' })}
                  <b>{user.login}</b>
                </div>
                <a
                  href="#"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                  onClick={handleCloseImpersonate}
                >
                  <i className="fas fa-sign-out-alt"></i>
                </a>
              </div>
            )}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
              style={{
                backgroundColor: theme === 'Dark' ? '#151521' : '',
                color: theme === 'Dark' ? '#bebfc2' : '',
                padding:
                  localStorage.getItem('isImpersonate') && localStorage.getItem('isImpersonate') == 'true'
                    ? '50px 0px'
                    : '25px 0px'
              }}
            >
              {layoutProps.subheaderDisplay && <SubHeader />}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>{children}</div>
                  {/*end::Container*/}
                </div>
              )}
              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      <QuickPanel />
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  )
}
