import { merchantApiClient } from '../../../../redux'
export const GET_CREDIT_LIMIT_TEMPLATE_LIST = `/api/v1/get-limit-template-list`

export const GET_CREDIT_LIMIT_TEMPLATES = '/api/v1/get-limit-template-list'
export const EDIT_AGENT_LIMIT_ITEM = '/api/v1/edit-agent-limit-item'
export const GET_CREDIT_LIMIT_TEMPLATE_DETAILS = '/api/v1/get-limit-template-details'
export const CREATE_CREDIT_LIMIT_TEMPLATE = '/api/v1/create-limit-template'
export const EDIT_CREDIT_LIMIT_TEMPLATE = '/api/v1/edit-limit-template'
export const DELETE_CREDIT_LIMIT_TEMPLATE = '/api/v1/delete-limit-template'
export const GET_LIMIT_TARGET_DATA = '/api/v1/get-limit-target-data'

export const getCreditLimitTemplates = () => {
  return merchantApiClient.get(GET_CREDIT_LIMIT_TEMPLATE_LIST)
}

export const editAgentLimitItem = payload => {
  return merchantApiClient.put(EDIT_AGENT_LIMIT_ITEM, payload)
}

export const createCreditLimitTemplate = payload => {
  return merchantApiClient.post(CREATE_CREDIT_LIMIT_TEMPLATE, payload)
}

export const editCreditLimitTemplate = payload => {
  return merchantApiClient.put(EDIT_CREDIT_LIMIT_TEMPLATE, payload)
}

export const getCreditLimitDetails = templateCode => {
  return merchantApiClient.get(`${GET_CREDIT_LIMIT_TEMPLATE_DETAILS}?templateCode=${templateCode}`)
}

export const deleteCreditLimitTemplate = templateCode => {
  return merchantApiClient.delete(`${DELETE_CREDIT_LIMIT_TEMPLATE}?templateCode=${templateCode}`)
}

export const getLimitTargetData = () => {
  return merchantApiClient.get(GET_LIMIT_TARGET_DATA)
}
