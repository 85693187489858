import React from 'react'

export const ColorColumnFormatter = (cellContent, row) => {
  let color
  if (row.status === 'Success') {
    return <span className="label label-inline label-light-success font-weight-bold">{row.status.toUpperCase()}</span>
  }
  if (row.status === 'Failed') {
    return <span className="label label-inline label-light-danger font-weight-bold">{row.status.toUpperCase()}</span>
  }
  if (row.status === 'Refunded') {
    return <span className="label label-inline label-light-info font-weight-bold">{row.status.toUpperCase()}</span>
  }
  if (row.status === 'Reversed') {
    return <span className="label label-light-primary label-inline font-weight-bold">{row.status.toUpperCase()}</span>
  }
}
