import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import style from '../settlement-earnings.module.css'
import classNames from 'classnames/bind'
import { injectIntl } from 'react-intl'
const cx = classNames.bind(style)
import { NoRecordsFoundMessage, PleaseWaitMessage } from '../../../../_metronic/_helpers'
import ActionsColumnFormatter from './ActionsColumnFormatter'
import Spinner from 'react-bootstrap/Spinner'
import { sortCaret } from '../../../../_metronic/_helpers'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
const defaultSorted = [{ dataField: 'date', order: 'asc' }]
const SettlementEarningsReportTable = ({ isLoading, reportList, generateAlert, theme }) => {
  const { SearchBar } = Search
  const [isDownLoading, setIsDownLoading] = useState(false)
  const [rowIndex, setRowIndex] = useState(null)
  let paginationPropsData = {}
  const SETTLEMENT_EARNINGS_COLUMNS = [
    {
      dataField: 'id',
      text: 'No.',
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      },
      formatter: (cell, row, rowIndex) => {
        let rowNumber = (paginationPropsData?.page - 1) * paginationPropsData?.sizePerPage + (rowIndex + 1)
        return rowNumber
      }
    },
    {
      dataField: 'fileName',
      text: 'File Name',
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        isDownLoading: isDownLoading,
        setIsDownLoading: setIsDownLoading,
        downLoadIndex: rowIndex,
        setRowIndex: setRowIndex,
        generateAlert: generateAlert,
        theme: theme
      },
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    }
  ]

  const options = {
    custom: true,
    totalSize: reportList.length
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField="id" data={reportList} columns={SETTLEMENT_EARNINGS_COLUMNS} search>
            {props => {
              paginationPropsData = paginationProps
              return (
                <div>
                  <p className={cx('settlement-earnings-title', theme === 'Dark' && 'settlement-earnings-title-dark')}>
                    Weekly Transaction Summary
                  </p>
                  {isLoading ? (
                    <div className={cx('contain-spinner')}>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <div>
                      <div className={cx('search-bar')}>
                        <SearchBar
                          {...props.searchProps}
                          className={theme === 'Dark' ? 'search-bar-input-dark' : undefined}
                        />
                      </div>
                      <div className={cx('pagination')}>
                        <div
                          style={{ marginBottom: '8px' }}
                          className={theme === 'Dark' ? 'pagination-dark' : undefined}
                        >
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div>
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses={cx('table-responsive')}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        keyField="id"
                        data={reportList === null ? [] : reportList}
                        columns={SETTLEMENT_EARNINGS_COLUMNS}
                        defaultSorted={defaultSorted}
                        pagination={paginationFactory(options)}
                        rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                      <PleaseWaitMessage entities={reportList} />
                      <NoRecordsFoundMessage entities={reportList} />
                    </div>
                  )}
                </div>
              )
            }}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </>
  )
}

export default injectIntl(SettlementEarningsReportTable)
