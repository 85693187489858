import React, { useState, useRef, useEffect, useLayoutEffect, useContext } from 'react'
import { TemplateContext } from '../../../../_metronic/layout/_core/TemplateProvider'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { requestValidateResetPasswordToken, requestResetPassword } from '../_redux/authCrud'
import { Alert } from 'react-bootstrap'

const initialValues = {
  password: '',
  confirmPassword: ''
}
let countDown = null
function ResetPassword(props) {
  let history = useHistory()
  const { intl } = props
  const [resultCode, setResultCode] = useState(0)
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false)
  const [seconds, setSeconds] = useState()
  const [messageError, setMessageError] = useState('')
  let [allowResetPassword, setAllowResetPassword] = useState(false)
  const templateContext = useContext(TemplateContext)

  useEffect(() => {
    let token = new URLSearchParams(window.location.search).get('token')
    validateResetPasswordToken(token)
  }, [])

  const validateResetPasswordToken = token => {
    if (!token && token === null) {
      setAllowResetPassword(!allowResetPassword)
      setMessageError(
        intl.formatMessage({
          id: 'AUTH.RESET.PASSWORD.VALIDATE.TOKEN.FAILED'
        })
      )
    } else {
      requestValidateResetPasswordToken(token)
        .then(response => {
          if (response?.data?.resultCode !== 0) {
            setAllowResetPassword(!allowResetPassword)
            setMessageError(
              intl.formatMessage({
                id: 'AUTH.RESET.PASSWORD.VALIDATE.TOKEN.FAILED'
              })
            )
          }
        })
        .catch(error => {
          setAllowResetPassword(!allowResetPassword)
          setMessageError(error?.response?.data?.message)
        })
    }
  }

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*^\S*$)(?=.*[!@#&()?[\{\}\]\:\;\'\,\?\/\*\~$^\+\=\<\>]).{8,50}$/,
        'Password must be 8-50 characters long and contain at least one upper case letter, one lower case letter, one numeric character, and one special character with no spaces.'
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .oneOf([Yup.ref('password'), null], "Confirm password doesn't match")
  })

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      let token = new URLSearchParams(window.location.search).get('token')
      requestResetPassword(values.password, values.confirmPassword, token)
        .then(response => {
          setResultCode(response?.data?.resultCode)
          setSubmitting(false)
          if (response.data.resultCode === 0) {
            setIsResetPasswordSuccess(true)
            setStatus(
              intl.formatMessage({
                id: 'AUTH.RESET.PASSWORD.SUCCESS'
              })
            )
            let countDownTime = 10
            setSeconds(countDownTime)
            countDown = setInterval(() => {
              countDownTime = countDownTime - 1
              setSeconds(countDownTime)
              if (countDownTime === 0) {
                clearInterval(countDown)
                history.push('/auth')
              }
            }, 1000)
          } else {
            setStatus(
              intl.formatMessage({
                id: 'AUTH.RESET.PASSWORD.VALIDATE.TOKEN.FAILED'
              })
            )
          }
        })
        .catch(error => {
          setResultCode(9999)
          setStatus(error.message)
          setSubmitting(false)
        })
    }
  })

  const handleDismiss = () => {
    formik.setStatus(null)
  }

  const handleBackToForgotPassword = () => {
    history.push('/auth/forgot-password')
  }

  const handleBackToLogin = () => {
    clearInterval(countDown)
    history.push('/auth')
  }

  return (
    <div className="login-form contact-us" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <Link to="/" className="flex-column-auto mt-5">
          <div className="max-h-100px logo"></div>
        </Link>
        {templateContext?.portalName ? (
          <h3 className="font-size-h1">
            <FormattedMessage id="PORTAL.NAME" values={{ name: templateContext.portalName }} />
          </h3>
        ) : (
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        )}
        <h3 className="font-size-h3">
          <FormattedMessage id="AUTH.RESET.PASSWORD.TITLE" />
        </h3>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
      >
        {allowResetPassword ? <Alert variant={'danger'}>{messageError}</Alert> : ''}
        {formik.status && (
          <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
            {formik.status}
          </Alert>
        )}
        {isResetPasswordSuccess && (
          <div>
            You will be redirected to the login page in <b>{seconds}</b>s ...
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <div className="form-group fv-plugins-icon-container">
            <input
              hidden={allowResetPassword || isResetPasswordSuccess}
              disabled={formik.isSubmitting}
              placeholder="New Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
              name="password"
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              hidden={allowResetPassword || isResetPasswordSuccess}
              disabled={formik.isSubmitting}
              placeholder="Confirm New Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('confirmPassword')}`}
              name="confirmPassword"
              {...formik.getFieldProps('confirmPassword')}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.confirmPassword}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            {allowResetPassword ? (
              <button
                id="kt_forgot_password_submit"
                onClick={handleBackToForgotPassword}
                type="button"
                className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
              >
                <span>Back to forgot password</span>
                {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            ) : (
              <button
                id="kt_forgot_password_submit"
                type="submit"
                hidden={isResetPasswordSuccess}
                disabled={formik.isSubmitting}
                className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
              >
                <span>Submit</span>
                {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            )}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-around align-items-center">
            {!isResetPasswordSuccess ? (
              <Link
                to={formik.isSubmitting ? '#' : '/auth'}
                className="text-dark-50 text-hover-primary-custom my-3 mr-2 text-hover-primary-custom"
                id="kt_forgot_cancel"
              >
                <FormattedMessage id={'AUTH.GENERAL.CANCEL_BUTTON'} />
              </Link>
            ) : (
              <button
                onClick={handleBackToLogin}
                id="kt_back_to_login"
                type="button"
                className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
              >
                <span>Back to login</span>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(ResetPassword))
