import { merchantApiClient } from '../../../../redux'

export const GET_SUB_DIVISION_AND_AGENT = '/api/v1/sub-division-and-agent'
export const GET_DOCUMENTS = '/api/v1/get-documents'
export const TRANSACTION_SEARCH = `/api/v1/transactions`
export const EXPORT_CSV_TRANSACTION_SEARCH = `/api/v1/export-csv-transactions`
export const EXPORT_EXCEL_TRANSACTION_SEARCH = `/api/v1/export-excel-transactions`
export const PRINT_TRANSACTION_SEARCH = `/api/v1/print-transactions`
export const GET_TERMINAL_ID = '/api/v1/get-terminal'
export const EXPORT_MERCHANT_PERFORMANCE_REPORT = '/api/v1/export-merchant-performance'
export const REFUND_TRANSACTION = `/api/v1/refund-transaction`
export const SEND_MAIL_URL = `/api/v1/send-report-to-email`
export const TRANSACTION_EXPORT_LIMIT = `/api/v1/transaction-export-limit`
export function refundTransaction(transactionId, voucherSerial, comments) {
  return merchantApiClient.put(REFUND_TRANSACTION, { transactionId, voucherSerial, comments })
}

export function searchTransactions({
  orderBy,
  orderType,
  transType,
  status,
  start,
  end,
  pageSize,
  pageIndex,
  agentCode,
  isReversed,
  isRefunded,
  includeSubAgent,
  ignoreReturnDate,
  terminalIds,
  exRefId,
  refId,
  phoneNo,
  voucherSerial
}) {
  return merchantApiClient.post(TRANSACTION_SEARCH, {
    // orderBy,
    orderType,
    transType,
    status,
    start,
    end,
    pageSize,
    pageIndex,
    agentCode,
    isReversed,
    includeSubAgent,
    isRefunded,
    ignoreReturnDate,
    terminalIds,
    exRefId,
    refId,
    phoneNo,
    voucherSerial
  })
}

export const getSubDivisionAndAgent = () => {
  return merchantApiClient.get(GET_SUB_DIVISION_AND_AGENT)
}

export const getNotice = () => {
  return merchantApiClient.post(GET_DOCUMENTS, { page: 'transaction-report' })
}

export const getTerminalId = () => {
  return merchantApiClient.get(GET_TERMINAL_ID)
}

export function exportCsvTransactions({
  orderBy,
  orderType,
  transType,
  status,
  start,
  end,
  pageSize,
  pageIndex,
  agentCode,
  isReversed,
  isRefunded,
  terminalIds,
  includeSubAgent,
  ignoreReturnDate,
  exRefId,
  refId,
  phoneNo,
  voucherSerial
}) {
  return merchantApiClient.post(EXPORT_CSV_TRANSACTION_SEARCH, {
    // orderBy,
    orderType,
    transType,
    status,
    start,
    end,
    pageSize,
    pageIndex,
    agentCode,
    isReversed,
    isRefunded,
    terminalIds,
    includeSubAgent,
    ignoreReturnDate,
    exRefId,
    refId,
    phoneNo,
    voucherSerial
  })
}

export function exportExcelTransactions({
  orderBy,
  orderType,
  transType,
  status,
  start,
  end,
  pageSize,
  pageIndex,
  agentCode,
  isReversed,
  isRefunded,
  terminalIds,
  includeSubAgent,
  ignoreReturnDate,
  exRefId,
  refId,
  phoneNo,
  voucherSerial
}) {
  return merchantApiClient.post(
    EXPORT_EXCEL_TRANSACTION_SEARCH,
    {
      // orderBy,
      orderType,
      transType,
      status,
      start,
      end,
      pageSize,
      pageIndex,
      agentCode,
      isReversed,
      isRefunded,
      terminalIds,
      includeSubAgent,
      ignoreReturnDate,
      exRefId,
      refId,
      phoneNo,
      voucherSerial
    },
    {
      responseType: 'blob'
    }
  )
}

export function printTransactions({
  orderBy,
  orderType,
  transType,
  status,
  start,
  end,
  pageSize,
  pageIndex,
  agentCode,
  isReversed,
  isRefunded,
  terminalIds,
  includeSubAgent,
  ignoreReturnDate,
  exRefId,
  refId,
  phoneNo,
  voucherSerial
}) {
  return merchantApiClient.post(PRINT_TRANSACTION_SEARCH, {
    // orderBy,
    orderType,
    transType,
    status,
    start,
    end,
    pageSize,
    pageIndex,
    agentCode,
    isReversed,
    isRefunded,
    terminalIds,
    includeSubAgent,
    ignoreReturnDate,
    exRefId,
    refId,
    phoneNo,
    voucherSerial
  })
}

export function exportMerchantTransactionReport(year, includeSubAgents) {
  return merchantApiClient.post(EXPORT_MERCHANT_PERFORMANCE_REPORT, {
    year,
    includeSubAgents
  })
}

export function sendMail(payload) {
  return merchantApiClient.post(SEND_MAIL_URL, payload)
}
