import React from 'react'
import style from '../auth.module.css'
import classNames from 'classnames/bind'
import { FormattedMessage } from 'react-intl'
const cx = classNames.bind(style)
const ChangePassword = ({ formik, getInputClasses, intl }) => {
  return (
    <>
      <div className="form-group fv-plugins-icon-container">
        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({
                id: 'OLD.PASSWORD.TITLE'
              })}
              disabled={formik.isSubmitting}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('oldPassword')}`}
              name="oldPassword"
              {...formik.getFieldProps('oldPassword')}
            />
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.oldPassword}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({
                id: 'NEW.PASSWORD.TITLE'
              })}
              disabled={formik.isSubmitting}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('newPassword')}`}
              name="newPassword"
              {...formik.getFieldProps('newPassword')}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.newPassword}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({
                id: 'CONFIRM.NEW.PASSWORD.TITLE'
              })}
              disabled={formik.isSubmitting}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('confirmNewPassword')}`}
              name="confirmNewPassword"
              {...formik.getFieldProps('confirmNewPassword')}
            />
            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.confirmNewPassword}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
            >
              <span>
                <FormattedMessage id="CHANGE.PASSWORD.BUTTON.TITLE" />
              </span>
              {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChangePassword
