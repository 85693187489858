import React, { useState } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import { actions } from '../redux/VoucherProductRedux'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { PRODUCT_REDEEM_STEP_VALIDATE } from '../../../../utils/Constants'
import { checkHasPermission } from '../../_helper/MenuHelper'
import { AGENT_TYPE_DISTRIBUTOR } from '../../../../utils/Constants'

function VoucherRedeemForm({
  cx,
  theme,
  intl,
  onShowAlert,
  voucherValidated,
  voucherRedeemLoading,
  voucherRedeemed,
  voucherStepSetting
}) {
  const [isSubmitRedeem, setIsSubmitRedeem] = useState(false)
  const { user } = useSelector(state => state.auth)
  const { agentUser } = useSelector(state => state.agentUser)
  let darkMode = theme === 'Dark' && cx('dark')
  const initialValues = {
    pin: voucherValidated?.data?.pin
  }

  const isAllowRedeem =
    checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_W']) &&
    agentUser?.data?.agentType !== AGENT_TYPE_DISTRIBUTOR

  const VoucherRedeemSchema = Yup.object().shape({
    pin: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(/^[0-9]+$/, 'Voucher Pin must be a number')
  })

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const handleResetVoucher = () => {
    formik.resetForm()
    formik.setStatus('')
    voucherStepSetting({
      currentStep: PRODUCT_REDEEM_STEP_VALIDATE
    })
    onShowAlert('', 0)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: VoucherRedeemSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setStatus('')
      voucherRedeemLoading(values.pin)
      setStatus(voucherRedeemed?.data?.resultDescription)
      setSubmitting(false)
      setIsSubmitRedeem(true)
    }
  })

  useEffect(() => {
    if (isSubmitRedeem) {
      onShowAlert(voucherRedeemed?.data?.resultDescription, voucherRedeemed?.data?.resultCode)
    }
  }, [voucherRedeemed])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Form.Group as={Form.Row} controlId="pinText">
        <Form.Label column sm="2" className={darkMode}>
          Pin:
        </Form.Label>
        <Col sm="10">
          <Form.Control
            disabled={true}
            size="lg"
            type="text"
            placeholder="Pin"
            className={getInputClasses('pin')}
            name="pin"
            {...formik.getFieldProps('pin')}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Form.Row} controlId="productName">
        <div className={cx('redeemValidate')}>
          <p>
            <b>Product Name:</b> {voucherValidated?.data?.productName}
          </p>
          <p>
            <b>Redeemable Value:</b>{' '}
            {`${voucherValidated?.data?.currencyCode} ${voucherValidated?.data?.redeemableValue}`}
          </p>
        </div>
      </Form.Group>

      <div className={cx('actions')}>
        <div className={cx('actions-control')} style={{ justifyContent: isAllowRedeem ? 'space-between' : 'center' }}>
          <button
            type="button"
            disabled={voucherValidated?.isLoading}
            className={`${cx('button', 'btn', 'btn-lg', 'action-btn', 'btn-secondary-custom')} ${darkMode}`}
            onClick={handleResetVoucher}
          >
            Cancel
          </button>
          {isAllowRedeem && (
            <button
              type="submit"
              disabled={voucherRedeemed?.isLoading}
              className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'action-btn', 'btn-primary-custom')}
            >
              Redeem
              {voucherRedeemed?.isLoading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          )}
        </div>
      </div>
    </form>
  )
}

export default injectIntl(connect(null, actions)(VoucherRedeemForm))
