import { merchantApiClient } from '../../../../../../redux'
export const GET_LIST_RESOURCE_MANAGEMENT = '/api/v1/get-resource-management'
export const SAVE_DOCUMENT = '/api/v1/save-document'
export const getListResourceManagement = payload => {
  return merchantApiClient.post(GET_LIST_RESOURCE_MANAGEMENT, payload)
}

export const saveDocument = (saveDocumentRequest, file) => {
  let formData = new FormData()

  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }

  formData.append(
    'saveDocumentRequest',
    new Blob([JSON.stringify(saveDocumentRequest)], {
      type: 'application/json'
    })
  )
  formData.append('file', file)
  return merchantApiClient.post(SAVE_DOCUMENT, formData, config)
}
