import React, { useState, useEffect } from 'react'
import WeeklyTransactionChart from './components/WeeklyTransactionChart'
import SuccessRateChart from './components/SuccessRateChart'
import TransactionTable from './components/TransactionTable'
import DailyCurrencyChart from './components/DailyCurrencyChart'
import style from './dashboard.module.css'
import classNames from 'classnames/bind'
import { connect, useDispatch, useSelector } from 'react-redux'
import { actions } from './redux/DashboardRedux'
import { currencyFormat } from '../../../utils/DataFormatUtils'
import moment from 'moment'
import Spinner from '../../../_metronic/_partials/spinner'
import { Client } from '@stomp/stompjs'
import { env } from '../../../env'
import { SUCCESS_RESULT_CODE } from '../../../utils/Constants'
import useAlerts from '../../../utils/hooks/useAlerts'
import { AlertList } from 'react-bs-notifier'
import { useIntl } from 'react-intl'
const cx = classNames.bind(style)

function Dashboard({
  dailyTransactions,
  weeklyTransactions,
  top10Transactions,
  dailyTransactionListLoading,
  weeklyTransactionListLoading
}) {
  const intl = useIntl()
  const { alerts, onDismissed, generateAlert, ALERT_TIMEOUT_15_SECONDS } = useAlerts()
  const NUMBER_OF_DAYS_OF_THE_WEEK = 6
  const [dailyTransactionCount, setDailyTransactionCount] = useState([])
  const [dailyTransactionCurrencies, setDailyTransactionCurrencies] = useState([])
  const [weeklyTransactionDay, setWeeklyTransactionDay] = useState(['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'])
  const [weeklyTransactionCounts, setWeeklyTransactionCounts] = useState([0, 0, 0, 0, 0, 0, 0])
  const [successRatePercent, setSuccessRatePercent] = useState([0])
  const [transactionList, setTransactionList] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dailyTransactionListLoading()
    weeklyTransactionListLoading()
    loadTop10Transactions({
      transType: 'all',
      status: 'all',
      start: null,
      end: null,
      pageSize: 10,
      pageIndex: 1,
      orderType: 'DESC',
      ignoreTotalSize: true,
      includeSubAgent: true,
      ignoreReturnDate: true
    })
  }, [])

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const { authToken, user } = useSelector(state => state.auth)

  const loadTop10Transactions = searchFilters => {
    const headers = {
      Username: user.login,
      Authorization: authToken
    }
    dispatch(actions.top10TransactionLoading())
    const onConnected = () => {
      client.subscribe(
        `/transactions/${user.login}/dashboard`,
        message => {
          if (message.body) {
            const { data, resultCode, resultDescription } = JSON.parse(message.body).body
            if (resultCode === SUCCESS_RESULT_CODE) {
              dispatch(
                actions.top10TransactionReceived({
                  resultCode,
                  resultDescription,
                  data
                })
              )
              client.deactivate()
            } else {
              dispatch(
                actions.top10TransactionFailed({
                  error: resultDescription
                })
              )
              generateAlert(
                'danger',
                intl.formatMessage({ id: 'DASHBOARD.TRANSACTION.LABLE.DASHBOARD_TRANSACTIONS' }),
                resultDescription
              )
              client.deactivate()
            }
          }
        },
        headers
      )

      client.publish({
        destination: `/app/transaction/${user.login}/dashboard`,
        body: JSON.stringify(searchFilters),
        headers: headers
      })
    }
    const client = new Client({
      brokerURL: env.REACT_APP_WS_URL,
      connectHeaders: headers,
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onConnect: onConnected
    })

    client.activate()

    return () => {
      client.deactivate()
    }
  }

  useEffect(() => {
    if (dailyTransactions.data) {
      let currencies = []
      let countCurrencies = []
      const data = dailyTransactions?.data
      data.forEach(item => {
        currencies.push(item.currencyCode)
        countCurrencies.push(item.total)
      })
      setDailyTransactionCurrencies(currencies)
      setDailyTransactionCount(countCurrencies)
    }
  }, [dailyTransactions.data])

  useEffect(() => {
    if (weeklyTransactions.data) {
      const data = weeklyTransactions.data
      let total = 0
      let successTotal = 0
      const days = setDaysArray(data)
      setWeeklyTransactionDay(days)
      const count = setCountArray(days, data)
      data.forEach(item => {
        total += item.total
        successTotal += item.success
      })
      let successRate = (successTotal / total) * 100
      setSuccessRatePercent([currencyFormat(successRate)])
      setWeeklyTransactionCounts(count)
    }
  }, [weeklyTransactions.data])

  useEffect(() => {
    if (top10Transactions.data) {
      setTransactionList(top10Transactions.data)
    }
  }, [top10Transactions.data])
  const setCountArray = (days, data) => {
    let count = []
    days.forEach(item => {
      let obj = data.find(o => {
        if (item === o.dayOfWeek) return o
      })
      if (obj) {
        const object = {
          x: obj.day,
          y: obj.total
        }
        count.push(object)
      } else {
        count.push({ x: 'NaN', y: 0 })
      }
    })
    return count
  }

  const setDaysArray = data => {
    let days = []
    let curr = new Date()
    let startDay = moment(curr.setDate(curr.getDate() - 6))
    for (let i = 0; i <= NUMBER_OF_DAYS_OF_THE_WEEK; i++) {
      let day = moment(startDay).format('ddd')
      days.push(day.toUpperCase())
      let nextDay = startDay.clone().add(1, 'days')
      startDay = nextDay
    }
    return days
  }

  return (
    <div>
      <AlertList
        position={'top-right'}
        alerts={alerts}
        timeout={ALERT_TIMEOUT_15_SECONDS}
        dismissTitle="Begone!"
        onDismiss={onDismissed}
      />
      <div className={cx('wrapper', theme === 'Dark' && 'wrapper-dark')}>
        <div className={cx('chart')}>
          <h2 className={cx('text-align-center')}>Daily Currency</h2>
          {dailyTransactions.isLoading ? (
            <Spinner wrapperClassName={cx('text-align-center')} size={25} />
          ) : dailyTransactions.error ? (
            <div className="text-danger">{dailyTransactions.error}</div>
          ) : dailyTransactionCount.length ? (
            <div style={{ marginTop: '25px', minWidth: '250px' }} className={cx('chart-responsive')}>
              <DailyCurrencyChart
                transactions={dailyTransactionCount}
                currencies={dailyTransactionCurrencies}
                theme={theme}
              />
            </div>
          ) : (
            <div className={cx('no-transaction')}>No Transaction</div>
          )}
        </div>
        <div className={cx('chart')}>
          <h2 className={cx('text-align-center')}>Weekly Transaction Count</h2>
          {weeklyTransactions.isLoading ? (
            <Spinner wrapperClassName={cx('text-align-center')} size={25} />
          ) : weeklyTransactions.error ? (
            <div className="text-danger">{weeklyTransactions.error}</div>
          ) : (
            <WeeklyTransactionChart
              date={weeklyTransactionDay}
              transactionCount={weeklyTransactionCounts}
              theme={theme}
            />
          )}
        </div>
        <div className={cx('chart')}>
          <h2 className={cx('text-align-center')}>Weekly Success Rate</h2>
          {weeklyTransactions.isLoading ? (
            <Spinner wrapperClassName={cx('text-align-center')} size={25} />
          ) : weeklyTransactions.error ? (
            <div className="text-danger">{weeklyTransactions.error}</div>
          ) : (
            <div style={{ minWidth: '250px' }}>
              <SuccessRateChart percent={successRatePercent} theme={theme} />
            </div>
          )}
        </div>
      </div>
      <div className={cx('wrapper-trans-table', theme === 'Dark' && 'wrapper-trans-table-dark')}>
        <TransactionTable
          isLoading={top10Transactions.isLoading}
          data={transactionList}
          error={top10Transactions.error}
          theme={theme}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dailyTransactions: state.dashboard.dailyTransactions,
  weeklyTransactions: state.dashboard.weeklyTransactions,
  top10Transactions: state.dashboard.top10Transactions
})

export default connect(mapStateToProps, actions)(Dashboard)
