import { InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React, { memo, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'

const daysInMonth = moment().daysInMonth()
const periodTypeEnums = { HOURLY: 1, DAILY: 2, WEEKLY: 3, MONTHLY: 4, DATE: 5 }

const PeriodValue = ({
  intl,
  cx,
  periodId,
  formik,
  handleInputChange,
  item,
  index,
  disabled,
  periodValue,
  openModalData,
  limitItems,
  isEnableEdit,
  setPeriodValue
}) => {
  const weeklyValues = [
    { value: 1, label: intl.formatMessage({ id: 'LABEL.DATE.SUNDAY' }) },
    { value: 2, label: intl.formatMessage({ id: 'LABEL.DATE.MONDAY' }) },
    { value: 3, label: intl.formatMessage({ id: 'LABEL.DATE.TUESDAY' }) },
    { value: 4, label: intl.formatMessage({ id: 'LABEL.DATE.WEDNESDAY' }) },
    { value: 5, label: intl.formatMessage({ id: 'LABEL.DATE.THURSDAY' }) },
    { value: 6, label: intl.formatMessage({ id: 'LABEL.DATE.FRIDAY' }) },
    { value: 7, label: intl.formatMessage({ id: 'LABEL.DATE.SATURDAY' }) }
  ]

  const getDayInMonthValues = () => {
    const results = []
    const currentMonth = moment.utc().month()
    for (let i = 1; i <= daysInMonth; i++) {
      results.push({
        value: i,
        label: moment
          .utc()
          .month(currentMonth)
          .date(i)
          .format('Do')
      })
    }
    return results
  }

  useEffect(() => {
    if (periodId === periodTypeEnums.HOURLY) {
      setFormikFieldValue(`limitItems.${index}.periodValue`, openModalData.modal, 1, limitItems, formik, index)
    } else if (periodId === periodTypeEnums.DAILY) {
      setFormikFieldValue(
        `limitItems.${index}.periodValue`,
        openModalData.modal,
        moment.utc().format('HH:mm'),
        limitItems,
        formik,
        index
      )
    } else if (periodId === periodTypeEnums.WEEKLY) {
      setFormikFieldValue(
        `limitItems.${index}.periodValue`,
        openModalData.modal,
        weeklyValues[0].value,
        limitItems,
        formik,
        index
      )
    } else if (periodId === periodTypeEnums.MONTHLY) {
      setFormikFieldValue(
        `limitItems.${index}.periodValue`,
        openModalData.modal,
        getDayInMonthValues()[0].value,
        limitItems,
        formik,
        index
      )
    } else if (periodId === periodTypeEnums.DATE) {
      setFormikFieldValue(`limitItems.${index}.periodValue`, openModalData.modal, new Date(), limitItems, formik, index)
    }
  }, [periodId])

  const setFormikFieldValue = (field, modal, defaultValue, limitItems, formik, index) => {
    if (modal === 'DETAILS_MODAL' && !isEnableEdit) {
      if (limitItems && limitItems[index]) {
        formik.setFieldValue(`${field}`, limitItems[index].periodValue)
        handleSetPeriodValues(periodValue, index, setPeriodValue, limitItems[index].periodValue)
      }
    } else {
      formik.setFieldValue(`${field}`, defaultValue)
      handleSetPeriodValues(periodValue, index, setPeriodValue, defaultValue)
    }
  }

  const handleSetPeriodValues = (periodValue, index, setPeriodValue, value) => {
    const periodValues = [...periodValue]
    if (periodValues[index]) {
      periodValues[index].value = value
      delete periodValues[index].error
      setPeriodValue(periodValues)
    }
  }
  return (
    <>
      {periodId == periodTypeEnums.HOURLY && (
        <TextField
          disabled={disabled}
          label={intl.formatMessage({
            id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_VALUE'
          })}
          onChange={e => handleInputChange(index, e, 'periodValue')}
          type="text"
          value={item.periodValue != null ? item.periodValue : ''}
          name={`periodValue${index}`}
          variant="outlined"
          size="small"
          className="w-100"
          error={Boolean(periodValue && periodValue[index] && periodValue[index]?.error)}
        />
      )}
      {periodId === periodTypeEnums.DAILY && (
        <TextField
          disabled={disabled}
          label={intl.formatMessage({
            id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_VALUE'
          })}
          readOnly={true}
          onChange={e => handleInputChange(index, e, 'periodValue')}
          type="time"
          value={item.periodValue}
          name={`periodValue${index}`}
          variant="outlined"
          size="small"
          className="w-100"
          error={Boolean(periodValue && periodValue[index] && periodValue[index]?.error)}
        />
      )}
      {periodId === periodTypeEnums.WEEKLY && (
        <>
          <InputLabel htmlFor={`periodValue${index}`} style={{ color: 'black' }}>
            {intl.formatMessage({
              id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_VALUE'
            })}
          </InputLabel>
          <Select
            disabled={disabled}
            onChange={e => handleInputChange(index, e, 'periodValue')}
            value={item.periodValue}
            error={Boolean(periodValue && periodValue[index] && periodValue[index]?.error)}
            input={<OutlinedInput labelWidth={170} name={`periodValue${index}`} id={`periodValue${index}`} />}
          >
            {weeklyValues.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      {periodId === periodTypeEnums.MONTHLY && (
        <>
          <InputLabel htmlFor={`periodValue${index}`} style={{ color: 'black' }}>
            {intl.formatMessage({
              id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_VALUE'
            })}
          </InputLabel>
          <Select
            disabled={disabled}
            onChange={e => handleInputChange(index, e, 'periodValue')}
            value={item.periodValue}
            error={Boolean(periodValue && periodValue[index] && periodValue[index]?.error)}
            input={<OutlinedInput labelWidth={170} name={`periodValue${index}`} id={`periodValue${index}`} />}
          >
            {getDayInMonthValues().map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      {periodId === periodTypeEnums.DATE && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disabled={disabled}
            disablePast
            variant="inline"
            inputVariant="outlined"
            onChange={e => handleInputChange(index, e, 'periodValue')}
            value={item.periodValue}
            name={`periodValue${index}`}
            autoOk={true}
            label={intl.formatMessage({
              id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_VALUE'
            })}
            ampm={false}
            format="dd/MM/yyyy"
            size="small"
            error={Boolean(periodValue && periodValue[index] && periodValue[index]?.error)}
          />
        </MuiPickersUtilsProvider>
      )}
    </>
  )
}

export default memo(PeriodValue)
