import {
  INVALID_SESSION_TOKEN_RESULT_CODE,
  EXPIRED_SESSION_TOKEN_RESULT_CODE,
  EXPIRED_JWT_TOKEN_RESULT_CODE,
  FORCE_LOG_OUT_RESULT_CODE,
  FORCE_LOG_OUT_MESSAGE,
  EXPIRED_SESSION_LOGIN_MESSAGE
} from '../utils/Constants'
import { KTCookie } from '../_metronic/_assets/js/components/cookie'
import { LOGOUT_URL, LOGIN_URL } from '../app/modules/Auth/_redux/authCrud'
import { GET_CUSTOM_TEMPLATE_DETAIL_URL } from './appCrud'
export default function setupAxios(axios, store, actionTypes) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState()

      if (config.url !== GET_CUSTOM_TEMPLATE_DETAIL_URL && authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }
      var theme = KTCookie.getCookie('theme_mode')
      if (theme) {
        config.headers.theme = theme
      }
      return config
    },
    err => Promise.reject(err)
  )

  axios.interceptors.response.use(
    response => {
      const {
        data: { resultCode, resultDescription },
        headers
      } = response
      if (
        response.config.url !== LOGOUT_URL &&
        [
          INVALID_SESSION_TOKEN_RESULT_CODE.toString(),
          EXPIRED_SESSION_TOKEN_RESULT_CODE.toString(),
          EXPIRED_JWT_TOKEN_RESULT_CODE.toString(),
          FORCE_LOG_OUT_RESULT_CODE.toString()
        ].includes(resultCode?.toString())
      ) {
        if (
          FORCE_LOG_OUT_RESULT_CODE.toString() === resultCode.toString() &&
          !window.location.pathname.includes(LOGIN_URL)
        ) {
          if (!window.localStorage.getItem(FORCE_LOG_OUT_MESSAGE)) {
            window.localStorage.setItem(FORCE_LOG_OUT_MESSAGE, resultDescription)
          }
        } else {
          if (!window.localStorage.getItem(EXPIRED_SESSION_LOGIN_MESSAGE)) {
            window.localStorage.setItem(EXPIRED_SESSION_LOGIN_MESSAGE, 'Your session has expired, please login again.')
          }
        }
        store.dispatch({ type: actionTypes.Logout })
      } else {
        const theme = headers.theme
        var currentTheme = KTCookie.getCookie('theme_mode')
        if (theme && !currentTheme) {
          KTCookie.setCookie('theme_mode', theme)
        }
      }
      return response
    },
    error => Promise.reject(error)
  )
}
