import { merchantApiClient } from '../../../../redux'

export const UPDATE_USER_PROFILE = '/api/v1/update-profile'
export const CHANGE_USER_PASSWORD = '/api/v1/change-password'
export const TURN_ON_TWO_FACTOR_AUTH = '/api/v1/turnon-twofactor-auth'
export const TURN_OFF_TWO_FACTOR_AUTH = '/api/v1/turnoff-twofactor-auth'
export const LOAD_TWO_FACTOR_AUTH = '/api/v1/load-twofactor-auth'

export const updateUserProfile = payload => {
  return merchantApiClient.put(UPDATE_USER_PROFILE, payload)
}

export const changeUserPassword = payload => {
  return merchantApiClient.put(CHANGE_USER_PASSWORD, payload)
}

export const turnOnTwoFactorAuth = () => {
  return merchantApiClient.put(TURN_ON_TWO_FACTOR_AUTH)
}

export const turnOffTwoFactorAuth = ({ password }) => {
  return merchantApiClient.put(TURN_OFF_TWO_FACTOR_AUTH, { password })
}

export const loadTwoFactorAuth = () => {
  return merchantApiClient.get(LOAD_TWO_FACTOR_AUTH)
}
