import { put, call, takeLatest } from 'redux-saga/effects'

import {
  getCompanyStructure,
  getAgentInfoByAgentCode,
  getCountryList,
  getStateList,
  getTimezoneList,
  getDocumentByAgentType
} from './companyDetailsCrud'

export const actionTypes = {
  companyStructureLoading: '[companyStructure] Loading',
  companyStructureReceived: '[companyStructure] Received',
  companyStructureFailed: '[companyStructure] Failed',
  agentInfoLoading: '[agentInfo] Loading',
  agentInfoReceived: '[agentInfo] Received',
  agentInfoFailed: '[agentInfo] Failed',
  agentInfoClear: '[agentInfo] Clear',
  dataNoticeLoading: '[dataNotice] Loading',
  dataNoticeReceived: '[dataNotice] Received',
  dataNoticeFailed: '[dataNotice] Failed',
  countryListLoading: '[countryListLoading] Loading',
  countryListReceived: '[countryListReceived] Received',
  countryListFailed: '[countryListFailed] Failed',
  stateListLoading: '[stateListLoading] Loading',
  stateListReceived: '[stateListReceived] Received',
  stateListFailed: '[stateListFailed] Failed',
  timezoneListLoading: '[timezoneList] Loading',
  timezoneListReceived: '[timezoneList] Received',
  timezoneListFailed: '[timezoneList] Failed',
  agentReportLoading: '[Agent Report] Loading',
  agentReportReceived: '[Agent Report] Received',
  agentReportClear: '[Agent Report] Clear'
}

const initialCompanyDetailsState = {
  companyStructure: {
    data: null,
    error: null,
    isLoading: false
  },
  agentInfo: {
    data: null,
    error: null,
    isLoading: false
  },
  dataNotice: {
    data: null,
    error: null,
    isLoading: false
  },
  countryList: {
    data: null,
    error: null,
    isLoading: false
  },
  stateList: {
    data: null,
    error: null,
    isLoading: false
  },
  timezoneList: {
    data: null,
    error: null,
    isLoading: false
  },
  agentReportData: {
    data: null,
    isLoading: false
  }
}

export const reducer = (state = initialCompanyDetailsState, action) => {
  switch (action.type) {
    //Company Structure
    case actionTypes.companyStructureLoading: {
      return {
        ...state,
        companyStructure: {
          ...state.companyStructure,
          isLoading: true
        }
      }
    }
    case actionTypes.companyStructureReceived: {
      const data = action.payload
      return {
        ...state,
        companyStructure: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.companyStructureFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        companyStructure: {
          ...state.companyStructure,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    // Agent Info
    case actionTypes.agentInfoLoading: {
      return {
        ...state,
        agentInfo: {
          ...state.agentInfo,
          isLoading: true
        }
      }
    }
    case actionTypes.agentInfoReceived: {
      const data = action.payload
      return {
        ...state,
        agentInfo: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.agentInfoFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        agentInfo: {
          ...state.agentInfo,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    case actionTypes.agentInfoClear: {
      return {
        ...state,
        agentInfo: {
          data: null,
          isLoading: false,
          error: null
        }
      }
    }
    // Data Notice
    case actionTypes.dataNoticeLoading: {
      return {
        ...state,
        dataNotice: {
          ...state.dataNotice,
          isLoading: true
        }
      }
    }
    case actionTypes.dataNoticeReceived: {
      const data = action.payload
      return {
        ...state,
        dataNotice: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.dataNoticeFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        dataNotice: {
          ...state.dataNotice,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    // Country List
    case actionTypes.countryListLoading: {
      return {
        ...state,
        countryList: {
          ...state.countryList,
          isLoading: true
        }
      }
    }
    case actionTypes.countryListReceived: {
      const data = action.payload

      return {
        ...state,
        countryList: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.countryListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        countryList: {
          ...state.countryList,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    // State List
    case actionTypes.stateListLoading: {
      return {
        ...state,
        stateList: {
          ...state.stateList,
          isLoading: true
        }
      }
    }
    case actionTypes.stateListReceived: {
      const data = action.payload
      return {
        ...state,
        stateList: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.stateListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        stateList: {
          ...state.stateList,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    // Timezone List
    case actionTypes.timezoneListLoading: {
      return {
        ...state,
        timezoneList: {
          ...state.timezoneList,
          isLoading: true
        }
      }
    }
    case actionTypes.timezoneListReceived: {
      const data = action.payload
      return {
        ...state,
        timezoneList: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.timezoneListFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        timezoneList: {
          ...state.timezoneList,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.agentReportLoading: {
      return {
        ...state,
        agentReportData: {
          data: null,
          isLoading: true,
          error: null
        }
      }
    }
    case actionTypes.agentReportReceived: {
      const data = action.payload
      return {
        ...state,
        agentReportData: {
          data: data.payload,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.agentReportClear: {
      return {
        ...state,
        agentReportData: {
          data: null,
          isLoading: false,
          error: null
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  companyStructureLoading: () => ({
    type: actionTypes.companyStructureLoading,
    payload: null
  }),
  companyStructureReceived: payload => ({
    type: actionTypes.companyStructureReceived,
    payload
  }),
  companyStructureFailed: payload => ({
    type: actionTypes.companyStructureFailed,
    payload
  }),
  agentInfoLoading: agentCode => ({
    type: actionTypes.agentInfoLoading,
    agentCode
  }),
  agentInfoReceived: payload => ({
    type: actionTypes.agentInfoReceived,
    payload
  }),
  agentInfoFailed: payload => ({
    type: actionTypes.agentInfoFailed,
    payload
  }),
  agentInfoClear: () => ({
    type: actionTypes.agentInfoClear
  }),
  dataNoticeLoading: () => ({
    type: actionTypes.dataNoticeLoading,
    payload: null
  }),
  dataNoticeReceived: payload => ({
    type: actionTypes.dataNoticeReceived,
    payload
  }),
  dataNoticeFailed: payload => ({
    type: actionTypes.dataNoticeFailed,
    payload
  }),
  countryListLoading: () => ({
    type: actionTypes.countryListLoading,
    payload: null
  }),
  countryListReceived: payload => ({
    type: actionTypes.countryListReceived,
    payload
  }),
  countryListFailed: payload => ({
    type: actionTypes.countryListFailed,
    payload
  }),
  stateListLoading: countryCode => ({
    type: actionTypes.stateListLoading,
    countryCode
  }),
  stateListReceived: payload => ({
    type: actionTypes.stateListReceived,
    payload
  }),
  stateListFailed: payload => ({
    type: actionTypes.stateListFailed,
    payload
  }),
  timezoneListLoading: () => ({
    type: actionTypes.timezoneListLoading,
    payload: null
  }),
  timezoneListReceived: payload => ({
    type: actionTypes.timezoneListReceived,
    payload
  }),
  timezoneListFailed: payload => ({
    type: actionTypes.timezoneListFailed,
    payload
  }),
  agentReportLoading: payload => ({
    type: actionTypes.agentReportLoading,
    payload
  }),
  agentReportReceived: payload => ({
    type: actionTypes.agentReportReceived,
    payload
  }),
  agentReportClear: () => ({
    type: actionTypes.agentReportClear,
    payload: null
  })
}

export function* saga() {
  yield takeLatest(actionTypes.companyStructureLoading, function* companyStructureSaga() {
    try {
      const response = yield call(getCompanyStructure)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.companyStructureReceived(data))
      else yield put(actions.companyStructureFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.companyStructureFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
  yield takeLatest(actionTypes.agentInfoLoading, function* agentInfoSaga(params) {
    try {
      const response = yield call(getAgentInfoByAgentCode, params.agentCode)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.agentInfoReceived(data))
      else yield put(actions.agentInfoFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.agentInfoFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
  yield takeLatest(actionTypes.dataNoticeLoading, function* dataNoticeSaga() {
    try {
      const response = yield call(getDocumentByAgentType)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.dataNoticeReceived(data))
      else yield put(actions.dataNoticeFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.dataNoticeFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
  yield takeLatest(actionTypes.countryListLoading, function* countryListSaga() {
    try {
      const response = yield call(getCountryList)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.countryListReceived(data))
      else yield put(actions.countryListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.countryListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.stateListLoading, function* stateListSaga(params) {
    try {
      const response = yield call(getStateList, params.countryCode)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.stateListReceived(data))
      else yield put(actions.stateListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.stateListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.timezoneListLoading, function* timezoneListSaga() {
    try {
      const response = yield call(getTimezoneList)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0) yield put(actions.timezoneListReceived(data))
      else yield put(actions.timezoneListFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.timezoneListFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  yield takeLatest(actionTypes.agentReportLoading, function* agentReportLoadingSaga(params) {
    yield put(actions.agentReportReceived(params))
  })
}
