import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { saveDocument } from '../../redux/resourceManagementCrud'
import { Button } from 'react-bootstrap'
import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { validateFileSize } from '../../../../../../../utils/FileUploadUtils'
import { env } from '../../../../../../../env'

const maxFilesSize = env.REACT_APP_PIN_ORDER_MAX_FILES_SIZE_IN_MB
const maxFileNameLength = env.REACT_APP_PIN_ORDER_MAX_FILE_NAME_LENGTH

const FormDocumentDetails = ({
  intl,
  documentTypeList,
  contentTypeList,
  locationList,
  cx,
  handleCloseDetailModal,
  document,
  generateAlert,
  reloadData,
  theme
}) => {
  const [typeInput, setTypeInput] = useState('HTML')
  const [checked, setChecked] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [fileChoose, setFileChoose] = useState('')
  const [fileInputError, setFileInputError] = useState('')

  const fileInput = useRef()
  const INIT_CREATE_DOCUMENT = {
    documentId: document.id,
    description: document.description,
    code: document.code,
    documentTypeCode: document.documentType,
    contentType: document.contentType,
    active: document.active,
    content: document.content,
    locationCode: document.location === null ? 'UNSET' : document.location?.url
  }

  const documentDetailsSchema = Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    documentTypeCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    contentType: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    content: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  useEffect(() => {
    setChecked(document.active === 'Active' ? true : false)
  }, [document.active])

  const updateDocumentFormik = useFormik({
    initialValues: INIT_CREATE_DOCUMENT,
    validationSchema: documentDetailsSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      saveDocument(values, fileChoose)
        .then(res => {
          const { resultCode, resultDescription } = res.data
          if (resultCode === 0) {
            generateAlert('success', 'Update Document', resultDescription)
            handleCloseDetailModal()
            reloadData()
            resetForm()
          } else {
            generateAlert('danger', 'Update Document', resultDescription)
          }
        })
        .catch(message => {
          generateAlert('danger', 'Update Document', message.toString())
        })
        .finally(data => {
          setSubmitting(false)
        })
    }
  })

  useEffect(() => {
    if (fileChoose != '') {
      updateDocumentFormik.setFieldValue('content', fileChoose?.name)
    }
  }, [fileChoose])

  const handleChangeDocumentType = e => {
    let documentType = e.target.value
    updateDocumentFormik.setFieldValue('documentTypeCode', documentType)
  }

  const handleChangeFile = e => {
    let contentType = e.target.value
    updateDocumentFormik.setFieldValue('contentType', contentType)
    updateDocumentFormik.setFieldValue('content', '')
    setTypeInput(contentType)
  }

  useEffect(() => {
    setTypeInput(document?.contentType)
  }, [document?.contentType])

  const handleChangeLocation = e => {
    let location = e.target.value
    updateDocumentFormik.setFieldValue('locationCode', location)
  }

  const handleChangeCheckbox = e => {
    setChecked(!checked)
  }

  useEffect(() => {
    if (checked) {
      updateDocumentFormik.setFieldValue('active', 'T')
    } else {
      updateDocumentFormik.setFieldValue('active', 'F')
    }
  }, [checked])

  const getInputClasses = fieldname => {
    if (updateDocumentFormik.touched[fieldname] && updateDocumentFormik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (updateDocumentFormik.touched[fieldname] && !updateDocumentFormik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const handleFileChange = event => {
    if (!event.target.files) {
      return
    }
    setFileInputError('')
    const file = event.target.files[0]
    if (!validateFileSize(file.size, maxFilesSize)) {
      fileInput.current.value = null
      setFileInputError(`Max file size: ${maxFilesSize}mb`)
      return
    }
    if (file.name?.length > maxFileNameLength) {
      fileInput.current.value = null
      setFileInputError(`Max filename length: ${maxFileNameLength}`)
      return
    }
    setFileChoose(file)
  }

  const handleTestHiddenFileInput = () => {
    window.document.getElementById('selectedFile').click()
  }

  const htmlDecode = input => {
    let e = window.document.createElement('textarea')
    e.innerHTML = input
    // handle case of empty input
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  return (
    <form
      onSubmit={updateDocumentFormik.handleSubmit}
      className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
    >
      <ul className={cx('create-document-content')}>
        <li className={cx('create-document-item')}>
          <FormControl sx={{ m: 2 }} className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Description"
              type="text"
              name="description"
              onChange={updateDocumentFormik.handleChange}
              value={updateDocumentFormik.values.description}
              onBlur={updateDocumentFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              disabled={isEdit ? false : true}
              error={Boolean(updateDocumentFormik.touched.description && updateDocumentFormik.errors.description)}
              helperText={(updateDocumentFormik.touched.description && updateDocumentFormik.errors.description) || ''}
            />
          </FormControl>
        </li>

        <li className={cx('create-document-item')}>
          <FormControl sx={{ m: 2 }} className={cx('width-100-percent')} variant="filled">
            <TextField
              label="Code"
              type="text"
              name="code"
              onChange={updateDocumentFormik.handleChange}
              value={updateDocumentFormik.values.code}
              onBlur={updateDocumentFormik.handleBlur}
              margin="normal"
              variant="outlined"
              size="small"
              disabled={isEdit ? false : true}
              error={Boolean(updateDocumentFormik.touched.code && updateDocumentFormik.errors.code)}
              helperText={(updateDocumentFormik.touched.code && updateDocumentFormik.errors.code) || ''}
            />
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl sx={{ m: 2 }} className={cx('width-100-percent')} variant="filled">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChangeCheckbox}
                  checked={checked}
                  disabled={isEdit ? false : true}
                  inputProps={{
                    style: isEdit ? undefined : { color: '#edeef2', backgroundColor: '#edeef2' }
                  }}
                />
              }
              label="Active"
            />
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              Merchant Portal Resource
            </InputLabel>
            <Select
              value={updateDocumentFormik.values.documentTypeCode}
              onChange={handleChangeDocumentType}
              onBlur={updateDocumentFormik.handleBlur}
              input={<OutlinedInput labelWidth={170} name="documentTypeCode" id="documentTypeCode" />}
              disabled={isEdit ? false : true}
              error={Boolean(
                updateDocumentFormik.touched.documentTypeCode && updateDocumentFormik.errors.documentTypeCode
              )}
            >
              {documentTypeList &&
                documentTypeList.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.code}>
                      {item.description}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText error>
              {(updateDocumentFormik.touched.documentTypeCode && updateDocumentFormik.errors.documentTypeCode) || ''}
            </FormHelperText>
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              File
            </InputLabel>
            <Select
              value={updateDocumentFormik.values.contentType}
              onChange={handleChangeFile}
              onBlur={updateDocumentFormik.handleBlur}
              input={<OutlinedInput labelWidth={25} name="contentType" id="contentType" />}
              disabled={isEdit ? false : true}
              error={Boolean(updateDocumentFormik.touched.contentType && updateDocumentFormik.errors.contentType)}
            >
              {contentTypeList &&
                contentTypeList.map(item => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText error>
              {(updateDocumentFormik.touched.contentType && updateDocumentFormik.errors.contentType) || ''}
            </FormHelperText>
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl className={cx('width-100-percent')} variant="filled">
            {typeInput === 'HTML' ? (
              <TextField
                id="outlined-multiline-static"
                label="Content"
                multiline
                rows="6"
                name="content"
                onChange={updateDocumentFormik.handleChange}
                value={htmlDecode(updateDocumentFormik.values.content)}
                onBlur={updateDocumentFormik.handleBlur}
                margin="normal"
                variant="outlined"
                size="small"
                error={Boolean(updateDocumentFormik.touched.content && updateDocumentFormik.errors.content)}
                helperText={(updateDocumentFormik.touched.content && updateDocumentFormik.errors.content) || ''}
                disabled={isEdit ? false : true}
              />
            ) : (
              <>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    disabled={updateDocumentFormik.isSubmitting}
                    type="file"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('content')}`}
                    name="content"
                    ref={fileInput}
                    onChange={handleFileChange}
                    id="selectedFile"
                    style={{ display: 'none' }}
                  />
                </div>
                <div className={cx('actions')}>
                  <Button
                    className={cx('upload-btn')}
                    disabled={isEdit ? false : true}
                    variant="outline-primary"
                    size="lg"
                    onClick={() => handleTestHiddenFileInput()}
                  >
                    Attach File
                  </Button>
                  {document.content == '' ? 'No File Chosen' : fileChoose != '' ? fileChoose.name : document.content}
                </div>
                {fileInputError && <div className={cx('error-message')}>{fileInputError}</div>}
              </>
            )}
          </FormControl>
        </li>
        <li className={cx('create-document-item')}>
          <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent')}>
            <InputLabel htmlFor="outlined-age-native-simple" style={{ color: 'black' }}>
              Location
            </InputLabel>
            <Select
              value={updateDocumentFormik.values.locationCode}
              onChange={handleChangeLocation}
              onBlur={updateDocumentFormik.handleBlur}
              input={<OutlinedInput labelWidth={60} name="locationCode" id="locationCode" />}
              error={Boolean(updateDocumentFormik.touched.locationCode && updateDocumentFormik.errors.locationCode)}
              disabled={isEdit ? false : true}
            >
              {locationList &&
                locationList.map(item => {
                  return (
                    <MenuItem key={item.name} value={item.url}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </Select>
            <FormHelperText error>
              {(updateDocumentFormik.touched.contentType && updateDocumentFormik.errors.contentType) || ''}
            </FormHelperText>
          </FormControl>
        </li>
      </ul>

      {isEdit ? (
        <div className={cx('action-button')}>
          <button
            disabled={updateDocumentFormik.isSubmitting}
            type="submit"
            className={cx(
              'button',
              'button-block',
              'btn',
              'btn-primary',
              'btn-lg',
              'submit-button',
              'btn-primary-custom'
            )}
          >
            <span>Submit</span>
            {updateDocumentFormik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <button
            onClick={() => {
              setIsEdit(false)
            }}
            className={cx(
              'button',
              'button-block',
              'btn-secondary',
              'btn',
              `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`,
              'btn-lg'
            )}
          >
            <span>Cancel</span>
          </button>
        </div>
      ) : (
        <div className={cx('action-button')}>
          <button
            onClick={e => {
              e.preventDefault()
              setIsEdit(true)
            }}
            className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
          >
            <span>Edit</span>
          </button>
        </div>
      )}
    </form>
  )
}

export default FormDocumentDetails
