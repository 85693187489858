import { put, call, takeLatest } from 'redux-saga/effects'
import { getListEntityName, getMerchantPerformanceReport } from './performanceReportCrud'

export const actionTypes = {
  merchantPerformanceReportLoading: '[Merchant Performance Report] Loading',
  merchantPerformanceReportReceived: '[Merchant Performance Report] Received',
  merchantPerformanceReportFailed: '[Merchant Performance Report] Failed'
}

const initialMerchantPerformanceReportState = {
  performanceReport: {
    data: [],
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialMerchantPerformanceReportState, action) => {
  switch (action.type) {
    case actionTypes.merchantPerformanceReportLoading: {
      return {
        ...state,
        performanceReport: {
          ...state.performanceReport,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.merchantPerformanceReportReceived: {
      const data = action.payload
      return {
        ...state,
        performanceReport: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.merchantPerformanceReportFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        performanceReport: {
          ...state.performanceReport,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  merchantPerformanceReportLoading: params => ({
    type: actionTypes.merchantPerformanceReportLoading,
    payload: null,
    params: params
  }),
  merchantPerformanceReportReceived: payload => ({
    type: actionTypes.merchantPerformanceReportReceived,
    payload
  }),
  merchantPerformanceReportFailed: payload => ({
    type: actionTypes.merchantPerformanceReportFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.merchantPerformanceReportLoading, function* merchantPerformanceReportLoadingSaga({
    params
  }) {
    try {
      const response = yield call(getMerchantPerformanceReport, params)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0)
        yield put(
          actions.merchantPerformanceReportReceived({
            resultCode,
            resultDescription,
            data
          })
        )
      else yield put(actions.merchantPerformanceReportFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.merchantPerformanceReportFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
