/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from 'react'
import { Redirect, Switch, Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Layout } from '../_metronic/layout'
import BasePage from './BasePage'
import { Logout, AuthPage } from './modules/Auth'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import { actions } from './modules/Auth/_redux/authRedux'
import { actions as appVersionAction } from '../redux/appRedux'
import { useDispatch } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import EmailAddressForm from './modules/Auth/pages/EmailAddressForm'
import EmailConfirmation from './modules/Auth/pages/EmailConfirmation'
import { FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY } from '../utils/Constants'
import { Client } from '@stomp/stompjs'
import { env } from '../env'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
export function Routes() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { authToken, user, error } = useSelector(state => state.auth)

  useEffect(() => {
    if (user) {
      const headers = {
        Username: user.login,
        Authorization: authToken
      }

      const onConnected = () => {
        client.subscribe(
          `/login/${user.login}`,
          message => {
            window.localStorage.setItem(FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY, message.body)
            if (window.localStorage.getItem(FORCE_LOGOUT_WHEN_DUPLICATE_LOGIN_KEY)) {
              history.push('/logout')
              client.deactivate()
            }
          },
          headers
        )
      }

      const client = new Client({
        brokerURL: env.REACT_APP_WS_URL,
        connectHeaders: headers,
        reconnectDelay: 5000,
        heartbeatIncoming: 10000,
        heartbeatOutgoing: 10000,
        onConnect: onConnected
      })
      const currentUri = window.location.pathname

      if (currentUri !== '/email-address-required' && !currentUri.includes('/email-confirmation')) {
        client.activate()
      }
      return () => {
        client.deactivate()
      }
    }
  }, [user])
  const isImpersonate = window.location.pathname === '/auth/impersonate-login'

  useEffect(() => {
    if (!isImpersonate && authToken && user === undefined) {
      dispatch(actions.requestUser())
    }
  }, [])

  useEffect(() => {
    dispatch(appVersionAction.versionLoading())
  }, [])

  const isAuthorized = Boolean(authToken)
  const isExistUser = Boolean(user)
  const { agentUser } = useSelector(state => state.agentUser)
  const intl = useIntl()
  useEffect(() => {
    if (agentUser.data) {
      const limitItems = agentUser.data?.limitItems
      const exceedLimit = []
      const limitPercent = agentUser.data?.limitAlertPercent
      if (limitPercent != null) {
        if (limitItems) {
          limitItems.forEach((item, index) => {
            const percent = (item.currentValue / item.limitValue) * 100
            if (percent > +limitPercent) {
              exceedLimit.push(item.targetCode)
            }
          })
          if (exceedLimit.length > 0) {
            Swal.fire(
              'Warning',
              intl.formatMessage(
                {
                  id: 'CREDIT.LIMIT.ERROR.ALERT.MESSAGE'
                },
                { productCode: `<b>${exceedLimit.join(', ')}</b>`, percent: `<b>${limitPercent}</b>` }
              ),
              'error'
            )
          }
        }
      }
    }
  }, [agentUser.data])
  return (
    <Switch>
      {!isAuthorized || isImpersonate ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/email-address-required" component={EmailAddressForm} />
      <Route path="/email-confirmation" component={EmailConfirmation} />

      {!isAuthorized && !isImpersonate ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : isExistUser ? (
        <Layout>
          <BasePage />
        </Layout>
      ) : (
        <>
          {error === true ? <Redirect to="/error" /> : ''}
          <Backdrop className="loading-back-drop" open={!isExistUser}>
            <svg className="splash-spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          </Backdrop>
        </>
      )}
    </Switch>
  )
}
