import React from 'react'
import style from '../payoutTransaction.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import WeeklyTransactionChart from './WeeklyTransactionChart'
import AvailablePayoutBalances from './AvailablePayoutBalance'

const PayoutTransactionReport = ({
  theme,
  maxBalance,
  otherBalances,
  weeklyTransactionDay,
  weeklyTransactionCounts,
  transactionQuantity,
  maxValueSuccessfulPayout,
  otherValueSuccessfulPayout,
  payoutVoucherCreditLimit
}) => {
  return (
    <>
      <div className={cx('payout-report-container')}>
        <div className={cx('payout-report-item')}>
          <h2 className="text-align-center">Available Payout Balance</h2>
          <AvailablePayoutBalances
            maxBalance={maxBalance}
            otherBalances={otherBalances}
            theme={theme}
            payoutVoucherCreditLimit={payoutVoucherCreditLimit}
          />
        </div>
        <div className={cx('payout-report-item')}>
          <h2 className="text-align-center">Weekly Transaction Count</h2>
          <WeeklyTransactionChart
            date={weeklyTransactionDay}
            transactionCount={weeklyTransactionCounts}
            theme={theme}
          />
        </div>
        <div className={cx('payout-report-item')}>
          <h2 className="text-align-center">Successful Payout</h2>
          <div className={cx('successful-payout')}>
            <p className={cx('success-payout-title')}>Quantity:</p>
            <p className={cx('value')}>{transactionQuantity} Transactions</p>
            <p className={cx('success-payout-title')} style={{ marginTop: '16px' }}>
              Value:
            </p>
            <div
              className={cx(
                (!maxBalance || maxBalance.balance == 0) && (otherBalances || otherBalances.length == 0) ? 'value' : ''
              )}
            >
              <AvailablePayoutBalances
                maxBalance={maxValueSuccessfulPayout}
                otherBalances={otherValueSuccessfulPayout}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PayoutTransactionReport
