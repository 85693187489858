import { merchantApiClient } from '../../../../redux'

export const GET_SETTLEMENT_EARNINGS_REPORT_LIST = `/api/v1/settlement-earnings-reports`
export const DOWNLOAD_REPORT_FILE = '/api/v1/download-settlement-earnings-report?fileName='

export function getSettlementEarningsReportList() {
  return merchantApiClient.get(GET_SETTLEMENT_EARNINGS_REPORT_LIST)
}

export function downloadReportFile(fileName) {
  return merchantApiClient.get(DOWNLOAD_REPORT_FILE + fileName)
}
