import { put, call, takeLatest } from 'redux-saga/effects'
// import { getSubDivision, getNotice, searchTransactions } from './reportsCrud'
import { getSettlementEarningsReportList } from './SettlementEarningsCrud'

export const actionTypes = {
  settlementEarningsReportLoading: '[settlementEarningsReport] Loading',
  settlementEarningsReportReceived: '[settlementEarningsReport] Received',
  settlementEarningsReportFailed: '[settlementEarningsReport] Failed'
}

const initialState = {
  settlementEarningsReport: {
    data: [],
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.settlementEarningsReportLoading: {
      return {
        ...state,
        settlementEarningsReport: {
          ...state.settlementEarningsReport,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.settlementEarningsReportReceived: {
      const payload = action.payload
      return {
        ...state,
        settlementEarningsReport: {
          data: payload.data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.settlementEarningsReportFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        settlementEarningsReport: {
          ...state.settlementEarningsReport,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  settlementEarningsReportLoading: params => ({
    type: actionTypes.settlementEarningsReportLoading,
    payload: null,
    params: params
  }),
  settlementEarningsReportReceived: payload => ({
    type: actionTypes.settlementEarningsReportReceived,
    payload
  }),
  settlementEarningsReportFailed: payload => ({
    type: actionTypes.settlementEarningsReportFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.settlementEarningsReportLoading, function* settlementEarningsReportSaga() {
    try {
      const response = yield call(getSettlementEarningsReportList)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === 0)
        yield put(
          actions.settlementEarningsReportReceived({
            resultCode,
            resultDescription,
            data
          })
        )
      else yield put(actions.settlementEarningsReportFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.settlementEarningsReportFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
