import React, { useState, useEffect } from 'react'
import style from '../userProfile.module.css'
import classNames from 'classnames/bind'
import { Modal } from 'react-bootstrap'
const cx = classNames.bind(style)

const GoogleAuthenticator = ({ data, handleClickChangeStatus, isSaving, theme }) => {
  const [isEnableQR, setIsEnableQR] = useState()
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [qrCode, setQrCode] = useState()
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState(false)

  useEffect(() => {
    setIsEnableQR(data?.data?.status)
    setQrCode(data?.data?.qrCode)
  }, [data])

  const handleClickChange = () => {
    setPasswordError(false)
    if (isEnableQR && !password) {
      setPasswordError(true)
      return
    }
    handleClickChangeStatus(password)
  }

  useEffect(() => {
    if (isSaving === false) {
      setIsEnableQR(data?.data?.status)
      setIsOpenDialog(false)
    }
  }, [isSaving])

  const handleOpen = () => {
    setIsOpenDialog(!isOpenDialog)
  }

  const handleClose = () => {
    setIsOpenDialog(!isOpenDialog)
    setPassword('')
    setPasswordError(false)
  }

  const handleChangePassword = e => {
    let value = e.target.value
    setPassword(value)
    if (!value) {
      setPasswordError(true)
    }
  }

  return (
    <>
      <div className={cx('display-flex', 'flex-direction-column')}>
        <div className={cx('display-flex', 'justify-content-center', 'align-item-baseline')}>
          <label style={{ fontSize: '14px', fontWeight: '700', marginRight: '20px' }}>Status:</label>
          <button
            onClick={handleOpen}
            className={cx('button', `btn-${isEnableQR ? 'enable' : 'disable'}`, 'btn', 'btn-lg')}
          >
            {isEnableQR ? 'Enabled' : 'Disabled'}
          </button>
        </div>
        {isEnableQR && (
          <div style={{ marginTop: '10px' }} className={cx('display-flex', 'flex-direction-column')}>
            <p className={cx('title')}>
              Use google authenticator application scan this QR Code to get Verification code or enter account and key
              below:
            </p>
            <p className={cx('title')}>
              Account: <span>{data?.data?.account}</span>
            </p>
            <p className={cx('title')}>
              Secret key: <span>{data?.data?.secretKey}</span>
            </p>
            <img className={cx('qr-code', 'align-self-center')} src={qrCode && `data:image/jpeg;base64,${qrCode}`} />
          </div>
        )}
        {isOpenDialog && (
          <Modal centered show={isOpenDialog} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Two-factor authentication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isEnableQR ? (
                <>
                  <div className={cx('margin-bottom-10')}>Enter your password to disable two-factor authentication</div>
                  <div className={cx('width-100-percent', 'flex-direction-column')}>
                    <input
                      value={password}
                      onChange={handleChangePassword}
                      disabled={isSaving}
                      placeholder="Password"
                      type="password"
                      className={cx(
                        'form-control',
                        theme === 'Dark' && 'form-control-dark',
                        passwordError ? 'error' : ''
                      )}
                      required
                    />
                    {passwordError ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">Password is required</div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                'Are you sure you want to enable two-factor authentication?'
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleClickChange}
                disabled={isSaving}
                className={cx('button', 'btn-primary', 'btn', 'btn-lg', 'btn-primary-custom')}
              >
                <span>Confirm</span>
                {isSaving && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <button
                onClick={handleClose}
                disabled={isSaving}
                className={cx('button', 'btn-primary', 'btn', 'btn-lg', 'btn-primary-custom')}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  )
}

export default GoogleAuthenticator
