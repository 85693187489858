import React, { forwardRef } from 'react'
import moment from 'moment'

export const VoucherReceiptFormPrint = forwardRef((props, ref) => {
  let currentTime = moment().format('DD/MM/YYYY HH:MM A')

  return (
    <div ref={ref}>
      <form>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', justifyItems: 'baseline' }}>
            <div style={{ padding: 10, fontWeight: 'bold' }}>
              <span>{currentTime}</span>
            </div>
            <div style={{ padding: 10, fontWeight: 'bold' }}>
              <span>Redeem Voucher</span>
            </div>
          </div>
          <div style={{ margin: '10px auto' }}>
            <div style={{ padding: '0 0 0 10px' }}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ margin: 'auto', width: '315px' }}>
                  <div
                    style={{
                      borderRadius: '7px',
                      border: '1px solid #000',
                      padding: '10px 20px 10px 20px',
                      lineHeight: '10px'
                    }}
                  >
                    <p style={{ margin: '0 0 10px', textAlign: 'left', lineHeight: '18px' }}>
                      <b>Ref ID:</b> {props.voucherRedeemed?.data?.refId}
                    </p>
                    <p style={{ margin: '0 0 10px', textAlign: 'left', lineHeight: '18px' }}>
                      <b>Product Name:</b> {props.voucherRedeemed?.data?.productName}
                    </p>
                    <p style={{ margin: '0 0 10px', textAlign: 'left', lineHeight: '18px' }}>
                      <b>Value:</b> {props.voucherRedeemed?.data?.redeemedValue}
                    </p>
                    <p style={{ margin: '0 0 10px', textAlign: 'left', lineHeight: '18px' }}>
                      <b>Status:</b> {props.voucherRedeemed?.data?.status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
})
