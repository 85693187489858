/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useLayoutEffect, useContext } from 'react'
import { TemplateContext } from '../_metronic/layout/_core/TemplateProvider'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from '../app/Routes'
import { I18nProvider } from '../_metronic/i18n'
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout'
import { toAbsoluteUrl } from '../_metronic/_helpers'
import { BASE64_IMAGE_PREFIX } from '../utils/Constants'

const DEFAULT_PRIMARY_COLOR = '#9c0d1b'
const DEFAULT_SECONDARY_COLOR = '#E4E6EF'
const DEFAULT_BRAND_LOGO_LIGHT = `url(${toAbsoluteUrl('/media/logos/logo-dark.png')})`
const DEFAULT_PORTAL_NAME = 'Flexepin Merchant Portal'
export default function App({ store, persistor, basename }) {
  const templateContext = useContext(TemplateContext)
  useLayoutEffect(() => {
    if (templateContext) {
      document.documentElement.style.setProperty(
        '--primary-color',
        templateContext.primaryColor ? templateContext.primaryColor : DEFAULT_PRIMARY_COLOR
      )
      document.documentElement.style.setProperty(
        '--secondary-color',
        templateContext.secondaryColor ? templateContext.secondaryColor : DEFAULT_SECONDARY_COLOR
      )
      document.documentElement.style.setProperty(
        '--logo',
        templateContext.brandLogoLight
          ? `url(${BASE64_IMAGE_PREFIX + templateContext.brandLogoLight})`
          : DEFAULT_BRAND_LOGO_LIGHT
      )
      if (templateContext.faviconLogoLight) {
        setLogoTabLogo(BASE64_IMAGE_PREFIX + templateContext.faviconLogoLight)
      }

      document.title = templateContext.portalName ? templateContext.portalName : DEFAULT_PORTAL_NAME
    }
  }, [templateContext])

  const setLogoTabLogo = href => {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.head.appendChild(link)
    }
    if (href) {
      link.href = href
    }
  }

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  )
}
