import { put, call, takeLatest } from 'redux-saga/effects'
import { loadTwoFactorAuth } from './userProfileCrud'

export const actionTypes = {
  twoFactorAuthLoading: '[twoFactorAuth] Loading',
  twoFactorAuthReceived: '[twoFactorAuth] Received',
  twoFactorAuthFailed: '[twoFactorAuth] Failed'
}

const initialUserProfileState = {
  twoFactorAuth: {
    data: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialUserProfileState, action) => {
  switch (action.type) {
    case actionTypes.twoFactorAuthLoading: {
      return {
        ...state,
        twoFactorAuth: {
          ...state.twoFactorAuth,
          isLoading: true
        }
      }
    }
    case actionTypes.twoFactorAuthReceived: {
      const data = action.payload
      return {
        ...state,
        twoFactorAuth: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.twoFactorAuthFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        twoFactorAuth: {
          ...state.twoFactorAuth,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  twoFactorAuthLoading: () => ({
    type: actionTypes.twoFactorAuthLoading,
    payload: null
  }),
  twoFactorAuthReceived: payload => ({
    type: actionTypes.twoFactorAuthReceived,
    payload
  }),
  twoFactorAuthFailed: payload => ({
    type: actionTypes.twoFactorAuthFailed,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.twoFactorAuthLoading, function* twoFactorAuthSaga() {
    try {
      const response = yield call(loadTwoFactorAuth)
      const { resultCode, resultDescription, qrCode, secretKey, account, status } = response.data
      const data = {
        qrCode,
        secretKey,
        account,
        status
      }
      if (resultCode === 0) yield put(actions.twoFactorAuthReceived(data))
      else yield put(actions.twoFactorAuthFailed({ resultCode, resultDescription }))
    } catch (error) {
      yield put(
        actions.twoFactorAuthFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })
}
