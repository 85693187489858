import React, { useState, useRef } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import * as auth from '../../../modules/Auth/_redux/authRedux'
import { requestContactSupport } from '../../../modules/Auth/_redux/authCrud'
import { BLOCKED_FILE_TYPES, ACCEPTED_FILE_TYPES } from '../../../../utils/Constants'
import { validateFileSize } from '../../../../utils/FileUploadUtils'
import { bytesFormat } from '../../../../utils/BytesFormatUtils'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { Button } from 'react-bootstrap'
import { useEffect } from 'react'
import { env } from '../../../../env'

function ContactUsForm({ cx, theme, intl, onShowAlert, email }) {
  let darkMode = theme === 'Dark' && cx('dark')
  const initialValues = {
    email,
    subject: '',
    content: '',
    transId: '',
    files: []
  }

  const [resultCode, setResultCode] = useState(0)
  const fileInput = useRef()
  const [filesArr, setFilesArr] = useState([])
  const [fileSize, setFileSize] = useState(0)
  const maxFilesSize = env.REACT_APP_CONTACT_US_MAX_FILES_SIZE_IN_MB

  const ContactUsSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    subject: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    content: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ContactUsSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setStatus('')
      requestContactSupport(values.email, values.subject, values.content, filesArr, values.transId)
        .then(response => {
          setSubmitting(false)
          setResultCode(response?.data?.resultCode)
          if (response?.data?.resultCode === 0) {
            resetForm()
            setStatus(
              intl.formatMessage({
                id: 'AUTH.CONTACT.US.SUCCESS'
              })
            )
            setFilesArr([])
            fileInput.current.value = ''
            setFileSize(0)
          } else {
            setStatus(response?.data?.resultDescription)
          }
        })
        .catch(error => {
          setSubmitting(false)
          setResultCode(9999)
          setStatus(error?.response?.data?.resultDescription || error.message)
        })
    }
  })

  const removeElementByValue = (arr, value) => {
    var index = arr.indexOf(value)

    if (index !== -1) {
      arr.splice(index, 1)
    }
  }

  const deleteFileItem = fileName => {
    if (filesArr.length > 0) {
      filesArr.forEach(file => {
        if (file.name == fileName) {
          removeElementByValue(filesArr, file)
          setFileSize(prev => prev - file.size)
        }
      })
    }

    setFilesArr([...filesArr])

    if (filesArr.length == 0) {
      fileInput.current.value = ''
      setFileSize(0)
    }
  }

  const handleFileChange = event => {
    formik.setStatus(null)
    if (!event.target.files) {
      return
    }

    let files = Array.from(event.target.files)
    let filesSize = 0
    let listBlockedFileNames = []
    let listAcceptedFiles = []

    files.forEach(file => {
      let fileType = file.name.split('.').pop()
      if (BLOCKED_FILE_TYPES.includes(fileType)) {
        listBlockedFileNames.push(file.name)
      } else if (!filesArr.some(item => item.name === file.name)) {
        filesSize += file.size
        listAcceptedFiles.push(file)
      }
    })
    filesSize += fileSize

    if (!validateFileSize(filesSize, maxFilesSize)) {
      setResultCode(-1)
      formik.setStatus(
        intl.formatMessage(
          {
            id: 'AUTH.CONTACT.US.FILE.SIZE.EXCEEDED'
          },
          { limit: maxFilesSize }
        )
      )
      fileInput.current.value = ''
      return
    }

    if (listBlockedFileNames?.length) {
      let fileNames = listBlockedFileNames.join(', ')
      setResultCode(-1)
      formik.setStatus(
        intl.formatMessage(
          {
            id: 'AUTH.CONTACT.US.INVALID.FILE.TYPE'
          },
          { fileName: fileNames }
        )
      )
      fileInput.current.value = ''
    }

    let newList = filesArr.concat(listAcceptedFiles)
    setFileSize(filesSize)
    setFilesArr(newList)
    formik.setFieldValue('files', newList)
  }

  const handleTestHiddenFileInput = () => {
    document.getElementById('selectedFile').click()
  }

  const handleResetForm = () => {
    formik.resetForm()
    setFilesArr([])
    setFileSize(0)
    formik.setStatus('')
  }

  useEffect(() => {
    onShowAlert(formik.status, resultCode)
  }, [formik.status])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Form.Group as={Form.Row} controlId="emailText">
        <Form.Label column sm="2" className={darkMode}>
          Email*
        </Form.Label>
        <Col sm="10">
          <Form.Control
            disabled={formik.isSubmitting}
            size="lg"
            type="text"
            placeholder="Email"
            className={getInputClasses('email')}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email && (
            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Form.Row} controlId="subjectText">
        <Form.Label column sm="2" className={darkMode}>
          Subject*
        </Form.Label>
        <Col sm="10">
          <Form.Control
            disabled={formik.isSubmitting}
            size="lg"
            type="text"
            placeholder="Subject"
            className={getInputClasses('subject')}
            name="subject"
            {...formik.getFieldProps('subject')}
          />
          {formik.touched.subject && formik.errors.subject && (
            <Form.Control.Feedback type="invalid">{formik.errors.subject}</Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Form.Row} controlId="transIDText">
        <Form.Label column sm="2" className={darkMode}>
          Trans ID
        </Form.Label>
        <Col sm="10">
          <Form.Control
            disabled={formik.isSubmitting}
            size="lg"
            type="text"
            placeholder="Transaction ID"
            name="transId"
            {...formik.getFieldProps('transId')}
          />
        </Col>
      </Form.Group>

      <Form.Control
        disabled={formik.isSubmitting}
        as="textarea"
        rows={10}
        placeholder="Content*"
        className={getInputClasses('content')}
        name="content"
        {...formik.getFieldProps('content')}
      />
      {formik.touched.content && formik.errors.content && (
        <Form.Control.Feedback type="invalid">{formik.errors.content}</Form.Control.Feedback>
      )}

      <div className="form-group fv-plugins-icon-container">
        <input
          disabled={formik.isSubmitting}
          type="file"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('file')}`}
          name="files"
          multiple="multiple"
          accept={ACCEPTED_FILE_TYPES}
          ref={fileInput}
          onChange={handleFileChange}
          id="selectedFile"
          style={{ display: 'none' }}
        />
      </div>
      <div className={cx('actions')}>
        <Button
          className={cx('upload-btn', 'btn-primary-custom')}
          disabled={formik.isSubmitting}
          variant="primary"
          size="lg"
          onClick={handleTestHiddenFileInput}
        >
          Attach File
        </Button>
        {filesArr?.length === 0 && 'No File Chosen'}
      </div>
      {filesArr?.map(file => {
        return (
          <div className={`form-control form-control-solid ${cx('file-arr-wrap')}`} key={file.name}>
            <p className={cx('file-name-uploaded')}>{file.name}</p>
            <p style={{ margin: 0 }}>({bytesFormat(file.size)})</p>
            <div onClick={() => deleteFileItem(file.name)} className={cx('close-file-icon')}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        )
      })}

      <div className={cx('actions')}>
        <div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'action-btn', 'btn-primary-custom')}
          >
            Submit
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <div>
          <button
            type="button"
            disabled={formik.isSubmitting}
            className={`${cx('button', 'btn', 'btn-lg', 'action-btn')} ${darkMode}`}
            onClick={handleResetForm}
          >
            Reset
          </button>
        </div>
      </div>
    </form>
  )
}

export default injectIntl(connect(null, auth.actions)(ContactUsForm))
