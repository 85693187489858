import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    color: 'var(--primary-color)'
  }
}))

export default function Spinner({ wrapperClassName, style, ...props }) {
  const classes = useStyles()

  return (
    <div className={wrapperClassName}>
      <CircularProgress classes={{ colorPrimary: classes.colorPrimary }} style={style} {...props} />
    </div>
  )
}
