import React, { useState, useContext } from 'react'
import { TemplateContext } from '../../../../_metronic/layout/_core/TemplateProvider'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { requestPassword } from '../_redux/authCrud'
import { Alert } from 'react-bootstrap'

const initialValues = {
  merchantId: '',
  username: '',
  email: ''
}

function ForgotPassword(props) {
  const { intl } = props
  const [isRequested, setIsRequested] = useState(false)
  const [resultCode, setResultCode] = useState(0)
  const templateContext = useContext(TemplateContext)
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),
    username: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .trim(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.INVALID_WHITESPACE'
          },
          { name: 'The Username' }
        )
      )
      .strict(true)
  })

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setTimeout(() => {
        requestPassword(values.username, values.email)
          .then(response => {
            if (response?.data?.resultCode === 0) {
              resetForm()
              setIsRequested(false)
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.FORGOT.SUCCESS'
                })
              )
            } else {
              setStatus(response?.data?.resultDescription)
            }
            setSubmitting(false)
            setResultCode(response?.data?.resultCode)
          })
          .catch(error => {
            setIsRequested(false)
            setSubmitting(false)
            setResultCode(9999)
            setStatus(error.message)
          })
      }, 1000)
    }
  })

  const handleDismiss = () => {
    formik.setStatus(null)
  }

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-20">
            <Link to="/" className="flex-column-auto mt-5">
              <div className="max-h-100px logo"></div>
            </Link>
            {templateContext?.portalName ? (
              <h3 className="font-size-h1">
                <FormattedMessage id="PORTAL.NAME" values={{ name: templateContext.portalName }} />
              </h3>
            ) : (
              <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.LOGIN.TITLE" />
              </h3>
            )}
            <h3 className="font-size-h3">
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h3>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
                {formik.status}
              </Alert>
            )}
            <div className="form-group fv-plugins-icon-container">
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="text"
                  placeholder="Username"
                  disabled={formik.isSubmitting}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('username')}`}
                  name="username"
                  {...formik.getFieldProps('username')}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.username}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="text"
                  placeholder="Email"
                  disabled={formik.isSubmitting}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
                  name="email"
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>

              <div className="form-group fv-plugins-icon-container">
                <button
                  id="kt_forgot_password_submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
                >
                  <span>Forgot Password</span>
                  {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Link
                  to={formik.isSubmitting ? '#' : '/auth'}
                  className="text-dark-50 text-hover-primary-custom my-3 mr-2"
                  id="kt_forgot_cancel"
                >
                  <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
                </Link>
                <Link
                  to={formik.isSubmitting ? '#' : '/auth/contact-support'}
                  className="text-dark-50 text-hover-primary-custom my-3 mr-2"
                  id="kt_forgot_contact_support"
                >
                  <FormattedMessage id="AUTH.GENERAL.CONTACT_SUPPORT_BUTTON" />
                </Link>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
