import { put, call, takeLatest } from 'redux-saga/effects'
import { getAPIVersion } from './appCrud'
export const actionTypes = {
  versionLoading: '[Version] Loading',
  versionReceived: '[Version Mode] Received'
}

const initialVersionState = {
  versionData: {
    version: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialVersionState, action) => {
  switch (action.type) {
    case actionTypes.versionLoading: {
      return {
        ...state,
        versionData: {
          ...state.versionData,
          isLoading: true
        }
      }
    }
    case actionTypes.versionReceived: {
      const versionData = action.payload
      return {
        ...state,
        versionData: {
          version: versionData,
          isLoading: false,
          error: null
        }
      }
    }

    default:
      return state
  }
}

export const actions = {
  versionLoading: () => ({
    type: actionTypes.versionLoading,
    payload: null
  }),
  versionReceived: payload => ({
    type: actionTypes.versionReceived,
    payload
  })
}

export function* saga() {
  yield takeLatest(actionTypes.versionLoading, function* versionLoadingSaga() {
    try {
      const data = yield call(getAPIVersion)
      const { versionNumber } = data.data
      yield put(actions.versionReceived(versionNumber))
    } catch (error) {
    } finally {
    }
  })
}
