import React from 'react'
import { Modal } from 'react-bootstrap'
import style from './LoginTokenDialog.module.css'
import classNames from 'classnames/bind'
import { ACCOUNT_LOCKED } from '../../../utils/Constants'
import { env } from '../../../env'
const cx = classNames.bind(style)
const protocol = env.REACT_APP_PROTOCOL

const LoginTokenDialog = ({ isOpenModal, resultCode, message, intl }) => {
  let messageDialog = {
    title: intl.formatMessage({
      id: 'ACCESS.DENIED.TITLE'
    }),
    body: intl.formatMessage({
      id: 'INVALID.INFORMATION.MESSAGE'
    })
  }
  if (resultCode === ACCOUNT_LOCKED && message) {
    messageDialog.title = intl.formatMessage({
      id: 'USER.LOCKED.TITLE'
    })
    messageDialog.body = message
  }

  const redirectLoginPage = () => {
    window.open(protocol + window.location.host + '/auth/login', '_self')
  }
  return (
    <Modal centered show={isOpenModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className={cx('warning-color')}>
            <i className="flaticon-warning-sign icon-xl text-danger"></i>&nbsp;{messageDialog.title}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={cx('warning-color')}>{messageDialog.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={redirectLoginPage} className={cx('button', 'btn', 'btn-primary', 'btn-lg')}>
          <span>CLOSE</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default LoginTokenDialog
