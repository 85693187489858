/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import SVG from 'react-inlinesvg'
import { actions } from '../../../../../redux/basePageRedux'
import { connect } from 'react-redux'
import { KTCookie } from '../../../../_assets/js/components/cookie.js'
const ThemeModeDropdown = ({ themeModeLoading, themeMode }) => {
  const [theme, setTheme] = useState(() => {
    var themeMode = KTCookie.getCookie('theme_mode')
    return themeMode ? themeMode : 'Light'
  })

  useEffect(() => {
    setTheme(themeMode?.themeMode?.theme)
  }, [themeMode?.themeMode?.theme])

  useEffect(() => {
    if (theme && theme === 'Light') {
      document.body.classList.add(`brand-light`)
      document.body.classList.remove(`brand-dark`)
    } else {
      document.body.classList.add(`brand-dark`)
      document.body.classList.remove(`brand-light`)
    }
    KTCookie.setCookie('theme_mode', theme)
  }, [theme])

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-theme-mode">
        <div className={'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 btn-hover-primary-custom'}>
          <span className="svg-icon svg-icon-md svg-icon-danger" style={{ width: '50px', alignItems: 'center' }}>
            <SVG src={toAbsoluteUrl(`/media/svg/icons/Theme/${theme}.svg`)} />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xs ">
        <div className="navi navi-spacer-x-0 pt-5 theme-menu">
          <Dropdown.Item
            className={`navi-item px-6 ${theme === 'Light' && 'active-theme'}`}
            onClick={() => themeModeLoading('Light')}
            href="#"
          >
            <div className="navi-link">
              <div style={{ color: theme === 'Dark' && '#bebfc2' }} className="navi-icon mr-2">
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Theme/Light.svg')} />
                </span>
              </div>
              <div className="navi-text">
                <div style={{ color: theme === 'Dark' && '#bebfc2' }} className=" btn font-weight-bold">
                  Light
                </div>
              </div>
            </div>
          </Dropdown.Item>

          <Dropdown.Item
            className={`navi-item px-6 ${theme === 'Dark' && 'active-theme'}`}
            onClick={() => themeModeLoading('Dark')}
            href="#"
          >
            <div className="navi-link">
              <div style={{ color: theme === 'Dark' && '#bebfc2' }} className="navi-icon mr-2">
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Theme/Dark.svg')} />
                </span>
              </div>
              <div className="navi-text">
                <div style={{ color: theme === 'Dark' && '#bebfc2' }} className=" btn font-weight-bold">
                  Dark
                </div>
              </div>
            </div>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const mapStateToProps = state => ({ themeMode: state.themeMode })

export default connect(mapStateToProps, actions)(ThemeModeDropdown)
