import React, { useState, useEffect } from 'react'
import style from '../userProfile.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)

const ProfileDetails = ({
  data,
  countryList,
  stateList,
  stateListLoading,
  formik,
  isEdit,
  handleClickEdit,
  handleClickCancel,
  theme
}) => {
  const [selectedFile, setSelectedFile] = useState()
  useEffect(() => {
    getBase64(selectedFile)
  }, [selectedFile])

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = ''
      let reader = new FileReader()
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file)
      }
      reader.onload = () => {
        baseURL = reader.result
        formik.setFieldValue('logo', baseURL)
        resolve(baseURL)
      }
    })
  }

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  const handleSubmit = () => {
    formik.submitForm()
  }

  const handleClickEditProfile = () => {
    handleClickEdit()
  }

  const handleClickCancelChange = () => {
    handleClickCancel()
    formik.resetForm()
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        <div className={cx('form-logo')}>
          <div className={cx('image-input image-input-outline')} id="kt_image_1">
            {formik?.values?.logo?.length > 0 ? (
              <div
                className="image-input-wrapper"
                style={{
                  backgroundImage: `url(${formik?.values?.logo})`
                }}
              ></div>
            ) : (
              <div className={cx('display-flex', 'justify-content-center', 'title-logo')}>
                <span className="symbol-label text-success font-weight-bold">
                  {data.firstName ? data.firstName[0] : null}
                </span>
              </div>
            )}

            {isEdit && (
              <label
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i className="fa fa-pen icon-sm text-muted"></i>
                <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" onChange={e => onSelectFile(e)} />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
            )}

            <span
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="cancel"
              data-toggle="tooltip"
              title="Cancel avatar"
            >
              <i className="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>

        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input', 'required-field')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>First Name</label>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.firstName}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
              type="text"
              name="firstName"
              className="form-control"
              {...formik.getFieldProps('firstName')}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
          </div>
          <span className={cx('required-symbol')}>*</span>
        </div>

        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input', 'required-field')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Surname</label>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.surname}
              disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
              onChange={formik.handleChange}
              name="surname"
              className="form-control"
              {...formik.getFieldProps('surname')}
            />
            {formik.touched.surname && formik.errors.surname ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.surname}</div>
              </div>
            ) : null}
          </div>
          <span className={cx('required-symbol')}>*</span>
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Job Title</label>
          <input
            value={formik.values.position}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
            name="position"
            className={cx('form-control', theme === 'Dark' && 'form-control-dark')}
            {...formik.getFieldProps('position')}
          />
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Email</label>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
              type="email"
              name="emailAddress"
              className={cx(
                'form-control',
                theme === 'Dark' && 'form-control-dark',
                formik.touched.emailAddress && formik.errors.emailAddress ? 'error' : ''
              )}
              {...formik.getFieldProps('emailAddress')}
            />
            {formik.touched.emailAddress && formik.errors.emailAddress ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.emailAddress}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Phone</label>
          <div className={cx('width-100-percent', 'flex-direction-column')}>
            <input
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
              name="phoneNumber"
              className={cx(
                'form-control',
                theme === 'Dark' && 'form-control-dark',
                formik.touched.phoneNumber && formik.errors.phoneNumber ? 'error' : ''
              )}
              {...formik.getFieldProps('phoneNumber')}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.phoneNumber}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Address Line 1</label>
          <input
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
            name="addressLine1"
            className={cx('form-control', theme === 'Dark' && 'form-control-dark')}
            {...formik.getFieldProps('addressLine1')}
          />
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Address Line 2</label>
          <input
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
            name="addressLine2"
            className={cx('form-control', theme === 'Dark' && 'form-control-dark')}
            {...formik.getFieldProps('addressLine2')}
          />
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Post/ZIP code</label>
          <input
            value={formik.values.zipCode}
            disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
            onChange={formik.handleChange}
            name="zipCode"
            className={cx('form-control', theme === 'Dark' && 'form-control-dark')}
            {...formik.getFieldProps('zipCode')}
          />
        </div>
        <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
          <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>Country</label>
          <select
            onChange={formik.handleChange}
            value={formik.values.countryCode}
            disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
            className={cx('form-control', theme === 'Dark' && 'form-control-dark')}
            name="countryCode"
          >
            {countryList?.data?.map(item => {
              return (
                <option key={item.code} value={item.code}>
                  {item.name}
                </option>
              )
            })}
          </select>
        </div>
        {stateList?.data?.length > 0 && (
          <div className={cx('form-group', 'display-flex', 'align-item-baseline', 'form-label-input')}>
            <label className={cx('title-input', theme === 'Dark' && 'title-input-dark')}>State</label>
            <select
              value={formik.values.stateCode}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting ? formik.isSubmitting : !isEdit}
              name="stateCode"
              className={cx('form-control', theme === 'Dark' && 'form-control-dark')}
            >
              {stateList?.data?.map(item => {
                return (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                )
              })}
            </select>
          </div>
        )}
      </form>
      <div style={{ marginTop: '48px' }} className={cx('form-group', 'display-flex', 'space-evelyn', 'form-button')}>
        {!isEdit && (
          <button
            onClick={handleClickEditProfile}
            style={{ marginRight: '5px' }}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
          >
            Edit
          </button>
        )}
        {isEdit && (
          <>
            <button
              type="submit"
              disabled={stateListLoading || formik.isSubmitting}
              onClick={handleSubmit}
              style={{ marginRight: '5px' }}
              className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
            >
              Update
              {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <button
              onClick={handleClickCancelChange}
              style={{ marginRight: '5px' }}
              className={cx(
                'button',
                'btn',
                'btn-lg',
                theme === 'Dark' ? 'btn-dark' : 'btn-secondary btn-secondary-custom'
              )}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </>
  )
}

export default ProfileDetails
