import React, { useEffect, useRef, useState } from 'react'
import style from '../../../../reports.module.css'
import classNames from 'classnames/bind'
import moment from 'moment'
const cx = classNames.bind(style)
import { exportExcelTransactions, printTransactions } from '../../../../redux/reportsCrud'
import { exportFileData } from '../../../../../../../utils/ExporterUtils'
import ReactToPrint from 'react-to-print'
import { TransactionPrint } from './TransactionPrint'
import { useIntl } from 'react-intl'
import { env } from '../../../../../../../env'
import { Client } from '@stomp/stompjs'
import { useSelector } from 'react-redux'
import {
  SUCCESS_RESULT_CODE,
  RETRY_CONNECT_SOCKET_LIMIT,
  SOCKET_NORMAL_CLOSURE_CODE,
  INVALID_SESSION_TOKEN_RESULT_CODE,
  EXPIRED_JWT_TOKEN_RESULT_CODE,
  EXPIRED_SESSION_TOKEN_RESULT_CODE,
  FORCE_LOG_OUT_RESULT_CODE
} from '../../../../../../../utils/Constants'

let data = {}
let isPrint = false
export const ActionList = ({ filters, submitExport, generateAlert, forceLogout }) => {
  const intl = useIntl()
  const [dataToPrint, setDataToPrint] = useState({})
  const ref = useRef()
  const { authToken, user } = useSelector(state => state.auth)
  useEffect(() => {
    if (dataToPrint.transactions && isPrint) {
      document.getElementById('print-button').click()
    }
  }, [dataToPrint?.transactions])

  const convertFilters = filters => {
    if (filters.subDivision && filters.agentCode === '') {
      filters.agentCode = filters.subDivision
    }
    filters.pageIndex = 1
    filters.ignoreReturnDate = false
    delete filters.subDivision
    delete filters.salesReports
    return filters
  }

  const handleExportCSV = () => {
    let retry = true
    let failedConnectionTotal = 0
    const convertFilter = convertFilters(filters)
    const headers = {
      Username: user.login,
      Authorization: authToken
    }
    submitExport(true)
    const onConnected = () => {
      client.subscribe(
        `/transactions/${user.login}/exportCSV`,
        message => {
          const response = {
            data: message.body,
            headers: message.headers
          }
          let jsonResponse
          try {
            jsonResponse = JSON.parse(response.data)
          } catch (e) {}
          if (jsonResponse && jsonResponse.resultCode != SUCCESS_RESULT_CODE) {
            if (
              [
                INVALID_SESSION_TOKEN_RESULT_CODE,
                EXPIRED_SESSION_TOKEN_RESULT_CODE,
                EXPIRED_JWT_TOKEN_RESULT_CODE,
                FORCE_LOG_OUT_RESULT_CODE
              ].includes(jsonResponse.resultCode)
            ) {
              forceLogout()
            } else {
              generateAlert(
                'danger',
                intl.formatMessage({ id: 'TRANSACTION.LABEL.EXPORT_CSV_FILE' }),
                jsonResponse.resultDescription
              )
            }
          } else {
            exportFileData(response)
          }
          submitExport(false)
          retry = false
          client.deactivate()
        },
        headers
      )

      client.publish({
        destination: `/app/transaction/${user.login}/exportCSV`,
        body: JSON.stringify(convertFilter),
        headers: headers
      })
    }
    const client = new Client({
      brokerURL: env.REACT_APP_WS_URL,
      connectHeaders: headers,
      reconnectDelay: 1000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onConnect: onConnected,
      onWebSocketError: () => {
        failedConnectionTotal++
        if (failedConnectionTotal >= RETRY_CONNECT_SOCKET_LIMIT) {
          generateAlert(
            'danger',
            intl.formatMessage({ id: 'TRANSACTION.LABEL.EXPORT_CSV_FILE' }),
            intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_MESSAGE' })
          )
          submitExport(false)
          retry = false
          client.deactivate()
        } else {
          retry = true
        }
      },
      onWebSocketClose: closeEvent => {
        if (retry && closeEvent.code !== SOCKET_NORMAL_CLOSURE_CODE) {
          client.activate()
        }
      }
    })
    client.activate()

    return () => {
      client.deactivate()
    }
  }

  const handlePrint = () => {
    let retry = true
    let failedConnectionTotal = 0
    submitExport(true)

    const convertFilter = convertFilters(filters)
    const headers = {
      Username: user.login,
      Authorization: authToken
    }
    isPrint = true
    const onConnected = () => {
      client.subscribe(
        `/transactions/${user.login}/print`,
        message => {
          const response = JSON.parse(message.body).body

          if (response.resultCode === SUCCESS_RESULT_CODE) {
            let currentTime = moment().format('DD/MM/YYYY HH:MM A')
            data = { transactions: response.data, exportTime: currentTime }
          } else {
            isPrint = false
            if (
              [
                INVALID_SESSION_TOKEN_RESULT_CODE,
                EXPIRED_SESSION_TOKEN_RESULT_CODE,
                EXPIRED_JWT_TOKEN_RESULT_CODE,
                FORCE_LOG_OUT_RESULT_CODE
              ].includes(response.resultCode)
            ) {
              forceLogout()
            } else {
              generateAlert(
                'danger',
                intl.formatMessage({ id: 'TRANSACTION.LABEL.PRINT_REPORT' }),
                response.resultDescription
              )
            }
          }
          submitExport(false)
          retry = false
          client.deactivate()
        },
        headers
      )

      client.publish({
        destination: `/app/transaction/${user.login}/print`,
        body: JSON.stringify(convertFilter),
        headers: headers
      })
    }

    const client = new Client({
      brokerURL: env.REACT_APP_WS_URL,
      connectHeaders: headers,
      reconnectDelay: 1000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onConnect: onConnected,
      onWebSocketError: () => {
        failedConnectionTotal++
        if (failedConnectionTotal >= RETRY_CONNECT_SOCKET_LIMIT) {
          generateAlert(
            'danger',
            intl.formatMessage({ id: 'TRANSACTION.LABEL.PRINT_REPORT' }),
            intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_MESSAGE' })
          )
          isPrint = false
          submitExport(false)
          retry = false
          client.deactivate()
        } else {
          retry = true
        }
      },
      onWebSocketClose: closeEvent => {
        if (retry && closeEvent.code !== SOCKET_NORMAL_CLOSURE_CODE) {
          client.activate()
        }
      }
    })
    client.activate()
  }

  useEffect(() => {
    if (data) {
      setDataToPrint(data)
    }
  }, [data])

  const handleExportExcel = () => {
    let retry = true
    let failedConnectionTotal = 0
    const convertFilter = convertFilters(filters)
    const headers = {
      Username: user.login,
      Authorization: authToken
    }
    submitExport(true)
    const onConnected = () => {
      client.subscribe(
        `/transactions/${user.login}/exportExcel`,
        message => {
          const response = {
            data: message.binaryBody,
            headers: message.headers
          }

          let jsonResponse
          try {
            jsonResponse = JSON.parse(message.body)
          } catch (e) {}
          if (jsonResponse && jsonResponse.resultCode != SUCCESS_RESULT_CODE) {
            if (
              [
                INVALID_SESSION_TOKEN_RESULT_CODE,
                EXPIRED_SESSION_TOKEN_RESULT_CODE,
                EXPIRED_JWT_TOKEN_RESULT_CODE,
                FORCE_LOG_OUT_RESULT_CODE
              ].includes(jsonResponse.resultCode)
            ) {
              forceLogout()
            } else {
              generateAlert(
                'danger',
                intl.formatMessage({ id: 'TRANSACTION.LABEL.EXPORT_EXCEL_FILE' }),
                jsonResponse.resultDescription
              )
            }
          } else {
            exportFileData(response)
          }
          submitExport(false)
          retry = false
          client.deactivate()
        },
        headers
      )

      client.publish({
        destination: `/app/transaction/${user.login}/exportExcel`,
        body: JSON.stringify(convertFilter),
        headers: headers
      })
    }
    const client = new Client({
      brokerURL: env.REACT_APP_WS_URL,
      connectHeaders: headers,
      reconnectDelay: 1000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      onConnect: onConnected,
      onWebSocketError: () => {
        failedConnectionTotal++
        if (failedConnectionTotal >= RETRY_CONNECT_SOCKET_LIMIT) {
          generateAlert(
            'danger',
            intl.formatMessage({ id: 'TRANSACTION.LABEL.EXPORT_EXCEL_FILE' }),
            intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_MESSAGE' })
          )
          submitExport(false)
          retry = false
          client.deactivate()
        } else {
          retry = true
        }
      },
      onWebSocketClose: closeEvent => {
        if (retry && closeEvent.code !== SOCKET_NORMAL_CLOSURE_CODE) {
          client.activate()
        }
      }
    })

    client.activate()

    return () => {
      client.deactivate()
    }
  }

  return (
    <>
      <ul className="list-button-content">
        <li className="list-export-button" key={'1'}>
          <button type="button" className={cx('btn', 'btn-block', 'btn-item')} onClick={() => handleExportCSV()}>
            {intl.formatMessage({ id: 'TRANSACTION.OPTION.EXPORT_TO_CSV' })}
          </button>
        </li>
        <li className="list-export-button" key={'2'}>
          <button type="button" className={cx('btn', 'btn-block', 'btn-item')} onClick={() => handleExportExcel()}>
            {intl.formatMessage({ id: 'TRANSACTION.OPTION.EXPORT_TO_EXCEL' })}
          </button>
        </li>
        <li className="list-export-button" key={'3'}>
          <button type="button" className={cx('btn', 'btn-block', 'btn-item')} onClick={() => handlePrint()}>
            {intl.formatMessage({ id: 'TRANSACTION.OPTION.PRINT' })}
          </button>
          <div style={{ display: 'none' }}>
            <ReactToPrint
              content={() => ref.current}
              onBeforePrint={() => {
                isPrint = false
              }}
              removeAfterPrint
              trigger={() => <button id="print-button">{intl.formatMessage({ id: 'TRANSACTION.LABEL.PRINT' })}</button>}
            ></ReactToPrint>
            <TransactionPrint ref={ref} data={dataToPrint} />
          </div>
        </li>
      </ul>
    </>
  )
}
