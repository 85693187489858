import React, { useEffect } from 'react'
import * as auth from '../_redux/authRedux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ImpersonateLogin = props => {
  const history = useHistory()

  useEffect(() => {
    const tokens = window.location.search.split('?token=')
    if (tokens[1]) {
      props.login(tokens[1])
      localStorage.setItem('isImpersonate', 'true')
      history.push('/')
    }
  }, [])
  return <></>
}

export default connect(null, auth.actions)(ImpersonateLogin)
