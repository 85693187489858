import React from 'react'
import { MenuItem, FormControl, Select, InputLabel, FormControlLabel } from '@material-ui/core'
import { useFormik } from 'formik'
import Checkbox from '../../../../../../_metronic/_partials/checkbox'
import { getListYears } from '../../../../../../utils/dateUtils'

const YEARS = getListYears(2010, new Date().getFullYear())

const PerformanceReportFilter = ({
  cx,
  initFilterData,
  darkMode,
  merchantPerformanceReportLoading,
  isLoading,
  onDataGetYearAndIncludeSubAgents
}) => {
  const formik = useFormik({
    initialValues: initFilterData,
    onSubmit: values => {
      onDataGetYearAndIncludeSubAgents(values.year, values.includeSubAgents ? 'Y' : 'N')
      const payload = {
        ...values,
        includeSubAgents: values.includeSubAgents ? 'Y' : 'N'
      }
      merchantPerformanceReportLoading(payload)
    }
  })

  const handleReset = () => {
    formik.handleReset()
  }

  return (
    <form onSubmit={formik.handleSubmit} className={`row ${cx('filter')}`}>
      <div className={cx('col-12', 'col-md-7', 'order-1', 'order-md-1', 'select-wrapper')}>
        <FormControl variant="outlined" size="small" margin="normal" className={cx('width-100-percent', 'select')}>
          <InputLabel id="yearLabel">Year</InputLabel>
          <Select
            id="year"
            labelId="yearLabel"
            value={formik.values.year}
            onChange={formik.handleChange}
            name="year"
            label="Year"
          >
            {YEARS.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <div className={cx('col-12', 'col-md-5', 'order-3', 'order-md-2', 'sub-agents')}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.includeSubAgents}
              onChange={formik.handleChange}
              name="includeSubAgents"
              color="secondary"
            />
          }
          label="Include Sub-Agents"
          labelPlacement="top"
        />
      </div>

      <div className={cx('col-12', 'col-md-5', 'actions', 'order-4', 'order-md-4')}>
        <button
          disabled={isLoading}
          type="submit"
          className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'button-submit', 'btn-primary-custom')}
        >
          Submit
          {isLoading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <button
          onClick={handleReset}
          className={`${cx('button', 'btn-secondary', 'btn', 'btn-lg', 'reset-btn')} ${darkMode}`}
        >
          Reset
        </button>
      </div>
    </form>
  )
}

export default PerformanceReportFilter
