import React, { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import * as columnFormatters from './column-formatters'
import { Pagination } from '../../../../../_metronic/_partials/controls'
// import { ActionList } from './action-list/ActionList'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../../../../_metronic/_helpers'
import style from '../../pinOrder.module.css'
import classNames from 'classnames/bind'
import Icon from '@material-ui/core/Icon'
import { useSelector } from 'react-redux'
import { checkHasPermission } from '../../../_helper/MenuHelper'

const cx = classNames.bind(style)
const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
]

const PinOrderTable = ({
  filters,
  updateFilters,
  setIsPaging,
  pinBatchOrdersData,
  generateAlert,
  openPinOrderDetailsDialog,
  setPinOrderModalOpen,
  sortData,
  setSortData,
  theme,
  onOpenDeleteModal
}) => {
  const { user } = useSelector(state => state.auth)
  const [isDownLoading, setIsDownLoading] = useState(false)
  const [rowIndex, setRowIndex] = useState(null)
  const INIT_DATA = { totalCount: 0, count: 0, entities: [], listLoading: false }
  const INIT_COLUMNS = [
    {
      dataField: 'orderRef',
      text: 'Order Ref',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'start' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'start'
      }
    },
    {
      dataField: 'fileName',
      text: 'File Name',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },

    {
      dataField: 'user',
      text: 'User',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center'
      }
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        isDownLoading: isDownLoading,
        setIsDownLoading: setIsDownLoading,
        downLoadIndex: rowIndex,
        setRowIndex: setRowIndex,
        generateAlert: generateAlert,
        openPinOrderDetailsDialog: openPinOrderDetailsDialog,
        theme: theme,
        user: user,
        onOpenDeleteModal
      },
      hidden: false,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', textAlign: 'center' }
      },
      style: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'flex-start'
      }
    }
  ]
  const [data, setData] = useState(INIT_DATA)

  const { totalCount, count, listLoading, entities } = data

  useEffect(() => {
    let data = pinBatchOrdersData?.data
    let count = 0
    let transList = []

    if (data?.data && data?.data.length) {
      transList = data.data
      count = data.totalPage
      for (let i = 0; i < transList.length; i++) {
        transList[i].id = i + 1
      }
    }
    setData({
      count: count,
      totalCount: data?.totalRecord,
      entities: transList,
      listLoading: pinBatchOrdersData.isLoading
    })
  }, [pinBatchOrdersData])

  const getHandlerTableChange = () => {
    return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
      const pageNumber = page || 1
      let updatedFilters =
        type === 'sort'
          ? { ...filters, orderBy: sortField, orderType: sortOrder }
          : type === 'pagination'
          ? { ...filters, pageIndex: pageNumber, pageSize: sizePerPage }
          : filters
      setSortData({ orderBy: sortField, orderType: sortOrder })
      setIsPaging(true)
      updateFilters(updatedFilters)
    }
  }

  const paginationTotalRenderer = (from, to, size) => {
    let infoCallBack = 'Showing ' + from + ' to ' + to
    let exportShowing = null
    infoCallBack += ' of ' + size + ' entries'
    return (
      <span className="react-bootstrap-table-pagination-total">
        {infoCallBack} <br />
        {exportShowing ? exportShowing : ''}
      </span>
    )
  }

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: filters.pageSize,
    page: filters.pageIndex,
    paginationTotalRenderer
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <ToolkitProvider keyField="id" data={entities} columns={INIT_COLUMNS} columnToggle>
                {props => {
                  return (
                    <div>
                      <div className={cx('function-button-content')}>
                        <div className={cx('pin-order-table-title')}>
                          <p>Pin Orders List</p>
                        </div>
                        <div>
                          {checkHasPermission(user.acls, ['SCP.EVDS.PIN.BATCH.FILE.REQUEST_C']) && (
                            <button
                              type="button"
                              className={cx('add-new-button', 'btn', 'btn-primary', 'btn-primary-custom')}
                              onClick={() => setPinOrderModalOpen(true)}
                            >
                              New Pin Order
                              <Icon
                                className={cx('add-new-icon', theme === 'Dark' && cx('add-new-icon-dark'))}
                                color="action"
                              >
                                add_circle
                              </Icon>
                            </button>
                          )}
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses={cx('table-responsive')}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        remote
                        keyField="id"
                        data={entities === null ? [] : entities}
                        columns={INIT_COLUMNS}
                        sort={sortData}
                        rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                        onTableChange={getHandlerTableChange()}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                      {!listLoading && (
                        <>
                          <PleaseWaitMessage entities={entities} />
                          <NoRecordsFoundMessage entities={entities} />
                        </>
                      )}
                    </div>
                  )
                }}
              </ToolkitProvider>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </>
  )
}

export default PinOrderTable
