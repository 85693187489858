import { merchantApiClient } from '../../../../redux'

export const GET_TRANSACTION_LIST = `/api/v1/transactions`
export const GET_DAILY_TRANSACTION_STATS = '/api/v1/daily-stats'
export const GET_WEEKLY_TRANSACTION_STATS = '/api/v1/weekly-stats'

export function getTransactionList(
  transType,
  status,
  start,
  end,
  pageSize,
  pageIndex,
  orderType,
  ignoreTotalSize,
  includeSubAgent,
  ignoreReturnDate
) {
  return merchantApiClient.post(GET_TRANSACTION_LIST, {
    transType,
    status,
    start,
    end,
    pageSize,
    pageIndex,
    orderType,
    ignoreTotalSize,
    includeSubAgent,
    ignoreReturnDate
  })
}

export function getDailyTransactionStats() {
  return merchantApiClient.get(GET_DAILY_TRANSACTION_STATS)
}

export function getWeeklyTransactionStats() {
  return merchantApiClient.get(GET_WEEKLY_TRANSACTION_STATS)
}
