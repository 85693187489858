import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../pinOrder.module.css'
import classNames from 'classnames/bind'
import TextField from '@material-ui/core/TextField'
import { deletePinOrder } from '../../redux/pinOrderCrud'

const cx = classNames.bind(style)

const DeletePinOrderModal = ({ orderRef, onCloseModal, generateAlert, reloadData }) => {
  const [comment, setComment] = useState('')
  const [deleting, setDeleting] = useState(false)

  const handleDeletePinOrder = () => {
    setDeleting(true)

    deletePinOrder({ orderRef: orderRef, comment })
      .then(response => {
        const { resultCode, resultDescription } = response.data
        if (resultCode === 0) {
          generateAlert('success', 'Delete Order', resultDescription)
          handleCloseModal()
          reloadData()
        } else {
          generateAlert('danger', 'Delete Order', resultDescription)
        }
      })
      .catch(({ message }) => {
        generateAlert('danger', 'Delete Order', message)
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  const handleCloseModal = () => {
    if (!deleting) {
      setComment('')
      onCloseModal()
    }
  }

  return (
    <Modal
      show={Boolean(orderRef)}
      onHide={handleCloseModal}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={deleting ? 'static' : true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete Pin Order</Modal.Title>
        <Button onClick={handleCloseModal} variant="secondary">
          x
        </Button>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <h6 className={cx('question')}>Are you sure you want to delete pin order with orderRef {orderRef}?</h6>
        <TextField
          className={cx('comment-textarea')}
          label="Comment"
          variant="outlined"
          rows={5}
          multiline
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={deleting}
          style={{
            backgroundColor: 'var(--secondary-color)',
            borderColor: 'var(--secondary-color)'
          }}
          variant="secondary"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          disabled={deleting}
          style={{
            backgroundColor: 'var(--primary-color)',
            borderColor: 'var(--primary-color)'
          }}
          variant="primary"
          onClick={handleDeletePinOrder}
        >
          OK
          {deleting && <span className={cx('ml-3', 'spinner', 'spinner-white', 'submit-btn-spinner')}></span>}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeletePinOrderModal
