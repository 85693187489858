import {
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@material-ui/core'
import { useFormik } from 'formik'
import Multiselect from 'multiselect-react-dropdown'
import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import * as Yup from 'yup'
import PeriodValue from './components/PeriodValue'
import { SUCCESS_RESULT_CODE } from '../../../../../utils/Constants'

const ModalTitle = ({ modalType, intl }) => {
  return (
    <>
      {modalType === 'CREATE_MODAL' && intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.TITLE' })}
      {modalType === 'DETAILS_MODAL' && intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.DETAILS.TITLE' })}
    </>
  )
}

const CreditLimitTemplateModal = ({
  openModalData,
  onClose,
  intl,
  cx,
  theme,
  subDivisionAndAgentData,
  currencyList,
  productCodeList,
  creditLimitDetailsData,
  isLoading,
  onCreateCreditLimitTemplate,
  createCreditLimitTemplateData,
  generateAlert,
  creditLimitTemplatesLoading,
  createCreditLimitTemplateReceived,
  onEditCreditLimitTemplate,
  editCreditLimitTemplateData,
  editCreditLimitTemplateReceived,
  onReloadAgentUserInfo,
  hasPermissionToEdit
}) => {
  const [targetCodeList, setTargetCodeList] = useState()
  const [agents, setAgents] = useState()
  const targetTypes = ['CURRENCY', 'PRODUCT']
  const limitTypes = ['VALUE', 'COUNT']
  const [limitValue, setLimitValue] = useState()
  const [periodValue, setPeriodValue] = useState()
  const [isValidToSave, setIsValidToSave] = useState(false)
  const status = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ]
  const [limitItemsError, setLimitItemsError] = useState()
  const periodTypes = [
    { value: 1, label: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.RESET_PERIOD_TYPE.LABEL.HOURLY' }) },
    { value: 2, label: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.RESET_PERIOD_TYPE.LABEL.DAILY' }) },
    { value: 3, label: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.RESET_PERIOD_TYPE.LABEL.WEEKLY' }) },
    { value: 4, label: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.RESET_PERIOD_TYPE.LABEL.MONTHLY' }) },
    { value: 5, label: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.RESET_PERIOD_TYPE.LABEL.DATE' }) }
  ]

  const [isEnableEdit, setIsEnableEdit] = useState(false)

  useEffect(() => {
    if (currencyList) {
      setTargetCodeList([currencyList])
    }
  }, [creditLimitDetailsData, currencyList])

  useEffect(() => {
    if (subDivisionAndAgentData.data?.data?.agents) {
      setAgents(subDivisionAndAgentData.data?.data?.agents)
    }
  }, [subDivisionAndAgentData.data?.data?.agents])

  const initLimitItemValue = {
    limitItemId: 0,
    targetType: 'CURRENCY',
    targetCode: 'AUD',
    limitType: 'VALUE',
    limitValue: '1',
    periodId: '1',
    periodValue: '1'
  }

  const initialValues = {
    limitInfo: {
      templateCode: creditLimitDetailsData?.limitInfo?.templateCode ?? '',
      templateName: creditLimitDetailsData?.limitInfo?.templateName ?? '',
      assignedAgentCodes: creditLimitDetailsData?.limitInfo?.assignedAgentCodes ?? '',
      status:
        creditLimitDetailsData?.limitInfo?.status && creditLimitDetailsData?.limitInfo?.status === 'active'
          ? true
          : false
    },
    limitItems: creditLimitDetailsData?.limitItems ?? [initLimitItemValue]
  }

  const [currentItemLength, setCurrentItemLength] = useState(initialValues.limitItems.length)

  const createLimitTemplateSchema = Yup.object().shape({
    limitInfo: Yup.object().shape({
      templateCode: Yup.string().required(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.REQUIRED'
          },
          { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.TEMPLATE_CODE' }) }
        )
      ),

      templateName: Yup.string().required(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.REQUIRED'
          },
          { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.TEMPLATE_NAME' }) }
        )
      ),
      assignedAgentCodes: Yup.string().required(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.REQUIRED'
          },
          { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.AGENTS' }) }
        )
      ),
      status: Yup.string().required(
        intl.formatMessage(
          {
            id: 'AUTH.VALIDATION.REQUIRED'
          },
          { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.STATUS' }) }
        )
      )
    }),
    limitItems: Yup.array()
      .of(
        Yup.object().shape({
          targetType: Yup.string().required(
            intl.formatMessage(
              {
                id: 'AUTH.VALIDATION.REQUIRED'
              },
              { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.TARGET_TYPE' }) }
            )
          ),
          targetCode: Yup.string().required(
            intl.formatMessage(
              {
                id: 'AUTH.VALIDATION.REQUIRED'
              },
              { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.CURRENCY' }) }
            )
          ),
          limitType: Yup.string().required(
            intl.formatMessage(
              {
                id: 'AUTH.VALIDATION.REQUIRED'
              },
              { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.LIMIT_TYPE' }) }
            )
          ),
          periodId: Yup.string().required(
            intl.formatMessage(
              {
                id: 'AUTH.VALIDATION.REQUIRED'
              },
              { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_TYPE' }) }
            )
          )
        })
      )
      .min(1, intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.ERROR.MESSAGE.LIMIT_ITEMS' }))
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createLimitTemplateSchema,
    onSubmit: (values, { setSubmitting, resetForm, setTouched }) => {
      if (openModalData.modal === 'CREATE_MODAL') {
        const payload = JSON.parse(JSON.stringify(values))
        delete payload.limitInfo.status
        onCreateCreditLimitTemplate(payload)
      } else if (openModalData.modal === 'DETAILS_MODAL') {
        onEditCreditLimitTemplate(values)
      }
      setTouched({})
    }
  })

  useEffect(() => {
    if (
      createCreditLimitTemplateData &&
      createCreditLimitTemplateData.data &&
      createCreditLimitTemplateData.data.resultCode === SUCCESS_RESULT_CODE &&
      !createCreditLimitTemplateData.isLoading
    ) {
      generateAlert(
        'success',
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.TITLE' }),
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.MESSAGE_SUCCESS' })
      )
      onClose({ ...openModalData, isOpen: false })
      creditLimitTemplatesLoading()
      onReloadAgentUserInfo()
    } else if (
      createCreditLimitTemplateData &&
      createCreditLimitTemplateData.data &&
      createCreditLimitTemplateData.data.resultCode !== SUCCESS_RESULT_CODE &&
      !createCreditLimitTemplateData.isLoading
    ) {
      generateAlert(
        'danger',
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.TITLE' }),
        createCreditLimitTemplateData.data?.resultDescription
      )
    }
    createCreditLimitTemplateReceived(null)
  }, [createCreditLimitTemplateData.data])

  useEffect(() => {
    if (
      editCreditLimitTemplateData &&
      editCreditLimitTemplateData.data &&
      editCreditLimitTemplateData.data.resultCode === SUCCESS_RESULT_CODE &&
      !editCreditLimitTemplateData.isLoading
    ) {
      generateAlert(
        'success',
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.EDIT.TITLE' }),
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.EDIT.MESSAGE_SUCCESS' })
      )
      onClose({ ...openModalData, isOpen: false })
      onReloadAgentUserInfo()
      creditLimitTemplatesLoading()
      setIsEnableEdit(false)
    } else if (
      editCreditLimitTemplateData &&
      editCreditLimitTemplateData.data &&
      editCreditLimitTemplateData.data.resultCode !== SUCCESS_RESULT_CODE &&
      !editCreditLimitTemplateData.isLoading
    ) {
      generateAlert(
        'danger',
        intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.EDIT.TITLE' }),
        editCreditLimitTemplateData.data?.resultDescription
      )
    }
    editCreditLimitTemplateReceived(null)
  }, [editCreditLimitTemplateData.data])

  const handleAddNewLimitItem = () => {
    formik.setFieldValue('limitItems', [...formik.values.limitItems, initLimitItemValue])
    setLimitValue([...limitValue, { index: limitValue.length, value: 1 }])
    setPeriodValue([...periodValue, { index: periodValue.length, value: 1 }])
    setTargetCodeList([...targetCodeList, currencyList])
  }

  const handleRemoveLimitItem = index => {
    const limitItems = formik.values.limitItems
    limitItems.splice(index, 1)
    formik.setFieldValue('limitItems', limitItems)
    targetCodeList.splice(index, 1)
  }

  const handleInputChange = (index, e, field) => {
    let value = e?.target?.value
    if (value === undefined && field === 'periodValue') {
      value = e
    } else if (value && field === 'templateCode') {
      value = value.replace(/\s/g, '').toUpperCase()
    }

    if (index !== null) {
      if (field === 'targetType') {
        if (value === 'CURRENCY') {
          targetCodeList[index] = currencyList
          formik.setFieldValue(`limitItems.${index}.targetCode`, currencyList[0].code)
        } else if (value === 'PRODUCT') {
          formik.setFieldValue(`limitItems.${index}.targetCode`, productCodeList[0].code)
          targetCodeList[index] = productCodeList
        }
      }
      if (field === 'limitValue') {
        handleSetValueAndError(
          limitValue,
          setLimitValue,
          value,
          index,
          intl.formatMessage(
            {
              id: 'GREATER_OR_EQUAL_THAN'
            },
            {
              name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.LIMIT_VALUE' }),
              value: 1
            }
          )
        )
      }

      if (field === 'periodValue') {
        handleSetValueAndError(
          periodValue,
          setPeriodValue,
          value,
          index,
          intl.formatMessage(
            {
              id: 'AUTH.VALIDATION.REQUIRED'
            },
            { name: intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_VALUE' }) }
          )
        )
      }

      formik.setFieldValue(`limitItems.${index}.${field}`, value)
    } else {
      formik.setFieldValue(`limitInfo.${field}`, value)
    }
  }

  const checkLimitValueAndPeriodValueIsValid = (limitValue, periodValue) => {
    if (limitValue && periodValue) {
      const limitErrors = limitValue.filter(item => {
        return item.error
      })

      const periodErrors = periodValue.filter(item => {
        return item.error
      })

      if ((limitErrors && limitErrors.length > 0) || (periodErrors && periodErrors.length > 0)) {
        return true
      }
    }
    return false
  }

  const handleSetValueAndError = (values, setValues, changeValue, index, error) => {
    if (values && values[index]) {
      const newValuess = [...values]
      if (changeValue === undefined || changeValue <= 0 || changeValue === '') {
        newValuess[index] = {
          index,
          value: changeValue,
          error
        }
      } else {
        newValuess[index] = { index, value: changeValue }
      }
      setValues(newValuess)
    } else {
      if (values) {
        setValues([...values, { index, value: changeValue }])
      } else {
        setValues([{ index, value: changeValue }])
      }
    }
  }

  const multiselectRef = React.createRef()

  const handleSelect = selectedList => {
    const agentList = []
    const assignedAgentCodesList = selectedList.map(option => {
      if (option.code) {
        agentList.push(option)
        return option.code
      } else {
        if (agents) {
          const agent = agents.find(item => {
            return item.code === option
          })
          if (agent) {
            agentList.push(agent)
          }
        }
        return option
      }
    })

    const assignedAgentCodes = assignedAgentCodesList.join(',')
    if (multiselectRef.current && multiselectRef.current.state) {
      multiselectRef.current.state.selectedValues = agentList
    }
    formik.setFieldValue('limitInfo.assignedAgentCodes', assignedAgentCodes)
  }

  useLayoutEffect(() => {
    if (currentItemLength != formik.values.limitItems.length) {
      const element = document.getElementById('limitItems')
      if (element) {
        element.scrollTop = element.scrollHeight
        setCurrentItemLength(formik.values.limitItems.length)
      }
    }
  }, [formik.values.limitItems])

  useEffect(() => {
    if (openModalData.modal === 'CREATE_MODAL') {
      const defaultValue = {
        limitInfo: {
          templateCode: '',
          templateName: '',
          assignedAgentCodes: '',
          status: true
        },
        limitItems: [initLimitItemValue]
      }
      formik.setValues(defaultValue)
      setTargetCodeList([currencyList])
    }
  }, [openModalData])

  useEffect(() => {
    if (creditLimitDetailsData) {
      const values = JSON.parse(JSON.stringify(initialValues))
      formik.setValues(values)
      handleSelect(values.limitInfo.assignedAgentCodes.split(','))
      formik.setErrors({})
      const targetCodes = []
      values.limitItems.forEach((item, index) => {
        if (item.targetType === 'CURRENCY') {
          targetCodes.push(currencyList)
        } else if (item.targetType === 'PRODUCT') {
          targetCodes.push(productCodeList)
        }
      })
      if (targetCodeList) {
        targetCodeList.shift()
      }
      setTargetCodeList(targetCodes)
    }
  }, [creditLimitDetailsData])

  const customStyles = {
    chips: {
      backgroundColor: 'var(--primary-color)'
    },
    option: {
      backgroundColor: theme === 'Dark' ? 'var(--button-dark)' : 'var(--page-light)'
    }
  }

  useEffect(() => {
    if (formik.errors && formik.errors.limitItems) {
      if (openModalData.modal === 'DETAILS_MODAL') {
        setLimitItemsError(formik.errors.limitItems)
      }
    } else {
      setLimitItemsError(undefined)
    }
    return () => {
      if (openModalData.modal === 'CREATE_MODAL') {
        setLimitItemsError(undefined)
      }
    }
  }, [formik.errors, openModalData])

  useEffect(() => {
    if (openModalData.modal === 'DETAILS_MODAL' && openModalData.isOpen) {
      if (creditLimitDetailsData && creditLimitDetailsData.limitItems && creditLimitDetailsData.limitItems.length > 0) {
        const limitValues = []
        const periodValues = []

        creditLimitDetailsData.limitItems.forEach((item, index) => {
          limitValues.push({ index, value: item.limitValue })
          periodValues.push({ index, value: item.periodValue })
        })
        setLimitValue(limitValues)
        setPeriodValue(periodValues)
      }
    } else {
      if (formik.values && formik.values.limitItems && formik.values.limitItems.length > 0) {
        const limitValues = []
        const periodValues = []

        formik.values.limitItems.forEach((item, index) => {
          limitValues.push({ index, value: item.limitValue })
          periodValues.push({ index, value: item.periodValue })
        })
        setLimitValue(limitValues)
        setPeriodValue(periodValues)
      }
    }
  }, [openModalData, creditLimitDetailsData])

  useEffect(() => {
    setIsValidToSave(checkLimitValueAndPeriodValueIsValid(limitValue, periodValue))
  }, [limitValue, periodValue])

  return (
    <Modal
      key={2}
      backdrop="static"
      keyboard={false}
      show={openModalData.isOpen === true && isLoading === false}
      onHide={() => {
        onClose({ ...openModalData, isOpen: false })
        if (multiselectRef.current) {
          multiselectRef.current.resetSelectedValues()
        }
      }}
      centered
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <ModalTitle intl={intl} modalType={openModalData.modal} />
        </Modal.Title>
        <Button
          type="button"
          onClick={() => {
            onClose({ ...openModalData, isOpen: false })
            formik.setTouched({})
            setIsEnableEdit(false)
            setLimitValue(undefined)
            setPeriodValue(undefined)
          }}
          variant="secondary"
        >
          x
        </Button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="row flex-sm-column flex-md flex-lg-row">
            <div className="col-sm col-md col-lg-3 col-xl-3 d-flex flex-column mb-sm-5">
              <span className="text-center h6 mb-5">
                {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.LIMIT.INFOR.TITLE.TEMPLATE_DETAILS' })}
              </span>
              <FormControl size="small" className={cx('w-100')} variant="outlined">
                <TextField
                  disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                  label={intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.TEMPLATE_NAME' })}
                  type="text"
                  value={formik.values.limitInfo?.templateName}
                  onChange={e => handleInputChange(null, e, 'templateName')}
                  name="templateName"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={Boolean(
                    formik.touched.limitInfo?.templateName && formik.errors.limitInfo?.templateName?.length > 0
                  )}
                />
                {Boolean(
                  formik.touched.limitInfo?.templateName && formik.errors.limitInfo?.templateName?.length > 0
                ) && (
                  <FormHelperText style={{ color: '#f018a6' }}>{formik.errors.limitInfo?.templateName}</FormHelperText>
                )}
              </FormControl>

              <FormControl size="small" className={cx('w-100 my-2')} variant="outlined">
                <TextField
                  disabled={openModalData.modal === 'DETAILS_MODAL'}
                  label={intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.TEMPLATE_CODE' })}
                  type="text"
                  value={formik.values.limitInfo?.templateCode}
                  name="templateCode"
                  onChange={e => handleInputChange(null, e, 'templateCode')}
                  variant="outlined"
                  size="small"
                  className="w-100"
                  error={Boolean(
                    formik.touched.limitInfo?.templateCode && formik.errors.limitInfo?.templateCode?.length > 0
                  )}
                />
                {Boolean(
                  formik.touched.limitInfo?.templateCode && formik.errors.limitInfo?.templateCode?.length > 0
                ) && (
                  <FormHelperText style={{ color: '#f018a6' }}>{formik.errors.limitInfo?.templateCode}</FormHelperText>
                )}
              </FormControl>

              <FormControl size="small" variant="outlined">
                <Multiselect
                  className="my-2"
                  options={agents}
                  onSelect={handleSelect}
                  onRemove={handleSelect}
                  disable={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                  displayValue="name"
                  placeholder={intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.AGENTS' })}
                  style={{
                    ...customStyles,
                    searchBox: {
                      zIndex: 2,
                      backgroundColor: theme === 'Dark' ? 'var(--page-dark)' : 'var(--page-light)',
                      borderColor:
                        formik.touched.limitInfo?.assignedAgentCodes &&
                        formik.errors.limitInfo?.assignedAgentCodes &&
                        '#f70ba5'
                    }
                  }}
                  ref={multiselectRef}
                />
                {Boolean(
                  formik.touched.limitInfo?.assignedAgentCodes &&
                    formik.errors.limitInfo?.assignedAgentCodes?.length > 0
                ) && (
                  <FormHelperText style={{ color: '#f018a6' }}>
                    {formik.errors.limitInfo?.assignedAgentCodes}
                  </FormHelperText>
                )}
              </FormControl>
              {openModalData.modal === 'DETAILS_MODAL' && (
                <FormControl size="small" className={cx('w-100 my-2')} variant="outlined">
                  <InputLabel htmlFor={`status`} style={{ color: 'black' }}>
                    {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.STATUS' })}
                  </InputLabel>
                  <Select
                    disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                    onChange={e => handleInputChange(null, e, 'status')}
                    value={formik.values.limitInfo?.status}
                    error={Boolean(formik.touched.limitInfo?.status && formik.errors.limitInfo?.status?.length > 0)}
                    input={<OutlinedInput labelWidth={80} name={`status`} id={`status`} />}
                  >
                    {openModalData.modal === 'DETAILS_MODAL' &&
                      status.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {Boolean(formik.touched.limitInfo?.status && formik.errors.limitInfo?.status?.length > 0) && (
                    <FormHelperText style={{ color: '#f018a6' }}>{formik.errors.limitInfo?.status}</FormHelperText>
                  )}
                </FormControl>
              )}
            </div>

            <div className="col-sm col-md col-lg-9 col-xl-9">
              <span className="d-block text-center h6 mb-10 w-100">
                {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.LIMIT.ITEMS.TITLE.LIMIT_ITEMS' })}
              </span>
              <ul
                id="limitItems"
                style={{ maxHeight: '330px', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #d7d7d7' }}
                className="list-group"
              >
                {formik.values.limitItems.map((item, index) => {
                  return (
                    <li key={index} className="list-group-item row d-flex flex-column bg-transparent">
                      {formik.values.limitItems.length > 1 && (
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            disabled={openModalData.modal === 'DETAILS_MODAL' && !isEnableEdit}
                            onClick={() => handleRemoveLimitItem(index)}
                            className={cx(
                              'd-flex',
                              'btn',
                              'btn-icon',
                              `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
                              'btn-hover-danger',
                              'btn-sm',
                              'view-btn',
                              'delete-button'
                            )}
                          >
                            X
                          </button>
                        </div>
                      )}
                      <div className="row my-2">
                        <div className="col">
                          <FormControl className="w-100" size="small" variant="outlined">
                            <InputLabel htmlFor={`targetType${index}`} style={{ color: 'black' }}>
                              {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.TARGET_TYPE' })}
                            </InputLabel>
                            <Select
                              disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                              onChange={e => handleInputChange(index, e, 'targetType')}
                              value={item.targetType || targetTypes[0]}
                              error={Boolean(
                                formik.errors.limitItems &&
                                  formik.errors.limitItems[index] &&
                                  formik.errors.limitItems[index]?.targetType
                              )}
                              input={
                                <OutlinedInput labelWidth={80} name={`targetType${index}`} id={`targetType${index}`} />
                              }
                            >
                              {targetTypes.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            {Boolean(
                              formik.errors.limitItems &&
                                formik.errors.limitItems[index] &&
                                formik.errors.limitItems[index]?.targetType
                            ) && (
                              <FormHelperText style={{ color: '#f018a6' }}>
                                {formik.errors.limitItems[index]?.targetType}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col">
                          <FormControl size="small" className="w-100" variant="outlined">
                            <InputLabel htmlFor={`targetCode${index}`} style={{ color: 'black' }}>
                              {item.targetType === 'CURRENCY'
                                ? intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.CURRENCY' })
                                : intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.PRODUCT' })}
                            </InputLabel>
                            <Select
                              disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                              onChange={e => handleInputChange(index, e, 'targetCode')}
                              value={item.targetCode}
                              error={Boolean(
                                formik.errors.limitItems &&
                                  formik.errors.limitItems[index] &&
                                  formik.errors.limitItems[index]?.targetCode
                              )}
                              input={
                                <OutlinedInput labelWidth={70} name={`targetCode${index}`} id={`targetCode${index}`} />
                              }
                            >
                              {targetCodeList &&
                                targetCodeList[index] &&
                                targetCodeList[index].map((item, index) => (
                                  <MenuItem key={index} value={item.code}>
                                    {item.code} - {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                            {Boolean(
                              formik.errors.limitItems &&
                                formik.errors.limitItems[index] &&
                                formik.errors.limitItems[index]?.targetCode
                            ) && (
                              <FormHelperText style={{ color: '#f018a6' }}>
                                {formik.errors.limitItems[index]?.targetCode}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col">
                          <FormControl size="small" className="w-100" variant="outlined">
                            <InputLabel htmlFor={`limitType${index}`} style={{ color: 'black' }}>
                              {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.LIMIT_TYPE' })}
                            </InputLabel>
                            <Select
                              disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                              onChange={e => handleInputChange(index, e, 'limitType')}
                              value={item.limitType || limitTypes[0]}
                              error={Boolean(
                                formik.errors.limitItems &&
                                  formik.errors.limitItems[index] &&
                                  formik.errors.limitItems[index]?.limitType
                              )}
                              input={
                                <OutlinedInput labelWidth={70} name={`limitType${index}`} id={`limitType${index}`} />
                              }
                            >
                              {item.targetType === 'PRODUCT' &&
                                limitTypes.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              {item.targetType === 'CURRENCY' && (
                                <MenuItem key={1} value={limitTypes[0]}>
                                  {limitTypes[0]}
                                </MenuItem>
                              )}
                            </Select>
                            {Boolean(
                              formik.errors.limitItems &&
                                formik.errors.limitItems[index] &&
                                formik.errors.limitItems[index]?.limitType
                            ) && (
                              <FormHelperText style={{ color: '#f018a6' }}>
                                {formik.errors.limitItems[index]?.limitType}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col">
                          <FormControl size="small" className="w-100" variant="outlined">
                            <TextField
                              disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                              onChange={e => handleInputChange(index, e, 'limitValue')}
                              label={intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.LIMIT_VALUE' })}
                              type="number"
                              value={limitValue?.value || item.limitValue}
                              name={`limitValue${index}`}
                              variant="outlined"
                              size="small"
                              error={Boolean(limitValue && limitValue[index] && limitValue[index].error)}
                              className="w-100"
                            />

                            {Boolean(limitValue && limitValue[index] && limitValue[index].error) && (
                              <FormHelperText style={{ color: '#f018a6' }}>{limitValue[index].error}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col">
                          <FormControl size="small" className="w-100" variant="outlined">
                            <InputLabel htmlFor={`periodId${index}`} style={{ color: 'black' }}>
                              {intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.CREATE.FIELD.TITLE.RESET_PERIOD_TYPE' })}
                            </InputLabel>
                            <Select
                              disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                              onChange={e => handleInputChange(index, e, 'periodId')}
                              value={item.periodId || periodTypes[0].value}
                              error={Boolean(
                                formik.errors.limitItems &&
                                  formik.errors.limitItems[index] &&
                                  formik.errors.limitItems[index]?.periodId
                              )}
                              input={
                                <OutlinedInput labelWidth={120} name={`periodId${index}`} id={`periodId${index}`} />
                              }
                            >
                              {periodTypes.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {Boolean(
                              formik.errors.limitItems &&
                                formik.errors.limitItems[index] &&
                                formik.errors.limitItems[index]?.periodId
                            ) && (
                              <FormHelperText style={{ color: '#f018a6' }}>
                                {formik.errors.limitItems[index]?.periodId}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                        <div className="col">
                          <FormControl size="small" className="w-100" variant="outlined">
                            <PeriodValue
                              disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                              intl={intl}
                              cx={cx}
                              formik={formik}
                              handleInputChange={handleInputChange}
                              item={item}
                              periodId={item.periodId}
                              index={index}
                              periodValue={periodValue}
                              openModalData={openModalData}
                              limitItems={creditLimitDetailsData?.limitItems}
                              isEnableEdit={isEnableEdit}
                              setPeriodValue={setPeriodValue}
                            />
                            {Boolean(periodValue && periodValue[index] && periodValue[index]?.error) && (
                              <FormHelperText style={{ color: '#f018a6' }}>{periodValue[index]?.error}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
              {limitItemsError && (
                <>
                  <span style={{ color: '#f018a6' }} className="d-block text-center">
                    {limitItemsError}
                  </span>
                </>
              )}
              <div className="d-flex w-100 justify-content-center mt-5">
                <Button
                  disabled={!isEnableEdit && openModalData.modal === 'DETAILS_MODAL'}
                  onClick={() => handleAddNewLimitItem()}
                  className={`d-flex justify-content-between align-items-center`}
                  variant="primary"
                >
                  {intl.formatMessage({ id: 'LABEL.NEW.ITEM' })}
                  <Icon
                    className={cx('add-new-icon', 'ml-2', theme === 'Dark' && cx('add-new-icon-dark'))}
                    color="action"
                  >
                    add_circle
                  </Icon>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {hasPermissionToEdit && openModalData.modal === 'DETAILS_MODAL' && !isEnableEdit && (
          <Button onClick={() => setIsEnableEdit(true)} variant="primary">
            {intl.formatMessage({ id: 'LABEL.EDIT' })}
          </Button>
        )}
        {openModalData.modal === 'DETAILS_MODAL' && isEnableEdit && (
          <>
            {' '}
            <Button
              onClick={() => formik.submitForm()}
              disabled={editCreditLimitTemplateData.isLoading || isValidToSave || !formik.isValid}
              variant="primary"
            >
              {editCreditLimitTemplateData.isLoading ? (
                <>
                  <Spinner size="sm" animation="border" role="status"></Spinner>{' '}
                  <span>{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
                </>
              ) : (
                <>{intl.formatMessage({ id: 'LABEL.SAVE' })} </>
              )}
            </Button>
            <Button
              onClick={() => {
                onClose({ ...openModalData, isOpen: false, data: '' })
                if (multiselectRef.current) {
                  multiselectRef.current.resetSelectedValues()
                }
                formik.setTouched({})
                setIsEnableEdit(false)
              }}
              variant="secondary"
            >
              {intl.formatMessage({ id: 'LABEL.CANCEL' })}
            </Button>
          </>
        )}

        {openModalData.modal === 'CREATE_MODAL' && !isEnableEdit && (
          <>
            {' '}
            <Button
              type="button"
              disabled={createCreditLimitTemplateData.isLoading || isValidToSave || !formik.isValid}
              onClick={() => formik.submitForm()}
              variant="primary"
            >
              {createCreditLimitTemplateData.isLoading ? (
                <>
                  <Spinner size="sm" animation="border" role="status"></Spinner>{' '}
                  <span>{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
                </>
              ) : (
                <>{intl.formatMessage({ id: 'LABEL.SAVE' })} </>
              )}
            </Button>
            <Button
              disabled={createCreditLimitTemplateData.isLoading}
              onClick={() => {
                onClose({ ...openModalData, isOpen: false, data: '' })
                if (multiselectRef.current) {
                  multiselectRef.current.resetSelectedValues()
                }
                formik.setTouched({})
                setLimitValue(undefined)
                setPeriodValue(undefined)
              }}
              variant="secondary"
            >
              {intl.formatMessage({ id: 'LABEL.CANCEL' })}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default memo(CreditLimitTemplateModal)
