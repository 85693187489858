import { useState } from 'react'

const useAlerts = () => {
  const [alerts, setAlerts] = useState([])
  const ALERT_TIMEOUT_15_SECONDS = 15000

  const onDismissed = alert => {
    setAlerts(currentAlerts => {
      const idx = currentAlerts.indexOf(alert)
      if (idx < 0) return currentAlerts
      return [...currentAlerts.slice(0, idx), ...currentAlerts.slice(idx + 1)]
    })
  }

  const generateAlert = (type, header, message) => {
    setAlerts(currentAlerts => [
      ...currentAlerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  return {
    alerts,
    onDismissed,
    generateAlert,
    ALERT_TIMEOUT_15_SECONDS
  }
}

export default useAlerts
