import React from 'react'
import style from '../companyDetail.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
const agentHierarchies = [
  { name: 'Master Distribution', code: 'MD' },
  { name: 'Sub Division', code: 'D' },
  { name: 'Sub Division', code: 'SD' },
  { name: 'Retailer', code: 'R' }
]
const GeneralDetails = ({ isEdit, isAdd, formik, countryListData, stateListData, timezoneListData, theme }) => {
  const toInputUppercase = e => {
    e.target.value = ('' + e.target.value).toUpperCase().trim()
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        <ul className={cx('wrapper')}>
          <li>
            <label>Level</label>
            <div className={cx('input-group')}>
              <select
                value={formik.values.level}
                onChange={formik.handleChange}
                disabled
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="level"
              >
                {agentHierarchies &&
                  agentHierarchies.map(item => {
                    return (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          </li>
          <li>
            <label>Agent Code</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Agent Code"
                disabled={!isAdd}
                type="text"
                onInput={toInputUppercase}
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="agentCode"
                maxLength="8"
                {...formik.getFieldProps('agentCode')}
              />
              {formik.touched.agentCode && formik.errors.agentCode ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.agentCode}</div>
                </div>
              ) : null}
            </div>
          </li>
          <li>
            <label>Name</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Name"
                disabled={!isEdit && !isAdd}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="name"
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>
          </li>
          <li>
            <label>Country</label>
            <div className={cx('input-group')}>
              <select
                value={formik.values.countryCode}
                onChange={formik.handleChange}
                disabled={!isEdit && !isAdd}
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="countryCode"
              >
                {countryListData &&
                  countryListData.map(item => {
                    return (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          </li>

          {stateListData?.length > 0 && (
            <li>
              <label>State/Province</label>
              <div className={cx('input-group')}>
                <select
                  value={formik.values.stateCode}
                  onChange={formik.handleChange}
                  disabled={!isEdit && !isAdd}
                  className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                  name="stateCode"
                >
                  {stateListData?.map(item => {
                    return (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </li>
          )}

          <li>
            <label>Timezone</label>
            <div className={cx('input-group')}>
              <select
                disabled={!isEdit && !isAdd}
                onChange={formik.handleChange}
                value={formik.values.timezone}
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="timezone"
              >
                {timezoneListData &&
                  timezoneListData.map(item => {
                    return (
                      <option key={item.id} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          </li>
          <li>
            <label>Creation Date</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Creation Date"
                disabled
                type="date"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="creationDate"
                {...formik.getFieldProps('creationDate')}
              />
            </div>
          </li>
          <li>
            <label>Status</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Status"
                disabled
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="status"
                {...formik.getFieldProps('status')}
              />
            </div>
          </li>
          <li>
            <label>Tax</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Tax"
                disabled
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="tax"
                {...formik.getFieldProps('tax')}
              />
            </div>
          </li>
          <li>
            <label>Agent Type</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Agent Type"
                disabled
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="agentType"
                {...formik.getFieldProps('agentType')}
              />
            </div>
          </li>
          <li>
            <label>Parent Code</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Parent"
                disabled
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="parentCode"
                {...formik.getFieldProps('parentCode')}
              />
            </div>
          </li>
        </ul>
      </form>
    </>
  )
}

export default GeneralDetails
