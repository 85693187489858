import { merchantApiClient } from '../../../../redux'

export const GET_PIN_BATCH_ORDERS = '/api/v1/pin-batch-orders'
export const DOWNLOAD_PIN_BATCH_ORDER_FILE = '/api/v1/download-pin-batch-order'
export const GET_PIN_BATCH_ORDER_DETAILS = '/api/v1/pin-batch-order-details?orderRef='
export const GET_PRODUCT_ORDER_LIST = '/api/v1/pin-batch-order/products'
export const SUBMIT_ORDER_LIST = '/api/v1/submit-pin-batch-orders'
export const DOWNLOAD_PIN_BATCH_ORDER_DOCUMENT = '/api/v1/download-pin-batch-order-document'
export const DELETE_PIN_ORDER = '/api/v1/delete-pin-order'

export function getPinBatchOrders({ searchText, start, end, orderBy, orderType, pageSize, pageIndex }) {
  return merchantApiClient.post(GET_PIN_BATCH_ORDERS, {
    searchText,
    start,
    end,
    orderBy,
    orderType,
    pageSize,
    pageIndex
  })
}

export function downloadPinBatchOrderFile({ type, orderRef }) {
  return merchantApiClient.get(DOWNLOAD_PIN_BATCH_ORDER_FILE + `?type=${type}&orderRef=${orderRef}`)
}

export function getPinBatchOrderDetails({ orderRef }) {
  return merchantApiClient.get(GET_PIN_BATCH_ORDER_DETAILS + orderRef)
}

export function getProductOrderList() {
  return merchantApiClient.get(GET_PRODUCT_ORDER_LIST)
}

export function submitOrderList(pinOrders, files) {
  let formData = new FormData()

  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }

  formData.append(
    'pinOrders',
    new Blob([JSON.stringify(pinOrders)], {
      type: 'application/json'
    })
  )
  formData.append('files', files)

  return merchantApiClient.post(SUBMIT_ORDER_LIST, formData, config)
}

export function downloadPinBatchOrderDocument({ orderRef }) {
  return merchantApiClient.get(DOWNLOAD_PIN_BATCH_ORDER_DOCUMENT + `?orderRef=${orderRef}`, {
    responseType: 'arraybuffer'
  })
}

export function deletePinOrder({ orderRef, comment }) {
  return merchantApiClient.post(DELETE_PIN_ORDER, { orderRef, comment })
}
