import React, { useEffect, useState } from 'react'
import { useSelector, connect } from 'react-redux'
import ResourceManagementTable from './components/resource-management-table/ResourceManagementTable'
import { CardBody } from '../../../../../_metronic/_partials/controls'
import style from './resourceManagement.module.css'
import classNames from 'classnames/bind'
import CreateDocumentModal from './components/resource-management-create-modal/CreateDocumentModal'
import DocumentDetailModal from './components/resource-management-detail-modal/DocumentDetailsModal'
import { actions } from './redux/resourceManagementRedux'
const cx = classNames.bind(style)
import { AlertList } from 'react-bs-notifier'
import ResourceManagementFilter from './components/ResourceManagementFilter'
const ALERT_TIMEOUT = 15000
const INIT_FILTER = {
  page: 'resource-management',
  pageIndex: 1,
  pageSize: 10,
  orderBy: 'id',
  orderType: 'desc'
}
const ResourceManagement = ({ resourceManagementData, resourceManagementLoading, resourceManagementReceived }) => {
  const { theme } = useSelector(state => ({
    theme: state.themeMode.themeMode.theme
  }))

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)
  const [document, setDocument] = useState(null)
  const [sortData, setSortData] = useState({ dataField: 'id', order: 'desc' })
  const [isPaging, setIsPaging] = useState(false)
  const [searchFilter, setSearchFilter] = useState(INIT_FILTER)

  useEffect(() => {
    if (isPaging) {
      resourceManagementLoading({ ...searchFilter })
      setSearchFilter({ ...searchFilter })
      setIsPaging(false)
    }
    return () => resourceManagementReceived([])
  }, [isPaging])

  const reloadData = () => {
    resourceManagementLoading({
      page: 'resource-management',
      pageIndex: 1,
      pageSize: 10,
      orderBy: 'id',
      orderType: 'desc'
    })
  }

  const handleCloseCreateModal = () => {
    setIsOpenCreateModal(false)
  }

  const openDocumentDetailsDialog = doc => {
    setIsOpenDetailModal(true)
    setDocument(doc)
  }

  const handleCloseDetailModal = () => {
    setIsOpenDetailModal(false)
  }

  const documentTypeList = [
    {
      id: 1,
      code: 'UNSET',
      description: 'All'
    },
    {
      id: 2,
      code: 'MER_ALL',
      description: 'Merchant portal - All'
    },
    {
      id: 3,
      code: 'MER_DISTRIBUTOR',
      description: 'Merchant portal - Distributor'
    },
    {
      id: 4,
      code: 'MER_MERCHANT',
      description: 'Merchant portal - Merchant'
    }
  ]

  const statusList = [
    { code: 'UNSET', description: 'All' },
    { code: 'T', description: 'Active' },
    { code: 'F', description: 'Inactive' }
  ]

  const contentTypeList = ['HTML', 'FILE']
  const locationList = [
    { name: 'All', url: 'UNSET' },
    { name: 'Company Details', url: 'company-details' },
    { name: 'Transaction Search', url: 'transaction-search' },
    { name: 'Report', url: 'transaction-report' }
  ]

  const [alerts, setAlerts] = useState([])
  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const convertResourceManagementList = documentList => {
    if (documentList?.length > 0) {
      documentList?.map(doc => {
        let location = locationList.find(location => {
          return location.url == doc.location
        })

        if (location) {
          doc.location = location
        }
      })
    }
    return documentList
  }

  return (
    <>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>
      <div className={cx('wrapper-element', 'resource-management')}>
        <p className="page-title">Resource Management</p>
        <ResourceManagementFilter
          theme={theme}
          documentTypeList={documentTypeList}
          locationList={locationList}
          statusList={statusList}
          initFilter={INIT_FILTER}
          currentFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          resourceManagementLoading={resourceManagementLoading}
          isLoading={resourceManagementData.isLoading}
        />
      </div>
      <CardBody className={cx('wrapper', theme === 'Dark' && 'wrapper-dark')}>
        <ResourceManagementTable
          resourceManagementList={convertResourceManagementList(
            resourceManagementData?.data?.data ? resourceManagementData?.data?.data : []
          )}
          isLoading={resourceManagementData.isLoading}
          totalRecord={resourceManagementData.totalRecord}
          theme={theme}
          setIsOpenCreateModal={setIsOpenCreateModal}
          openDocumentDetailsDialog={openDocumentDetailsDialog}
          setSearchFilter={setSearchFilter}
          filter={searchFilter}
          sortData={sortData}
          setSortData={setSortData}
          setIsPaging={setIsPaging}
        />
      </CardBody>
      <CreateDocumentModal
        isOpenCreateModal={isOpenCreateModal}
        handleCloseCreateModal={handleCloseCreateModal}
        theme={theme}
        documentTypeList={documentTypeList}
        contentTypeList={contentTypeList}
        locationList={locationList}
        reloadData={reloadData}
        generateAlert={generateAlert}
      />
      <DocumentDetailModal
        isOpenDetailModal={isOpenDetailModal}
        handleCloseDetailModal={handleCloseDetailModal}
        theme={theme}
        documentTypeList={documentTypeList}
        contentTypeList={contentTypeList}
        locationList={locationList}
        document={document}
        reloadData={reloadData}
        generateAlert={generateAlert}
      />
    </>
  )
}

const mapStateToProps = state => ({
  resourceManagementData: state.resourceManagement.resources
})
export default connect(mapStateToProps, actions)(ResourceManagement)
