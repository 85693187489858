import { put, call, takeLatest } from 'redux-saga/effects'
import { getUsers, getUserDetail } from './userManagementCrud'

export const actionTypes = {
  getUserManagementLoading: '[Get User List] Loading ',
  getUserManagementReceived: '[Get User List] Received ',
  getUserManagementFailed: '[Get User List] Failed ',
  getUserDetailsLoading: '[Get User Details] Loading',
  getUserDetailsReceived: '[Get User Details] Received',
  getUserDetailsFailed: '[Get User Details] Failed'
}

const initialUserManagementState = {
  userList: {
    data: [],
    totalRecord: 0,
    error: null,
    isLoading: false
  },
  userDetails: {
    data: {},
    isLoading: false,
    error: null
  }
}

export const reducer = (state = initialUserManagementState, action) => {
  switch (action.type) {
    case actionTypes.getUserManagementLoading: {
      return {
        ...state,
        userList: {
          ...state.userList,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.getUserManagementReceived: {
      const data = action.payload
      return {
        ...state,
        userList: {
          data: data,
          isLoading: false
        }
      }
    }
    case actionTypes.getUserManagementFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        userList: {
          ...state.userList,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.getUserDetailsLoading: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: true
        }
      }
    }
    case actionTypes.getUserDetailsReceived: {
      const data = action.payload
      return {
        ...state,
        userDetails: {
          data: data,
          isLoading: false
        }
      }
    }
    case actionTypes.getUserDetailsFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    default: {
      return state
    }
  }
}

export const actions = {
  getUserManagementLoading: params => ({
    type: actionTypes.getUserManagementLoading,
    payload: null,
    params: params
  }),
  getUserManagementReceived: payload => ({
    type: actionTypes.getUserManagementReceived,
    payload
  }),
  getUserManagementFailed: payload => ({
    type: actionTypes.getUserManagementFailed,
    payload
  }),
  getUserDetailsLoading: params => ({
    type: actionTypes.getUserDetailsLoading,
    params
  }),
  getUserDetailsReceived: payload => ({
    type: actionTypes.getUserDetailsReceived,
    payload
  }),
  getUserDetailsFailed: payload => ({
    type: actionTypes.getUserDetailsFailed,
    payload
  })
}

function* getUserManagementSaga({ params }) {
  try {
    const response = yield call(getUsers, params)

    const { resultCode, resultDescription, totalOfRecords, totalOfPages, users } = response.data
    if (resultCode === 0) {
      yield put(
        actions.getUserManagementReceived({
          resultCode,
          totalOfRecords,
          totalOfPages,
          users
        })
      )
    } else {
      yield put(
        actions.getUserManagementReceived({
          resultCode,
          resultDescription
        })
      )
    }
  } catch (error) {
    yield put(
      actions.getUserManagementReceived({
        resultCode: '',
        resultDescription: error.toString()
      })
    )
  }
}

function* getUserDetailsSaga({ params }) {
  try {
    const response = yield call(getUserDetail, params)
    const data = response.data
    if (data.resultCode === 0) yield put(actions.getUserDetailsReceived(data))
    else {
      yield put(
        actions.getUserDetailsFailed({ resultCode: data.resultCode, resultDescription: data.resultDescription })
      )
    }
  } catch (error) {
    yield put(actions.getUserDetailsFailed({ resultCode: '', resultDescription: error.toString() }))
  }
}

export function* saga() {
  yield takeLatest(actionTypes.getUserManagementLoading, getUserManagementSaga)
  yield takeLatest(actionTypes.getUserDetailsLoading, getUserDetailsSaga)
}
