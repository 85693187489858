import React, { useEffect, useMemo } from 'react'
import { AsideMenuList } from './AsideMenuList'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { actions } from '../../../../../app/modules/Engine/_redux/status/statusRedux'
import Spinner from '../../../../_partials/spinner'

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService()
  const { status, isLoading, theme } = useSelector(
    state => ({
      status: state.engine.status,
      isLoading: state.engine.status.isLoading,
      theme: state.themeMode.themeMode.theme
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getStatusLoading())
    const id = setInterval(() => {
      dispatch(actions.getStatusLoading())
    }, 300000)
    return () => {
      clearInterval(id)
    }
  }, [])

  const handleClickChangeStatus = () => {
    dispatch(actions.getStatusLoading())
  }

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true)
    }
  }, [uiService])

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu aside-menu-items my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} />
      </div>
      <div
        className={`aside-menu my-2 ${layoutProps.asideClassesFromConfig}`}
        data-menu-vertical="1"
        style={{
          position: 'absolute',
          display: 'inline-block',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          color: 'white'
        }}
      >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {isLoading ? (
            <Spinner style={{ height: 20, width: 20, color: theme === 'Dark' && 'white' }} />
          ) : (
            <li className={`menu-item`} aria-haspopup="true">
              <span style={{ color: theme === 'Light' ? 'black' : 'white' }}>API Status</span>
              <b
                style={{ color: status.status === 'ALIVE' ? 'green' : 'red', cursor: 'pointer' }}
                onClick={handleClickChangeStatus}
              >
                {status.status}
              </b>
            </li>
          )}
        </ul>
      </div>
      {/* end::Menu Container */}
    </>
  )
}
