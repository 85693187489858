import React, { useCallback, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { CardBody } from '../../../_metronic/_partials/controls'
import CreditLimitsTable from './components/table/CreditLimitsTable'
import classNames from 'classnames/bind'
import style from './credit-limits.module.css'
import { connect, useSelector } from 'react-redux'
import { actions as creditLimitsActions } from './redux/creditLimitsRedux'
import { actions as basePageActions } from '../../../redux/basePageRedux'
import { actions as authActions } from '../../modules/Auth/_redux/authRedux'
import { actions as actionReport } from '../reports/redux/reportsRedux'
import { actions as actionAgentUsers } from '../../modules/agentUser/_redux/AgentUserRedux'
import { checkHasPermission } from '../_helper/MenuHelper'
import AgentLimitDetailsModal from './components/modals/AgentLimitDetailsModal'
import CreditLimitTemplateModal from './components/modals/CreditLimitTemplateModal'
import DeleteCreditLimitTemplateModal from './components/modals/DeleteCreditLimitTemplateModal'
import { AlertList } from 'react-bs-notifier'
const cx = classNames.bind(style)
const ALERT_TIMEOUT = 2000

const CreditLimits = ({
  intl,
  theme,
  creditLimitTemplates,
  creditLimitTemplatesLoading,
  user,
  subDivisionAndAgentLoading,
  subDivisionAndAgentData,
  limitTargetDataLoading,
  limitTargetData,
  creditLimitDetailsLoading,
  creditLimitDetails,
  deleteCreditLimitTemplateLoading,
  deleteCreditLimitTemplateData,
  deleteCreditLimitTemplateReceived,
  createCreditLimitTemplateLoading,
  createCreditLimitTemplateData,
  createCreditLimitTemplateReceived,
  editAgentLimitItemLoading,
  editAgentLimitItemData,
  editAgentLimitItemReceived,
  editCreditLimitTemplateLoading,
  editCreditLimitTemplateData,
  editCreditLimitTemplateReceived,
  agentUserLoading
}) => {
  const agentType = useSelector(state => state.agentUser?.agentUser?.data?.agentType)
  const [creditLimitTemplateData, setCreditLimitTemplateData] = useState([])
  const [agentLimitsData, setAgentLimitData] = useState()
  const [isOpenEditAgentLimitModal, setIsOpenEditAgentLimitModal] = useState(false)
  const [isEnableEditAgentLimit, setIsEnableEditAgentLimit] = useState(false)
  const [templateName, setTemplateName] = useState()
  const [openModalData, setOpenModalData] = useState({})
  const [openDeleteModalData, setOpenDeleteModalData] = useState({ isOpen: false, data: null })
  const [alerts, setAlerts] = useState([])
  const [currencyList, setCurrencyList] = useState()
  const [productList, setProductList] = useState()

  useEffect(() => {
    handleLoadCreditLimitTemplates()
    handleLoadLimitTargetDataLoading()
  }, [])

  useEffect(() => {
    if (limitTargetData && limitTargetData.data) {
      if (limitTargetData.data.currency) {
        const currencies = [...limitTargetData.data.currency]
        sortAlphabetically(currencies)
        setCurrencyList(currencies)
      }
      if (limitTargetData.data.product) {
        const products = [...limitTargetData.data.product]
        sortAlphabetically(products)
        setProductList(products)
      }
    }
  }, [limitTargetData])

  const sortAlphabetically = list => {
    list.sort((a, b) => {
      if (a.code < b.code) {
        return -1
      }
      if (a.code > b.code) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    if (
      openModalData &&
      openModalData.modal === 'DETAILS_MODAL' &&
      openModalData.isOpen === true &&
      openModalData.data
    ) {
      handleLoadCreditLimitDetails(openModalData.data)
    }
  }, [openModalData])

  useEffect(() => {
    if (agentType) {
      handleLoadSubDivisionAndAgent()
    }
  }, [agentType])

  useEffect(() => {
    if (creditLimitTemplates.data) {
      const listData = creditLimitTemplates.data
      listData.map((item, index) => {
        item.id = index + 1
      })
      setCreditLimitTemplateData(listData)
    }
  }, [creditLimitTemplates.data])

  const handleLoadCreditLimitTemplates = useCallback(() => {
    creditLimitTemplatesLoading()
  }, [])

  const handleLoadLimitTargetDataLoading = useCallback(() => {
    limitTargetDataLoading()
  }, [])

  const handleLoadCreditLimitDetails = useCallback(value => {
    creditLimitDetailsLoading(value)
  }, [])

  const handleLoadSubDivisionAndAgent = useCallback(() => {
    subDivisionAndAgentLoading()
  }, [])

  const handleSetAgentLimitData = useCallback(
    data => {
      setAgentLimitData(data)
      setIsOpenEditAgentLimitModal(true)
    },
    [agentLimitsData]
  )

  const handleCloseEditAgentLimitModal = useCallback(() => {
    setIsOpenEditAgentLimitModal(false)
  }, [])

  const handleEnableEditAgentLimit = useCallback(value => {
    setIsEnableEditAgentLimit(value)
  }, [])

  const handleSetTemplateName = useCallback(value => {
    setTemplateName(value)
  }, [])

  const handleOpenModal = useCallback(value => {
    setOpenModalData(value)
  }, [])

  const handleOpenDeleteModal = useCallback(value => {
    setOpenDeleteModalData(value)
  }, [])

  const handleDeleteCreditLimitTemplate = useCallback(value => {
    deleteCreditLimitTemplateLoading(value)
  }, [])

  const handleCreateCreditLimitTemplate = useCallback(value => {
    createCreditLimitTemplateLoading(value)
  }, [])

  const handleEditAgentLimitItem = useCallback(value => {
    editAgentLimitItemLoading(value)
  }, [])

  const handleEditCreditLimitTemplate = useCallback(value => {
    editCreditLimitTemplateLoading(value)
  }, [])

  const handleReloadAgentUserInfo = useCallback(() => {
    agentUserLoading()
  }, [])

  const generateAlert = useCallback((type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }, [])
  const onDismissed = useCallback(alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }, [])

  return (
    <>
      <div className={cx('wrapper-element', 'user-list-wrapper')}>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
        <CardBody>
          <CreditLimitsTable
            isLoading={creditLimitTemplates.isLoading}
            theme={theme}
            intl={intl}
            creditLimitTemplatesData={creditLimitTemplateData}
            hasPermissionToDelete={checkHasPermission(user.acls, ['SCP.TTI.USER.LIMIT.TEMPLATE.ADMIN_D'])}
            hasPermissionToCreate={checkHasPermission(user.acls, ['SCP.TTI.USER.LIMIT.TEMPLATE.ADMIN_C'])}
            onSetAgentLimitData={handleSetAgentLimitData}
            onSetTemplateName={handleSetTemplateName}
            onOpenModal={handleOpenModal}
            onOpenDeleteModal={handleOpenDeleteModal}
            openCreditLimitDetailsData={{
              isLoading: creditLimitDetails.isLoading,
              rowIndex: openModalData?.rowIndex
            }}
          />
        </CardBody>
        <AgentLimitDetailsModal
          intl={intl}
          agentLimitData={agentLimitsData}
          cx={cx}
          isOpen={isOpenEditAgentLimitModal}
          onClose={handleCloseEditAgentLimitModal}
          onEnableEdit={handleEnableEditAgentLimit}
          isEdit={isEnableEditAgentLimit}
          templateName={templateName}
          hasPermissionToUpdate={checkHasPermission(user.acls, ['SCP.TTI.USER.LIMIT.TEMPLATE.ADMIN_W'])}
          theme={theme}
          onEditAgentLimitItem={handleEditAgentLimitItem}
          generateAlert={generateAlert}
          creditLimitTemplatesLoading={handleLoadCreditLimitTemplates}
          editAgentLimitItemData={editAgentLimitItemData}
          onReloadAgentUserInfo={handleReloadAgentUserInfo}
          onResetAgentLimitItemState={editAgentLimitItemReceived}
        />
        <CreditLimitTemplateModal
          intl={intl}
          cx={cx}
          openModalData={openModalData}
          onClose={handleOpenModal}
          theme={theme}
          subDivisionAndAgentData={subDivisionAndAgentData}
          currencyList={currencyList}
          productCodeList={productList}
          creditLimitDetailsData={creditLimitDetails.data}
          isLoading={creditLimitDetails.isLoading}
          onCreateCreditLimitTemplate={handleCreateCreditLimitTemplate}
          createCreditLimitTemplateData={createCreditLimitTemplateData}
          generateAlert={generateAlert}
          creditLimitTemplatesLoading={handleLoadCreditLimitTemplates}
          createCreditLimitTemplateReceived={createCreditLimitTemplateReceived}
          onEditCreditLimitTemplate={handleEditCreditLimitTemplate}
          editCreditLimitTemplateData={editCreditLimitTemplateData}
          editCreditLimitTemplateReceived={editCreditLimitTemplateReceived}
          onReloadAgentUserInfo={handleReloadAgentUserInfo}
          hasPermissionToEdit={checkHasPermission(user.acls, ['SCP.TTI.USER.LIMIT.TEMPLATE.ADMIN_W'])}
        />
        <DeleteCreditLimitTemplateModal
          onOpen={handleOpenDeleteModal}
          isOpen={openDeleteModalData?.isOpen}
          data={openDeleteModalData?.data}
          onDeleteCreditLimitTemplate={handleDeleteCreditLimitTemplate}
          deleteCreditLimitTemplateData={deleteCreditLimitTemplateData}
          generateAlert={generateAlert}
          creditLimitTemplatesLoading={handleLoadCreditLimitTemplates}
          deleteCreditLimitTemplateReceived={deleteCreditLimitTemplateReceived}
          onReloadAgentUserInfo={handleReloadAgentUserInfo}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  creditLimitTemplates: state.creditLimitTemplate.creditLimitTemplate,
  limitTargetData: state.creditLimitTemplate.limitTargetData,
  creditLimitDetails: state.creditLimitTemplate.creditLimitDetails,
  theme: state.themeMode.themeMode.theme,
  user: state.auth.user,
  subDivisionAndAgentData: state.reports.subDivisionAndAgent,
  deleteCreditLimitTemplateData: state.creditLimitTemplate.deleteCreditLimitTemplate,
  createCreditLimitTemplateData: state.creditLimitTemplate.createCreditLimitTemplate,
  editCreditLimitTemplateData: state.creditLimitTemplate.editCreditLimitTemplateData,
  editAgentLimitItemData: state.creditLimitTemplate.editAgentLimitItemData
})

export default connect(mapStateToProps, {
  ...creditLimitsActions,
  ...basePageActions,
  ...authActions,
  ...actionReport,
  ...actionAgentUsers
})(injectIntl(CreditLimits))
