import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { LayoutSplashScreen } from '../../../../_metronic/layout'
import { logout } from '../_redux/authCrud'
import { actions as actionBasePage } from '../../../../redux/basePageRedux'
import * as auth from '../_redux/authRedux'

class Logout extends Component {
  componentDidMount() {
    logout()
      .then(res => {})
      .catch(error => {})
      .finally(() => {
        this.props.logout()
        this.props.resetStore()
      })
  }

  render() {
    const { hasAuthToken } = this.props
    document.documentElement.setAttribute('data-color-scheme', 'Light')

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />
  }
}

export default connect(
  state => ({
    hasAuthToken: Boolean(state.auth.authToken)
  }),
  { ...auth.actions, ...actionBasePage }
)(Logout)
