import { put, call, takeLatest } from 'redux-saga/effects'

import {
  createCreditLimitTemplate,
  deleteCreditLimitTemplate,
  editAgentLimitItem,
  editCreditLimitTemplate,
  getCreditLimitDetails,
  getCreditLimitTemplates,
  getLimitTargetData
} from './creditLimitsCrud'
import { SUCCESS_RESULT_CODE } from '../../../../utils/Constants'

export const actionTypes = {
  creditLimitTemplatesLoading: '[Credit Limit Template] Loading',
  creditLimitTemplatesReceived: '[Credit Limit Template] Received',
  creditLimitTemplatesFailed: '[Credit Limit Template] Failed',
  limitTargetDataLoading: '[Limit Target Data] Loading',
  limitTargetDataReceived: '[Limit Target Data] Received',
  limitTargetDataFailed: '[Limit Target Data] Failed',
  productCodeListLoading: '[productCodeList] Loading',
  productCodeListReceived: '[productCodeList] Received',
  productCodeListFailed: '[productCodeList] Failed',
  creditLimitDetailsLoading: '[Credit Limit Details] Loading',
  creditLimitDetailsReceived: '[Credit Limit Details] Received',
  creditLimitDetailsFailed: '[Credit Limit Details] Failed',
  createCreditLimitTemplateLoading: '[Create Credit Limit Template] Loading',
  createCreditLimitTemplateReceived: '[Create Credit Limit Template] Received',
  createCreditLimitTemplateFailed: '[Create Credit Limit Template] Failed',
  deleteCreditLimitTemplateLoading: '[Delete Credit Limit Template] Loading',
  deleteCreditLimitTemplateReceived: '[Delete Credit Limit Template] Received',
  deleteCreditLimitTemplateFailed: '[Delete Credit Limit Template] Failed',
  editAgentLimitItemLoading: '[Edit Agent Limit Item] Loading',
  editAgentLimitItemReceived: '[Edit Agent Limit Item] Received',
  editAgentLimitItemFailed: '[Edit Agent Limit Item] Failed',
  editCreditLimitTemplateLoading: '[Edit Credit Limit Template] Loading',
  editCreditLimitTemplateReceived: '[Edit Credit Limit Template] Received',
  editCreditLimitTemplateFailed: '[Edit Credit Limit Template] Failed'
}

const initialCreditLimitTemplateState = {
  creditLimitTemplate: {
    data: null,
    error: null,
    isLoading: false
  },
  limitTargetData: {
    data: null,
    error: null,
    isLoading: false
  },
  creditLimitDetails: {
    data: null,
    error: null,
    isLoading: false
  },
  deleteCreditLimitTemplate: {
    data: null,
    error: null,
    isLoading: false
  },
  createCreditLimitTemplate: {
    data: null,
    error: null,
    isLoading: false
  },
  editAgentLimitItemData: {
    data: null,
    error: null,
    isLoading: false
  },
  editCreditLimitTemplateData: {
    data: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialCreditLimitTemplateState, action) => {
  switch (action.type) {
    //creditLimitTemplate
    case actionTypes.creditLimitTemplatesLoading: {
      return {
        ...state,
        creditLimitTemplate: {
          ...state.creditLimitTemplate,
          isLoading: true
        }
      }
    }
    case actionTypes.creditLimitTemplatesReceived: {
      const data = action.payload
      return {
        ...state,
        creditLimitTemplate: {
          ...state.creditLimitTemplate,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.creditLimitTemplatesFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        creditLimitTemplate: {
          ...state.creditLimitTemplate,
          error: resultDescription,
          isLoading: false
        }
      }
    }

    // limitTargetData
    case actionTypes.limitTargetDataLoading: {
      return {
        ...state,
        limitTargetData: {
          ...state.limitTargetData,
          isLoading: true
        }
      }
    }
    case actionTypes.limitTargetDataReceived: {
      const data = action.payload
      return {
        ...state,
        limitTargetData: {
          ...state.limitTargetData,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.limitTargetDataFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        limitTargetData: {
          ...state.limitTargetData,
          error: resultDescription,
          isLoading: false
        }
      }
    }

    //creditLimitDetails
    case actionTypes.creditLimitDetailsLoading: {
      return {
        ...state,
        creditLimitDetails: {
          ...state.creditLimitDetails,
          isLoading: true
        }
      }
    }
    case actionTypes.creditLimitDetailsReceived: {
      const data = action.payload
      return {
        ...state,
        creditLimitDetails: {
          ...state.creditLimitDetails,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.creditLimitDetailsFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        creditLimitDetails: {
          ...state.creditLimitDetails,
          error: resultDescription,
          isLoading: false
        }
      }
    }

    //deleteCreditLimitTemplate
    case actionTypes.deleteCreditLimitTemplateLoading: {
      return {
        ...state,
        deleteCreditLimitTemplate: {
          ...state.deleteCreditLimitTemplate,
          isLoading: true
        }
      }
    }
    case actionTypes.deleteCreditLimitTemplateReceived: {
      const data = action.payload
      return {
        ...state,
        deleteCreditLimitTemplate: {
          ...state.deleteCreditLimitTemplate,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.deleteCreditLimitTemplateFailed: {
      const data = action.payload
      return {
        ...state,
        deleteCreditLimitTemplate: {
          ...state.deleteCreditLimitTemplate,
          data: data,
          error: data.resultDescription,
          isLoading: false
        }
      }
    }

    //createCreditLimitTemplate
    case actionTypes.createCreditLimitTemplateLoading: {
      return {
        ...state,
        createCreditLimitTemplate: {
          ...state.createCreditLimitTemplate,
          isLoading: true
        }
      }
    }
    case actionTypes.createCreditLimitTemplateReceived: {
      const data = action.payload
      return {
        ...state,
        createCreditLimitTemplate: {
          ...state.createCreditLimitTemplate,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.createCreditLimitTemplateFailed: {
      const data = action.payload
      return {
        ...state,
        createCreditLimitTemplate: {
          ...state.createCreditLimitTemplate,
          data: data,
          error: data.resultDescription,
          isLoading: false
        }
      }
    }

    //editAgentLimitItem
    case actionTypes.editAgentLimitItemLoading: {
      return {
        ...state,
        editAgentLimitItemData: {
          ...state.editAgentLimitItemData,
          isLoading: true
        }
      }
    }
    case actionTypes.editAgentLimitItemReceived: {
      const data = action.payload
      return {
        ...state,
        editAgentLimitItemData: {
          ...state.editAgentLimitItemData,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.editAgentLimitItemFailed: {
      const data = action.payload
      return {
        ...state,
        editAgentLimitItemData: {
          ...state.editAgentLimitItemData,
          data: data,
          error: data.resultDescription,
          isLoading: false
        }
      }
    }

    //editCreditLimitTemplate
    case actionTypes.editCreditLimitTemplateLoading: {
      return {
        ...state,
        editCreditLimitTemplateData: {
          ...state.editCreditLimitTemplateData,
          isLoading: true
        }
      }
    }
    case actionTypes.editCreditLimitTemplateReceived: {
      const data = action.payload
      return {
        ...state,
        editCreditLimitTemplateData: {
          ...state.editCreditLimitTemplateData,
          data: data,
          error: null,
          isLoading: false
        }
      }
    }

    case actionTypes.editCreditLimitTemplateFailed: {
      const data = action.payload
      return {
        ...state,
        editCreditLimitTemplateData: {
          ...state.editCreditLimitTemplateData,
          data: data,
          error: data.resultDescription,
          isLoading: false
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  //creditLimit
  creditLimitTemplatesLoading: () => ({
    type: actionTypes.creditLimitTemplatesLoading,
    payload: null
  }),
  creditLimitTemplatesReceived: payload => ({
    type: actionTypes.creditLimitTemplatesReceived,
    payload
  }),
  creditLimitTemplatesFailed: payload => ({
    type: actionTypes.creditLimitTemplatesFailed,
    payload
  }),

  //limitTargetData
  limitTargetDataLoading: () => ({
    type: actionTypes.limitTargetDataLoading,
    payload: null
  }),
  limitTargetDataReceived: payload => ({
    type: actionTypes.limitTargetDataReceived,
    payload
  }),
  limitTargetDataFailed: payload => ({
    type: actionTypes.limitTargetDataFailed,
    payload
  }),

  //creditLimitDetails
  creditLimitDetailsLoading: payload => ({
    type: actionTypes.creditLimitDetailsLoading,
    payload
  }),
  creditLimitDetailsReceived: payload => ({
    type: actionTypes.creditLimitDetailsReceived,
    payload
  }),
  creditLimitDetailsFailed: payload => ({
    type: actionTypes.creditLimitDetailsFailed,
    payload
  }),

  //creditLimitDetails
  editAgentLimitItemLoading: payload => ({
    type: actionTypes.editAgentLimitItemLoading,
    payload
  }),
  editAgentLimitItemReceived: payload => ({
    type: actionTypes.editAgentLimitItemReceived,
    payload
  }),
  editAgentLimitItemFailed: payload => ({
    type: actionTypes.editAgentLimitItemFailed,
    payload
  }),

  //deleteCreditLimitTemplate
  deleteCreditLimitTemplateLoading: payload => ({
    type: actionTypes.deleteCreditLimitTemplateLoading,
    payload
  }),
  deleteCreditLimitTemplateReceived: payload => ({
    type: actionTypes.deleteCreditLimitTemplateReceived,
    payload
  }),
  deleteCreditLimitTemplateFailed: payload => ({
    type: actionTypes.deleteCreditLimitTemplateFailed,
    payload
  }),

  //createCreditLimitTemplate
  createCreditLimitTemplateLoading: payload => ({
    type: actionTypes.createCreditLimitTemplateLoading,
    payload
  }),
  createCreditLimitTemplateReceived: payload => ({
    type: actionTypes.createCreditLimitTemplateReceived,
    payload
  }),
  createCreditLimitTemplateFailed: payload => ({
    type: actionTypes.createCreditLimitTemplateFailed,
    payload
  }),

  //editAgentLimitItem
  editAgentLimitItemLoading: payload => ({
    type: actionTypes.editAgentLimitItemLoading,
    payload
  }),
  editAgentLimitItemReceived: payload => ({
    type: actionTypes.editAgentLimitItemReceived,
    payload
  }),
  editAgentLimitItemFailed: payload => ({
    type: actionTypes.editAgentLimitItemFailed,
    payload
  }),

  //editCreditLimitTemplate
  editCreditLimitTemplateLoading: payload => ({
    type: actionTypes.editCreditLimitTemplateLoading,
    payload
  }),
  editCreditLimitTemplateReceived: payload => ({
    type: actionTypes.editCreditLimitTemplateReceived,
    payload
  }),
  editCreditLimitTemplateFailed: payload => ({
    type: actionTypes.editCreditLimitTemplateFailed,
    payload
  })
}

export function* saga() {
  //creditLimit
  yield takeLatest(actionTypes.creditLimitTemplatesLoading, function* creditLimitTemplatesLoadingSaga() {
    try {
      const response = yield call(getCreditLimitTemplates)
      const { resultCode, resultDescription, data } = response.data
      if (resultCode === SUCCESS_RESULT_CODE) yield put(actions.creditLimitTemplatesReceived(data))
      else yield put(actions.creditLimitTemplatesFailed({ resultDescription }))
    } catch (error) {
      yield put(
        actions.creditLimitTemplatesFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  // limitTargetData
  yield takeLatest(actionTypes.limitTargetDataLoading, function* limitTargetDataLoadingSaga() {
    try {
      const response = yield call(getLimitTargetData)
      const { resultCode, resultDescription, currency, product } = response.data
      const data = { currency, product }
      yield put(actions.limitTargetDataReceived(data))
    } catch (error) {
      yield put(
        actions.limitTargetDataFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  //creditLimitDetails
  yield takeLatest(actionTypes.creditLimitDetailsLoading, function* creditLimitDetailsLoadingSaga(params) {
    try {
      const resp = yield call(getCreditLimitDetails, params.payload)
      const { resultCode, resultDescription, response } = resp.data
      if (resultCode === SUCCESS_RESULT_CODE) yield put(actions.creditLimitDetailsReceived(response))
      else yield put(actions.creditLimitDetailsFailed({ resultDescription }))
    } catch (error) {
      yield put(
        actions.creditLimitDetailsFailed({
          resultCode: '',
          resultDescription: error.toString()
        })
      )
    }
  })

  //deleteCreditLimitTemplate
  yield takeLatest(actionTypes.deleteCreditLimitTemplateLoading, function* deleteCreditLimitTemplateLoadingSaga(
    params
  ) {
    try {
      const resp = yield call(deleteCreditLimitTemplate, params.payload)
      const { resultCode, resultDescription } = resp.data
      const response = { resultCode, resultDescription }
      if (resultCode === SUCCESS_RESULT_CODE) yield put(actions.deleteCreditLimitTemplateReceived(response))
      else yield put(actions.deleteCreditLimitTemplateFailed(response))
    } catch (error) {
      yield put(
        actions.deleteCreditLimitTemplateFailed({
          resultCode: 9999,
          resultDescription: error.toString()
        })
      )
    }
  })

  //createCreditLimitTemplate
  yield takeLatest(actionTypes.createCreditLimitTemplateLoading, function* createCreditLimitTemplateLoadingSaga(
    params
  ) {
    try {
      const resp = yield call(createCreditLimitTemplate, params.payload)
      const { resultCode, resultDescription } = resp.data
      const response = { resultCode, resultDescription }
      if (resultCode === SUCCESS_RESULT_CODE) yield put(actions.createCreditLimitTemplateReceived(response))
      else yield put(actions.createCreditLimitTemplateFailed(response))
    } catch (error) {
      yield put(
        actions.createCreditLimitTemplateFailed({
          resultCode: 9999,
          resultDescription: error.toString()
        })
      )
    }
  })

  //editAgentLimitItem
  yield takeLatest(actionTypes.editAgentLimitItemLoading, function* editAgentLimitItemLoadingSaga(params) {
    try {
      const resp = yield call(editAgentLimitItem, params.payload)
      const { resultCode, resultDescription } = resp.data
      const response = { resultCode, resultDescription }
      if (resultCode === SUCCESS_RESULT_CODE) yield put(actions.editAgentLimitItemReceived(response))
      else yield put(actions.editAgentLimitItemFailed(response))
    } catch (error) {
      yield put(
        actions.editAgentLimitItemFailed({
          resultCode: 9999,
          resultDescription: error.toString()
        })
      )
    }
  })

  //editCreditLimitTemplate
  yield takeLatest(actionTypes.editCreditLimitTemplateLoading, function* editCreditLimitTemplateLoadingSaga(params) {
    try {
      const resp = yield call(editCreditLimitTemplate, params.payload)
      const { resultCode, resultDescription } = resp.data
      const response = { resultCode, resultDescription }
      if (resultCode === SUCCESS_RESULT_CODE) yield put(actions.editCreditLimitTemplateReceived(response))
      else yield put(actions.editCreditLimitTemplateFailed(response))
    } catch (error) {
      yield put(
        actions.editCreditLimitTemplateFailed({
          resultCode: 9999,
          resultDescription: error.toString()
        })
      )
    }
  })
}
