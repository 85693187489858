const PHONE_NUMBER_REGEX = /^(\+|\d)[0-9]{3,20}$/
export const validatePhoneNumber = number => {
  return PHONE_NUMBER_REGEX.test(number)
}

export const getInputClasses = (formik, fieldname) => {
  if (formik.touched[fieldname] && formik.errors[fieldname]) {
    return 'is-invalid'
  }

  if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    return 'is-valid'
  }

  return ''
}
