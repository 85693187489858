import { merchantApiClient } from '../../../../redux'

export const GET_COMPANY_STRUCTURE = '/api/v1/company-structure'
export const GET_AGENT_INFO = '/api/v1/agent-info?agentCode='
export const GET_COUNTRY_LIST = '/api/v1/country-list'
export const GET_STATE_LIST = '/api/v1/state-list?countryCode='
export const GET_TIMEZONE = 'api/v1/timezone-list'
export const SAVE_AND_UPDATE_AGENT = 'api/v1/agent'
export const GET_DOCUMENTS = '/api/v1/get-documents'

export const getDocumentByAgentType = () => {
  return merchantApiClient.post(GET_DOCUMENTS, { page: 'company-details' })
}

export const getCompanyStructure = () => {
  return merchantApiClient.get(GET_COMPANY_STRUCTURE)
}

export const getAgentInfoByAgentCode = agentCode => {
  return merchantApiClient.get(GET_AGENT_INFO + agentCode)
}

export const getCountryList = () => {
  return merchantApiClient.get(GET_COUNTRY_LIST)
}

export const getStateList = countryCode => {
  return merchantApiClient.get(GET_STATE_LIST + countryCode)
}

export const getTimezoneList = () => {
  return merchantApiClient.get(GET_TIMEZONE)
}

export const updateAgent = (agentDetail, agentPhysicalAddress) => {
  return merchantApiClient.put(SAVE_AND_UPDATE_AGENT, { agentDetail: agentDetail, agentAddress: agentPhysicalAddress })
}

export const createAgent = (agentDetail, agentPhysicalAddress) => {
  return merchantApiClient.post(SAVE_AND_UPDATE_AGENT, { agentDetail: agentDetail, agentAddress: agentPhysicalAddress })
}
