import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../resourceManagement.module.css'
import classNames from 'classnames/bind'
import FormDocumentDetail from './FormDocumentDetail'
const cx = classNames.bind(style)
import { FormattedMessage, injectIntl } from 'react-intl'

const DocumentDetailModal = ({
  isOpenDetailModal,
  handleCloseDetailModal,
  theme,
  documentTypeList,
  contentTypeList,
  locationList,
  intl,
  document,
  reloadData,
  generateAlert
}) => {
  return (
    <Modal
      show={isOpenDetailModal}
      onHide={handleCloseDetailModal}
      centered
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Document Detail</Modal.Title>
        <Button onClick={handleCloseDetailModal} variant="secondary">
          x
        </Button>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <FormDocumentDetail
          intl={intl}
          theme={theme}
          documentTypeList={documentTypeList}
          contentTypeList={contentTypeList}
          locationList={locationList}
          cx={cx}
          document={document}
          handleCloseDetailModal={handleCloseDetailModal}
          reloadData={reloadData}
          generateAlert={generateAlert}
        />
      </Modal.Body>
    </Modal>
  )
}

export default injectIntl(DocumentDetailModal)
