import { put, call, takeLatest } from 'redux-saga/effects'
import { validateVoucherProduct, redeemVoucherProduct } from './VoucherProductCrud'
import { PRODUCT_REDEEM_STEP_VALIDATE } from '../../../../utils/Constants'
import { INTERNAL_SERVER_ERROR_RESULT_CODE } from '../../../../utils/Constants'

export const actionTypes = {
  voucherValidateLoading: '[Voucher Validate] Loading',
  voucherValidateReceived: '[Voucher Validate] Received',
  voucherValidateFailed: '[Voucher Validate] Failed',
  voucherRedeemLoading: '[Voucher Redeem] Loading',
  voucherRedeemReceived: '[Voucher Redeem] Received',
  voucherRedeemFailed: '[Voucher Redeem] Failed',
  voucherStepSetting: '[Voucher Step] Setting Step'
}

const initialProductRedeemState = {
  voucherValidated: {
    data: [],
    isLoading: false,
    error: null
  },
  voucherRedeemed: {
    data: [],
    isLoading: false,
    error: null
  },
  voucherStep: {
    data: {
      currentStep: PRODUCT_REDEEM_STEP_VALIDATE
    },
    isLoading: false,
    error: null
  }
}

export const reducer = (state = initialProductRedeemState, action) => {
  switch (action.type) {
    case actionTypes.voucherValidateLoading: {
      return {
        ...state,
        voucherValidated: {
          ...state.voucherValidated,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.voucherValidateReceived: {
      const data = action.payload
      return {
        ...state,
        voucherValidated: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.voucherValidateFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        voucherValidated: {
          ...state.voucherValidated,
          isLoading: false,
          error: resultDescription
        }
      }
    }
    case actionTypes.voucherRedeemLoading: {
      return {
        ...state,
        voucherRedeemed: {
          ...state.voucherRedeemed,
          data: [],
          isLoading: true
        }
      }
    }
    case actionTypes.voucherRedeemReceived: {
      const data = action.payload
      return {
        ...state,
        voucherRedeemed: {
          data: data,
          isLoading: false,
          error: null
        }
      }
    }
    case actionTypes.voucherRedeemFailed: {
      const { resultDescription } = action.payload
      return {
        ...state,
        voucherRedeemed: {
          ...state.voucherRedeemed,
          isLoading: false,
          error: resultDescription
        }
      }
    }

    case actionTypes.voucherStepSetting: {
      const data = action.payload
      if (data?.currentStep === PRODUCT_REDEEM_STEP_VALIDATE) {
        return {
          voucherValidated: {
            data: [],
            isLoading: false,
            error: null
          },
          voucherRedeemed: {
            data: [],
            isLoading: false,
            error: null
          },
          voucherStep: {
            data: data,
            isLoading: false,
            error: null
          }
        }
      } else {
        return {
          ...state,
          voucherStep: {
            data: data,
            isLoading: false,
            error: null
          }
        }
      }
    }
    default: {
      return state
    }
  }
}

export const actions = {
  voucherValidateLoading: params => ({
    type: actionTypes.voucherValidateLoading,
    payload: null,
    params: params
  }),
  voucherValidateReceived: payload => ({
    type: actionTypes.voucherValidateReceived,
    payload
  }),
  voucherValidateFailed: payload => ({
    type: actionTypes.voucherValidateFailed,
    payload
  }),
  voucherRedeemLoading: params => ({
    type: actionTypes.voucherRedeemLoading,
    payload: null,
    params: params
  }),
  voucherRedeemReceived: payload => ({
    type: actionTypes.voucherRedeemReceived,
    payload
  }),
  voucherRedeemFailed: payload => ({
    type: actionTypes.voucherRedeemFailed,
    payload
  }),
  voucherStepSetting: payload => ({
    type: actionTypes.voucherStepSetting,
    payload
  })
}

function* validateVoucherLoadingSaga({ params }) {
  try {
    const response = yield call(validateVoucherProduct, params)
    const { resultCode, resultDescription, productName, currencyCode, redeemableValue } = response.data
    if (resultCode !== INTERNAL_SERVER_ERROR_RESULT_CODE) {
      yield put(
        actions.voucherValidateReceived({
          resultCode,
          resultDescription,
          productName,
          currencyCode,
          redeemableValue,
          pin: params
        })
      )
    } else {
      yield put(actions.voucherValidateFailed({ resultCode, resultDescription }))
    }
  } catch (error) {
    yield put(
      actions.voucherValidateFailed({
        resultCode: '',
        resultDescription: error.toString()
      })
    )
  }
}

function* redeemVoucherLoadingSaga({ params }) {
  try {
    const response = yield call(redeemVoucherProduct, params)
    const { resultCode, resultDescription, refId, productName, redeemedValue, status } = response.data
    if (resultCode !== INTERNAL_SERVER_ERROR_RESULT_CODE) {
      yield put(
        actions.voucherRedeemReceived({
          resultCode,
          resultDescription,
          refId,
          productName,
          redeemedValue,
          status
        })
      )
    } else {
      yield put(
        actions.voucherRedeemFailed({
          resultCode,
          resultDescription
        })
      )
    }
  } catch (error) {
    yield put(
      actions.voucherRedeemFailed({
        resultCode: '',
        resultDescription: error.toString()
      })
    )
  }
}

export function* saga() {
  yield takeLatest(actionTypes.voucherValidateLoading, validateVoucherLoadingSaga)
  yield takeLatest(actionTypes.voucherRedeemLoading, redeemVoucherLoadingSaga)
  yield takeLatest(actionTypes.voucherStepSetting, function* voucherStepSetting() {})
}
