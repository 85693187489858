import React from 'react'
import { Button } from 'react-bootstrap'
import Spinner from '../../../../_metronic/_partials/spinner'
import Notice from '../../../modules/notice/Notice'

export default function ResourcePanel({ cx, data, isLoading }) {
  const handleClick = () => {
    window.open('https://flexepin.atlassian.net/servicedesk/customer/portals', '_blank')
  }
  return (
    <div>
      <Button className="btn-primary-custom" variant="primary" size="lg" onClick={handleClick}>
        Customer Support Desk
      </Button>
      {isLoading && <Spinner wrapperClassName={cx('text-align-center')} size={25} />}
      {data.length > 0 && (
        <Notice messageProp={data[0].content} contentTypeProp={data[0].contentType} documentId={data[0].id} />
      )}
    </div>
  )
}
