import React, { useState, useEffect, useRef } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { Button } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import style from '../../pinOrder.module.css'
import classNames from 'classnames/bind'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers'
import { submitOrderList } from '../../redux/pinOrderCrud'
import { validateFileSize } from '../../../../../utils/FileUploadUtils'
import { env } from '../../../../../env'

const cx = classNames.bind(style)
const maxFilesSize = env.REACT_APP_PIN_ORDER_MAX_FILES_SIZE_IN_MB
const maxFileNameLength = env.REACT_APP_PIN_ORDER_MAX_FILE_NAME_LENGTH

const OrderItems = ({
  orderListDetails,
  removePinBatchToCreateOrderList,
  clearCreateOrderList,
  generateAlert,
  handleCloseModal,
  reloadData,
  theme
}) => {
  const [fileChoosse, setFileChoosse] = useState('')
  const [entities, setEntities] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fileInputError, setFileInputError] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    setEntities(orderListDetails)
  }, [orderListDetails])

  const INIT_COLUMNS = [
    {
      dataField: 'productCode',
      text: 'PRODUCT',
      headerStyle: () => {
        return { width: '30px', textAlign: 'center' }
      },
      style: {
        textAlign: 'center',
        width: '30px'
      }
    },
    {
      dataField: 'currency',
      text: 'CURRENCY',
      headerStyle: () => {
        return { width: '30px', textAlign: 'center' }
      },
      style: {
        textAlign: 'center',
        width: '30px'
      }
    },
    {
      dataField: 'value',
      text: 'VALUE',
      headerStyle: () => {
        return { width: '30px', textAlign: 'center' }
      },
      style: {
        textAlign: 'center',
        width: '30px'
      }
    },
    {
      dataField: 'qty',
      text: 'QUANTITY',
      headerStyle: () => {
        return { width: '30px', textAlign: 'center' }
      },
      style: {
        textAlign: 'center',
        width: '30px'
      }
    },
    {
      dataField: 'remove',
      text: 'Action',
      formatter: (cellContent, row) => {
        return (
          <a
            className={cx(
              'btn',
              'btn-icon',
              `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
              'btn-hover-danger',
              'btn-sm'
            )}
            onClick={() => {
              removePinBatchToCreateOrderList(row.productCode)
            }}
          >
            <span className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        )
      },
      headerStyle: () => {
        return { width: '30px', textAlign: 'center' }
      },
      style: {
        textAlign: 'center',
        width: '30px'
      }
    }
  ]

  const handleSelectFile = e => {
    setFileInputError('')
    const file = e.target.files[0]
    if (!validateFileSize(file.size, maxFilesSize)) {
      inputRef.current.value = null
      setFileInputError(`Max file size: ${maxFilesSize}mb`)
      return
    }
    if (file.name?.length > maxFileNameLength) {
      inputRef.current.value = null
      setFileInputError(`Max filename length: ${maxFileNameLength}`)
      return
    }
    setFileChoosse(file)
  }

  const resetFileInput = () => {
    inputRef.current.value = null
    setFileChoosse('')
  }

  const submitOrders = () => {
    if (orderListDetails.length > 0) {
      setIsSubmitting(true)
      let listOrderCoverted = []
      orderListDetails.map(item => {
        const { productCode, qty, comment } = item
        let order = { product: productCode, qty, comment }
        listOrderCoverted.push(order)
      })
      let pinOrders = { pinOrders: listOrderCoverted }
      submitOrderList(pinOrders, fileChoosse)
        .then(response => {
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Create Orders', resultDescription)
            clearCreateOrderList()
            handleCloseModal()
            reloadData()
          } else {
            generateAlert('danger', 'Create Orders', resultDescription)
          }
        })
        .catch(({ response, message }) => {
          generateAlert('danger', 'Create Orders', message)
        })
        .finally(data => {
          setIsSubmitting(false)
        })
    }
  }

  const clearAttachFile = e => {
    e.preventDefault()
    resetFileInput()
  }

  return (
    <div>
      <div>
        <BootstrapTable
          wrapperClasses={cx('table-responsive')}
          classes="table table-head-custom table-vertical-center"
          bootstrap4
          bordered={true}
          keyField="productCode"
          data={entities === null ? [] : entities}
          columns={INIT_COLUMNS}
          rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
        ></BootstrapTable>
      </div>
      <div className={cx('attach-file-content')}>
        <label htmlFor="upload-photo">
          <input
            ref={inputRef}
            onChange={e => handleSelectFile(e)}
            style={{ display: 'none' }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            accept=".csv, .doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png, .gif, .bmp, .tif, .webm, .txt, .csv"
          />

          <Button className={cx('attach-file-button')} variant="contained" component="span">
            <Icon className={cx('add-new-icon', theme === 'Dark' && cx('add-new-icon-dark'))} color="action">
              add_circle
            </Icon>
            <span>Attach File</span>
          </Button>
          {!fileChoosse && <div className={cx('no-file')}>No File Chosen</div>}
          {fileInputError && <div className={cx('error-message')}>{fileInputError}</div>}
          <div
            style={{
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'row'
            }}
          >
            <span
              onClick={e => {
                e.preventDefault()
              }}
              className={cx('value-chosen-file')}
            >
              {fileChoosse && fileChoosse?.name}
            </span>
            {fileChoosse && (
              <a style={{ color: 'var(--primary-color)' }} onClick={clearAttachFile}>
                Remove
              </a>
            )}
          </div>
        </label>
      </div>
      <div className={cx('action-button-content')}>
        <button
          type="button"
          className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
          onClick={() => {
            submitOrders()
          }}
        >
          <span>Request</span>
          {isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <button
          type="button"
          className={cx('btn', 'button', `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`, 'btn-lg')}
          onClick={() => {
            clearCreateOrderList()
            resetFileInput()
          }}
        >
          <span>Clear</span>
        </button>
      </div>
    </div>
  )
}

export default OrderItems
