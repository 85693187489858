/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers'

export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openRefundTransactionDialog, allowRefund }) => {
  if (
    allowRefund === true &&
    row.txType.toUpperCase() === 'SALE' &&
    row.status.toUpperCase() === 'SUCCESS' &&
    !row.redeemedDate
  ) {
    return (
      <>
        <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openRefundTransactionDialog(row)}>
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl('/media/svg/icons/Transaction/Refund-svgrepo-com.svg')} />
          </span>
        </a>
      </>
    )
  }
  return <></>
}
