import React from 'react'
import classNames from 'classnames/bind'
import style from '../../../credit-limits.module.css'
const cx = classNames.bind(style)

const ProgressBar = ({ currentValue, limitValue }) => {
  const getPercentValueAndColor = (currentValue, limitValue) => {
    let percentAndColor = {}
    const percent = isFinite(+currentValue / +limitValue) ? (+currentValue / +limitValue) * 100 : 0
    if (percent > 80) {
      percentAndColor = { color: 'bg-danger', value: percent }
    } else if (percent > 50) {
      percentAndColor = { color: 'bg-warning', value: percent }
    } else {
      percentAndColor = { color: 'bg-success', value: percent }
    }
    return percentAndColor
  }

  const percentAndColor = getPercentValueAndColor(currentValue, limitValue)

  return (
    <>
      <div
        className={cx('progress-bar', 'progress-bar-striped', percentAndColor.color)}
        role="progressbar"
        style={{ width: `${percentAndColor.value}%` }}
        aria-valuenow={percentAndColor.value}
        aria-valuemin="0"
        aria-valuemax={limitValue}
      >
        {`${percentAndColor.value.toFixed(2)}%`}
      </div>
    </>
  )
}
export const AssignedAgentColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { intl, onSetAgentLimitData, onSetTemplateName, theme }
) => {
  return (
    <>
      <div className="d-flex flex-column">
        {row.agentLimits &&
          row.agentLimits.length > 0 &&
          row.agentLimits.map((item, index) => {
            return (
              <div
                key={index}
                style={{ zIndex: 0 }}
                className={cx(
                  'label',
                  'label-inline',
                  'font-weight-bold',
                  'p-4',
                  'py-6',
                  'assigned-agent-label-light',
                  'cursor-pointer',
                  'assigned-agent',
                  'my-2'
                )}
              >
                {item.agentCode}
                <div className={cx('popup-information')}>
                  <div className="text-center p-4 font-weight-bold h6 border-bottom">
                    {intl.formatMessage({ id: 'CREDIT.LIMIT.POPUP_LIMIT_INFORMATION' })}
                  </div>
                  <div
                    className="text-center text-primary p-4 font-weight-bold"
                    style={{ textDecoration: 'underline', fontSize: '12px' }}
                    onClick={() => {
                      onSetAgentLimitData(item)
                      onSetTemplateName(row.limitInfo.templateName)
                    }}
                  >
                    <span>
                      {intl.formatMessage({ id: 'CREDIT.LIMIT.POPUP_EXPLORE_THE_DETAILS' })} {'>>'}
                    </span>
                  </div>
                  <ul className="list-group">
                    {item.limitItems &&
                      item.limitItems.length > 0 &&
                      item.limitItems.map((item, index) => {
                        return (
                          <li
                            style={{
                              backgroundColor: theme === 'Dark' ? 'var(--page-dark)' : 'var(--page-light)',
                              color: theme === 'Dark' ? 'var(--text-color-dark)' : 'var(--text-color-light)'
                            }}
                            key={index}
                            className="list-group-item d-flex justify-content-between align-items-center p-3 border-0"
                          >
                            <span className="font-weight-bold mx-2" style={{ fontSize: '1rem' }}>
                              {item.targetCode}
                            </span>
                            <div className="progress w-100 mx-2">
                              <ProgressBar currentValue={item.currentValue} limitValue={item.limitValue} />
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}
