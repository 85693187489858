import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import * as engine from '../app/modules/Engine/_redux/status/statusRedux'
import * as agentUser from '../app/modules/agentUser/_redux/AgentUserRedux'
import * as dashboard from '../app/pages/dashboard/redux/DashboardRedux'
import * as companyDetails from '../app/pages/companyDetails/redux/companyDetailsRedux'
import * as userProfile from '../app/pages/userProfile/redux/userProfileRedux'
import * as reports from '../app/pages/reports/redux/reportsRedux'
import * as settlementEarnings from '../app/pages/settlementEarnings/redux/SettlementEarningsRedux'
import * as pinBatchOrders from '../app/pages/pinOrder/redux/pinOrderRedux'
import * as themeMode from './basePageRedux'
import * as resources from '../app/pages/resources/redux/resourcesRedux'
import * as resourceManagement from '../app/pages/portalManagement/components/resourceManagement/redux/resourceManagementRedux'
import * as contactUs from '../app/pages/contactUs/redux/ContactUsRedux'
import * as performanceReport from '../app/pages/portalManagement/components/performanceReport/redux/performanceReportRedux'
import * as userManagement from '../app/pages/userManagement/redux/userManagementRedux'
import * as productRedeem from '../app/pages/productRedeem/redux/VoucherProductRedux'
import * as payoutTransaction from '../app/pages/payoutTransaction/redux/payoutTransactionRedux'
import * as appVersion from '../redux/appRedux'
import * as creditLimitTemplate from '../app/pages/creditLimits/redux/creditLimitsRedux'
export const appReducer = combineReducers({
  auth: auth.reducer,
  engine: engine.reducer,
  agentUser: agentUser.reducer,
  dashboard: dashboard.reducer,
  companyDetails: companyDetails.reducer,
  userProfile: userProfile.reducer,
  reports: reports.reducer,
  settlementEarnings: settlementEarnings.reducer,
  pinBatchOrders: pinBatchOrders.reducer,
  themeMode: themeMode.reducer,
  resources: resources.reducer,
  resourceManagement: resourceManagement.reducer,
  contactUs: contactUs.reducer,
  performanceReport: performanceReport.reducer,
  userManagement: userManagement.reducer,
  productRedeem: productRedeem.reducer,
  payoutTransaction: payoutTransaction.reducer,
  appVersion: appVersion.reducer,
  creditLimitTemplate: creditLimitTemplate.reducer
})

export const rootReducer = (state, action) => {
  if (action.type === themeMode.actionTypes.resetStore) {
    state.pinBatchOrders = undefined
    state.productRedeem = undefined
  }

  return appReducer(state, action)
}

export function* rootSaga() {
  yield all([
    auth.saga(),
    engine.saga(),
    agentUser.saga(),
    dashboard.saga(),
    companyDetails.saga(),
    userProfile.saga(),
    reports.saga(),
    settlementEarnings.saga(),
    pinBatchOrders.saga(),
    themeMode.saga(),
    resources.saga(),
    resourceManagement.saga(),
    contactUs.saga(),
    performanceReport.saga(),
    userManagement.saga(),
    productRedeem.saga(),
    payoutTransaction.saga(),
    appVersion.saga(),
    creditLimitTemplate.saga()
  ])
}
