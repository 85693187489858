import React from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'
import style from './invalidDomainDialog.module.css'
import classNames from 'classnames/bind'
import { env } from '../../../env'
const cx = classNames.bind(style)
const protocol = env.REACT_APP_PROTOCOL
const InvalidDomainDialog = ({ isOpenModal, seconds, countDownTime, onCancel, redirectDomain, loginToken }) => {
  const redirect = () => {
    onCancel()
    window.open(protocol + redirectDomain + '/auth/login?loginToken=' + loginToken, '_self')
  }
  return (
    <Modal centered show={isOpenModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Access Denied</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You don't have permission to access this site. Redirecting to <b>{protocol + redirectDomain}</b> in {seconds}{' '}
          seconds.
        </p>
        <ProgressBar className={cx('progress-bar-custom')} now={seconds} animated max={countDownTime} />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onCancel} className={cx('button', 'btn', 'btn-secondary', 'btn-lg')}>
          <span>Cancel</span>
        </button>
        <button className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')} onClick={redirect}>
          <span>Redirect</span>
        </button>
      </Modal.Footer>
    </Modal>
  )
}
export default InvalidDomainDialog
