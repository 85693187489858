import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { actions } from '../redux/VoucherProductRedux'
import ReactToPrint from 'react-to-print'
import { VoucherReceiptFormPrint } from './VoucherReceiptFormPrint'
import { PRODUCT_REDEEM_STEP_VALIDATE } from '../../../../utils/Constants'

function VoucherReceiptForm({ cx, theme, voucherRedeemed, voucherStepSetting, onShowAlert }) {
  let componentRef = useRef()
  let darkMode = theme === 'Dark' && cx('dark')
  const handlePrintReceiptVoucher = () => {
    document.getElementById('print-button').click()
  }

  const handleResetVoucher = () => {
    voucherStepSetting({
      currentStep: PRODUCT_REDEEM_STEP_VALIDATE
    })
    onShowAlert('', 0)
  }

  return (
    <div>
      <form>
        <div>
          <div style={{ margin: '10px auto' }}>
            <div style={{ padding: '0 0 0 10px' }}>
              <div style={{ textAlign: 'center' }}>
                <div className={cx('receipt-container')}>
                  <div className={cx('receipt-content')}>
                    <p>
                      <b>Ref ID:</b> {voucherRedeemed?.data?.refId}
                    </p>
                    <p>
                      <b>Product Name:</b> {voucherRedeemed?.data?.productName}
                    </p>
                    <p>
                      <b>Value:</b> {voucherRedeemed?.data?.redeemedValue}
                    </p>
                    <p>
                      <b>Status:</b> {voucherRedeemed?.data?.status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cx('actions')}>
          <div className={cx('actions-control')} style={{ justifyContent: 'space-between' }}>
            <button
              type="button"
              disabled={voucherRedeemed?.isLoading}
              className={`${cx('button', 'btn', 'btn-lg', 'action-btn', 'btn-secondary-custom')} ${darkMode}`}
              onClick={handlePrintReceiptVoucher}
            >
              Print
            </button>

            <button
              type="button"
              className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'action-btn', 'btn-primary-custom')}
              onClick={handleResetVoucher}
            >
              OK
            </button>
          </div>
        </div>
      </form>
      <div style={{ display: 'none' }}>
        <ReactToPrint
          content={() => componentRef}
          trigger={() => <button id="print-button">Print</button>}
          removeAfterPrint
        ></ReactToPrint>
        <VoucherReceiptFormPrint ref={el => (componentRef = el)} voucherRedeemed={voucherRedeemed} />
      </div>
    </div>
  )
}

export default injectIntl(connect(null, actions)(VoucherReceiptForm))
