import React from 'react'
import Chart from 'react-apexcharts'

const SuccessRateChart = ({ percent, theme }) => {
  let state = {
    options: {
      series: [...percent],
      chart: {
        width: '100%',
        height: '100%',
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%'
          },
          track: {
            background: '#a6a6a7'
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false
            },
            value: {
              offsetY: 10,
              show: true,
              fontSize: '20px',
              color: theme === 'Dark' ? '#bebfc2' : ''
            }
          }
        }
      },
      colors: ['#42b926']
    }
  }
  return (
    <div>
      <Chart
        options={state.options}
        series={state.options.series}
        type={state.options?.chart.type}
        width={state.options?.chart.width}
      />
    </div>
  )
}

export default SuccessRateChart
