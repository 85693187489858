import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Dropdown, Overlay } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import clsx from 'clsx'
import { actions } from '../../../../../app/modules/agentUser/_redux/AgentUserRedux'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { currencyFormat } from '../../../../../utils/DataFormatUtils'
import { actions as actionsTheme } from '../../../../../redux/basePageRedux'
import { useIntl } from 'react-intl'

const ProgressBar = ({ currentValue, limitValue }) => {
  const getPercentValueAndColor = (currentValue, limitValue) => {
    let percentAndColor = {}
    const percent = ((+currentValue / +limitValue) * 100).toFixed(2)
    if (percent > 80) {
      percentAndColor = { color: 'bg-danger', value: percent }
    } else if (percent > 50) {
      percentAndColor = { color: 'bg-warning', value: percent }
    } else {
      percentAndColor = { color: 'bg-success', value: percent }
    }
    return percentAndColor
  }

  const percentAndColor = getPercentValueAndColor(currentValue, limitValue)

  return (
    <>
      <div
        className={`progress-bar progress-bar-striped ${percentAndColor.color}`}
        role="progressbar"
        style={{ width: `${percentAndColor.value}%` }}
        aria-valuenow={percentAndColor.value}
        aria-valuemin="0"
        aria-valuemax={limitValue}
      ></div>
    </>
  )
}

function AccumulatedBalanceDropDown({ agentUserLoading, owedBalances, owedBalancesLoading, themeMode, agentUser }) {
  const intl = useIntl()
  const [theme, setTheme] = useState('Light')
  const [limitItems, setLimitItems] = useState()
  useEffect(() => {
    agentUserLoading()
  }, [])

  useEffect(() => {
    if (!agentUser.isLoading && agentUser.data && !agentUser.data.hideAccumulatedBalance) {
      owedBalancesLoading()
    }
  }, [agentUser])

  useEffect(() => {
    setTheme(themeMode?.themeMode?.theme)
  }, [themeMode?.themeMode?.theme])

  const { data, isLoading, error } = owedBalances

  let maxBalance
  let otherBalances

  if (data) {
    if (data.length === 1) {
      maxBalance = data[0]
    }

    if (data && data.length > 1) {
      maxBalance = data.filter(
        balance => balance.balance === Math.max(...data.map(accumulatedBalance => accumulatedBalance.balance))
      )[0]
      const currencyCodeOfMax = maxBalance.currencyCode
    }
  }

  const formatNumber = number => {
    let parsedNumber = parseFloat(number)
    if (Math.floor(parsedNumber) === parsedNumber) {
      return Math.floor(parsedNumber)
    } else {
      return parsedNumber.toFixed(2)
    }
  }

  const [creditIconColor, setCreditIconColor] = useState('text-success')

  useEffect(() => {
    if (agentUser.data && agentUser.data?.limitItems) {
      let largestPercent = 0
      agentUser.data?.limitItems.forEach(item => {
        const percent = (item.currentValue / item.limitValue) * 100
        if (percent > largestPercent) {
          largestPercent = percent
        }
      })
      if (largestPercent > 80) {
        setCreditIconColor('text-danger')
      } else if (largestPercent > 50) {
        setCreditIconColor('text-warning')
      } else {
        setCreditIconColor('text-success')
      }
    }
  }, [agentUser.data])

  useEffect(() => {
    if (agentUser && agentUser.data && agentUser.data?.limitItems && agentUser.data?.limitItems.length > 0) {
      const limitItemList = [...agentUser.data?.limitItems]
      limitItemList.sort((a, b) => {
        const percentValueA = isFinite(+a.currentValue / +a.limitValue) ? (+a.currentValue / +a.limitValue) * 100 : 0
        const percentValueB = isFinite(+b.currentValue / +b.limitValue) ? (+b.currentValue / +b.limitValue) * 100 : 0
        return percentValueB - percentValueA
      })

      setLimitItems(limitItemList)
    }
  }, [agentUser.data?.limitItems])

  return (
    <>
      <>
        {isLoading ? (
          <div className="topbar-item">
            <CircularProgress size={15} color="secondary" />
          </div>
        ) : (
          <>
            {limitItems && (
              <Dropdown drop="down" alignRight>
                <div className="topbar-item">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="agent-limit-tooltip">
                        <div className="d-flex flex-column">
                          <div
                            className="border-bottom text-center pb-2"
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                          >
                            {intl.formatMessage({ id: 'TOOLTIP.TITLE.AGENT_LIMITS' })}
                          </div>

                          {limitItems.map((item, index) => (
                            <div key={index} className="row py-3">
                              <div className="col-4">
                                <b>{item.targetCode}</b>
                              </div>
                              <div className="col-4">
                                <div className="progress">
                                  <ProgressBar currentValue={item.currentValue} limitValue={item.limitValue} />
                                </div>
                              </div>
                              <div className="col">
                                <b>{`${formatNumber(item.currentValue)}/${formatNumber(item.limitValue)}`}</b>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Tooltip>
                    }
                  >
                    <i
                      className={`fas fa-credit-card svg-icon menu-icon icon-xl p-10 cursor-pointer ${creditIconColor}`}
                    ></i>
                  </OverlayTrigger>
                </div>
              </Dropdown>
            )}
            {agentUser.data && agentUser.data.hideAccumulatedBalance === false && (
              <Dropdown drop="down" alignRight>
                <>
                  {otherBalances ? (
                    <div className="topbar-item">
                      <Dropdown.Toggle
                        variant="outline-light"
                        className="btn "
                        id="dropdown-toggle-accumulated-balance"
                        style={{ border: '0px' }}
                      >
                        <div className="btn mr-1" style={{ color: theme == 'Light' ? 'black' : 'white' }}>
                          {maxBalance
                            ? maxBalance.currencyCode + ' ' + maxBalance.symbol + currencyFormat(maxBalance.balance)
                            : currencyFormat(0)}
                        </div>
                      </Dropdown.Toggle>
                    </div>
                  ) : (
                    <div className="topbar-item">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="language-panel-tooltip"
                            style={{
                              color: theme == 'Light' ? 'black !important' : 'white !important',
                              backgroundColor: theme == 'Dark' ? '#333 !important' : undefined
                            }}
                          >
                            {intl.formatMessage({ id: 'TOOLTIP.TITLE.ACCUMULATED_BALANCE' })}
                          </Tooltip>
                        }
                      >
                        <div className="btn mr-1" style={{ color: theme == 'Light' ? 'black' : 'white' }}>
                          {maxBalance
                            ? maxBalance.currencyCode + ' ' + maxBalance.symbol + currencyFormat(maxBalance.balance)
                            : currencyFormat(0)}
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}

                  <Dropdown.Menu
                    className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
                    style={{ border: '0px' }}
                  >
                    <ul className="navi navi-hover py-4 menu-dark ">
                      {otherBalances &&
                        otherBalances.map(balance => {
                          return (
                            <li key={balance.currencyCode} className={clsx('navi-item')}>
                              <div
                                className="btn mr-1 cursor-alias"
                                style={{ color: theme == 'Light' ? 'black' : 'white' }}
                              >
                                {balance.currencyCode + ' ' + balance.symbol + currencyFormat(balance.balance)}
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  </Dropdown.Menu>
                </>
              </Dropdown>
            )}
          </>
        )}
      </>
    </>
  )
}
const mapStateToProps = state => ({
  owedBalances: state.agentUser.owedBalances,
  themeMode: state.themeMode,
  agentUser: state.agentUser.agentUser
})

export default connect(mapStateToProps, { ...actions, ...actionsTheme })(AccumulatedBalanceDropDown)
