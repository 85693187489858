import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { actions } from './redux/companyDetailsRedux'
import CompanyStructure from './components/CompanyStructure'
import ModalDetails from './components/ModalDetail'
import Notice from '../../modules/notice/Notice'
import style from './companyDetail.module.css'
import classNames from 'classnames/bind'
import Spinner from 'react-bootstrap/Spinner'
import CustomSpinner from '../../../_metronic/_partials/spinner'
import { AlertList } from 'react-bs-notifier'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
const cx = classNames.bind(style)
let isPanning = false
let centerViewAction = true
const DEFAULT_DISPLAYED_LIMIT = 4
function CompanyDetails({
  companyStructure,
  agentInfo,
  agentInfoLoading,
  clearAgentInfo,
  companyStructureLoading,
  dataNotice,
  dataNoticeLoading,
  countryListLoading,
  timezoneListLoading,
  agentReportClear
}) {
  const [isAgentInfoLoading, setIsAgentInfoLoading] = useState(true)
  const [generalDetails, setGeneralDetails] = useState()
  const [physicalAddress, setPhysicalAddress] = useState()
  const [companyStructureData, setCompanyStructureData] = useState([])
  const [topNoticeData, setTopNoticeData] = useState()
  const [bottomNoticesData, setBottomNoticesData] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [alerts, setAlerts] = useState([])
  const [percentScreen, setPercentScreen] = useState(100)
  const [contentType, setContentType] = useState('')
  const [documentId, setDocumentId] = useState()
  const [agentReportInfo, setAgentReportInfo] = useState({ type: null, agentCode: null })
  const [docActiveData, setDocActiveData] = useState([])
  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const ALERT_TIMEOUT = 15000
  const handleClickCompanyStructure = (e, type, level) => {
    if (!isPanning) {
      const agentCode = e.target.id
      agentInfoLoading(agentCode)
      setAgentReportInfo({ type, agentCode })
      setIsOpenModal(!isOpenModal)
    }
  }
  useEffect(() => {
    agentReportClear()
  }, [])

  const setStopPanning = () => {
    setTimeout(() => {
      isPanning = false
    }, 1)
  }

  const setPanning = () => {
    isPanning = true
  }

  const handleCloseModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  useEffect(() => {
    companyStructureLoading()
    countryListLoading()
    timezoneListLoading()
    dataNoticeLoading()
  }, [])

  useEffect(() => {
    if (dataNotice.data != null) {
      const data = dataNotice.data
      let docActiveDataTemp = []
      for (let i = 0; i < data.length; i++) {
        if (docActiveDataTemp.length === DEFAULT_DISPLAYED_LIMIT) {
          break
        }
        if (data[i].active === 'T') {
          docActiveDataTemp.push(data[i])
        }
      }
      setDocActiveData(docActiveDataTemp)
    }
  }, [dataNotice])

  useEffect(() => {
    let bottomNoticesDataArr = []
    if (docActiveData.length !== 0) {
      setTopNoticeData(docActiveData[0].content)
      setContentType(docActiveData[0].contentType)
      setDocumentId(docActiveData[0].id)

      for (var i = 1; i < docActiveData.length; i++) {
        if (i > 3) break
        bottomNoticesDataArr.push(docActiveData[i])
      }

      setBottomNoticesData(bottomNoticesDataArr)
    }
  }, [docActiveData])

  const [companyStructureWidth, setCompanyStructureWidth] = useState(0)
  const [chartWidth, setChartWidth] = useState(0)
  const [currentScale, setCurrentScale] = useState(1)
  const [isScaleCenter, setIsScaleCenter] = useState(false)
  const [initScale, setInitScale] = useState(1)
  const [isCalculatedCompanyStructureWidth, setIsCalculatedCompanyStructureWidth] = useState(false)

  const companyStructureRef = useRef(null)
  const transformComponentRef = useRef(null)

  const chartRef = useCallback(node => {
    if (node !== null) {
      setChartWidth(node.offsetWidth)
    }
  }, [])

  useEffect(() => {
    if (!isCalculatedCompanyStructureWidth) {
      setCompanyStructureWidth(companyStructureRef.current.offsetWidth)
      setIsCalculatedCompanyStructureWidth(true)
    }
  })

  useEffect(() => {
    if (companyStructureWidth && companyStructureWidth != 0 && chartWidth && chartWidth != 0) {
      if (companyStructureWidth >= chartWidth) return
      let initScale = parseFloat(companyStructureWidth / chartWidth).toFixed(2)
      setInitScale(parseFloat(initScale).toFixed(1))
      setCurrentScale(parseFloat(initScale).toFixed(1))
      setTimeout(() => {
        if (transformComponentRef.current) {
          transformComponentRef.current.centerView(initScale)
        }
        let percent = (initScale / 1) * 100
        setPercentScreen(parseFloat(percent).toFixed(0))
      }, 500)
    }
  }, [companyStructureWidth, chartWidth])

  useEffect(() => {
    if (companyStructure != null) {
      setCompanyStructureData(companyStructure)
    }
  }, [companyStructure])

  useEffect(() => {
    setGeneralDetails(agentInfo?.data?.agentDetail)
    setPhysicalAddress(agentInfo?.data?.agentAddress)
    setIsAgentInfoLoading(agentInfo.isLoading)
  }, [agentInfo])

  useEffect(() => {
    return () => {
      if (!centerViewAction) {
        centerViewAction = true
      }
    }
  }, [])

  useEffect(() => {
    if (isScaleCenter && transformComponentRef.current) {
      transformComponentRef.current.centerView(currentScale)
    }
  }, [currentScale])

  const calculateScaleAndZoomIn = () => {
    if (percentScreen >= 300) return
    let newScale = (currentScale * 10 + 1) / 10
    setCurrentScale(newScale)
    let newPercent = (newScale / 1) * 100
    setPercentScreen(parseFloat(newPercent).toFixed(0))
  }

  const calculateScaleAndZoomOut = () => {
    if (percentScreen <= 10) return
    let newScale = (currentScale * 10 - 1) / 10
    setCurrentScale(newScale)
    let newPercent = (newScale / 1) * 100
    setPercentScreen(parseFloat(newPercent).toFixed(0))
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', height: '100%' }}>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>

      {topNoticeData && (
        <div className={cx('company-detail-notice-top', theme === 'Dark' && 'company-detail-notice-top-dark')}>
          <Notice messageProp={topNoticeData} contentTypeProp={contentType} documentId={documentId} />
        </div>
      )}
      <div
        style={{
          position: 'relative'
        }}
        className={cx('company-detail-content', theme === 'Dark' && 'company-detail-content-dark')}
      >
        <p className={cx('company-structure-title', theme === 'Dark' && 'company-structure-title-dark')}>
          Company Structure
        </p>
        {isAgentInfoLoading && (
          <div className={cx('backdrop')}>
            <CustomSpinner style={{ color: theme === 'Dark' && 'white' }} />
          </div>
        )}
        <div ref={companyStructureRef} className={cx('company-structure')}>
          <TransformWrapper
            ref={transformComponentRef}
            onPanningStop={setStopPanning}
            onPanning={setPanning}
            initialScale={currentScale}
            initialPositionX={0}
            minScale={0.1}
            maxScale={3}
            initialPositionY={0}
            centerOnInit={true}
            centerZoomedOut={true}
            panning={{ disabled: companyStructureWidth <= 400 ? true : false }}
            onWheelStop={(ref, event) => {
              let newScale = parseFloat(ref.state.scale).toFixed(1)
              setIsScaleCenter(false)
              setCurrentScale(newScale)
              let newPercent = (newScale / 1) * 100
              setPercentScreen(parseFloat(newPercent).toFixed(0))
            }}
          >
            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => {
              return (
                <React.Fragment>
                  <div className={cx('tool-box')}>
                    <a
                      className={cx(
                        'btn',
                        'btn-icon',
                        `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
                        'btn-hover-danger',
                        'btn-md'
                      )}
                      onClick={() => {
                        setIsScaleCenter(true)
                        calculateScaleAndZoomIn()
                      }}
                    >
                      <span
                        className={cx(
                          'svg-icon',
                          'svg-icon-lg',
                          theme === 'Dark' ? 'svg-icon-light' : 'svg-icon-danger'
                        )}
                      >
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Design/ZoomPlus.svg')} />
                      </span>
                    </a>
                    <a
                      className={cx(
                        'btn',
                        'btn-icon',
                        `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
                        'btn-hover-danger',
                        'btn-md'
                      )}
                      onClick={() => {
                        setIsScaleCenter(true)
                        calculateScaleAndZoomOut()
                      }}
                    >
                      <span
                        className={cx(
                          'svg-icon',
                          'svg-icon-lg',
                          theme === 'Dark' ? 'svg-icon-light' : 'svg-icon-danger'
                        )}
                      >
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Design/ZoomMinus.svg')} />
                      </span>
                    </a>

                    <a
                      className={cx(
                        'btn',
                        'btn-icon',
                        `btn-${theme === 'Dark' ? 'dark' : 'light'}`,
                        'btn-hover-danger',
                        'btn-md'
                      )}
                      onClick={() => {
                        let initScale = 1
                        if (companyStructureWidth < chartWidth) {
                          initScale = parseFloat(companyStructureRef.current.offsetWidth / chartWidth).toFixed(2)
                        }
                        setInitScale(parseFloat(initScale).toFixed(1))
                        setCurrentScale(parseFloat(initScale).toFixed(1))
                        transformComponentRef.current.centerView(initScale)
                        let percent = (initScale / 1) * 100
                        setPercentScreen(parseFloat(percent).toFixed(0))
                      }}
                    >
                      <span
                        className={cx(
                          'svg-icon',
                          'svg-icon-lg',
                          theme === 'Dark' ? 'svg-icon-light' : 'svg-icon-danger'
                        )}
                      >
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Size.svg')} />
                      </span>
                    </a>
                    <div>{percentScreen}%</div>
                  </div>
                  <TransformComponent style={{ cursor: 'grapping' }}>
                    {!companyStructureData?.data ? (
                      <div className={cx('contain-spinner')}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div ref={chartRef} style={{ cursor: 'grabbing' }}>
                        <CompanyStructure
                          onClick={handleClickCompanyStructure}
                          data={companyStructureData.data}
                          level={0}
                        />
                      </div>
                    )}
                  </TransformComponent>
                </React.Fragment>
              )
            }}
          </TransformWrapper>
        </div>
        {isOpenModal && !isAgentInfoLoading ? (
          <ModalDetails
            isOpenModal={isOpenModal}
            onClick={handleCloseModal}
            generalDetails={generalDetails}
            physicalAddress={physicalAddress}
            isHiddenAddDivision={true}
            isHiddenAddRetailer={true}
            clearAgentInfo={clearAgentInfo}
            generateAlert={generateAlert}
            companyStructureLoading={companyStructureLoading}
            theme={theme}
            agentReportInfo={agentReportInfo}
          />
        ) : (
          ''
        )}
      </div>
      {bottomNoticesData && (
        <>
          <div className={cx('company-detail-notice-bottom', theme === 'Dark' && 'company-detail-notice-bottom-dark')}>
            {bottomNoticesData.map((item, index) => {
              return (
                <div key={index} className={cx('notice-bottom-item')}>
                  <Notice messageProp={item.content} contentTypeProp={item.contentType} documentId={item.id} />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  companyStructure: state.companyDetails.companyStructure,
  agentInfo: state.companyDetails.agentInfo,
  dataNotice: state.companyDetails.dataNotice,
  countryList: state.companyDetails.countryList
})

export default connect(mapStateToProps, actions)(CompanyDetails)
