/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import SVG from 'react-inlinesvg'
import style from '../../../userManagement.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { theme, openEditUserModal, isOpenEditModal, isLoadingUserDetails, hasPermissionToDelete, handleOpenDeleteModal }
) => {
  return (
    <>
      <a
        key={1}
        onClick={() => (isLoadingUserDetails ? {} : openEditUserModal(row.userId))}
        style={{ minWidth: '40px', marginRight: '5px' }}
        className={cx('btn', 'btn-icon', `btn-${theme === 'Dark' ? 'dark' : 'light'}`, 'btn-hover-danger', 'btn-sm')}
      >
        <span className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}>
          {isOpenEditModal === row.userId && isLoadingUserDetails ? (
            <span className="ml-1 spinner spinner-white" style={{ left: '-10px' }}></span>
          ) : (
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')} />
          )}
        </span>
      </a>
      {hasPermissionToDelete && (
        <a
          key={2}
          style={{ minWidth: '40px' }}
          onClick={() => handleOpenDeleteModal({ username: row.username })}
          className={cx('btn', 'btn-icon', `btn-${theme === 'Dark' ? 'dark' : 'light'}`, 'btn-hover-danger', 'btn-sm')}
        >
          <span className={cx('svg-icon svg-icon-md', theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white')}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Trash.svg')} />
          </span>
        </a>
      )}
    </>
  )
}
