import { put, call, takeEvery } from 'redux-saga/effects'
import { getStatus } from './statusCrud'
export const actionTypes = {
  getStatusLoading: '[EngineStatus] Loading',
  getStatusRecevied: '[EngineStatus] Recevied',
  getStatusFailed: '[EngineStatus] Failed'
}

const initialStatusState = {
  status: {
    status: null,
    error: null,
    isLoading: false
  }
}

export const reducer = (state = initialStatusState, action) => {
  switch (action.type) {
    case actionTypes.getStatusLoading: {
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: true
        }
      }
    }
    case actionTypes.getStatusRecevied: {
      const { status } = action.payload
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          status,
          error: null
        }
      }
    }
    case actionTypes.getStatusFailed: {
      const { error } = action.payload
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
          status: error
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  getStatusLoading: () => ({
    type: actionTypes.getStatusLoading
  }),
  getStatusRecevied: payload => ({
    type: actionTypes.getStatusRecevied,
    payload
  }),
  getStatusFailed: payload => ({
    type: actionTypes.getStatusFailed,
    payload
  })
}

function* getStatusSaga(action) {
  try {
    const { data } = yield call(getStatus)
    yield put({ type: actionTypes.getStatusRecevied, payload: data })
  } catch (e) {
    yield put({ type: actionTypes.getStatusFailed, payload: { error: e.message } })
  }
}

export function* saga() {
  yield takeEvery(actionTypes.getStatusLoading, getStatusSaga)
}
