import React, { useEffect, useState } from 'react'
import style from './performanceReport.module.scss'
import classNames from 'classnames/bind'
import { connect } from 'react-redux'
import { actions } from './redux/performanceReportRedux'
import { useSelector } from 'react-redux'
import PerformanceReportFilter from './components/PerformanceReportFilter'
import PerformanceReportTable from './components/PerformanceReportTable'
import Spinner from '../../../../../_metronic/_partials/spinner'
import { AlertList } from 'react-bs-notifier'
import { exportMerchantTransactionReport } from '../../../reports/redux/reportsCrud'
import { useIntl } from 'react-intl'

const ALERT_TIMEOUT = 15000
const cx = classNames.bind(style)

const INIT_FILTER = { year: new Date().getFullYear(), includeSubAgents: false }

const PerformanceReport = ({
  performanceReportData,
  merchantPerformanceReportLoading,
  merchantPerformanceReportReceived
}) => {
  const intl = useIntl()
  const { agentUser } = useSelector(state => state.agentUser)
  const [alerts, setAlerts] = useState([])
  const [year, setYear] = useState(INIT_FILTER.year)
  const [includeSubAgents, setIncludeSubAgents] = useState(INIT_FILTER.includeSubAgents)

  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }
  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  let darkMode = theme === 'Dark' ? cx('dark') : cx('btn-secondary-custom')

  useEffect(() => {
    merchantPerformanceReportLoading(INIT_FILTER)

    return () => merchantPerformanceReportReceived([])
  }, [])

  useEffect(() => {
    if (performanceReportData.error != null) {
      generateAlert('danger', 'Merchant Performance Report', performanceReportData.error)
    }
  }, [performanceReportData])

  const setChildDataCallback = (year, includeSubAgents) => {
    setYear(year)
    setIncludeSubAgents(includeSubAgents)
  }

  const handleExportExcel = () => {
    exportMerchantTransactionReport(year, includeSubAgents)
      .then(response => {
        if (response.data && response.data.resultCode) {
          if (response.data.resultCode !== 0) {
            generateAlert(
              'danger',
              intl.formatMessage({ id: 'PERFORMANCE.REPORT.BUTTON.OPERATIONS' }),
              response.data.resultDescription
            )
          }
        } else {
          const base64String = response.data && response.data.response?.file
          const byteCharacters = atob(base64String)
          const byteNumbers = new Array(byteCharacters.length)
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          const blob = new Blob([byteArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const agentName = agentUser?.data?.agentDetail?.name
          const blobUrl = URL.createObjectURL(blob)
          const currentDate = new Date()
            .toISOString()
            .replace(/T/, '_')
            .replace(/\..+/, '')
            .replace(/:/g, '-')
          const nameFile = `Performance_Report_${agentName}_${currentDate}.xlsx`
          const link = document.createElement('a')
          link.href = blobUrl
          link.download = nameFile
          link.click()
          URL.revokeObjectURL(blobUrl)
        }
      })
      .catch(error =>
        generateAlert('danger', intl.formatMessage({ id: 'PERFORMANCE.REPORT.BUTTON.OPERATIONS' }), error.toString())
      )
  }

  return (
    <>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>
      <div className={cx('performance-report')}>
        <div className={cx('filter-wrapper', 'wrapper-element')}>
          <p className="page-title">Performance Report</p>
          <PerformanceReportFilter
            cx={cx}
            initFilterData={INIT_FILTER}
            darkMode={darkMode}
            merchantPerformanceReportLoading={merchantPerformanceReportLoading}
            isLoading={performanceReportData.isLoading}
            onDataGetYearAndIncludeSubAgents={setChildDataCallback}
          />
        </div>
        <div className={cx('table-wrapper', 'wrapper-element')}>
          {performanceReportData.isLoading ? (
            <Spinner wrapperClassName={cx('text-align-center')} size={25} />
          ) : (
            <>
              <p className={cx('title')}>Voucher Redeemed</p>
              <div className={cx('transaction-table-button')}>
                <div className={cx('function-button-item')}>
                  <button type="button" className={cx('operations-button', 'btn', 'btn-primary', 'btn-primary-custom')}>
                    <span className={cx('action-button-title')}>
                      {' '}
                      {intl.formatMessage({ id: 'PERFORMANCE.REPORT.BUTTON.OPERATIONS' })}
                    </span>
                    <span className={cx('caret')}></span>
                  </button>
                  <div className={cx('action-wrap')}>
                    <div className={cx('action-list')}>
                      <ul className="list-button-content">
                        <li className="list-export-button" key={'2'}>
                          <button
                            type="button"
                            className={cx('btn', 'btn-block', 'btn-item')}
                            onClick={() => handleExportExcel()}
                          >
                            {intl.formatMessage({ id: 'PERFORMANCE.REPORT.DOWNLOAD.TITLE' })}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.vouchersRedeemed}
                formatter={{ enable: false }}
              />
              <p className={cx('title')}>Gross Value</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.grossValue}
                formatter={{ enable: true, percentage: false }}
              />
              <p className={cx('title')}>Net Merchant Value</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.netMerchantValue}
                formatter={{ enable: true, percentage: false }}
              />
              <p className={cx('title')}>Gross Flex Margin</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.grossFlexMargin}
                formatter={{ enable: true, percentage: false }}
              />
              <p className={cx('title')}>Gross Flex Percentage</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.grossFlexPercentage}
                formatter={{ enable: true, percentage: true }}
              />
              <p className={cx('title')}>COGS</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.cogs}
                formatter={{ enable: true, percentage: false }}
              />
              <p className={cx('title')}>Net Flex Margin</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.netFlexMargin}
                formatter={{ enable: true, percentage: false }}
              />
              <p className={cx('title')}>Net Flex Percentage</p>
              <PerformanceReportTable
                cx={cx}
                data={performanceReportData?.data?.data?.netFlexPercentage}
                formatter={{ enable: true, percentage: true }}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  performanceReportData: state.performanceReport?.performanceReport,
  entitiesData: state.performanceReport?.entitiesData
})

export default connect(mapStateToProps, actions)(PerformanceReport)
