import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import UserManagementTable from './components/user-management-table/UserManagementTable'
import { CardBody } from '../../../_metronic/_partials/controls'
import style from './userManagement.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import CreateUserModal from './components/create-user-modal/CreateUserModal'
import UserDetailModal from './components/user-detail-modal/UserDetailModal'
import { connect } from 'react-redux'
import { actions } from './redux/userManagementRedux'
import { AlertList } from 'react-bs-notifier'
import { checkHasPermission } from '../_helper/MenuHelper'
import DeleteUserModal from './components/delete-user-modal/DeleteUserModal'
const ALERT_TIMEOUT = 15000

const UserManagement = ({ userManagement, getUserManagementLoading, getUserDetailsLoading }) => {
  const INIT_FILTER = {
    orderBy: 'username',
    orderType: 'asc',
    pageSize: 10,
    pageIndex: 1
  }
  const [searchFilters, setSearchFilters] = useState(INIT_FILTER)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState('')
  const [requestDeleteUser, setRequestDeleteUser] = useState('')
  const [alerts, setAlerts] = useState([])
  const { user } = useSelector(state => state.auth)

  useEffect(() => {
    getUserManagementLoading(searchFilters)
  }, [searchFilters])

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const reloadData = () => {
    getUserManagementLoading({ ...searchFilters })
  }

  const handleFiltersChange = filters => {
    setSearchFilters({ ...filters })
  }

  const handleCloseModal = () => {
    setIsOpenCreateModal(false)
  }
  const handleCloseEditModal = () => {
    setIsOpenEditModal('')
  }
  const openEditUserModal = id => {
    getUserDetailsLoading(id)
    setIsOpenEditModal(id)
  }

  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }
  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const handleOpenDeleteModal = payload => {
    setRequestDeleteUser(payload)
  }

  const handleCloseDeleteModal = () => {
    setRequestDeleteUser('')
  }

  return (
    <>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>
      <div className={cx('wrapper-element', 'user-list-wrapper')}>
        <CardBody>
          <UserManagementTable
            filters={searchFilters}
            cx={cx}
            updateFilters={handleFiltersChange}
            theme={theme}
            userManagementData={userManagement?.userList}
            setIsOpenCreateModal={setIsOpenCreateModal}
            setIsOpenEditModal={setIsOpenEditModal}
            openEditUserModal={openEditUserModal}
            isOpenEditModal={isOpenEditModal}
            isUserDetailsLoading={userManagement?.userDetails.isLoading}
            handleOpenDeleteModal={handleOpenDeleteModal}
            hasPermissionToDelete={checkHasPermission(user.acls, ['SCP.TTI.ADMIN.USER_D'])}
          />
        </CardBody>
        <CreateUserModal
          generateAlert={generateAlert}
          getUserManagementLoading={getUserManagementLoading}
          searchFilters={searchFilters}
          handleCloseModal={handleCloseModal}
          isOpenModal={isOpenCreateModal}
          cx={cx}
          theme={theme}
        />
        {!userManagement.userDetails.isLoading && (
          <UserDetailModal
            generateAlert={generateAlert}
            getUserManagementLoading={getUserManagementLoading}
            searchFilters={searchFilters}
            userDetailsData={userManagement?.userDetails}
            handleCloseModal={handleCloseEditModal}
            isOpenModal={Boolean(isOpenEditModal)}
            cx={cx}
            theme={theme}
          />
        )}
        <DeleteUserModal
          requestDeleteUser={requestDeleteUser}
          onCloseModal={handleCloseDeleteModal}
          generateAlert={generateAlert}
          reloadData={reloadData}
          theme={theme}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  userManagement: state.userManagement
})
export default connect(mapStateToProps, actions)(UserManagement)
