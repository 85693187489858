import React, { memo, useCallback, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

import { injectIntl } from 'react-intl'
const DeleteCreditLimitTemplateModal = injectIntl(
  ({
    intl,
    onOpen,
    isOpen,
    data,
    onDeleteCreditLimitTemplate,
    deleteCreditLimitTemplateData,
    generateAlert,
    creditLimitTemplatesLoading,
    deleteCreditLimitTemplateReceived,
    onReloadAgentUserInfo
  }) => {
    const handleDeleteLimitTemplate = () => {
      if (data && data.templateCode) {
        onDeleteCreditLimitTemplate(data.templateCode)
      }
    }

    useEffect(() => {
      if (
        deleteCreditLimitTemplateData &&
        deleteCreditLimitTemplateData.data &&
        !deleteCreditLimitTemplateData.isLoading
      ) {
        onOpen({ isOpen: false, data: null })
        generateAlert(
          'success',
          intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.DELETE.TITLE' }),
          intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.DELETE.MESSAGE_SUCCESS' })
        )
        creditLimitTemplatesLoading()
        onReloadAgentUserInfo()
        deleteCreditLimitTemplateReceived(null)
      }
    }, [deleteCreditLimitTemplateData])

    return (
      <>
        <Modal show={isOpen} centered aria-labelledby="modal-dialog modal-dialog-centered">
          <Modal.Header closeButton>
            <Modal.Title id="modal-dialog">{intl.formatMessage({ id: 'CREDIT.LIMIT.MODAL.DELETE.TITLE' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {intl.formatMessage(
              { id: 'CREDIT.LIMIT.MODAL.DELETE.MESSAGE' },
              { templateName: <b>{data?.templateName}</b> }
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={deleteCreditLimitTemplateData.isLoading}
              onClick={() => handleDeleteLimitTemplate()}
              variant="primary"
            >
              {deleteCreditLimitTemplateData.isLoading ? (
                <div>
                  <Spinner size="sm" animation="border" role="status"></Spinner>{' '}
                  <span>{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
                </div>
              ) : (
                <> {intl.formatMessage({ id: 'LABEL.OK' })}</>
              )}
            </Button>
            <Button onClick={() => onOpen({ isOpen: false, data: null })} variant="secondary">
              {intl.formatMessage({ id: 'LABEL.CANCEL' })}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
)

export default memo(DeleteCreditLimitTemplateModal)
