import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert } from 'react-bootstrap'
import ContactUsForm from './components/ContactUsForm'
import ResourcePanel from './components/ResourcePanel'
import { actions } from './redux/ContactUsRedux'
import style from './contact-us.module.scss'
import classNames from 'classnames/bind'
import { useEffect } from 'react'
const cx = classNames.bind(style)

export function ContactUs() {
  const dispatch = useDispatch()
  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))
  const { resource } = useSelector(state => state.contactUs)
  const { user } = useSelector(state => state.auth)
  const [docActiveData, setDocActiveData] = useState([])
  useEffect(() => {
    if (resource.data !== null) {
      const data = resource.data
      let docActiveDataTemp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].active === 'T') {
          docActiveDataTemp.push(data[i])
          break
        }
      }
      setDocActiveData(docActiveDataTemp)
    }
  }, [resource.data])

  const [alert, setAlert] = useState('')
  const [resultCode, setResultCode] = useState(0)

  useEffect(() => {
    if (!resource.data?.length) {
      dispatch(actions.resourceLoading())
    }
  }, [])

  const handleDismiss = () => {
    handleShowAlert('', 0)
  }

  const handleShowAlert = (message, resultCode) => {
    setAlert(message)
    setResultCode(resultCode)
  }

  return (
    <div className={`${cx('wrapper-element')} ${cx('contact-us-wrapper')}`}>
      <div className={cx('title', 'page-title')}>Contact Us</div>
      <div className={`row ${cx('content')}`}>
        {alert && (
          <div className={`col-12 ${cx('alert')}`}>
            <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
              {alert}
            </Alert>
          </div>
        )}
        <div className={`col-12 col-md-7 ${cx('form-wrapper')}`}>
          <ContactUsForm cx={cx} theme={theme} onShowAlert={handleShowAlert} email={user?.emailAddress} />
        </div>
        <div className={`col-12 col-md-5 ${cx('resource-panel-wrapper')}`}>
          <ResourcePanel cx={cx} data={docActiveData} isLoading={resource.isLoading} />
        </div>
      </div>
    </div>
  )
}
