import React, { useState, useRef, useContext } from 'react'
import { TemplateContext } from '../../../../_metronic/layout/_core/TemplateProvider'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { requestContactSupport } from '../_redux/authCrud'
import { Alert } from 'react-bootstrap'
import { BLOCKED_FILE_TYPES, ACCEPTED_FILE_TYPES } from '../../../../utils/Constants'
import { validateFileSize } from '../../../../utils/FileUploadUtils'
import style from '../auth.module.css'
import classNames from 'classnames/bind'
import { bytesFormat } from '../../../../utils/BytesFormatUtils'
import { env } from '../../../../env'
const cx = classNames.bind(style)

const initialValues = {
  email: '',
  subject: '',
  content: '',
  files: []
}
function ContactSupport(props) {
  const { intl } = props
  const [resultCode, setResultCode] = useState(0)
  const fileInput = useRef()
  const [filesArr, setFilesArr] = useState([])
  const [fileSize, setFileSize] = useState(0)
  const templateContext = useContext(TemplateContext)
  const maxFilesSize = env.REACT_APP_CONTACT_US_MAX_FILES_SIZE_IN_MB

  const ContactSupportSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      ),

    subject: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    content: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ContactSupportSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      requestContactSupport(values.email, values.subject, values.content, filesArr)
        .then(response => {
          setSubmitting(false)
          setResultCode(response?.data?.resultCode)
          if (response?.data?.resultCode === 0) {
            resetForm()
            setStatus(
              intl.formatMessage({
                id: 'AUTH.CONTACT.US.SUCCESS'
              })
            )
            setFilesArr([])
            fileInput.current.value = ''
            setFileSize(0)
          } else {
            setStatus(response?.data?.resultDescription)
          }
        })
        .catch(error => {
          setSubmitting(false)
          setResultCode(9999)
          setStatus(error?.response?.data?.resultDescription || error.message)
        })
    }
  })

  const removeElementByValue = (arr, value) => {
    var index = arr.indexOf(value)

    if (index !== -1) {
      arr.splice(index, 1)
    }
  }

  const deleteFileItem = fileName => {
    if (filesArr.length > 0) {
      filesArr.forEach(file => {
        if (file.name == fileName) {
          removeElementByValue(filesArr, file)
          setFileSize(prev => prev - file.size)
        }
      })
    }

    setFilesArr([...filesArr])

    if (filesArr.length == 0) {
      fileInput.current.value = ''
      setFileSize(0)
    }
  }

  const handleFileChange = event => {
    formik.setStatus(null)
    if (!event.target.files) {
      return
    }

    let files = Array.from(event.target.files)
    let filesSize = 0
    let listBlockedFileNames = []
    let listAcceptedFiles = []

    files.forEach(file => {
      let fileType = file.name.split('.').pop()
      if (BLOCKED_FILE_TYPES.includes(fileType)) {
        listBlockedFileNames.push(file.name)
      } else if (!filesArr.some(item => item.name === file.name)) {
        filesSize += file.size
        listAcceptedFiles.push(file)
      }
    })
    filesSize += fileSize

    if (!validateFileSize(filesSize, maxFilesSize)) {
      setResultCode(-1)
      formik.setStatus(
        intl.formatMessage(
          {
            id: 'AUTH.CONTACT.US.FILE.SIZE.EXCEEDED'
          },
          { limit: maxFilesSize }
        )
      )
      fileInput.current.value = ''
      return
    }

    if (listBlockedFileNames?.length) {
      let fileNames = listBlockedFileNames.join(', ')
      setResultCode(-1)
      formik.setStatus(
        intl.formatMessage(
          {
            id: 'AUTH.CONTACT.US.INVALID.FILE.TYPE'
          },
          { fileName: fileNames }
        )
      )
      fileInput.current.value = ''
    }

    let newList = filesArr.concat(listAcceptedFiles)
    setFileSize(filesSize)
    setFilesArr(newList)
    formik.setFieldValue('files', newList)
  }

  const handleDismiss = () => {
    formik.setStatus(null)
  }

  const handleTestHiddenFileInput = () => {
    document.getElementById('selectedFile').click()
  }

  return (
    <div className="login-form contact-us" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <Link to="/" className="flex-column-auto mt-5">
          <div className="max-h-100px logo"></div>
        </Link>
        {templateContext?.portalName ? (
          <h3 className="font-size-h1">
            <FormattedMessage id="PORTAL.NAME" values={{ name: templateContext.portalName }} />
          </h3>
        ) : (
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        )}
        <h3 className="font-size-h3">
          <FormattedMessage id="AUTH.CONTACT.SUPPORT" />
        </h3>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
      >
        {formik.status && (
          <Alert variant={resultCode === 0 ? 'success' : 'danger'} onClose={handleDismiss} dismissible>
            {formik.status}
          </Alert>
        )}
        <div className="form-group fv-plugins-icon-container">
          <div className="form-group fv-plugins-icon-container">
            <input
              disabled={formik.isSubmitting}
              placeholder="Email*"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
              name="email"
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              disabled={formik.isSubmitting}
              placeholder="Subject*"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('subject')}`}
              name="subject"
              {...formik.getFieldProps('subject')}
            />
            {formik.touched.subject && formik.errors.subject ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.subject}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <textarea
              disabled={formik.isSubmitting}
              type="content"
              placeholder="Content*"
              className={`form-control form-control-solid  py-5 ${getInputClasses('content')}`}
              name="content"
              {...formik.getFieldProps('content')}
            ></textarea>
            {formik.touched.content && formik.errors.content ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.content}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              disabled={formik.isSubmitting}
              type="file"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('file')}`}
              name="files"
              multiple="multiple"
              accept={ACCEPTED_FILE_TYPES}
              ref={fileInput}
              onChange={handleFileChange}
              id="selectedFile"
              style={{ display: 'none' }}
            />
          </div>

          <div
            className={`form-group fv-plugins-icon-container ${cx('select-files-input-fake')}`}
            onClick={handleTestHiddenFileInput}
          >
            <input type="button" value="Choose Files" style={{ padding: '2px 6px' }} />
          </div>

          {filesArr?.map(file => {
            return (
              <div
                className={`form-control form-control-solid ${cx('file-arr-wrap')}`}
                key={file.name}
                style={{ marginBottom: '16px', display: 'flex' }}
              >
                <p className={cx('file-name-uploaded')}>{file.name}</p>

                <p style={{ margin: 0 }}>({bytesFormat(file.size)})</p>

                <div onClick={() => deleteFileItem(file.name)} className={cx('close-file-icon')}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            )
          })}

          <div className="form-group fv-plugins-icon-container">
            <button
              id="kt_forgot_password_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-lg btn-primary btn-block btn-primary-custom`}
            >
              <span>Submit</span>
              {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <div className="form-group d-flex flex-wrap justify-content-around align-items-center">
            <Link
              to={formik.isSubmitting ? '#' : '/auth'}
              className="text-dark-50 text-hover-primary-custom my-3 mr-2"
              id="kt_forgot_cancel"
            >
              <FormattedMessage id="AUTH.GENERAL.CANCEL_BUTTON" />
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(ContactSupport))
