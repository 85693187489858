import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import style from '../../pinOrder.module.css'
import classNames from 'classnames/bind'
import OrderDetails from './OrderDetails'
import { FormattedMessage, injectIntl } from 'react-intl'
import { actions } from '../../redux/pinOrderRedux'
import { connect } from 'react-redux'
import OrderItems from './OrderItems'

const cx = classNames.bind(style)

const CreatePinOrderModal = ({
  intl,
  isOpenModal,
  handleCloseModal,
  productOrderList,
  agentUser,
  addPinBatchToCreateOrderList,
  productOrderListLoading,
  removePinBatchToCreateOrderList,
  clearCreateOrderList,
  orderListDetails,
  generateAlert,
  reloadData,
  theme
}) => {
  const [productList, setProductList] = useState([])

  useEffect(() => {
    productOrderListLoading()
  }, [])

  useEffect(() => {
    setProductList(productOrderList.data)
  }, [productOrderList.data])
  const { data } = agentUser
  return (
    <Modal
      show={isOpenModal}
      onHide={handleCloseModal}
      centered
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Pin Orders</Modal.Title>
        <Button onClick={handleCloseModal} variant="secondary">
          x
        </Button>
      </Modal.Header>
      <Modal.Body className={cx('modal-body')}>
        <div className={cx('create-pin-orders-body')}>
          <div className={cx('pin-orders-details', theme === 'Dark' && 'pin-orders-details-dark')}>
            <p className={cx('create-pin-orders-title')}>Request a new Pin Order</p>
            <OrderDetails
              productList={productList}
              agentName={data?.agentDetail?.name}
              intl={intl}
              addPinBatchToCreateOrderList={addPinBatchToCreateOrderList}
              theme={theme}
            />
          </div>
          <div className={cx('pin-orders-items', theme === 'Dark' && 'pin-orders-items-dark')}>
            <p className={cx('create-pin-orders-title')}>Pin Order Items</p>
            <OrderItems
              orderListDetails={orderListDetails}
              removePinBatchToCreateOrderList={removePinBatchToCreateOrderList}
              clearCreateOrderList={clearCreateOrderList}
              generateAlert={generateAlert}
              handleCloseModal={handleCloseModal}
              reloadData={reloadData}
              theme={theme}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => ({
  agentUser: state.agentUser.agentUser,
  productOrderList: state.pinBatchOrders.productOrderList,
  orderListDetails: state.pinBatchOrders.createOrderList
})

export default injectIntl(connect(mapStateToProps, actions)(CreatePinOrderModal))
