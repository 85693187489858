import axios from 'axios'
import { env } from '../../../../../env'

const BASE_URL = env.REACT_APP_API_URL
export const GET_STATUS_URL = `/api/status`
const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

export function getStatus() {
  return instance.get(GET_STATUS_URL)
}
