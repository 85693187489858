import axios from 'axios'
import { env } from '../env'
const BASE_URL = env.REACT_APP_API_URL
const REQUEST_TIMEOUT = env.REACT_APP_REQUEST_TIMEOUT_IN_MILLISECONDS

const STATUS_CODE_LIST = [200, 400, 403, 500]
const merchantApiClient = axios.create({
  baseURL: BASE_URL,
  timeout: REQUEST_TIMEOUT,
  validateStatus: function(status) {
    return STATUS_CODE_LIST.includes(status)
  }
})

export default merchantApiClient
