import React from 'react'
import style from '../companyDetail.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
const PhysicalAddress = ({ formik, isEdit, stateListData, countryListData, theme }) => {
  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        <ul className={cx('wrapper')}>
          <li>
            <label>Email</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Email"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="email"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          </li>
          <li>
            <label>Contact Name</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Contact Name"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="contactName"
                {...formik.getFieldProps('contactName')}
              />
            </div>
          </li>
          <li>
            <label>Address Line 1</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Address Line 1"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="addressLine1"
                {...formik.getFieldProps('addressLine1')}
              />
            </div>
          </li>
          <li>
            <label>Address Line 2</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Address Line 2"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="addressLine2"
                {...formik.getFieldProps('addressLine2')}
              />
            </div>
          </li>
          <li>
            <label>Phone Number</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Phone Number"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="phoneNumber"
                {...formik.getFieldProps('phoneNumber')}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.phoneNumber}</div>
                </div>
              ) : null}
            </div>
          </li>
          <li>
            <label>Mobile Number</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Mobile Number"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="mobileNumber"
                {...formik.getFieldProps('mobileNumber')}
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.mobileNumber}</div>
                </div>
              ) : null}
            </div>
          </li>
          <li>
            <label>Toll Free Phone</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Toll Free Phone"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="tollFreePhone"
                {...formik.getFieldProps('tollFreePhone')}
              />
            </div>
          </li>
          <li>
            <label>Fax Number</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Fax Number"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="faxNumber"
                {...formik.getFieldProps('faxNumber')}
              />
            </div>
          </li>
          <li>
            <label>Toll Free Fax</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Toll Free Fax"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="tollFreeFax"
                {...formik.getFieldProps('tollFreeFax')}
              />
            </div>
          </li>
          {countryListData?.length > 0 && (
            <li>
              <label>Country</label>
              <div className={cx('input-group')}>
                <select
                  value={formik.values.countryCode}
                  onChange={formik.handleChange}
                  disabled
                  className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                  name="countryCode"
                >
                  {countryListData?.map(item => {
                    return (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </li>
          )}
          {stateListData?.length > 0 && (
            <li>
              <label>State/Province</label>
              <div className={cx('input-group')}>
                <select
                  value={formik.values.stateCode}
                  onChange={formik.handleChange}
                  disabled
                  className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                  name="stateCode"
                >
                  {stateListData?.map(item => {
                    return (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </li>
          )}
          <li>
            <label>City</label>
            <div className={cx('input-group')}>
              <input
                placeholder="City"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="city"
                {...formik.getFieldProps('city')}
              />
            </div>
          </li>
          <li>
            <label>Zip/Post Code</label>
            <div className={cx('input-group')}>
              <input
                placeholder="Zip/Post Code"
                disabled={!isEdit}
                type="text"
                className={cx('form-control', 'py-2', 'px-1', theme === 'Dark' && 'form-control-dark')}
                name="zipCode"
                {...formik.getFieldProps('zipCode')}
              />
            </div>
          </li>
        </ul>
      </form>
    </>
  )
}

export default PhysicalAddress
