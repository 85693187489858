import React, { useState } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { injectIntl } from 'react-intl'
import { actions } from '../redux/VoucherProductRedux'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { useEffect } from 'react'

function VoucherValidateForm({ cx, theme, intl, onShowAlert, voucherValidateLoading, voucherValidated }) {
  let darkMode = theme === 'Dark' && cx('dark')
  const [isSubmitValidate, setIsSubmitValidate] = useState(false)
  const initialValues = {
    pin: ''
  }

  const VoucherValidateSchema = Yup.object().shape({
    pin: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(/^[0-9]+$/, 'Voucher Pin must be a number')
  })

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: VoucherValidateSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setStatus('')
      voucherValidateLoading(values.pin)
      setStatus(voucherValidated?.data?.resultDescription)
      setSubmitting(false)
      setIsSubmitValidate(true)
    }
  })

  useEffect(() => {
    if (isSubmitValidate) {
      onShowAlert(voucherValidated?.data?.resultDescription, voucherValidated?.data?.resultCode)
    }
  }, [voucherValidated])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Form.Group as={Form.Row} controlId="pinText">
        <Form.Label column sm="2" className={darkMode}>
          Pin:
        </Form.Label>
        <Col sm="10">
          <Form.Control
            disabled={formik.isSubmitting}
            size="lg"
            type="text"
            placeholder="Pin"
            className={getInputClasses('pin')}
            name="pin"
            {...formik.getFieldProps('pin')}
          />
          {formik.touched.pin && formik.errors.pin && (
            <Form.Control.Feedback type="invalid">{formik.errors.pin}</Form.Control.Feedback>
          )}
          <span className={cx('required-symbol')}>*</span>
        </Col>
      </Form.Group>

      <div className={cx('actions')}>
        <div>
          <button
            type="submit"
            disabled={voucherValidated?.isLoading}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'action-btn', 'btn-primary-custom')}
          >
            Validate
            {voucherValidated?.isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </div>
    </form>
  )
}

export default injectIntl(connect(null, actions)(VoucherValidateForm))
