import clsx from 'clsx'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { currencyFormat } from '../../../../utils/DataFormatUtils'

const AvailablePayoutBalances = ({ maxBalance, otherBalances, theme, payoutVoucherCreditLimit }) => {
  return (
    <>
      {(!maxBalance || maxBalance.balance == 0) && (otherBalances || otherBalances.length == 0) ? (
        <div
          style={{
            marginTop: '16px',
            fontSize: '16px',
            color: theme == 'Light' ? 'var(--text-color-light)' : 'var(--text-color-dark)'
          }}
        >
          {payoutVoucherCreditLimit
            ? 'Credit Limit: ' + currencyFormat(payoutVoucherCreditLimit)
            : currencyFormat(payoutVoucherCreditLimit)}
        </div>
      ) : (
        <Dropdown drop="down">
          <Dropdown.Toggle
            variant="outline-light"
            className="btn "
            id="dropdown-toggle-accumulated-balance"
            style={{ border: '0px' }}
          >
            <div
              className="btn mr-1"
              style={{
                fontSize: '16px',
                color: theme == 'Light' ? 'var(--text-color-light)' : 'var(--text-color-dark)'
              }}
            >
              {maxBalance &&
                maxBalance.balance > 0 &&
                maxBalance.currencyCode + ' ' + maxBalance.symbol + currencyFormat(maxBalance.balance)}
            </div>
          </Dropdown.Toggle>
          {otherBalances.length > 0 && (
            <Dropdown.Menu
              className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
              style={{ width: '100%', border: '0px' }}
            >
              <ul className="navi navi-hover py-4 menu-dark ">
                {otherBalances &&
                  otherBalances.map(balance => {
                    return (
                      <li key={balance.currencyCode} className={clsx('navi-item')}>
                        <div
                          className="btn mr-1 cursor-alias"
                          style={{
                            color: theme == 'Light' ? 'var(--text-color-light)' : 'var(--text-color-dark)',
                            fontSize: '16px',
                            textAlign: 'center',
                            width: '100%'
                          }}
                        >
                          {balance.currencyCode + ' ' + balance.symbol + currencyFormat(balance.balance)}
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </Dropdown.Menu>
          )}
        </Dropdown>
      )}
    </>
  )
}

export default AvailablePayoutBalances
