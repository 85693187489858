import { IS_ENABLE_PAYOUT_KEY } from '../../../utils/Constants'

export function checkHasPermission(userRoles, menuRoles) {
  if (typeof menuRoles === 'string') {
    menuRoles = [menuRoles]
  }

  if (userRoles) {
    for (var i = 0; i < menuRoles.length; i++) {
      if (userRoles.includes(menuRoles[i])) {
        return true
      }
    }
  }
  return false
}

export function checkExtensionRole(agentUser, role, isRoleMenu) {
  if (agentUser?.isLoading) {
    if (isRoleMenu) {
      return false
    }
    return true
  } else {
    switch (role) {
      case IS_ENABLE_PAYOUT_KEY: {
        return agentUser.data?.agentDetail?.isEnablePayout === 'true'
      }
      default:
        return false
    }
  }
}
