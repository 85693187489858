import React from 'react'
import Table from 'react-bootstrap/Table'
import { currencyFormat, formatValueWithPrefixAndSuffix } from '../../../../../../utils/DataFormatUtils'

const PerformanceReportTable = ({ cx, data, formatter }) => {
  const columnNames = [
    'Currency',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const generateRowData = currency => {
    return (
      <tr>
        <td>{data[currency]?.label}</td>
        {data[currency]?.data &&
          data[currency].data.map((item, index) => {
            let value = item.value
            if (formatter?.enable) {
              if (formatter.percentage) {
                value = formatValueWithPrefixAndSuffix('', value, '%')
              } else {
                value = currencyFormat(value)
              }
            }
            return <td key={index}>{value}</td>
          })}
      </tr>
    )
  }

  return (
    <div>
      <Table striped responsive>
        <thead>
          <tr>
            {columnNames.map((item, index) => {
              return <th key={index}>{item}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {data && generateRowData(data?.aud?.label.toLowerCase())}
          {data && generateRowData(data?.cad?.label.toLowerCase())}
          {data && generateRowData(data?.eur?.label.toLowerCase())}
          {data && generateRowData(data?.gbp?.label.toLowerCase())}
          {data && generateRowData(data?.usd?.label.toLowerCase())}
          {data && generateRowData(data?.nzd?.label.toLowerCase())}
        </tbody>
      </Table>
    </div>
  )
}

export default PerformanceReportTable
