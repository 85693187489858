import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import GeneralDetails from './GeneralDetails'
import { connect } from 'react-redux'
import PhysicalAddress from './PhysicalAddress'
import style from '../companyDetail.module.css'
import { actions } from '../redux/companyDetailsRedux'
import classNames from 'classnames/bind'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { updateAgent, createAgent } from '../redux/companyDetailsCrud'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
const cx = classNames.bind(style)

const ModalDetails = ({
  intl,
  isOpenModal,
  onClick,
  generalDetails,
  physicalAddress,
  agentInfoClear,
  agentUser,
  countryList,
  stateList,
  stateListLoading,
  timezoneList,
  userAcls,
  isHiddenAddDivision,
  isHiddenAddRetailer,
  generateAlert,
  companyStructureLoading,
  theme,
  agentReportInfo,
  agentReportLoading,
  agentReportData
}) => {
  const PERMISSION_TO_EDIT = 'SCP.TTI.ADMIN.USER_W'
  const [isEdit, setIsEdit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isAddDivision, setIsAddDivision] = useState(false)
  const [isAddRetailer, setIsAddRetailer] = useState(false)
  const [stateListData, setStateListData] = useState([])
  const [timezoneListData, setTimezoneListData] = useState([])
  const [countryListData, setCountryListData] = useState([])
  const [userAclsData, setUserAclsData] = useState()
  const [rightToEdit, setRightToEdit] = useState()
  const history = useHistory()

  let createdDate = moment(generalDetails?.createdDate).format('YYYY-MM-DD')
  let today = moment().format('YYYY-MM-DD')

  const initialValueGeneral = {
    level: generalDetails?.level ?? '',
    countryCode: generalDetails?.countryCode ?? '',
    agentCode: generalDetails?.agentCode ?? '',
    stateCode: generalDetails?.stateCode ?? '',
    name: generalDetails?.name ?? '',
    timezone: generalDetails?.timezone ?? '',
    creationDate: createdDate ?? '',
    status: generalDetails?.status ?? '',
    tax: generalDetails?.tax ?? '',
    agentType: generalDetails?.type ?? '',
    parentCode: generalDetails?.parentCode ?? ''
  }

  const initialValuePhysicalAddress = {
    email: physicalAddress?.email ?? '',
    contactName: physicalAddress?.contactName ?? '',
    addressLine1: physicalAddress?.addressLine1 ?? '',
    addressLine2: physicalAddress?.addressLine2 ?? '',
    phoneNumber: physicalAddress?.phoneNumber ?? '',
    mobileNumber: physicalAddress?.mobileNumber ?? '',
    tollFreePhone: physicalAddress?.tollFreePhone ?? '',
    faxNumber: physicalAddress?.faxNumber ?? '',
    tollFreeFax: physicalAddress?.tollFreeFax ?? '',
    countryCode: physicalAddress?.countryCode ?? '',
    stateCode: physicalAddress?.stateCode ?? '',
    city: physicalAddress?.city ?? '',
    zipCode: physicalAddress?.zipCode ?? ''
  }

  const initialValueCreateGeneralDetails = {
    level: '',
    countryCode: generalDetails?.countryCode ?? '',
    agentCode: '',
    stateCode: generalDetails?.stateCode ?? '',
    name: '',
    timezone: generalDetails?.timezone ?? '',
    creationDate: today,
    status: 'Active',
    tax: generalDetails?.tax ?? '',
    agentType: 'Prepaid',
    parentCode: generalDetails?.agentCode ?? ''
  }

  const initialValueCreatePhysicalAddress = {
    email: '',
    contactName: '',
    addressLine1: '',
    addressLine2: '',
    phoneNumber: '',
    mobileNumber: '',
    tollFreePhone: '',
    faxNumber: '',
    tollFreeFax: '',
    countryCode: generalDetails?.countryCode ?? '',
    stateCode: generalDetails?.stateCode ?? '',
    city: '',
    zipCode: ''
  }

  const handleCloseModal = () => {
    onClick()
    agentInfoClear()
    formikGeneralDetail.setValues(null)
    formikPhysicalAddress.setValues(null)
  }

  const handleCancel = () => {
    formikGeneralDetail.setValues(initialValueGeneral)
    formikPhysicalAddress.setValues(initialValuePhysicalAddress)
    if (isEdit) {
      setIsEdit(false)
    }
    if (isAddDivision) {
      setIsAddDivision(false)
    }
    if (isAddRetailer) {
      setIsAddRetailer(false)
    }
  }

  const handleClickEdit = () => {
    setIsEdit(true)
  }

  const handleClickAddDivision = () => {
    formikGeneralDetail.setValues({
      ...initialValueCreateGeneralDetails,
      level: 'SD'
    })
    formikPhysicalAddress.setValues(initialValueCreatePhysicalAddress)
    setIsAddDivision(!isAddDivision)
  }

  const handleClickAddRetailer = () => {
    formikGeneralDetail.setValues({
      ...initialValueCreateGeneralDetails,
      level: 'R'
    })
    formikPhysicalAddress.setValues(initialValueCreatePhysicalAddress)
    setIsAddRetailer(!isAddRetailer)
  }

  const handleClickReport = () => {
    agentReportLoading(agentReportInfo)
  }

  useEffect(() => {
    if (agentReportData.data != null) {
      history.push('/reports')
    }
  }, [agentReportData])

  const physicalAddressSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format'),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^(\+|\d)[0-9]{3,20}$/, intl.formatMessage({ id: 'INVALID.PHONE.NUMBER' })),
    mobileNumber: Yup.string()
      .trim()
      .matches(/^(\+|\d)[0-9]{3,20}$/, intl.formatMessage({ id: 'INVALID.PHONE.NUMBER' }))
  })

  const generalDetailsSchema = Yup.object().shape({
    agentCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    name: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const formikPhysicalAddress = useFormik({
    initialValues: initialValuePhysicalAddress,
    validationSchema: physicalAddressSchema,
    onSubmit: () => {}
  })

  const formikGeneralDetail = useFormik({
    initialValues: initialValueGeneral,
    validationSchema: generalDetailsSchema,
    onSubmit: () => {}
  })

  useEffect(() => {
    if (formikGeneralDetail?.values?.countryCode) {
      formikPhysicalAddress.setFieldValue('countryCode', formikGeneralDetail.values.countryCode)
      stateListLoading(formikGeneralDetail.values.countryCode)
    }
  }, [formikGeneralDetail.values.countryCode])

  useEffect(() => {
    setUserAclsData(userAcls.acls)
  }, [userAcls])

  useEffect(() => {
    formikPhysicalAddress.setFieldValue('stateCode', formikGeneralDetail.values.stateCode)
  }, [formikGeneralDetail.values.stateCode])

  useEffect(() => {
    if (stateList?.data) {
      if (stateList?.data.length > 0) {
        formikGeneralDetail.setFieldValue('stateCode', stateList?.data[0].code)
      } else {
        formikGeneralDetail.setFieldValue('stateCode', '')
      }
      setStateListData(stateList?.data)
    }
  }, [stateList.data])

  useEffect(() => {
    setTimezoneListData(timezoneList?.data)
  }, [timezoneList])

  useEffect(() => {
    setCountryListData(countryList?.data)
  }, [countryList])

  useEffect(() => {
    const edit = checkPermissionToEdit()
    setRightToEdit(edit)
  }, [userAclsData])

  const handleSubmit = () => {
    formikGeneralDetail.submitForm()
    formikPhysicalAddress.submitForm()
    if (!formikGeneralDetail.isValid || !formikPhysicalAddress.isValid) return

    let details = formikGeneralDetail.values
    let physicalAddress = formikPhysicalAddress.values
    setIsSaving(true)
    if (isEdit) {
      updateAgent(details, physicalAddress)
        .then(response => {
          setIsSaving(false)
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', 'Edit Agent Details', resultDescription)
            setIsEdit(false)
          } else {
            generateAlert('danger', 'Edit Agent Details', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', 'Edit Agent Details', error.error)
          setIsSaving(false)
        })
    } else {
      createAgent(details, physicalAddress)
        .then(response => {
          setIsSaving(false)
          const { resultCode, resultDescription } = response.data
          if (resultCode === 0) {
            generateAlert('success', isAddDivision ? 'Add Sub-Division' : 'Add Retailer', resultDescription)
            handleCloseModal()
            companyStructureLoading()
            setIsEdit(false)
          } else {
            generateAlert('danger', isAddDivision ? 'Add Sub-Division' : 'Add Retailer', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', isAddDivision ? 'Add Sub-Division' : 'Add Retailer', error.error)
          setIsSaving(false)
        })
    }
  }
  const checkPermissionToEdit = () => {
    if (userAclsData) {
      const edit = userAclsData?.some(item => {
        return item === PERMISSION_TO_EDIT
      })
      return edit
    }
  }

  return (
    <Modal
      show={isOpenModal}
      onHide={handleCloseModal}
      centered
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className={cx(theme === 'Dark' && 'modal-header-dark')}>
        <Modal.Title className={cx(theme === 'Dark' && 'modal-header-title-dark')} id="contained-modal-title-vcenter">
          {isEdit
            ? 'Edit Agent Details'
            : isAddDivision
            ? 'Add Sub Division'
            : isAddRetailer
            ? 'Add Retailer'
            : 'Agent Details'}{' '}
        </Modal.Title>
        <Button onClick={handleCloseModal} variant="secondary">
          x
        </Button>
      </Modal.Header>
      <Modal.Body className={cx(theme === 'Dark' && 'modal-body-dark')}>
        <div className={cx('company-details-information')}>
          <div className={cx('infomation-item', theme === 'Dark' && 'infomation-item-dark')}>
            <p className={cx('title')}>General Details</p>
            {agentUser?.isLoading ? (
              <span className="ml-1 spinner spinner-black"></span>
            ) : !generalDetails ? (
              <span className="ml-1 spinner spinner-black"></span>
            ) : (
              <GeneralDetails
                isEdit={isEdit}
                isAdd={isAddDivision || isAddRetailer}
                formik={formikGeneralDetail}
                countryListData={countryListData}
                stateListData={stateListData}
                timezoneListData={timezoneListData}
                theme={theme}
              />
            )}
          </div>
          <div className={cx('infomation-item', theme === 'Dark' && 'infomation-item-dark')}>
            <p className={cx('title')}>Physical Address</p>
            {agentUser?.isLoading ? (
              <span className="ml-1 spinner spinner-black"></span>
            ) : !physicalAddress ? (
              <span className="ml-1 spinner spinner-black"></span>
            ) : (
              <PhysicalAddress
                formik={formikPhysicalAddress}
                isEdit={isEdit || isAddDivision || isAddRetailer}
                stateListData={stateListData}
                countryListData={countryListData}
                theme={theme}
              />
            )}
          </div>
        </div>
      </Modal.Body>

      <div className={cx('modal-footer', theme === 'Dark' && 'modal-footer-dark')}>
        <div className={cx('modal-footer-actions')}>
          {isEdit || isAddDivision || isAddRetailer ? (
            <button
              onClick={handleCancel}
              style={{ marginRight: '5px' }}
              className={cx(
                'button',
                'button-block',
                'btn',
                'btn-primary',
                'button-cancel',
                'btn-lg',
                'btn-primary-custom'
              )}
            >
              <span>Cancel</span>
            </button>
          ) : (
            <></>
          )}
        </div>
        {isEdit || isAddDivision || isAddRetailer ? (
          <div className={cx('modal-footer-actions')}>
            <button
              type="submit"
              onClick={handleSubmit}
              className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
            >
              <span>Save Changes</span>
              {isSaving && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        ) : (
          <div className={cx('modal-footer-actions')}>
            <button
              disabled={!rightToEdit}
              onClick={handleClickEdit}
              style={{ marginRight: '5px' }}
              className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
            >
              Edit
            </button>
            {isHiddenAddDivision && (
              <button
                onClick={handleClickAddDivision}
                disabled
                style={{ marginRight: '5px' }}
                className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
              >
                Add Sub Division
              </button>
            )}
            {isHiddenAddRetailer && (
              <button
                onClick={handleClickAddRetailer}
                disabled
                style={{ marginRight: '5px' }}
                className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
              >
                Add Retailer
              </button>
            )}
            <button
              onClick={handleClickReport}
              style={{ marginRight: '5px' }}
              className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
            >
              Report
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  stateList: state.companyDetails.stateList,
  countryList: state.companyDetails.countryList,
  timezoneList: state.companyDetails.timezoneList,
  agentReportData: state.companyDetails.agentReportData,
  userAcls: state.auth.user
})

export default injectIntl(connect(mapStateToProps, actions)(ModalDetails))
