import React, { useEffect, useState } from 'react'
import PinOrderFilter from './components/PinOrderFilter'
import PinOrderTable from './components/pin-order-table/PinOrderTable'
import { Card, CardBody } from '../../../_metronic/_partials/controls'
import style from './pinOrder.module.css'
import classNames from 'classnames/bind'
const cx = classNames.bind(style)
import { actions } from './redux/pinOrderRedux'
import { connect, useSelector } from 'react-redux'
import { AlertList } from 'react-bs-notifier'
import PinOrderDetailsDialog from './components/pin-order-details-dialog/PinOrderDetailsDialog'
import CreatePinOrderModal from './components/create-pin-orders-modal/CreatePinOrderModal'
import DeletePinOrderModal from './components/delete-pin-order-modal/DeletePinOrderModal'

const ALERT_TIMEOUT = 15000
const DEFAULT_SORT = { dataField: 'orderRef', order: 'desc' }

const PinOrder = ({ pinBatchOrders, pinBatchOrderListLoading, pinBatchOrderDetailsLoading }) => {
  const INIT_FILTER = {
    searchText: '',
    start: '',
    end: '',
    pageSize: 10,
    pageIndex: 1,
    orderBy: 'orderRef',
    orderType: 'desc'
  }

  const [searchFilters, setSearchFilters] = useState(INIT_FILTER)
  const [isPaging, setIsPaging] = useState(false)
  const [isClear, setIsClear] = useState(false)
  const [open, setOpen] = useState(false)
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false)
  const [pinOrderModalOpen, setPinOrderModalOpen] = useState(false)
  const [deletePinOrderId, setDeletePinOrderId] = useState('')
  const [sortData, setSortData] = useState(DEFAULT_SORT)
  const onChange = ranges => {
    if (ranges.startDate === '' && ranges.endDate === '') {
      setOpen(false)
    }
  }

  const handleFiltersChange = filters => {
    setSearchFilters({ ...filters })
  }

  const reloadData = () => {
    pinBatchOrderListLoading({ ...searchFilters })
  }

  const resetSortData = () => {
    setSortData({ ...DEFAULT_SORT })
  }

  useEffect(() => {
    if (isPaging) {
      setIsPaging(false)
    }
  }, [isPaging])

  useEffect(() => {
    if (isPaging) {
      pinBatchOrderListLoading(searchFilters)
      setSearchFilters({ ...searchFilters })
    }
  }, [isPaging])

  useEffect(() => {
    if (isOpenModalDetails || pinOrderModalOpen) {
      document.body.style.cssText = 'overflow: hidden !important'
    } else {
      document.body.style.cssText = 'overflow: unset !important'
    }
  }, [isOpenModalDetails, pinOrderModalOpen])

  useEffect(() => {
    if (isClear) {
      pinBatchOrderListLoading({ ...searchFilters })
      setIsClear(false)
    }
  }, [isClear])

  const [alerts, setAlerts] = useState([])
  const generateAlert = (type, header, message) => {
    setAlerts(alerts => [
      ...alerts,
      {
        id: new Date().getTime(),
        type: type,
        headline: header,
        message: message,
        showIcon: false
      }
    ])
  }

  const onDismissed = alert => {
    setAlerts(alerts => {
      const idx = alerts.indexOf(alert)
      if (idx < 0) return alerts
      return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
    })
  }

  const openPinOrderDetailsDialog = orderRef => {
    setIsOpenModalDetails(true)
    pinBatchOrderDetailsLoading({ orderRef })
  }
  const handleCloseModal = () => {
    setPinOrderModalOpen(false)
  }

  const handleOpenDeleteModal = id => {
    setDeletePinOrderId(id)
  }
  const handleCloseDeleteModal = () => {
    setDeletePinOrderId('')
  }

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  return (
    <div>
      <div>
        <AlertList
          position={'top-right'}
          alerts={alerts}
          timeout={ALERT_TIMEOUT}
          dismissTitle="Begone!"
          onDismiss={onDismissed}
        />
      </div>
      <div className={cx('wrapper-element', 'pin-order-filter-content')}>
        <p className="page-title">Pin Orders</p>
        <PinOrderFilter
          searchFilters={searchFilters}
          handleSubmit={handleFiltersChange}
          setSearchFilters={setSearchFilters}
          onChange={onChange}
          open={open}
          setOpen={setOpen}
          pinBatchOrderListLoading={pinBatchOrderListLoading}
          isLoading={pinBatchOrders?.pinBatchOrders?.isLoading}
          setIsClear={setIsClear}
          resetSortData={resetSortData}
          theme={theme}
        />
      </div>

      <div className={cx('wrapper-element')}>
        <CardBody>
          <PinOrderTable
            setPinOrderModalOpen={setPinOrderModalOpen}
            filters={searchFilters}
            updateFilters={handleFiltersChange}
            setIsPaging={setIsPaging}
            pinBatchOrdersData={pinBatchOrders?.pinBatchOrders}
            generateAlert={generateAlert}
            openPinOrderDetailsDialog={openPinOrderDetailsDialog}
            sortData={sortData}
            setSortData={setSortData}
            theme={theme}
            onOpenDeleteModal={handleOpenDeleteModal}
          />
        </CardBody>
      </div>

      <PinOrderDetailsDialog
        isOpenDetailsModal={isOpenModalDetails}
        setIsOpenDetailsModal={setIsOpenModalDetails}
        data={pinBatchOrders?.pinBatchOrderDetails}
        generateAlert={generateAlert}
        theme={theme}
      />
      <CreatePinOrderModal
        isOpenModal={pinOrderModalOpen}
        handleCloseModal={handleCloseModal}
        generateAlert={generateAlert}
        reloadData={reloadData}
        theme={theme}
      />
      <DeletePinOrderModal
        orderRef={deletePinOrderId}
        onCloseModal={handleCloseDeleteModal}
        generateAlert={generateAlert}
        reloadData={reloadData}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  pinBatchOrders: state.pinBatchOrders
})

export default connect(mapStateToProps, actions)(PinOrder)
