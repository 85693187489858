import React, { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Pagination } from '../../../../../_metronic/_partials/controls'
import * as columnFormatters from './column-formatters'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../../../../_metronic/_helpers'
import { Icon } from '@material-ui/core'
import { useSelector } from 'react-redux'

const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
]

const defaultSorted = [{ dataField: 'login', order: 'asc' }]
const UserManagementTable = ({
  filters,
  cx,
  updateFilters,
  theme,
  userManagementData,
  setIsOpenCreateModal,
  openEditUserModal,
  isOpenEditModal,
  handleOpenDeleteModal,
  hasPermissionToDelete
}) => {
  const isLoadingUserDetails = useSelector(state => state.userManagement.userDetails.isLoading)
  const INIT_DATA = { totalCount: 0, count: 0, entities: [], listLoading: false }
  const INIT_COLUMNS = [
    {
      dataField: 'userId',
      text: 'ID',
      hidden: true
    },
    {
      dataField: 'username',
      text: 'Login',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'surname',
      text: 'Surname',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'createdDate',
      text: 'Creation Date',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        isOpenEditModal: isOpenEditModal,
        openEditUserModal: openEditUserModal,
        isLoadingUserDetails: isLoadingUserDetails,
        theme,
        hasPermissionToDelete: hasPermissionToDelete,
        handleOpenDeleteModal: handleOpenDeleteModal
      },
      align: 'flex-end',
      hidden: false,
      headerStyle: () => {
        return { whiteSpace: 'nowrap' }
      },
      style: {
        whiteSpace: 'nowrap'
      }
    }
  ]
  const [data, setData] = useState(INIT_DATA)
  const { totalCount, count, entities, listLoading, maxTransactionCount, exportSize } = data

  useEffect(() => {
    let data = userManagementData.data
    let count = 0
    let transList = []

    if (data?.users && data?.users.length) {
      transList = data.users
      count = data?.maxTransactionCount ? data.maxTransactionCount : +data.totalOfRecords
      for (let i = 0; i < transList.length; i++) {
        transList[i].id = i + 1
      }
    }
    setData({
      count: count,
      totalCount: data?.totalOfRecords,
      entities: transList,
      listLoading: userManagementData.isLoading,
      maxTransactionCount: data?.maxTransactionCount
    })
  }, [userManagementData.data])

  const customTotal = (from, to, size) => {
    let infoCallBack = 'Showing ' + from + ' to ' + to
    let exportShowing = null
    if (exportSize) {
      exportShowing = 'Export available up to ' + exportSize + ' records'
    }
    if (maxTransactionCount && size > 0) {
      if (maxTransactionCount && totalCount <= maxTransactionCount) {
        infoCallBack += ' of ' + totalCount + ' entries'
      } else {
        infoCallBack += ', limit up to ' + maxTransactionCount + ' of ' + totalCount + ' records'
      }
    } else {
      infoCallBack += ' of ' + size + ' entries'
    }
    return (
      <span className="react-bootstrap-table-pagination-total">
        {infoCallBack} <br />
        {exportShowing ? exportShowing : ''}
      </span>
    )
  }

  const paginationOptions = {
    custom: true,
    totalSize: count,
    sizePerPageList: sizePerPageList,
    sizePerPage: filters.pageSize,
    page: filters.pageIndex,
    paginationTotalRenderer: customTotal
  }

  const getHandlerTableChange = () => {
    return (type, { page, sizePerPage, sortField, sortOrder, data }) => {
      const pageNumber = page || 1
      let updatedFilters =
        type === 'sort'
          ? { ...filters, orderBy: sortField, orderType: sortOrder }
          : type === 'pagination'
          ? { ...filters, pageIndex: pageNumber, pageSize: sizePerPage }
          : filters
      updateFilters(updatedFilters)
    }
  }

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <ToolkitProvider keyField="id" data={entities || []} columns={INIT_COLUMNS} columnToggle>
                {props => {
                  return (
                    <div>
                      <div>
                        <div className={cx('page-title')}>
                          <p>User List</p>
                        </div>
                      </div>
                      <div className={cx('function-button-content')}>
                        <div>
                          <button
                            type="button"
                            className={cx('add-new-button', 'btn', 'btn-primary', 'btn-primary-custom')}
                            onClick={() => setIsOpenCreateModal(true)}
                          >
                            New User
                            <Icon className={cx('add-new-icon')} color="action">
                              add_circle
                            </Icon>
                          </button>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses={cx('table-responsive')}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        bordered={false}
                        remote
                        keyField="id"
                        data={entities === null ? [] : entities}
                        columns={INIT_COLUMNS}
                        defaultSorted={defaultSorted}
                        onTableChange={getHandlerTableChange()}
                        rowClasses={theme === 'Dark' ? cx('row-dark') : undefined}
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                      {!listLoading && (
                        <>
                          <PleaseWaitMessage entities={entities} />
                          <NoRecordsFoundMessage entities={entities} />
                        </>
                      )}
                    </div>
                  )
                }}
              </ToolkitProvider>
            </Pagination>
          )
        }}
      </PaginationProvider>
    </>
  )
}

export default UserManagementTable
