export const currencyFormat = num => {
  return num
    ? parseFloat(num)
        .toFixed(2)
        .replace(',', '.')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : '0.00'
}

export const formatValueWithPrefixAndSuffix = (prefix, num, suffix) => {
  let value = ''
  let valueFormat = num
    ? parseFloat(num)
        .toFixed(2)
        .replace(',', '.')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    : '0.00'
  return value.concat(prefix, valueFormat, suffix)
}
