import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers'
import Spinner from 'react-bootstrap/Spinner'

const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { theme, hasPermissionToDelete, onOpenModal, onOpenDeleteModal, openCreditLimitDetailsData, intl }
) => {
  return (
    <>
      <a
        key={1}
        onClick={() => {
          onOpenModal({ modal: 'DETAILS_MODAL', isOpen: true, data: row.limitInfo.templateCode, rowIndex: rowIndex })
        }}
        style={{ minWidth: '40px', marginRight: '5px' }}
        className={`btn btn-icon btn-${theme === 'Dark' ? 'dark' : 'light'} btn-hover-danger btn-sm`}
      >
        {openCreditLimitDetailsData?.rowIndex === rowIndex && openCreditLimitDetailsData?.isLoading ? (
          <div>
            <Spinner animation="border" role="status">
              <span className="sr-only">{intl.formatMessage({ id: 'LABEL.LOADING' })}</span>
            </Spinner>
          </div>
        ) : (
          <span className={`svg-icon svg-icon-md' ${theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white'}`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Visible.svg')} />
          </span>
        )}
      </a>
      {hasPermissionToDelete && (
        <a
          onClick={() =>
            onOpenDeleteModal({
              isOpen: true,
              data: { templateName: row.limitInfo.templateName, templateCode: row.limitInfo.templateCode }
            })
          }
          key={2}
          style={{ minWidth: '40px' }}
          className={`btn btn-icon btn-${theme === 'Dark' ? 'dark' : 'light'} btn-hover-danger btn-sm`}
        >
          <span className={`svg-icon svg-icon-md ${theme === 'Light' ? 'svg-icon-danger ' : 'svg-icon-white'}`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Trash.svg')} />
          </span>
        </a>
      )}
    </>
  )
}

export default ActionsColumnFormatter
