import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { addUser } from '../../redux/userManagementCrud'
import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { checkHasPermission } from '../../../_helper/MenuHelper'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
const CreateUserModal = ({
  generateAlert,
  getUserManagementLoading,
  searchFilters,
  handleCloseModal,
  isOpenModal,
  cx,
  theme,
  intl
}) => {
  const { user } = useSelector(state => state.auth)
  const initialValues = {
    login: '',
    password: '',
    repeatPassword: '',
    description: '',
    emailAddress: '',
    phoneNumber: '',
    firstName: '',
    surname: '',
    position: '',
    resetPassword: false,
    refundVoucherPermission: false,
    validatePinPermission: false,
    redeemVoucherPermission: false
  }

  const createUsersSchema = Yup.object().shape({
    login: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*^\S*$)(?=.*[!@#&()?[\{\}\]\:\;\'\,\?\/\*\~$^\+\=\<\>]).{8,50}$/,
        'Password must be 8-50 characters long and contain at least one upper case letter, one lower case letter, one numeric character, and one special character with no spaces.'
      ),
    repeatPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
      .oneOf([Yup.ref('password'), null], "Repeat password doesn't match"),
    description: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    emailAddress: Yup.string().email('Wrong email format'),
    phoneNumber: Yup.string()
      .trim()
      .matches(/^(\+|\d)[0-9]{3,20}$/, intl.formatMessage({ id: 'INVALID.PHONE.NUMBER' })),
    firstName: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    ),
    surname: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD'
      })
    )
  })

  const createUserFormik = useFormik({
    initialValues: initialValues,
    validationSchema: createUsersSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      addUser(values)
        .then(res => {
          const { resultCode, resultDescription } = res.data
          if (resultCode === 0) {
            generateAlert('success', 'Create User', resultDescription)
            handleCloseModal()
            getUserManagementLoading(searchFilters)
            resetForm()
          } else {
            generateAlert('danger', 'Create User', resultDescription)
          }
        })
        .catch(error => {
          generateAlert('danger', 'Create User', error.message)
        })
        .finally(data => {
          setSubmitting(false)
        })
    }
  })

  const handleCloseCreateModal = () => {
    if (createUserFormik.isSubmitting) return
    createUserFormik.resetForm()
    handleCloseModal()
  }
  return (
    <>
      <Modal
        show={isOpenModal}
        onHide={handleCloseCreateModal}
        centered
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Create User</Modal.Title>
          <Button onClick={handleCloseCreateModal} variant="secondary">
            x
          </Button>
        </Modal.Header>
        <Modal.Body className={cx('modal-body')}>
          <form
            onSubmit={createUserFormik.handleSubmit}
            className={cx('form fv-plugins-bootstrap fv-plugins-framework', 'space-evelyn')}
          >
            <ul className={cx('user-create-container')}>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Login"
                    type="text"
                    name="login"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.login}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(createUserFormik.touched.login && createUserFormik.errors.login)}
                    helperText={(createUserFormik.touched.login && createUserFormik.errors.login) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Password"
                    type="password"
                    name="password"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.password}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    error={Boolean(createUserFormik.touched.password && createUserFormik.errors.password)}
                    helperText={(createUserFormik.touched.password && createUserFormik.errors.password) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Repeat Password"
                    type="password"
                    name="repeatPassword"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.repeatPassword}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    error={Boolean(createUserFormik.touched.repeatPassword && createUserFormik.errors.repeatPassword)}
                    helperText={
                      (createUserFormik.touched.repeatPassword && createUserFormik.errors.repeatPassword) || ''
                    }
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Description"
                    type="text"
                    name="description"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.description}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(createUserFormik.touched.description && createUserFormik.errors.description)}
                    helperText={(createUserFormik.touched.description && createUserFormik.errors.description) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Email Address"
                    type="text"
                    name="emailAddress"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.emailAddress}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(createUserFormik.touched.emailAddress && createUserFormik.errors.emailAddress)}
                    helperText={(createUserFormik.touched.emailAddress && createUserFormik.errors.emailAddress) || ''}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.phoneNumber}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 20
                    }}
                    error={Boolean(createUserFormik.touched.phoneNumber && createUserFormik.errors.phoneNumber)}
                    helperText={(createUserFormik.touched.phoneNumber && createUserFormik.errors.phoneNumber) || ''}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="First name"
                    type="text"
                    name="firstName"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.firstName}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 64
                    }}
                    error={Boolean(createUserFormik.touched.firstName && createUserFormik.errors.firstName)}
                    helperText={(createUserFormik.touched.firstName && createUserFormik.errors.firstName) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Surname"
                    type="text"
                    name="surname"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.surname}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 64
                    }}
                    error={Boolean(createUserFormik.touched.surname && createUserFormik.errors.surname)}
                    helperText={(createUserFormik.touched.surname && createUserFormik.errors.surname) || ''}
                  />
                </FormControl>
                <span className={cx('required-symbol')}>*</span>
              </li>
              <li className={cx('user-create-item')}>
                <FormControl sx={{ m: 2 }} className={cx('width-90-percent')} variant="filled">
                  <TextField
                    label="Position"
                    type="text"
                    name="position"
                    onChange={createUserFormik.handleChange}
                    value={createUserFormik.values.position}
                    onBlur={createUserFormik.handleBlur}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' }
                    }}
                    inputProps={{
                      style: theme === 'Dark' ? undefined : { color: 'black' },
                      maxLength: 128
                    }}
                    error={Boolean(createUserFormik.touched.position && createUserFormik.errors.position)}
                    helperText={(createUserFormik.touched.position && createUserFormik.errors.position) || ''}
                  />
                </FormControl>
                <span className={cx('symbol')}>*</span>
              </li>
              <li className={cx('user-create-item', 'checkbox-custom')}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createUserFormik.values.resetPassword}
                      onChange={createUserFormik.handleChange}
                      name="resetPassword"
                      color="primary"
                    />
                  }
                  label="Change password on first use"
                  labelPlacement="end"
                />
              </li>
              <li className={cx('user-create-item', 'checkbox-custom')}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createUserFormik.values.refundVoucherPermission}
                      onChange={createUserFormik.handleChange}
                      name="refundVoucherPermission"
                      color="primary"
                    />
                  }
                  label="Refund Voucher Permission"
                  labelPlacement="end"
                />
              </li>
              {checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_R']) && (
                <li className={cx('user-create-item', 'checkbox-custom')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createUserFormik.values.validatePinPermission}
                        onChange={createUserFormik.handleChange}
                        name="validatePinPermission"
                        color="primary"
                      />
                    }
                    label="Validate Pin Permission"
                    labelPlacement="end"
                  />
                </li>
              )}
              {checkHasPermission(user.acls, ['SCP.EVDS.PRODUCT.REDEMPTION_W']) && (
                <li className={cx('user-create-item', 'checkbox-custom')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createUserFormik.values.redeemVoucherPermission}
                        onChange={createUserFormik.handleChange}
                        name="redeemVoucherPermission"
                        color="primary"
                      />
                    }
                    label="Redeem Voucher Permission"
                    labelPlacement="end"
                  />
                </li>
              )}
            </ul>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <button
                onClick={handleCloseCreateModal}
                type="button"
                className={cx(
                  'button',
                  'button-block',
                  'btn-secondary',
                  'btn',
                  `btn-${theme === 'Dark' ? 'dark' : 'secondary-custom'}`,
                  'btn-lg'
                )}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className={cx('button', 'button-block', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
              >
                <span>Add</span>
                {createUserFormik.isSubmitting && (
                  <span style={{ marginRight: '10px' }} className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default injectIntl(CreateUserModal)
