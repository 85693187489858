import React, { useLayoutEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import classNames from 'classnames/bind'
import style from '../../../../reports.module.css'
import { FormControl, MenuItem, Select, Checkbox, OutlinedInput, FormControlLabel } from '@material-ui/core'
const cx = classNames.bind(style)
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { sendMail } from '../../../../redux/reportsCrud'
import { SUCCESS_RESULT_CODE } from '../../../../../../../utils/Constants'
import Form from 'react-bootstrap/Form'
import * as Yup from 'yup'
import { getInputClasses } from '../../../../../../../utils/ValidationUtils'
import { getTransactionExportLimit } from '../../../../../../../redux/appCrud'

export default function SendMailDialog({ show, onHide, filters, generateAlert }) {
  const { user } = useSelector(state => state.auth)
  const intl = useIntl()
  const initialSendMailValue = {
    fileType: intl.formatMessage({ id: 'TRANSACTION.OPTION.EXCEL' }),
    email: user?.emailAddress
  }
  const [exportLimit, setExportLimit] = useState(1000)
  useLayoutEffect(() => {
    getTransactionExportLimit()
      .then(resp => {
        const { resultCode, exportLimit } = resp.data
        if (resultCode === SUCCESS_RESULT_CODE) {
          setExportLimit(exportLimit)
        }
      })
      .catch(error => {})
  }, [])

  const convertFilters = filters => {
    if (filters.subDivision && filters.agentCode === '') {
      filters.agentCode = filters.subDivision
    }
    filters.pageIndex = 1
    filters.ignoreReturnDate = false
    delete filters.subDivision
    delete filters.salesReports
    return filters
  }

  const sendMailSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'TRANSACTION.LABEL.WRONG_EMAIL_FORMAT'
        })
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD'
        })
      )
  })

  const formik = useFormik({
    initialValues: initialSendMailValue,
    validationSchema: sendMailSchema,
    onSubmit: (values, { setSubmitting }) => {
      const payload = convertFilters({ ...filters, ...values })

      sendMail(payload)
        .then(resp => {
          const { resultCode, resultDescription } = resp.data
          if (resultCode != SUCCESS_RESULT_CODE) {
            generateAlert(
              'danger',
              intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_HEADER_TITLE' }),
              resultDescription
            )
          } else {
            generateAlert(
              'success',
              intl.formatMessage({ id: 'TRANSACTION.LABEL.YOUR_REQUEST_WAS_SUCCESSFUL' }),
              intl.formatMessage({ id: 'TRANSACTION.LABEL.WAITING_INFOR_SEND_MAIL' })
            )
          }
          onHide()
        })
        .catch(error => {
          generateAlert(
            'danger',
            intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_HEADER_TITLE' }),
            intl.formatMessage({ id: 'TRANSACTION.NETWORK.ERROR_MESSAGE' })
          )
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  })

  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))

  const fileTypesData = [
    {
      key: 'EXCEL',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.EXCEL' })
    },
    {
      key: 'CSV',
      value: intl.formatMessage({ id: 'TRANSACTION.OPTION.CSV' })
    }
  ]

  return (
    <Modal centered size="small" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title> {intl.formatMessage({ id: 'TRANSACTION.LABEL.SEND_EMAIL' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          <div className="row ">
            <div className="col-sm-12 my-2">
              <label>
                <b> {intl.formatMessage({ id: 'TRANSACTION.LABEL.SEND_TO' })}</b>
              </label>

              <div className={cx('input-group')}>
                <Form.Control
                  disabled={formik.isSubmitting}
                  size="lg"
                  type="text"
                  className={getInputClasses(formik, 'email')}
                  name="email"
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                )}
              </div>
            </div>

            <div className="col-sm-12 my-2">
              <label>
                <b> {intl.formatMessage({ id: 'TRANSACTION.LABEL.FILE_TYPE' })}</b>
              </label>

              <FormControl
                style={{ marginTop: '-2px' }}
                size="small"
                variant="outlined"
                margin="normal"
                className={cx('width-100-percent')}
              >
                <Select
                  onChange={e => formik.setFieldValue('fileType', e.target.value)}
                  value={formik.values.fileType}
                  input={<OutlinedInput name="fileType" id="fileType" />}
                >
                  {fileTypesData.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.key}>
                        {item.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-sm-12 my-2" style={{ fontSize: '11px' }}>
              {intl.formatMessage(
                {
                  id: 'TRANSACTION.INFOR.SENDING_MAIL_INFOR'
                },
                {
                  exportLimit: (
                    <React.Fragment key="unique-key-for-export-limit">
                      <b>{exportLimit}</b>
                    </React.Fragment>
                  )
                }
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className={cx('modal-footer-actions')}>
          <button
            onClick={onHide}
            style={{ marginRight: '5px' }}
            className={cx(
              'button',
              'btn',
              'btn-secondary',
              'btn-lg',
              theme === 'Dark' ? 'btn-dark' : 'btn-secondary-custom'
            )}
          >
            {intl.formatMessage({ id: 'LABEL.CANCEL' })}
          </button>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            style={{ marginRight: '5px' }}
            className={cx('button', 'btn', 'btn-primary', 'btn-lg', 'btn-primary-custom')}
          >
            {intl.formatMessage({ id: 'LABEL.SEND' })}
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
