import { merchantApiClient } from '../../../../redux'

export const VALIDATE_VOUCHER_URL = '/api/v1/voucher/validate/'

export const REDEEM_VOUCHER_URL = '/api/v1/voucher/redeem'

export const validateVoucherProduct = pin => {
  return merchantApiClient.get(VALIDATE_VOUCHER_URL + pin)
}

export const redeemVoucherProduct = pin => {
  return merchantApiClient.post(REDEEM_VOUCHER_URL, { pin })
}
