import React from 'react'
import Chart from 'react-apexcharts'

const DailyCurrencyChart = ({ transactions, currencies, theme }) => {
  let state = {
    series: [...transactions],
    options: {
      chart: {
        id: 'daily-currency',
        type: 'pie',
        width: '100%',
        foreColor: theme === 'Dark' && '#bebfc2'
      },
      labels: [...currencies],
      noData: {
        text: 'No Transaction',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: theme === 'Dark' ? '#bebfc2' : '#3f4253',
          fontSize: '20px'
        }
      }
    }
  }

  return (
    <Chart
      options={state.options}
      series={state.series}
      type={state.options?.chart.type}
      width={state.options?.chart.width}
    />
  )
}

export default DailyCurrencyChart
