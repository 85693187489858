import React, { useEffect, useMemo, useState, useContext } from 'react'
import { TemplateContext } from '../../_core/TemplateProvider'
import { Link } from 'react-router-dom'
import objectPath from 'object-path'
import SVG from 'react-inlinesvg'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../_helpers'
import { useSelector } from 'react-redux'
import { useLayoutEffect } from 'react'
import { BASE64_IMAGE_PREFIX } from '../../../../utils/Constants'

export function Brand() {
  const uiService = useHtmlClassService()

  const [logo, setLogo] = useState(toAbsoluteUrl('/media/logos/logo-light-mode.png'))

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    }
  }, [uiService])
  const { theme } = useSelector(state => ({
    theme: state.themeMode?.themeMode?.theme
  }))
  const templateContext = useContext(TemplateContext)

  useLayoutEffect(() => {
    let favicon
    let base64Logo
    if (templateContext) {
      if (theme == 'Dark') {
        base64Logo = templateContext.brandLogoDark
          ? BASE64_IMAGE_PREFIX + templateContext.brandLogoDark
          : toAbsoluteUrl('/media/logos/logo-dark-mode.png')

        favicon = templateContext.faviconLogoDark
          ? `url(${BASE64_IMAGE_PREFIX + templateContext.faviconLogoDark})`
          : `url('/media/logos/favicon.ico')`
      } else {
        base64Logo = templateContext.brandLogoLight
          ? BASE64_IMAGE_PREFIX + templateContext.brandLogoLight
          : toAbsoluteUrl('/media/logos/logo-light-mode.png')
        favicon = templateContext.faviconLogoLight
          ? `url(${BASE64_IMAGE_PREFIX + templateContext.faviconLogoLight})`
          : `url('/media/logos/favicon.ico')`
      }
      setLogo(base64Logo)
    }
    document.documentElement.style.setProperty('--favicon', favicon)
  }, [theme])

  return (
    <>
      {/* begin::Brand */}
      <div className={`brand flex-column-auto ${layoutProps.brandClasses}`} id="kt_brand">
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img alt="logo" src={logo} />
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-double-left.svg')} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  )
}
